<template>
	<Listbox
		as="div"
		v-model="selectedOption"
		v-slot="{ open }"
		class="h-12 relative focus:outline-none outline-none"
	>
		<ListboxLabel
			v-if="this.label"
			class="mb-1 font-medium text-gray-900 dark:text-gray-300 text-sm focus:outline-none outline-none"
		>
			{{ this.label }}
		</ListboxLabel>
		<ListboxButton
			as="button"
			v-on:blur="$emit('onBlur')"
			v-slot="{ active }"
			class="flex w-full rounded-md focus:outline-none outline-none"
		>
			<span
				v-on:blur="handleFocusOut"
				class="px-3 h-12 w-full flex items-center justify-between bg-white text-gray-800 text-left text-sm focus:outline-none outline-none rounded border border-gray-300"
				:class="
					active || open
						? 'focus:outline-none outline-none'
						: 'focus:outline-none outline-none'
				"
			>
				{{ selectedOption.name }}
				<svg class="w-5 h-5 text-gray-800" viewBox="0 0 20 20" fill="currentColor">
					<path
						fill-rule="evenodd"
						d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
						clip-rule="evenodd"
					/>
				</svg>
			</span>
		</ListboxButton>
		<Transition
			enter-active-class="transition duration-100"
			enter-from-class="opacity-0"
			enter-to-class="opacity-100"
			leave-active-class="transition duration-300"
			leave-from-class="opacity-100"
			leave-to-class="opacity-0"
		>
			<ListboxOptions
				class="max-h-56 mt-1 py-1 w-full absolute overflow-y-auto focus:outline-none outline-none shadow border rounded-md bg-white z-10"
			>
				<ListboxOption
					v-for="option in opts"
					:key="option.id"
					:value="option"
					:disabled="option.unavailable"
					v-slot="{ selected, active }"
					class="focus:outline-none outline-none"
				>
					<div
						class="cursor-pointer px-3 py-2 relative bg-white focus:outline-none outline-none"
						:class="{
							'bg-gray-800 text-white': active,
							'bg-gray-100 text-gray-400': option.unavailable
						}"
					>
						{{ option.name }}
						<span
							v-show="selected && !option.unavailable"
							class="pr-2 flex items-center absolute inset-y-0 right-0"
						>
							<svg
								class="w-5 h-5"
								:class="active ? 'text-white' : 'text-gray-700'"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fill-rule="evenodd"
									d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
									clip-rule="evenodd"
								/>
							</svg>
						</span>
					</div>
				</ListboxOption>
			</ListboxOptions>
		</Transition>
		<div v-if="error" class="mt-1 text-sm text-red-700">{{ error }}</div>
	</Listbox>
</template>

<script>
import {
	Listbox,
	ListboxButton,
	ListboxOptions,
	ListboxLabel,
	ListboxOption
} from "@headlessui/vue"

export default {
	name: "Select",
	emits: ["onChange", "onBlur"],
	props: {
		opts: {
			type: Array,
			required: true
		},
		error: String,
		label: {
			type: String
		},
		select: {
			type: Number,
			default: 0
		}
	},
	components: {
		Listbox,
		ListboxButton,
		ListboxOptions,
		ListboxLabel,
		ListboxOption
	},

	data() {
		const selectedOption = this.opts[this.select]

		return {
			selectedOption
		}
	},

	watch: {
		selectedOption: function(val, oldVal) {
			this.$emit("onChange", val)
		},
		select: function(nv, ov) {
			this.selectedOption = this.opts[nv]
		}
	}
}
</script>
