<template>
  <section
    class="p-3 pt-40 2xl:pt-32 pb-20 min-h-screen flex items-center justify-center bg-gray-50"
  >
    <div
      class="w-full max-w-screen-lg  md:grid md:grid-cols-2 overflow-hidden rounded-xl shadow-md"
    >
      <div class="hidden md:block relative overflow-hidden">
        <img
          class="absolute inset-0 z-10 w-full h-full object-cover"
          src="../assets/authenticate-bg.jpg"
          alt="authentication background image"
        />

        <div class="absolute inset-x-0 bottom-0 z-20">
          <h1 class="pb-5 text-whites font-medium text-center text-white">
            Looks like your are new here!
          </h1>
        </div>

        <div
          class="absolute inset-y-0 right-0 z-20 flex flex-col justify-center items-end space-y-2"
        >
          <router-link
            to="/login"
            class=" w-20 rounded-l-full p-2 pl-3 text-sm font-medium text-white focus:outline-none cursor-pointer"
          >
            Sign in
          </router-link>
          <router-link
            to="/register"
            class=" w-20 rounded-l-full p-2 pl-3 text-sm font-medium bg-white text-gray-900  focus:outline-none cursor-pointer"
          >
            Sign up
          </router-link>
        </div>
      </div>

      <div class="p-5 px-8 bg-white flex flex-col items-center justify-center">
        <h1 class="block w-full text-left text-xl font-semibold text-gray-900">
          Create an account
        </h1>

        <form
          @submit.prevent="handleRegister"
          novalidate
          name="form"
          class="mt-6 w-full flex flex-col flex-grow-0"
        >
          <div class="flex flex-col md:flex-row  justify-between gap-2">
            <div class="h-24 w-full">
              <label
                for="first_name"
                class="text-medium font-medium text-gray-900 text-sm"
                >First name</label
              >
              <input
                id="first_name"
                name="firstName"
                @input="handleChange"
                @blur="setTouched"
                @focus="checkError"
                v-model="firstName.value"
                type="text"
                placeholder="First name"
                :class="{ 'ring-2 ring-red-700': errors.firstName }"
                class="mt-1 border block w-full border-gray-300 rounded-md px-3 py-2 text-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500"
              />
              <h3
                v-if="errors.firstName && firstName.isTouched"
                class="text-xs text-red-700 mt-1"
              >
                {{ errors.firstName }}
              </h3>
            </div>

            <div class="h-24 w-full">
              <label
                for="last_name"
                class="text-medium font-medium text-gray-900 text-sm"
                >Last name</label
              >
              <input
                id="last_name"
                name="lastName"
                @input="handleChange"
                @blur="setTouched"
                @focus="checkError"
                v-model="lastName.value"
                type="text"
                placeholder="Last name"
                :class="{ 'ring-2 ring-red-700': errors.lastName }"
                class="mt-1 border block w-full border-gray-300 rounded-md px-3 py-2 text-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500"
              />
              <h3
                v-if="errors.lastName && lastName.isTouched"
                class="text-xs text-red-700 mt-1"
              >
                {{ errors.lastName }}
              </h3>
            </div>
          </div>

          <div class="mt-1 flex flex-col md:flex-row justify-between gap-2">
            <div class="h-24 w-full">
              <div class="flex justify-start gap-2 w-full">
                <label
                  for="username"
                  class="text-medium font-medium text-gray-900 text-sm"
                  >Username</label
                >
                <Loader v-if="getUserNameLoading" :bright="true" />
                <svg
                  v-if="
                    userName.value.length > 0 &&
                      !errors.userName &&
                      !getIsUserNameExist &&
                      !getUserNameLoading
                  "
                  name="valid"
                  class="w-5 h-5 text-green-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
                <svg
                  v-if="getIsUserNameExist && !getUserNameLoading"
                  name="invalid"
                  class="h-5 w-5 text-red-700"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <input
                id="username"
                name="userName"
                @input="handleChange"
                @blur="setTouched"
                @focus="checkError"
                v-model="userName.value"
                type="text"
                placeholder="User name"
                :class="{ 'ring-2 ring-red-700': errors.userName }"
                class="mt-1 border block w-full border-gray-300 rounded-md px-3 py-2 text-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500"
              />
              <h3
                v-if="errors.userName && userName.isTouched"
                class="text-xs text-red-700 mt-1"
              >
                {{ errors.userName }}
              </h3>
            </div>

            <div class="md:-mt-1 h-24 w-full">
              <label
                for="email"
                class="text-medium font-medium text-gray-900 text-sm"
                >Email</label
              >
              <input
                id="email"
                name="email"
                @input="handleChange"
                @blur="setTouched"
                @focus="checkError"
                v-model="email.value"
                novalidate
                type="email"
                placeholder="Email address"
                :class="{ 'ring-2 ring-red-700': errors.email }"
                class="mt-1 border block w-full border-gray-300 rounded-md px-3 py-2 text-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500"
              />
              <h3
                v-if="errors.email && email.isTouched"
                class="text-xs text-red-700 mt-1"
              >
                {{ errors.email }}
              </h3>
            </div>
          </div>

          <div class="mt-1 flex flex-col md:flex-row justify-between gap-2">
            <div class="h-24 w-full ">
              <label
                for="password"
                class="text-medium font-medium text-gray-900 text-sm"
                >Password</label
              >
              <input
                id="password"
                name="password"
                @input="handleChange"
                @blur="setTouched"
                @focus="checkError"
                v-model="password.value"
                type="password"
                placeholder="Password"
                :class="{ 'ring-2 ring-red-700': errors.password }"
                class="mt-1 border block w-full border-gray-300 rounded-md px-3 py-2 text-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500"
              />
              <h3
                v-if="errors.password && password.isTouched"
                class="text-xs text-red-700 mt-1"
              >
                {{ errors.password }}
              </h3>
            </div>

            <div class="h-24 w-full">
              <label
                for="confirm_password"
                class="text-medium font-medium text-gray-900 text-sm "
                >Confirm password</label
              >
              <input
                id="confirm_password"
                name="confirmPassword"
                @input="handleChange"
                @blur="setTouched"
                @focus="checkError"
                v-model="confirmPassword.value"
                type="password"
                placeholder="confirm password"
                :class="{ 'ring-2 ring-red-700': errors.confirmPassword }"
                class="mt-1 border block w-full border-gray-300 rounded-md px-3 py-2 text-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500"
              />
              <h3
                v-if="errors.confirmPassword && confirmPassword.isTouched"
                class="text-xs text-red-700 mt-1"
              >
                {{ errors.confirmPassword }}
              </h3>
            </div>
          </div>

          <div class="mt-3 flex items-center justify-between">
            <div class="flex items-start">
              <input
                id="remember_me"
                name="remember_me"
                type="checkbox"
                v-model="subscribe"
                class="h-4 w-4 focus:outline-none text-red-500 border-gray-300 rounded-md"
              />
              <label
                for="remember_me"
                class="ml-2 flex flex-col cursor-pointer"
              >
                <span class="text-sm font-bold text-gray-900">{{
                  "Send me tips, trends,freebies,updates & offers"
                }}</span>
                <span class="text-xs text-gray-400"
                  >You can unsubscribe at any time</span
                >
              </label>
            </div>
          </div>

          <button
            type="submit"
            :class="{ 'pointer-events-none cursor-wait': getAuthLoading }"
            class="mt-5 px-2 py-2 md:px-3 md:py-2 font-semibold text-center flex items-center justify-center text-white text-xs md:text-sm bg-buttonColor transition-colors duration-300 hover:bg-pink-700 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2 focus:ring-offset-white"
          >
            <Loader v-if="getAuthLoading" />
            <span v-if="!getAuthLoading">Create your account</span>
          </button>
          <router-link
            to="/login"
            class="md:hidden text-center w-full mt-3 text-blue-800 text-sm group"
            >already a member?
            <span class="text-gray-900 group-hover:underine"
              >Sign in</span
            ></router-link
          >

          <h2 class="mt-2 text-gray-500 text-xs text-center">
            Vooaxis collects and uses personal data in accordance with our
            <span class="text-gray-800 underline">
              <router-link to="#">
                Privacy Policy
              </router-link>
            </span>
            By creating an account, you agree to our
            <span class="text-gray-800 underline"
              ><router-link to="#">Terms and Conditions</router-link></span
            >
          </h2>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "../components/utils/Loader.vue";
export default {
  name: "Register",
  components: { Loader },

  computed: {
    ...mapGetters(["getAuthLoading"]),
    ...mapGetters(["getUserNameLoading"]),
    ...mapGetters(["getRegisterError"]),
    ...mapGetters(["getIsUserNameExist"]),
  },

  methods: {
    ...mapActions(["register"]),
    ...mapActions(["checkForUserName"]),

    checkError(e) {
      if (e.target.name === "form") {
        this.firstName.isTouched = true;
        this.lastName.isTouched = true;
        this.userName.isTouched = true;
        this.email.isTouched = true;
        this.password.isTouched = true;
        this.confirmPassword.isTouched = true;

        if (this.firstName.value.length <= 0) {
          this.errors.firstName = "First name is required";
        } else {
          this.errors.firstName = null;
        }

        if (this.lastName.value.length <= 0) {
          this.errors.lastName = "Last name is required";
        } else {
          this.errors.lastName = null;
        }

        const userNameRegEx = /^[a-zA-Z0-9]+$/;
        if (this.userName.value.length <= 0) {
          this.errors.userName = "User name is required";
        } else if (!userNameRegEx.exec(this.userName.value)) {
          this.errors.userName = "User name can only contain letters";
        } else if ((this, this.getIsUserNameExist)) {
          this.errors.userName = "User name already taken";
        } else {
          this.errors.userName = null;
        }

        const regExEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (this.email.value.length <= 0) {
          this.errors.email = "email is required";
        } else if (!regExEmail.exec(this.email.value)) {
          this.errors.email = "Invalid email";
        } else {
          this.errors.email = null;
        }

        const regEx = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        if (this.password.value.length <= 0) {
          this.errors.password = "Password is required";
        } else if (this.password.value.length < 8) {
          this.errors.password =
            "This field must have a minimum of 8 characters";
        } else {
          this.errors.password = null;
        }

        if (this.confirmPassword.value.length <= 0) {
          this.errors.confirmPassword = "confirm password is required";
        } else if (this.confirmPassword.value.length < 8) {
          this.errors.confirmPassword =
            "This field must have a minimum of 8 characters";
        } else {
          this.errors.confirmPassword = null;
        }
      } else {
        let field = e.target.name;
        let value = e.target.value;

        if (field === "firstName") {
          if (value.length <= 0) {
            this.errors.firstName = "First name is required";
          } else {
            this.errors.firstName = null;
          }
        }

        if (field === "lastName") {
          if (value.length <= 0) {
            this.errors.lastName = "Last name is required";
          } else {
            this.errors.lastName = null;
          }
        }

        if (field === "userName") {
          const regEx = /^[a-zA-Z0-9]+$/;
          if (value.length <= 0) {
            this.errors.userName = "User name is required";
          }
          if (!regEx.exec(value)) {
            this.errors.userName = "User name can only contain letters";
          } else if (this.getIsUserNameExist) {
            this.errors.userName = "User name already taken";
          } else {
            this.errors.userName = null;
          }
        }

        if (field === "email") {
          const regEx = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
          if (value.length <= 0) {
            this.errors.email = "email is required";
          } else if (!regEx.exec(value)) {
            this.errors.email = "Invalid email";
          } else {
            this.errors.email = null;
          }
        }

        if (field === "password") {
          const regEx = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
          if (value.length <= 0) {
            this.errors.password = "Password is required";
          } else if (value.length < 8) {
            this.errors.password =
              "This field must have a minimum of 8 characters";
          } else {
            this.errors.password = null;
          }
        }

        if (field === "confirmPassword") {
          const regEx = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
          if (value.length <= 0) {
            this.errors.confirmPassword = "confirm password is required";
          } else if (value.length < 8) {
            this.errors.confirmPassword =
              "This field must have a minimum of 8 characters";
          } else {
            this.errors.confirmPassword = null;
          }
        }
      }
    },
    setTouched(e) {
      const field = e.target.name;
      this[field].isTouched = true;
    },
    handleChange(e) {
      this.checkError(e);

      //check for username availability
      if (e.target.name === "userName") {
        this.checkForUserName(e.target.value);
      }
    },

    gtag_report_conversion_register(url) {
      var callback = function() {
        if (typeof url != "undefined") {
          window.location = url;
        }
      };
      gtag("event", "conversion", {
        send_to: "AW-642724947/bFt9CLfEkuwBENPovLIC",
        event_callback: callback,
      });
      return false;
    },

    handleRegister(e) {
      this.checkError(e);
      if (
        !this.errors.firstName &&
        !this.errors.lastName &&
        !this.errors.email &&
        !this.errors.userName &&
        !this.errors.password &&
        !this.errors.confirmPassword
      ) {
        if (this.password.value !== this.confirmPassword.value) {
          this.errors.confirmPassword = "Password didnt match! try again";
        } else {
          const userData = {
            firstName: this.firstName.value,
            lastName: this.lastName.value,
            userName: this.userName.value,
            email: this.email.value,
            password: this.password.value,
            confirmPassword: this.confirmPassword.value,
            newsletter: this.subscribe,
          };
          this.gtag_report_conversion_register();
          this.register(userData);
        }
      }
    },
  },

  data() {
    return {
      subscribe: false,

      firstName: {
        value: "",
        isTouched: false,
      },

      lastName: {
        value: "",
        isTouched: false,
      },

      userName: {
        value: "",
        isTouched: false,
      },

      email: {
        value: "",
        isTouched: false,
      },

      password: {
        value: "",
        isTouched: false,
      },

      confirmPassword: {
        value: "",
        isTouched: false,
      },

      errors: {
        firstName: null,
        lastName: null,
        userName: null,
        email: null,
        password: null,
        confirmpassword: null,
      },
    };
  },

  watch: {
    getRegisterError: function(nv, ov) {
      this.errors = nv;
    },
  },
};
</script>
