import { v4 as uuid } from "uuid";
import axios from "axios";

const state = {
  subscriberHomepage: {
    audios: [
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
    ],
    videos: [
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
    ],
  },
  searchSubscriberHomepage: {},
  myDownloads: [],
  singleProduct: {},
  freeProducts: [
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
  ],
  products: [
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
    {
      id: "",
      user: { id: "", userName: "" },
      previewUrl: "",
      thumbnailUrl: "",
      updatedAt: "",
      productName: "",
    },
  ],

  allItems: [
    {
      title: "After Effects Presets",
      subTitle:
        "After Effects Presets help you create the perfect video project.",
      products: [
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
      ],
    },
    {
      title: "After Effects Templates",
      subTitle: "Ready-to-use and easily customizable After Effects Templates.",
      products: [
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
      ],
    },
    {
      title: "Premiere Pro Templates",
      subTitle:
        "Our video templates help you create the perfect styles for video editors.",
      products: [
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
      ],
    },
    {
      title: "Stock Footage",
      subTitle:
        "Our stock footage help you create the perfect styles for video editors.",
      products: [
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
      ],
    },
    {
      title: "Photo",
      subTitle:
        "Our Photo help you create the perfect styles for video editors.",
      products: [
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
      ],
    },
    {
      title: "Premiere Pro Presets",
      subTitle:
        "Premiere Pro Presets help you create the perfect video project.",
      products: [
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
      ],
    },
    {
      title: "Royalty Free Music",
      subTitle:
        "logo & idents music tracks from our royalty free music library.",
      products: [
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
      ],
    },
    {
      title: "Sound Effects",
      subTitle: "Sound Effects for Videos, Apps, Films, and Games",
      products: [
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
      ],
    },
    {
      title: "Stock Motion Graphics",
      subTitle:
        "4K stock videos, HD clips, Animated Backgrounds for your video editing.",
      products: [
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
      ],
    },
    {
      title: "Motion Graphics Templates",
      subTitle:
        "Projects, lower thirds and transitions to add to your presentations.",
      products: [
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
      ],
    },
  ],

  allItemsCount: [],

  totalPages: 0,

  currentPage: 1,

  count: null,

  categories: [
    {
      id: uuid(),
      name: "Add Ons",
      value: "addOns",
      unavailable: false,
      fields: ["subCategory", "resolution", "plugin"],
    },
    {
      id: uuid(),
      name: "All Items",
      value: "allItems",
      unavailable: false,
      fields: ["subCategory", "resolution", "plugin"],
    },
    {
      id: uuid(),
      name: "After Effects Templates",
      value: "afterEffectsTemplates",
      unavailable: false,
      fields: ["subCategory", "resolution", "plugin", "version"],
    },
    {
      id: uuid(),
      name: "After Effects Presets",
      value: "afterEffectsPresets",
      unavailable: false,
      fields: ["subCategory", "resolution", "plugin", "version"],
    },
    {
      id: uuid(),
      name: "Premiere Pro Templates",
      value: "premiereProTemplates",
      unavailable: false,
      fields: ["subCategory", "resolution", "plugin", "version"],
    },
    {
      id: uuid(),
      name: "Premiere Pro Presets",
      value: "premiereProPresets",
      unavailable: false,
      fields: ["subCategory", "resolution", "plugin", "version"],
    },
    {
      id: uuid(),
      name: "Motion Graphics Templates",
      value: "motionGraphicsTemplates",
      unavailable: false,
      fields: ["subCategory", "resolution", "plugin", "version", "trackLength"],
    },
    {
      id: uuid(),
      name: "Stock Footage",
      value: "stockFootage",
      unavailable: false,
      fields: [
        "subCategory",
        "resolution",
        "alphaChannel",
        "frameRate",
        "looped",
      ],
    },
    {
      id: uuid(),
      name: "Photo",
      value: "photo",
      unavailable: false,
      fields: ["subCategory", "background"],
    },
    {
      id: uuid(),
      name: "Royalty Free Music",
      value: "royaltyFreeMusic",
      unavailable: false,
      fields: [
        "subCategory",
        "tempo",
        "vocal",
        "looped",
        "affiliation",
        "trackLength",
      ],
    },
    {
      id: uuid(),
      name: "Sound Effects",
      value: "soundEffects",
      unavailable: false,
      fields: ["subCategory", "looped", "trackLength"],
    },
    {
      id: uuid(),
      name: "Stock Motion Graphics",
      value: "stockMotionGraphics",
      unavailable: false,
      fields: [
        "subCategory",
        "resolution",
        "frameRate",
        "looped",
        "editorial",
        "trackLength",
      ],
    },
  ],

  background: [
    {
      id: uuid(),
      name: "Isolated",
      value: "isolated",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "Blurred",
      value: "blurred",
      unavailable: false,
    },
  ],

  vocals: [
    {
      id: 1,
      name: "Background Vocals",
      unavailable: false,
    },
    {
      id: 2,
      name: "Vocal Samples",
      unavailable: false,
    },
    {
      id: 3,
      name: "Instrumental Included",
      unavailable: false,
    },
    {
      id: 4,
      name: "Lead Vocals",
      unavailable: false,
    },
    {
      id: 5,
      name: "Male Vocals",
      unavailable: false,
    },
    {
      id: 6,
      name: "Female Vocals",
      unavailable: false,
    },
  ],

  compressions: [
    {
      id: uuid(),
      name: "Animation",
      value: "Animation",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "Animation + Alpha",
      value: "Animation + Alpha",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "H.264",
      value: "H.264",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "H.265",
      value: "H.265",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "ProRes 422",
      value: "ProRes 422",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "ProRes 4444",
      value: "ProRes 4444",
      unavailable: false,
    },
  ],

  frameRates: [
    {
      id: uuid(),
      name: "12 FPS",
      value: "12 FPS",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "18 FPS",
      value: "18 FPS",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "20 FPS",
      value: "20 FPS",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "22 FPS",
      value: "22 FPS",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "23.97 FPS",
      value: "23.97 FPS",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "24 FPS",
      value: "24 FPS",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "25 FPS",
      value: "25 FPS",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "29.97 FPS",
      value: "29.97 FPS",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "30 FPS",
      value: "30 FPS",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "50 FPS",
      value: "50 FPS",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "59.94 FPS",
      value: "59.94 FPS",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "60 FPS",
      value: "60 FPS",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "120 FPS",
      value: "120 FPS",
      unavailable: false,
    },
  ],

  versions: {
    afterEffectsTemplates: [
      {
        id: uuid(),
        name: "After Effects CC 2020",
        value: "After Effects CC 2020",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "After Effects CC 2019",
        value: "After Effects CC 2019",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "After Effects CC 2018",
        value: "After Effects CC 2018",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "After Effects CC 2017",
        value: "After Effects CC 2017",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "After Effects CC 2015",
        value: "After Effects CC 2015",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "After Effects CS6",
        value: "After Effects CS6",
        unavailable: false,
      },
    ],

    afterEffectsPresets: [
      {
        id: uuid(),
        name: "After Effects CC 2020",
        value: "After Effects CC 2020",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "After Effects CC 2019",
        value: "After Effects CC 2019",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "After Effects CC 2018",
        value: "After Effects CC 2018",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "After Effects CC 2017",
        value: "After Effects CC 2017",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "After Effects CC 2015",
        value: "After Effects CC 2015",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "After Effects CS6",
        value: "After Effects CS6",
        unavailable: false,
      },
    ],

    premiereProTemplates: [
      {
        id: uuid(),
        name: "Premiere CC 2020",
        value: "Premiere CC 2020",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Premiere CC 2019",
        value: "Premiere CC 2019",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Premiere CC 2018",
        value: "Premiere CC 2018",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Premiere CC 2017.1",
        value: "Premiere CC 2017.1",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Premiere CC 2017",
        value: "Premiere CC 2017",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Premiere CC 2015.3",
        value: "Premiere CC 2015.3",
        unavailable: false,
      },
    ],

    premiereProPresets: [
      {
        id: uuid(),
        name: "Premiere CC 2020",
        value: "Premiere CC 2020",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Premiere CC 2019",
        value: "Premiere CC 2019",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Premiere CC 2018",
        value: "Premiere CC 2018",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Premiere CC 2017.1",
        value: "Premiere CC 2017.1",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Premiere CC 2017",
        value: "Premiere CC 2017",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Premiere CC 2015.3",
        value: "Premiere CC 2015.3",
        unavailable: false,
      },
    ],

    motionGraphicsTemplates: [
      {
        id: uuid(),
        name: "Premiere CC 2020",
        value: "Premiere CC 2020",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Premiere CC 2019",
        value: "Premiere CC 2019",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Premiere CC 2018",
        value: "Premiere CC 2018",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Premiere CC 2017.1",
        value: "Premiere CC 2017.1",
        unavailable: false,
      },
    ],
  },

  plugins: [
    {
      id: 1,
      name: "Element 3D v1",
      value: "Element 3D v1",
      unavailable: false,
    },
    {
      id: 2,
      name: "Element 3D v2",
      value: "Element 3D v2",
      unavailable: false,
    },
    {
      id: 3,
      name: "Element 3D Pro Shaders",
      value: "Element 3D Pro Shaders",
      unavailable: false,
    },
    {
      id: 4,
      name: "Twitch",
      value: "Twitch",
      unavailable: false,
    },
    {
      id: 5,
      name: "Optical Flares",
      value: "Optical Flares",
      unavailable: false,
    },
    {
      id: 6,
      name: "Plexus",
      value: "Plexus",
      unavailable: false,
    },
    {
      id: 7,
      name: "Plexus 2",
      value: "Plexus 2",
      unavailable: false,
    },
    {
      id: 8,
      name: "Trapcode Particular",
      value: "Trapcode Particular",
      unavailable: false,
    },
    {
      id: 9,
      name: "Trapcode Form",
      value: "Trapcode Form",
      unavailable: false,
    },
    {
      id: 10,
      name: "Trapcode Shine",
      value: "Trapcode Shine",
      unavailable: false,
    },
    {
      id: 11,
      name: "Trapcode 3D Stroke",
      value: "Trapcode 3D Stroke",
      unavailable: false,
    },
    {
      id: 12,
      name: "Trapcode Starglow",
      value: "Trapcode Starglow",
      unavailable: false,
    },
    {
      id: 13,
      name: "Trapcode Mir",
      value: "Trapcode Mir",
      unavailable: false,
    },
    {
      id: 14,
      name: "Trapcode Lux",
      value: "Trapcode Lux",
      unavailable: false,
    },
    {
      id: 15,
      name: "Trapcode Sound Keys",
      value: "Trapcode Sound Keys",
      unavailable: false,
    },
    {
      id: 16,
      name: "Trapcode Horizon",
      value: "Trapcode Horizon",
      unavailable: false,
    },
    {
      id: 17,
      name: "Trapcode Echospace",
      value: "Trapcode Echospace",
      unavailable: false,
    },
    {
      id: 18,
      name: "Magic Bullet Looks",
      value: "Magic Bullet Looks",
      unavailable: false,
    },
    {
      id: 19,
      name: "Magic Bullet Colorista II",
      value: "Magic Bullet Colorista II",
      unavailable: false,
    },
    {
      id: 20,
      name: "Magic Bullet Colorista III",
      value: "Magic Bullet Colorista III",
      unavailable: false,
    },
    {
      id: 21,
      name: "Magic Bullet Mojo",
      value: "Magic Bullet Mojo",
      unavailable: false,
    },
    {
      id: 22,
      name: "Magic Bullet LUT Buddy",
      value: "Magic Bullet LUT Buddy",
      unavailable: false,
    },
    {
      id: 23,
      name: "Video Copilot SABER",
      value: "Video Copilot SABER",
      unavailable: false,
    },
    {
      id: 24,
      name: "Video Copilot ORB",
      value: "Video Copilot ORB",
      unavailable: false,
    },
    {
      id: 25,
      name: "Mettle SkyBox",
      value: "Mettle SkyBox",
      unavailable: false,
    },
    {
      id: 26,
      name: "Other",
      value: "Other",
      unavailable: false,
    },
  ],

  formats: [
    {
      id: 1,
      name: "MP3",
      value: "MP3",
      unavailable: false,
    },
    {
      id: 2,
      name: "WAV",
      value: "WAV",
      unavailable: false,
    },
  ],

  resolutions: [
    {
      id: uuid(),
      name: "4K (UHD)",
      value: "4K (UHD)",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "2K",
      value: "2K",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "1080p (Full HD)",
      value: "1080p (Full HD)",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "720p (HD)",
      value: "720p (HD)",
      unavailable: false,
    },
  ],

  subCategories: {
    afterEffectsTemplates: [
      {
        id: uuid(),
        name: "Broadcast Packages",
        value: "Broadcast Packages",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Elements",
        value: "Elements",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Infographics",
        value: "Infographics",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Logo Stings",
        value: "Logo Stings",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Openers",
        value: "Openers",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Product Promo",
        value: "Product Promo",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Titles",
        value: "Titles",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Video Displays",
        value: "Video Displays",
        unavailable: false,
      },
    ],

    premiereProTemplates: [
      {
        id: uuid(),
        name: "Broadcast Packages",
        value: "Broadcast Packages",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Elements",
        value: "Elements",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Infographics",
        value: "Infographics",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Logo Stings",
        value: "Logo Stings",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Openers",
        value: "Openers",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Product Promo",
        value: "Product Promo",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Titles",
        value: "Titles",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Video Displays",
        value: "Video Displays",
        unavailable: false,
      },
    ],

    afterEffectsPresets: [
      {
        id: uuid(),
        name: "Text",
        value: "Text",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Transitions",
        value: "Transitions",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Backgrounds",
        value: "Backgrounds",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Color",
        value: "Color",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Overlay",
        value: "Overlay",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Photo/Video",
        value: "Photo/Video",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Effects",
        value: "Effects",
        unavailable: false,
      },
    ],

    addOns: [
      {
        id: uuid(),
        name: "Text",
        value: "Text",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Transitions",
        value: "Transitions",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Backgrounds",
        value: "Backgrounds",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Color",
        value: "Color",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Overlay",
        value: "Overlay",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Photo/Video",
        value: "Photo/Video",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Effects",
        value: "Effects",
        unavailable: false,
      },
    ],

    premiereProPresets: [
      {
        id: uuid(),
        name: "Text",
        value: "Text",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Transitions",
        value: "Transitions",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Color",
        value: "Color",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Overlay",
        value: "Overlay",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Photo/Video",
        value: "Photo/Video",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "LUTs",
        value: "LUTs",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Effects",
        value: "Effects",
        unavailable: false,
      },
    ],

    motionGraphicsTemplates: [
      {
        id: uuid(),
        name: "Broadcast Packages",
        value: "Broadcast Packages",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Elements",
        value: "Elements",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Infographics",
        value: "Infographics",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Logo Stings",
        value: "Logo Stings",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Openers",
        value: "Openers",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Product Promo",
        value: "Product Promo",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Titles",
        value: "Titles",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Video Displays",
        value: "Video Displays",
        unavailable: false,
      },
    ],

    stockFootage: [
      {
        id: uuid(),
        name: "Buildings",
        value: "Buildings",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Business-corporate",
        value: "Business-corporate",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Cartoons",
        value: "Cartoons",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "City",
        value: "City",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Construction",
        value: "Construction",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Education",
        value: "Education",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Food",
        value: "Food",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Holidays",
        value: "Holidays",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Industrial",
        value: "Industrial",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Kids",
        value: "Kids",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Lifestyle",
        value: "Lifestyle",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Medical",
        value: "Medical",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Military",
        value: "Military",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Nature",
        value: "Nature",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Overhead",
        value: "Overhead",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "People",
        value: "People",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Religious",
        value: "Religious",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Science",
        value: "Science",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Slow-motion",
        value: "Slow-motion",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Special-events",
        value: "Special-events",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Sports",
        value: "Sports",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Stop-motion",
        value: "Stop-motion",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Technology",
        value: "Technology",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Time-lapse",
        value: "Time-lapse",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Vehicles",
        value: "Vehicles",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Weather",
        value: "Weather",
        unavailable: false,
      },
    ],

    photo: [
      {
        id: uuid(),
        name: "Landscape",
        value: "Landscape",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Potrait",
        value: "Potrait",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Square",
        value: "Square",
        unavailable: false,
      },
    ],

    stockMotionGraphics: [
      {
        id: uuid(),
        name: "Backgrounds",
        value: "Backgrounds",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Miscellaneous",
        value: "Miscellaneous",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Elements",
        value: "Elements",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Overlays",
        value: "Overlays",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Transitions",
        value: "Transitions",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Interface Effects",
        value: "Interface Effects",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Bugs",
        value: "Bugs",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Lower Thirds",
        value: "Lower Thirds",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Revealer",
        value: "Revealer",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Infographics",
        value: "Infographics",
        unavailable: false,
      },
    ],

    royaltyFreeMusic: [
      {
        id: uuid(),
        name: "Logos & Idents",
        value: "Logos-&-Idents",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Ambient",
        value: "Ambient",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Children's",
        value: "Children's",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Cinematic",
        value: "Cinematic",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Classical",
        value: "Classical",
        unavailable: false,
      },

      {
        id: uuid(),
        name: "Corporate",
        value: "Corporate",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Country, Western",
        value: "Country, Western",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Drum & Bass, Breakbeat",
        value: "Drum & Bass, Breakbeat",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Electronica",
        value: "Electronica",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Experimental, Abstract",
        value: "Experimental, Abstract",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Folk, Acoustic",
        value: "Folk, Acoustic",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Funk, Groove",
        value: "Funk, Groove",
        unavailable: false,
      },

      {
        id: uuid(),
        name: "Hip Hop",
        value: "Hip-Hop",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Holiday & Seasonal",
        value: "Holiday-&-Seasonal",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "House",
        value: "House",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Jazz",
        value: "Jazz",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Percussion",
        value: "Percussion",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Pop",
        value: "Pop",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Rock",
        value: "Rock",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Soul, R&B",
        value: "Soul, R&B",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Vocals, Voice",
        value: "Vocals, Voice",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "World Beat",
        value: "World Beat",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Miscellaneous",
        value: "Miscellaneous",
        unavailable: false,
      },
    ],

    soundEffects: [
      {
        id: uuid(),
        name: "Buttons & Menus",
        value: "Buttons & Menus",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Cartoon Sounds",
        value: "Cartoon Sounds",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Domestic Sounds",
        value: "Domestic Sounds",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Futuristic Sounds",
        value: "Futuristic Sounds",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Game Sounds",
        value: "Game Sounds",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Human Sounds",
        value: "Human Sounds",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Industrial Sounds",
        value: "Industrial Sounds",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Interface Sounds",
        value: "Interface Sounds",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Nature Sounds",
        value: "Nature Sounds",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Sound",
        value: "Sound",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Sound Packs",
        value: "Sound Packs",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Transitions & Movement",
        value: "Transitions & Movement",
        unavailable: false,
      },
      {
        id: uuid(),
        name: "Urban Sounds",
        value: "Urban Sounds",
        unavailable: false,
      },

      {
        id: uuid(),
        name: "Miscellaneous",
        value: "Miscellaneous",
        unavailable: false,
      },
    ],
  },

  bitRates: [
    {
      id: uuid(),
      name: "320 Kbps",
      value: "320 Kbps",
      unavailable: true,
    },
  ],

  sampleRates: [
    {
      id: uuid(),
      name: "16-Bit Stereo , 44.1 kHz",
      value: "16-Bit Stereos , 44.1 kHz",
      unavailable: true,
    },
  ],

  youtube: [
    {
      id: uuid(),
      name: "AdRev",
      value: "AdRev",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "CDBaby",
      value: "CDBaby",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "Epic Elite",
      value: "Epic Elite",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "HAAWK",
      value: "HAAWK",
      unavailable: false,
    },
    {
      id: uuid(),
      name: "Identifyy",
      value: "Identifyy",
      unavailable: false,
    },
  ],

  affiliation: [
    {
      id: uuid(),
      name: "Argentina SARDIC",
      value: "Argentina SARDIC",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Armenia ARMAUTHOR",
      value: "Armenia ARMAUTHOR",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Australia APRA",
      value: "Australia APRA",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Austria AKM",
      value: "Austria AKM",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Azerbaijan AAS",
      value: "Azerbaijan AAS",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Barbados COSCAP",
      value: "Barbados COSCAP",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Belgium SABAM",
      value: "Belgium SABAM",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Belize BSCAP",
      value: "Belize BSCAP",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Bolivia SOBODAYCOM",
      value: "Bolivia SOBODAYCOM",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Brazil ABRAMUS",
      value: "Brazil ABRAMUS",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Brazil ECAD",
      value: "Brazil ECAD",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Brazil UBC",
      value: "Brazil UBC",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Bulgaria Musicautor",
      value: "Bulgaria Musicautor",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Burkina Faso BBDA",
      value: "Burkina Faso BBDA",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Canada SOCAN",
      value: "Canada SOCAN",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Chile SCD",
      value: "Chile SCD",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "China MCSC",
      value: "China MCSC",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Colombia SAYCO",
      value: "Colombia SAYCO",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Costa Rica ACAM",
      value: "Costa Rica ACAM",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Croatia HDS",
      value: "Croatia HDS",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Czech Republic OSA",
      value: "Czech Republic OSA",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Denmark KODA",
      value: "Denmark KODA",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Dominican Republic SGACEDOM",
      value: "Dominican Republic SGACEDOM",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Eastern Caribbean ECCO",
      value: "Eastern Caribbean ECCO",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Ecuador SAYCE",
      value: "Ecuador SAYCE",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "El Salvador SACIM",
      value: "El Salvador SACIM",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Estonia EAU",
      value: "Estonia EAU",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Finland TEOSTO",
      value: "Finland TEOSTO",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "France SACEM",
      value: "France SACEM",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Georgian GCA",
      value: "Georgian GCA",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Germany GEMA",
      value: "Germany GEMA",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Greece AEPI",
      value: "Greece AEPI",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Guatemala AEI",
      value: "Guatemala AEI",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Honduras AACIMH",
      value: "Honduras AACIMH",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Hong Kong CASH",
      value: "Hong Kong CASH",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Hungary Artisjus",
      value: "Hungary Artisjus",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Iceland STEF",
      value: "Iceland STEF",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "India IPRS",
      value: "India IPRS",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Indonesia WAMI",
      value: "Indonesia WAMI",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Ireland IMRO",
      value: "Ireland IMRO",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Israel ACUM",
      value: "Israel ACUM",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Italy SIAE",
      value: "Italy SIAE",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Jamaica JACAP",
      value: "Jamaica JACAP",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Japan JASRAC",
      value: "Japan JASRAC",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Kazakhstan KazAK",
      value: "Kazakhstan KazAK",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Kenya MCSK",
      value: "Kenya MCSK",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Latvia AKKA/LAA",
      value: "Latvia AKKA/LAA",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Lithuania LATGA-A",
      value: "Lithuania LATGA-A",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Malaysia MACP",
      value: "Malaysia MACP",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Mexico SACM",
      value: "Mexico SACM",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Moldova ASDAC",
      value: "Moldova ASDAC",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Montenegro PAM CG",
      value: "Montenegro PAM CG",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Nepal MRCSN",
      value: "Nepal MRCSN",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Netherlands BUMA",
      value: "Netherlands BUMA",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "New Zealand APRA",
      value: "New Zealand APRA",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Nigeria COSON",
      value: "Nigeria COSON",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Norway TONO",
      value: "Norway TONO",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Panama SPAC",
      value: "Panama SPAC",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Paraguay APA",
      value: "Paraguay APA",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Peru APDAYC",
      value: "Peru APDAYC",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Philippines FILSCAP",
      value: "Philippines FILSCAP",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Poland ZAIKS",
      value: "Poland ZAIKS",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Portugal SPA",
      value: "Portugal SPA",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Republic of Macedonia ZAMP",
      value: "Republic of Macedonia ZAMP",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Romania UCMR-ADA",
      value: "Romania UCMR-ADA",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Russia RAO",
      value: "Russia RAO",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Serbia SOKOJ",
      value: "Serbia SOKOJ",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Singapore COMPASS",
      value: "Singapore COMPASS",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Slovak Republic SOZA",
      value: "Slovak Republic SOZA",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Slovenia SAZAS",
      value: "Slovenia SAZAS",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "South Africa SAMRO",
      value: "South Africa SAMRO",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "South Korea KOMCA",
      value: "South Korea KOMCA",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "South Korea KOSCAP",
      value: "South Korea KOSCAP",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Spain SGAE",
      value: "Spain SGAE",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Sweden STIM",
      value: "Sweden STIM",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Switzerland SUISA",
      value: "Switzerland SUISA",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Thailand MCT",
      value: "Thailand MCT",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Trinidad & Tobago COTT",
      value: "Trinidad & Tobago COTT",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Turkey MESAM",
      value: "Turkey MESAM",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Turkey MSG",
      value: "Turkey MSG",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Uganda UPRS",
      value: "Uganda UPRS",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Ukraine UACRR",
      value: "Ukraine UACRR",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "United Kingdom PRS",
      value: "United Kingdom PRS",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "United States ASCAP",
      value: "United States ASCAP",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "United States BMI",
      value: "United States BMI",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "United States SESAC",
      value: "United States SESAC",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Uruguay AGADU",
      value: "Uruguay AGADU",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Venezuela SACVEN",
      value: "Venezuela SACVEN",
      unavailable: false,
    },

    {
      id: uuid(),
      name: "Vietnam VCPMC",
      value: "Vietnam VCPMC",
      unavailable: false,
    },
  ],

  tempo: [
    {
      name: "Very Slow (Below 60 BPM)",
      value: "very-slow-(below-60-bpm)",
      operator: "<60",
    },
    {
      name: "Slow (60 - 90 BPM)",
      value: "slow-(60-90-bpm)",
      operator: ">60<90",
    },
    {
      name: "Medium (90 - 110 BPM)",
      value: "medium-(90-110-bpm)",
      operator: ">90<110",
    },
    {
      name: "Upbeat (110 - 140 BPM)",
      value: "upbeat-(110-140-bpm)",
      operator: ">110<140",
    },
    {
      name: "Fast (140 - 160 BPM)",
      value: "upbeat-(140-160-bpm)",
      operator: ">140<160",
    },
    {
      name: "Very Fast (160+ BPM)",
      value: "very-fast-(above-160-bpm)",
      operator: ">160",
    },
  ],
  similarProduct: [],
  authorSimilarProduct: [],
  downloadLoading: false,
  isDownloaded: false,
  sampleProducts: [],
};

const getters = {
  getSampleProducts: (state) => state.sampleProducts,
  getSubscriberHomepage: (state) => state.subscriberHomepage,
  getSearchSubscriberHomepage: (state) => state.searchSubscriberHomepage,
  getIsDownloaded: (state) => state.isDownloaded,
  getDownloadLoading: (state) => state.downloadLoading,
  getMyDownloads: (state) => state.myDownloads,
  getFreeProducts: (state) => state.freeProducts,
  getSingleProduct: (state) => state.singleProduct,
  getAuthorSimilarProduct: (state) => state.authorSimilarProduct,
  getSimilarProduct: (state) => state.similarProduct,
  getAllItems: (state) => state.allItems,
  getAllItemsCount: (state) => state.allItemsCount,
  getCount: (state) => state.count,
  getCurrentPage: (state) => state.currentPage,
  getTotalPages: (state) => state.totalPages,
  getProducts: (state) => state.products,
  getCategories: (state) => state.categories,
  getSubCategories: (state) => state.subCategories,
  getResolutions: (state) => state.resolutions,
  getPlugins: (state) => state.plugins,
  getVersions: (state) => state.versions,
  getCompressions: (state) => state.compressions,
  getFrameRates: (state) => state.frameRates,
  getFormats: (state) => state.formats,
  getBitRates: (state) => state.bitRates,
  getTempo: (state) => state.tempo,
  getSampleRates: (state) => state.sampleRates,
  getVocals: (state) => state.vocals,
  getYoutube: (state) => state.youtube,
  getAffiliation: (state) => state.affiliation,
};

const actions = {
  async fetchSampleProducts({ commit }) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_BASE_URI}/product/sample-products`
      );

      commit("setSampleProducts", data);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchRecentSearch({ commit }) {
    try {
      const query = localStorage.getItem("search");
      if (query) {
        const { data } = await axios.get(
          `${process.env.VUE_APP_BASE_URI}/product/search-subscriber-homepage/${query}`
        );
        commit("setSearchSubscriberHomepage", data);
      }
    } catch (error) {
      console.log(error);
    }
  },

  async fetchSubscriberHomepage({ commit }, type) {
    try {
      commit("setSubscriberHomepageDefault");
      const { data } = await axios.get(
        `${process.env.VUE_APP_BASE_URI}/product/subscriber-homepage/${type}`
      );
      commit("setSubscriberHomepage", data);
    } catch (error) {
      console.log(error);
    }
  },

  async downloadPreview({ commit, rootState }, params) {
    try {
      if (rootState.auth.currentUser) {
        commit("setDownloadLoading", true);
        commit("setIsDownloaded", false);

        const { data } = await axios.post(
          `${process.env.VUE_APP_BASE_URI}/buy/download-preview`,
          {
            id: params.id,
          }
        );

        const hiddenElement = document.createElement("a");

        hiddenElement.href = data;
        hiddenElement.target = "_blank";
        hiddenElement.click();
        commit("setDownloadLoading", false);
        commit("setIsDownloaded", true);
      } else {
        commit("setAlert", {
          id: Math.floor(Math.random() * 10000),
          title: "Login required",
          sub: "Please login to download preview",
          icon: "bi:info-circle-fill",
          type: "warning",
          login: true,
        });
        params.router.push("/login");
      }
    } catch (error) {
      console.log(error);
      commit("setDownloadLoading", false);
    }
  },

  async downloadFreeProduct({ commit }, id) {
    try {
      commit("setDownloadLoading", true);
      commit("setIsDownloaded", false);
      const { data } = await axios.post(
        `${process.env.VUE_APP_BASE_URI}/buy/download-free`,
        {
          id,
        }
      );

      const hiddenElement = document.createElement("a");

      hiddenElement.href = data;
      hiddenElement.target = "_self";
      hiddenElement.click();

      const download = await axios.get(
        `${process.env.VUE_APP_BASE_URI}/downloaded/get-downloaded-products/1`
      );
      commit("setMyDownloads", download.data.product);

      if (state.currentPage !== download.data.pages) {
        commit("setCurrentPage", download.data.pages);
        commit("setTotalPages", download.data.pages);
      }

      commit("setDownloadLoading", false);
      commit("setIsDownloaded", true);
    } catch (error) {
      console.log(error);
      commit("setDownloadLoading", false);
    }
  },

  async downloadProduct({ commit }, params) {
    try {
      commit("setDownloadLoading", true);
      commit("setIsDownloaded", false);
      const { data } = await axios.post(
        `${process.env.VUE_APP_BASE_URI}/buy/download`,
        {
          type: params.type,
          id: params.id,
          license: params.license,
        }
      );

      if (params.type === "download") {
        const hiddenElement = document.createElement("a");

        hiddenElement.href = data;
        hiddenElement.target = "_self";
        hiddenElement.click();
      }

      if (params.page) {
        const download = await axios.get(
          `${process.env.VUE_APP_BASE_URI}/downloaded/get-downloaded-products/${params.page}`
        );
        commit("setMyDownloads", download.data.product);
      }

      commit("setDownloadLoading", false);
      commit("setIsDownloaded", true);
    } catch (error) {
      console.log(error);
      commit("setDownloadLoading", false);
    }
  },

  async fetchDownloads({ commit }, page) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_BASE_URI}/downloaded/get-downloaded-products/${page}`
      );
      commit("setMyDownloads", data.product);

      commit("setTotalPages", data.pages);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchFreeProduct({ commit }, page) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_BASE_URI}/product/get-free-products/${page}`
      );
      commit("setFreeProducts", data.rows);

      if (state.currentPage !== data.pages) {
        commit("setCurrentPage", data.pages);
        commit("setTotalPages", data.pages);
      }
    } catch (error) {
      console.log(error);
    }
  },

  async fetchAuthorSimilarProduct({ commit }, params) {
    try {
      const product = await axios.post(
        `${process.env.VUE_APP_BASE_URI}/product/get-author-similar-product`,
        { category: params.category, id: params.userId }
      );
      const updatedData = product.data.filter((d) => d.id !== params.id);
      commit("setAuthorSimilarProduct", updatedData);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchSimilarProduct({ commit }, params) {
    try {
      const product = await axios.post(
        `${process.env.VUE_APP_BASE_URI}/product/get-similar-product`,
        { tags: params.tags, category: params.category }
      );
      const updatedData = product.data.filter((d) => d.id !== params.id);
      commit("setSimilarProduct", updatedData);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchSingleProduct({ commit }, id) {
    try {
      commit("setSingleProduct", {});
      const product = await axios.get(
        `${process.env.VUE_APP_BASE_URI}/product/get-single-product/${id}`
      );
      commit("setSingleProduct", product.data);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchProducts({ commit, state }, params) {
    commit("setProductsDefault");

    if (params.category !== "all items") {
      /* build object for count */
      const countObj = {};
      const category = state.categories.filter(
        (cat) => cat.name.toLowerCase() === params.category.toLowerCase()
      )[0];
      category.fields.forEach((field) => {
        if (params.minLength && params.maxLength) {
          countObj.minLength = params.minLength;
          countObj.maxLength = params.maxLength;
        }
        if (params.resolution) {
          countObj.resolution = params.resolution;
        }
        if (field === "subCategory") {
          countObj.subCategories = state.subCategories[category.value].map(
            (val) => val.name
          );
        }
        if (field === "vocal") {
          countObj.vocals = state.vocals.map((val) => val.name);
        }
        if (field === "tempo") {
          countObj.tempo = state.tempo.map((val) => val.value);
        }
        if (field === "looped") {
          countObj.looped = true;
        }
        if (field === "isolated") {
          countObj.isolated = true;
        }
        if (field === "blurred") {
          countObj.blurred = true;
        }
        if (field === "editorial") {
          countObj.editorial = true;
        }
        if (field === "alphaChannel") {
          countObj.alphaChannel = true;
        }
        if (field === "affiliation") {
          countObj.affiliation = true;
        }
        if (field === "plugin") {
          countObj.plugin = true;
        }

        if (field === "frameRate") {
          countObj.frameRate = state.frameRates.map((val) => val.value);
        }
      });

      let queryArray = [];

      let queryObj = "";

      if (params.resolution) {
        queryArray.push(`&resolution=${params.resolution}`);
      }
      if (params.plugins) {
        queryArray.push(`&plugins=${params.plugins}`);
      }
      if (params.vocal) {
        queryArray.push(`&vocal=${params.vocal}`);
      }
      if (params.tempo) {
        queryArray.push(`&tempo=${params.tempo}`);
      }
      if (params.frameRate) {
        queryArray.push(`&frameRate=${params.frameRate}`);
      }
      if (params.minLength) {
        queryArray.push(`&minLength=${params.minLength}`);
      }
      if (params.maxLength) {
        queryArray.push(`&maxLength=${params.maxLength}`);
      }
      if (params.looped) {
        queryArray.push(`&looped=${params.looped}`);
      }
      if (params.isolated) {
        queryArray.push(`&isolated=${params.isolated}`);
      }
      if (params.blurred) {
        queryArray.push(`&blurred=${params.blurred}`);
      }
      if (params.editorial) {
        queryArray.push(`&editorial=${params.editorial}`);
      }
      if (params.alphaChannel) {
        queryArray.push(`&alphaChannel=${params.alphaChannel}`);
      }
      if (params.affiliation) {
        queryArray.push(`&affiliation=${params.affiliation}`);
      }
      if (params.search) {
        queryArray.push(`&search=${params.search}`);
      }
      if (params.sortBy) {
        queryArray.push(`&sortBy=${params.sortBy}`);
      }

      queryObj = queryArray.join("");

      const { data } = await axios.get(
        `${process.env.VUE_APP_BASE_URI}/product/published-product-home/${
          params.category
        }/${params.subCategory}/${params.page}?${queryObj && queryObj}
				`
      );
      commit("setProducts", data.rows);
      if (state.currentPage !== data.pages) {
        commit("setCurrentPage", data.pages);
        commit("setTotalPages", data.pages);
      }

      const count = await axios.post(
        `${process.env.VUE_APP_BASE_URI}/product/count-by-category/${params.category}?sortBy=${params.sortBy}`,
        countObj
      );
      commit("setCount", { ...count.data, mainCount: data.count });
    } else if (params.category === "all items") {
      const { data } = await axios.get(
        `${process.env.VUE_APP_BASE_URI}/product/published-product-home/${
          params.category
        }?${params.search && `&search=${params.search}`}`
      );

      const count = await axios.post(
        `${process.env.VUE_APP_BASE_URI}/product/count-by-category/${params.category}`
      );

      if (!params.search) {
        const tempArray = [];
        data.forEach((obj) => {
          state.allItems.forEach((stateItem) => {
            if (stateItem.title === obj.title) {
              let updObj = stateItem;
              updObj = { ...updObj, ...obj };
              tempArray.push(updObj);
            }
          });
        });
        commit("setAllItems", tempArray);
        commit("setAllItemsCount", count.data);
      } else {
        //commit("setProducts", data.rows)
        const tempArray = [];
        data.data.forEach((obj) => {
          state.allItems.forEach((stateItem) => {
            if (stateItem.title === obj.title) {
              let updObj = stateItem;
              updObj = { ...updObj, ...obj };
              tempArray.push(updObj);
            }
          });
        });
        commit("setAllItems", tempArray);
        commit("setAllItemsCount", { mainCount: data.mainCount });
      }
    }
  },

  setToDefault({ commit }) {
    commit("setProductsDefault");
  },
};

const mutations = {
  setSampleProducts: (state, val) => (state.sampleProducts = val),
  setSearchSubscriberHomepage: (state, val) =>
    (state.searchSubscriberHomepage = val),
  setSubscriberHomepage: (state, val) => (state.subscriberHomepage = val),
  setIsDownloaded: (state, val) => (state.isDownloaded = val),
  setDownloadLoading: (state, val) => (state.downloadLoading = val),
  setMyDownloads: (state, val) => (state.myDownloads = val),
  setFreeProducts: (state, val) => (state.freeProducts = val),
  setProducts: (state, val) => (state.products = val),
  setSubscriberHomepageDefault: (state) => {
    state.subscriberHomepage = {
      audios: [
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
      ],
      videos: [
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
        {
          id: "",
          user: { id: "", userName: "" },
          previewUrl: "",
          thumbnailUrl: "",
          updatedAt: "",
          productName: "",
        },
      ],
    };
  },
  setProductsDefault: (state) =>
    (state.products = [
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
      {
        id: "",
        user: { id: "", userName: "" },
        previewUrl: "",
        thumbnailUrl: "",
        updatedAt: "",
        productName: "",
      },
    ]),
  setCurrentPage: (state, val) => (state.currentPage = val),
  setTotalPages: (state, val) => (state.totalPages = val),
  setCount: (state, val) => (state.count = val),
  setAllItems: (state, val) => (state.allItems = val),
  setAllItemsCount: (state, val) => (state.allItemsCount = val),
  setSingleProduct: (state, val) => (state.singleProduct = val),
  setSimilarProduct: (state, val) => (state.similarProduct = val),
  setAuthorSimilarProduct: (state, val) => (state.authorSimilarProduct = val),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
