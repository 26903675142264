import axios from "axios";
const state = {
  searchQuery: "",
  searchSelectedOption: "",
  suggestions: [],
  cancelToken: undefined,
};

const getters = {
  //test
  getSuggestions: (state) => state.suggestions,
  searchQuery: (state) => state.searchQuery,
  searchSelectedOption: (state) => state.searchSelectedOption,
};

const actions = {
  async fetchSuggestions({ commit, state }, params) {
    try {
      if (typeof state.cancelToken != typeof undefined) {
        state.cancelToken.cancel("cancelling the previous request");
      }

      commit("setCancelToken", axios.CancelToken.source());

      let array = [];
      const {
        data,
      } = await axios.post(
        `${process.env.VUE_APP_BASE_URI}/product/suggestion`,
        params,
        { cancelToken: state.cancelToken.token }
      );
      array = data.map((d) => d.productName);
      commit("setSuggestions", array);
    } catch (error) {
      console.log(error);
    }
  },

  setSearchQuery({ commit }, search) {
    commit("setSearchQueryState", search);
  },
  setSearchOption({ commit }, option) {
    commit("setSearchOptionState", option);
  },
};

const mutations = {
  setCancelToken: (state, val) => (state.cancelToken = val),
  setSuggestions: (state, val) => (state.suggestions = val),
  setSearchQueryState: (state, searchQuery) =>
    (state.searchQuery = searchQuery),
  setSearchOptionState: (state, searchSelectedOption) =>
    (state.searchSelectedOption = searchSelectedOption),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
