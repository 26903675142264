import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import VerifyEmail from "../views/VerifyEmail.vue";
import ResetPassword from "../views/ResetPassword.vue";
import SingleItem from "../views/SingleItem.vue";
import Pricing from "../views/Pricing.vue";
import MyDownloads from "../views/MyDownloads.vue";
import MyCollections from "../views/MyCollections.vue";
import SingleCollection from "../views/SingleCollection.vue";
import ContributorProfile from "../views/ContributorProfile.vue";
import AfterEffectsTemplates from "../views/AfterEffectsTemplates.vue";
import PremiereProTemplates from "../views/PremiereProTemplates.vue";
import MotionGraphicTemplates from "../views/MotionGraphicTemplates.vue";
import StockMotionGraphics from "../views/StockMotionGraphics.vue";
import StockFootages from "../views/StockFootages.vue";
import Photo from "../views/Photo.vue";
import RoyaltyFreeMusic from "../views/RoyaltyFreeMusic.vue";
import SoundEffects from "../views/SoundEffects.vue";
import AddOns from "../views/AddOns.vue";
import AfterEffectsPresets from "../views/AfterEffectsPresets.vue";
import PremiereProPresets from "../views/PremiereProPresets.vue";
import AllItems from "../views/AllItems.vue";
import License from "../views/License.vue";
import FreeFiles from "../views/FreeFiles.vue";
import Account from "../views/Account.vue";
import Checkout from "../views/Checkout.vue";
import BecomeAuthor from "../views/BecomeAuthor.vue";
import About from "../views/About.vue";
import UploadRules from "../views/UploadRules.vue";
import UserTerms from "../views/UserTerms.vue";

const routes = [
  {
    path: "/",
    name: "General",
    component: Home,
  },
  {
    path: "/user-terms",
    name: "user terms",
    component: UserTerms,
  },

  {
    path: "/upload-rules",
    name: "uploadRules",
    component: UploadRules,
  },

  {
    path: "/checkout/:plan",
    name: "checkout",
    component: Checkout,
  },

  {
    path: "/pricing",
    name: "pricing",
    component: Pricing,
  },

  {
    path: "/license",
    name: "license",
    component: License,
  },

  {
    path: "/account/:upgrade?",
    name: "account",
    component: Account,
  },

  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: ForgotPassword,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/become-an-author",
    name: "become author",
    component: BecomeAuthor,
  },

  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/verify-my-email/:token",
    name: "verifyEmail",
    component: VerifyEmail,
  },
  {
    path: "/reset-password/:token",
    name: "resetPassword",
    component: ResetPassword,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },

  {
    path: "/downloads",
    name: "downloads",
    component: MyDownloads,
    meta: {
      blocked: true,
    },
  },

  {
    path: "/collection/:id",
    name: "single collections",
    component: SingleCollection,
    meta: {
      blocked: true,
    },
  },

  {
    path: "/collections",
    name: "collections",
    component: MyCollections,
    meta: {
      blocked: true,
    },
  },

  {
    path: "/free",
    name: "free",
    component: FreeFiles,
  },

  {
    path: "/user/:userName/:profileCategory?/:page?",
    name: "profile",
    component: ContributorProfile,
  },

  {
    path:
      "/item/:name?/:id/:subCategory?/:resolution?/:plugin?/:search?/:sort?/:page?",
    name: "single product",
    component: SingleItem,
  },

  {
    path: "/all-items",
    name: "all items",
    component: AllItems,
  },
  {
    path:
      "/after-effects-templates/:subCategory?/:resolution?/:plugin?/:search?/:sort?/:page?",
    name: "after effects templates",
    component: AfterEffectsTemplates,
  },
  {
    path:
      "/premiere-pro-templates/:subCategory?/:resolution?/:plugin?/:search?/:sort?/:page?",
    name: "premiere pro templates",
    component: PremiereProTemplates,
  },
  {
    path:
      "/motion-graphics-templates/:subCategory?/:resolution?/:plugin?/:search?/:sort?/:page?",
    name: "motion graphics templates",
    component: MotionGraphicTemplates,
  },
  {
    path:
      "/stock-motion-graphics/:subCategory?/:frameRate?/:minLength?/:maxLength?/:looped?/:editorial?/:search?/:sort?/:page?",
    name: "stock motion graphics",
    component: StockMotionGraphics,
  },
  {
    path:
      "/stock-footage/:subCategory?/:resolution?:frameRate?/:minLength?/:maxLength?/:looped?/:alphaChannel?/:search?/:sort?/:page?",
    name: "stock footage",
    component: StockFootages,
  },
  {
    path: "/photo/:subCategory?/:background?/:search?/:sort?/:page?",
    name: "photo",
    component: Photo,
  },
  {
    path:
      "/royalty-free-music/:subCategory?/:looped?/:affiliation?/:vocal?/:tempo?/:minLength?/:maxLength?/:search?/:sort?/:page?",
    name: "royalty free music",
    component: RoyaltyFreeMusic,
  },
  {
    path:
      "/sound-effects/:subCategory?/:looped?/:affiliation?/:vocal?/:tempo?/:minLength?/:maxLength?/:search?/:sort?/:page?",
    name: "sound effects",
    component: SoundEffects,
  },
  {
    path: "/add-ons/:subCategory?/:resolution?/:plugin?/:search?/:sort?/:page?",
    name: "add ons",
    component: AddOns,
  },
  {
    path:
      "/after-effects-presets/:subCategory?/:resolution?/:plugin?/:search?/:sort?/:page?",
    name: "after effects presets",
    component: AfterEffectsPresets,
  },
  {
    path:
      "/premiere-pro-presets/:subCategory?/:resolution?/:plugin?/:search?/:sort?/:page?",
    name: "premiere pro presets",
    component: PremiereProPresets,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const user =
  // navigation guard to check for logged in users
  router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((user) => user.meta.requiresAuth);

    const token = localStorage.getItem("token");

    if (requiresAuth && token) {
      next("/");
    } else {
      next();
    }
  });

const blocked =
  // navigation guard to check for logged in users
  router.beforeEach((to, from, next) => {
    const blocked = to.matched.some((user) => user.meta.blocked);

    const token = localStorage.getItem("token");

    if (blocked) {
      if (token) {
        next();
      } else {
        next("/");
      }
    } else {
      next();
    }
  });

export default router;
