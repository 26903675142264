<template>
  <div
    v-if="title && products && id"
    class="
      relative
      min-h-collectionCardHeight
      w-full
      border border-gray-300
      rounded-md
      p-5
      bg-white
    "
  >
    <div class="flex items-center justify-between">
      <h1 class="text-sm font-medium text-gray-800">{{ title }}</h1>
      <Menu
        as="div"
        class="mt-1 relative focus:outline-none outline-none"
        v-slot="{ open }"
      >
        <MenuButton class="focus:outline-none outline-none">
          <EffectButton
            id="menuBtn"
            ref="menuBtn"
            :rounded="true"
            :active="open"
            class="focus:outline-none outline-none"
          >
            <svg
              class="w-5 h-5 text-gray-700"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
              />
            </svg>
          </EffectButton>
        </MenuButton>
        <Transition
          class="absolute z-50"
          enter-active-class="transition transform duration-100 ease-in"
          enter-from-class="opacity-0 scale-95"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="transition transform duration-300 ease-out"
          leave-from-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-95"
        >
          <MenuItems
            ref="menuItems"
            id="menuItems"
            class="
              absolute
              z-50
              py-2
              w-36
              m-1
              top-0
              right-0
              focus:outline-none
              outline-none
              rounded-md
              shadow-lg
              bg-white
              overflow-hidden
              border border-gray-300
              dark:border-darkBackground
            "
          >
            <MenuItem @click="changeState('edit')" v-slot="{ active }">
              <div
                :class="[
                  active
                    ? 'bg-gray-100 text-blue-700'
                    : 'bg-white text-gray-500 ',
                ]"
                class="
                  px-3
                  py-2
                  cursor-pointer
                  text-xs
                  flex
                  items-center
                  justify-start
                  space-x-2
                  focus:outline-none
                "
              >
                <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                  />
                </svg>

                <span>Edit Collection</span>
              </div>
            </MenuItem>

            <MenuItem @click="changeState('delete')" v-slot="{ active }">
              <div
                :class="[
                  active
                    ? 'bg-gray-100 dark:text-red-500 text-red-600'
                    : 'bg-white text-gray-500 ',
                ]"
                class="
                  px-3
                  py-2
                  cursor-pointer
                  text-xs
                  flex
                  items-center
                  justify-start
                  space-x-2
                  focus:outline-none
                  outline-none
                "
              >
                <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fill-rule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>

                <span class="whitespace-nowrap">Delete Collection</span>
              </div>
            </MenuItem>
          </MenuItems>
        </Transition>
      </Menu>
    </div>

    <!-- edit -->
    <form
      @submit.prevent="handleEdit"
      v-if="state === 'edit'"
      class="mt-10 w-full h-full flex items-center flex-col justify-start"
    >
      <input
        v-model="editTitle"
        placeholder="Collection Name"
        class="
          w-full
          rounded-md
          border
          text-sm
          placeholder-gray-500
          border-gray-300
          focus:outline-none
          px-3
          py-2
        "
        type="text"
      />

      <div class="w-full flex items-center justify-between space-x-4">
        <button
          @click="changeState('default')"
          class="
            mt-6
            px-4
            py-2
            rounded-md
            text-sm text-gray-500
            border border-gray-300
            text-center
            hover:bg-gray-200
            focus:outline-none
          "
        >
          Cancel
        </button>
        <button
          @click="handleEdit"
          class="
            mt-6
            w-full
            px-3
            py-2
            rounded-md
            text-sm text-white
            font-medium
            text-center
            bg-buttonColor
            focus:outline-none
          "
        >
          Save Changes
        </button>
      </div>
    </form>
    <!-- end of edit -->

    <!-- share -->
    <div v-if="state === 'share'" class="">
      <h1 class="mt-5 mb-2 text-sm font-medium text-gray-800">
        Share this collection?
      </h1>
      <SwitchGroup
        as="div"
        class="my-5 flex items-center space-x-4 focus:outline-none"
      >
        <SwitchLabel
          class="text-sm font-medium text-gray-800 dark:text-gray-300"
        >
          Link Sharing
        </SwitchLabel>

        <Switch
          as="button"
          v-model="sharing"
          class="
            relative
            inline-flex
            items-center
            w-12
            h-7
            rounded-full
            focus:outline-none
          "
          :class="sharing ? 'bg-brandPurple' : 'bg-gray-300'"
          v-slot="{ checked }"
        >
          <span
            class="
              inline-block
              w-5
              h-5
              transform
              bg-white
              rounded-full
              transition-all
              duration-300
              shadow
            "
            :class="{ 'translate-x-6': checked, 'translate-x-1': !checked }"
          />
        </Switch>
      </SwitchGroup>

      <h1 v-if="sharing" class="my-5 text-sm font-medium text-gray-800">
        Anyone with the link can view this collection
      </h1>

      <div class="flex items-center justify-center">
        <input
          placeholder="link"
          class="
            w-full
            rounded-md
            border
            text-sm
            placeholder-gray-500
            border-gray-300
            focus:outline-none
            px-3
            py-2
          "
          type="text"
          disabled
          value="https://motionridge.com/account/collections/hstet58yuu3"
        />
        <button
          class="
            px-3
            py-2
            text-sm
            font-medium
            text-brandPurple
            whitespace-nowrap
          "
        >
          Copy Link
        </button>
      </div>
    </div>
    <!-- end of share -->

    <!-- delete -->
    <div v-if="state === 'delete'" class="mt-5 w-full">
      <div class="p-5 flex flex-col justify-center items-center">
        <div class="flex items-center justify-center">
          <div
            class="
              flex-shrink-0 flex
              items-center
              justify-center
              h-12
              w-12
              rounded-full
              bg-red-100
              sm:mx-0
              sm:h-10
              sm:w-10
            "
          >
            <svg
              class="h-6 w-6 text-red-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <h1
            class="
              ml-5
              text-base
              md:text-xl
              font-bold
              text-gray-800
              dark:text-gray-200
            "
          >
            Delete ?
          </h1>
        </div>

        <div class="mt-3 flex flex-col items-center justify-center">
          <h3
            class="
              w-full
              text-center
              my-3
              text-gray-600
              dark:text-gray-500
              text-sm
            "
          >
            Are you sure you want to delete this collection,this action is not
            reversible
          </h3>
        </div>

        <div
          class="
            mt-5
            w-full
            rounded-md
            sm:flex sm:flex-row-reverse
            justify-center
          "
        >
          <button
            @click="deleteCollection({ collectionId: id })"
            type="button"
            class="
              px-4
              py-2
              w-full
              md:w-24
              sm:ml-3
              sm:w-auto
              inline-flex
              justify-center
              font-medium
              sm:text-sm
              text-xs text-white
              bg-red-600
              hover:bg-red-700
              rounded-md
              border border-transparent
              focus:outline-none
            "
          >
            <span>Delete</span>
          </button>
          <button
            @click="state = 'default'"
            type="button"
            class="
              mt-3
              px-4
              py-2
              sm:ml-3
              sm:mt-0
              sm:w-auto
              w-full
              inline-flex
              justify-center
              font-medium
              sm:text-sm
              text-xs text-gray-700
              bg-white
              hover:bg-gray-50
              rounded-md
              border border-gray-300
              focus:outline-none
            "
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
    <!-- end of delete -->

    <!-- grid -->
    <div v-if="state === 'default'" class="w-full">
      <div class="mt-10 my-5 grid grid-cols-2 gap-5">
        <div
          class="bg-gray-400 rounded-md w-full image-container overflow-hidden"
        >
          <img
            v-if="products[0] && products[0].thumbnailUrl"
            :src="products[0] && products[0].thumbnailUrl"
            class="absolute inset-0 object-cover"
          />
          <img
            v-if="products[0] && !products[0].thumbnailUrl"
            src="https://firebasestorage.googleapis.com/v0/b/motion-ridge-b81bb.appspot.com/o/motion-ridge-item-preview-image%2Fsa_preview_img.jpg?alt=media&token=8d173ecd-bcb1-4527-ab12-05935739f9ef"
            class="absolute inset-0 object-cover"
          />
        </div>
        <div
          class="bg-gray-400 rounded-md w-full image-container overflow-hidden"
        >
          <img
            v-if="products[1] && products[1].thumbnailUrl"
            :src="products[1] && products[1].thumbnailUrl"
            class="absolute inset-0 object-cover"
          />
          <img
            v-if="products[1] && !products[1].thumbnailUrl"
            src="https://firebasestorage.googleapis.com/v0/b/motion-ridge-b81bb.appspot.com/o/motion-ridge-item-preview-image%2Fsa_preview_img.jpg?alt=media&token=8d173ecd-bcb1-4527-ab12-05935739f9ef"
            class="absolute inset-0 object-cover"
          />
        </div>
        <div
          class="bg-gray-400 rounded-md w-full image-container overflow-hidden"
        >
          <img
            v-if="products[2] && products[2].thumbnailUrl"
            :src="products[2] && products[2].thumbnailUrl"
            class="absolute inset-0 object-cover"
          />
          <img
            v-if="products[2] && !products[2].thumbnailUrl"
            src="https://firebasestorage.googleapis.com/v0/b/motion-ridge-b81bb.appspot.com/o/motion-ridge-item-preview-image%2Fsa_preview_img.jpg?alt=media&token=8d173ecd-bcb1-4527-ab12-05935739f9ef"
            class="absolute inset-0 object-cover"
          />
        </div>
        <div
          class="bg-gray-400 rounded-md w-full image-container overflow-hidden"
        >
          <img
            v-if="products[3] && products[3].thumbnailUrl"
            :src="products[3] && products[3].thumbnailUrl"
            class="absolute inset-0 object-cover"
          />
          <img
            v-if="products[3] && !products[3].thumbnailUrl"
            src="https://firebasestorage.googleapis.com/v0/b/motion-ridge-b81bb.appspot.com/o/motion-ridge-item-preview-image%2Fsa_preview_img.jpg?alt=media&token=8d173ecd-bcb1-4527-ab12-05935739f9ef"
            class="absolute inset-0 object-cover"
          />
        </div>
      </div>
      <!-- end of grid -->

      <router-link
        :to="`/collection/${id}`"
        class="
          py-2
          px-3
          text-center text-sm text-gray-600
          bg-gray-200
          hover:bg-gray-300
          rounded-md
          block
          w-full
          focus:outline-none
        "
      >
        View Collection
      </router-link>
    </div>
  </div>
  <div v-else class="">not lioggged</div>
</template>

<script>
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  Switch,
  SwitchGroup,
  SwitchLabel,
} from "@headlessui/vue";
import EffectButton from "./EffectButton.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CollectionCard",
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    EffectButton,
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  props: ["title", "products", "id"],

  methods: {
    ...mapActions(["editCollectionTitle"]),
    ...mapActions(["deleteCollection"]),
    ...mapActions(["fetchAvailableCollections"]),

    handleEdit() {
      if (this.editTitle.length > 0) {
        this.editCollectionTitle({ name: this.editTitle, id: this.id });
        this.state = "default";
      }
    },

    changeState(state) {
      this.state = state;
    },
  },
  data() {
    return {
      state: "default",
      sharing: false,
      editTitle: this.title,
    };
  },
};
</script>
<style scoped>
.image-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
</style>
