<template>
  <section
    class="p-3 pt-40 2xl:pt-32 pb-20 min-h-screen flex items-center justify-center bg-gray-50"
  >
    <div
      class="w-full max-w-screen-lg  md:grid md:grid-cols-2 overflow-hidden rounded-xl shadow-md"
    >
      <div class="hidden md:block relative overflow-hidden">
        <img
          class="absolute inset-0 z-10 w-full h-full object-cover"
          src="../assets/authenticate-bg.jpg"
          alt="authentication background image"
        />

        <div class="absolute inset-x-0 bottom-0 z-20">
          <h1 class="pb-5 text-white font-medium text-center">
            We are glad to see you again
          </h1>
        </div>

        <div
          class="absolute inset-y-0 right-0 z-20 flex flex-col justify-center items-end space-y-2"
        >
          <router-link
            to="/login"
            class=" w-20 rounded-l-full p-2 pl-3 text-sm font-medium bg-white text-gray-900 focus:outline-none cursor-pointer"
          >
            Sign in
          </router-link>
          <router-link
            to="/register"
            class=" w-20 rounded-l-full p-2 pl-3 text-sm font-medium text-white  focus:outline-none cursor-pointer"
          >
            Sign up
          </router-link>
        </div>
      </div>

      <div
        class="px-8 py-10 md:py-28 md:my-3 bg-white flex flex-col items-center justify-center"
      >
        <h1 class="block w-full text-left text-xl font-semibold text-gray-900">
          Sign into your account
        </h1>

        <form
          @submit.prevent="handleLogin"
          name="form"
          class="mt-6 w-full flex flex-col"
        >
          <div class="h-16">
            <input
              name="email"
              @input="handleChange"
              @blur="setTouched"
              @focus="checkError"
              v-model="email.value"
              type="text"
              placeholder="Email address"
              :class="{
                'ring-2 ring-red-700': errors.email && email.isTouched,
              }"
              class="border block w-full border-gray-300 rounded-md px-3 py-2 text-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500"
            />
            <h3
              v-if="errors.email && email.isTouched"
              class="text-xs text-red-700 mt-1"
            >
              {{ errors.email }}
            </h3>
          </div>
          <div class="mt-1 h-16">
            <input
              name="password"
              @input="handleChange"
              @blur="setTouched"
              @focus="checkError"
              v-model="password.value"
              type="password"
              placeholder="Password"
              :class="{
                'ring-2 ring-red-700': errors.password && password.isTouched,
              }"
              class="border block w-full border-gray-300 rounded-md px-3 py-2 text-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500"
            />
            <h3
              v-if="errors.password && password.isTouched"
              class="text-xs text-red-700 mt-1"
            >
              {{ errors.password }}
            </h3>
          </div>

          <div class="mt-6 flex items-center justify-end">
            <router-link
              to="/forgot-password"
              class="text-blue-800 text-sm hover:underline"
              >Forgot password?</router-link
            >
          </div>

          <button
            type="submit"
            class="mt-5 px-2 py-2 md:px-3 md:py-2 flex justify-center items-center font-semibold text-center text-white text-xs md:text-sm bg-buttonColor transition-colors duration-300 hover:bg-pink-700 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2 focus:ring-offset-white"
          >
            <Loader v-if="getAuthLoading" />
            <span v-if="!getAuthLoading">Sign in</span>
          </button>
          <router-link
            to="/register"
            class="md:hidden text-center w-full mt-3 text-blue-800 text-sm group"
            >Dont have an Account?
            <span class="text-gray-900 group-hover:underine"
              >Register</span
            ></router-link
          >
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "../components/utils/Loader.vue";
export default {
  name: "Log in",
  components: { Loader },

  computed: {
    ...mapGetters(["getAuthLoading"]),
    ...mapGetters(["getLoginError"]),
  },

  mounted() {
    this.errors = this.getLoginError;
  },

  methods: {
    ...mapActions(["login"]),

    checkError(e) {
      if (e.target.name === "form") {
        this.email.isTouched = true;
        this.password.isTouched = true;

        if (e.target.name === "email") {
          const regEx = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
          if (value.length <= 0) {
            this.errors.email = "email is required";
          } else if (!regEx.exec(value)) {
            this.errors.email = "Invalid email";
          } else {
            this.errors.email = null;
          }
        }

        if (this.password.value.length <= 0) {
          this.errors.password = "Password is required";
        } else {
          this.errors.password = null;
        }
      } else {
        let field = e.target.name;
        let value = e.target.value;
        if (field === "email") {
          const regEx = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
          if (value.length <= 0) {
            this.errors.email = "email is required";
          } else if (!regEx.exec(value)) {
            this.errors.email = "Invalid email";
          } else {
            this.errors.email = null;
          }
        }

        if (field === "password") {
          if (value.length <= 0) {
            this.errors.password = "Password is required";
          } else {
            this.errors.password = null;
          }
        }
      }
    },
    setTouched(e) {
      const field = e.target.name;
      this[field].isTouched = true;
    },
    handleChange(e) {
      this.checkError(e);
    },

    handleLogin(e) {
      this.checkError(e);
      if (!this.errors.email && !this.errors.password) {
        let userData = {
          email: this.email.value,
          password: this.password.value,
        };
        this.login(userData);
      }
    },
  },
  data() {
    return {
      remember: false,
      email: {
        value: "",
        isTouched: false,
      },

      password: {
        value: "",
        isTouched: false,
      },

      errors: {
        email: null,
        password: null,
      },
    };
  },

  watch: {
    getLoginError: function(nv, ov) {
      this.errors = nv;
    },
  },
};
</script>
