<template>
  <div class="w-full relative inset-0 z-30 bg-white rounded-md shadow-md">
    <div
      class="
        h-16
        rounded-md rounded-b-none
        bg-white
        w-full
        flex
        items-center
        justify-between
        overflow-hidden
      "
    >
      <div
        v-if="peaks"
        class="w-20 h-full flex items-center justify-center bg-brandBlack"
      >
        <button v-if="!isPlaying" @click="play" class="focus:outline-none">
          <svg
            class="h-8 w-8 text-white"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <button v-else @click="pause" class="focus:outline-none">
          <svg
            class="h-8 w-8 text-white"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div
        v-else
        class="
          w-20
          h-full
          flex
          items-center
          justify-center
          bg-gray-500
          animate-pulse
        "
      ></div>
      <div class="relative h-full w-full overflow-hidden">
        <!-- loader head -->
        <div
          v-if="!title"
          class="
            absolute
            h-full
            inset-0
            z-30
            bg-gray-400
            rounded-md rounded-l-none rounded-b-none
            animate-pulse
            flex
            items-center
            justify-center
          "
        >
          <svg
            aria-hidden="true"
            focusable="false"
            width="2em"
            height="2em"
            class="text-white"
            style="
              -ms-transform: rotate(360deg);
              -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
            "
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 1024 1024"
          >
            <path
              d="M960 576v96q0 13-9.5 22.5T928 704t-22.5-9.5T896 672v64q0 13-9.5 22.5T864 768t-22.5-9.5T832 736V608q0 13-9.5 22.5T800 640t-22.5-9.5T768 608v256q0 13-9.5 22.5T736 896t-22.5-9.5T704 864v64q0 13-9.5 22.5T672 960t-22.5-9.5T640 928V608q0 13-9.5 22.5T608 640t-22.5-9.5T576 608v128q0 13-9.5 22.5T544 768t-22.5-9.5T512 736v64q0 13-9.5 22.5T480 832t-22.5-9.5T448 800v192q0 13-9.5 22.5T416 1024t-22.5-9.5T384 992V864q0 13-9.5 22.5T352 896t-22.5-9.5T320 864V672q0 13-9.5 22.5T288 704t-22.5-9.5T256 672v64q0 13-9.5 22.5T224 768t-22.5-9.5T192 736v-64q0 13-9.5 22.5T160 704t-22.5-9.5T128 672v-64q0 13-9.5 22.5T96 640t-22.5-9.5T64 608v-32q-27 0-45.5-19T0 511.5t18.5-45T64 448v-32q0-13 9.5-22.5T96 384t22.5 9.5T128 416v-64q0-13 9.5-22.5T160 320t22.5 9.5T192 352v-64q0-13 9.5-22.5T224 256t22.5 9.5T256 288v64q0-13 9.5-22.5T288 320t22.5 9.5T320 352V160q0-13 9.5-22.5T352 128t22.5 9.5T384 160V32q0-13 9.5-22.5T416 0t22.5 9.5T448 32v192q0-13 9.5-22.5T480 192t22.5 9.5T512 224v64q0-13 9.5-22.5T544 256t22.5 9.5T576 288v128q0-13 9.5-22.5T608 384t22.5 9.5T640 416V96q0-13 9.5-22.5T672 64t22.5 9.5T704 96v64q0-13 9.5-22.5T736 128t22.5 9.5T768 160v256q0-13 9.5-22.5T800 384t22.5 9.5T832 416V288q0-13 9.5-22.5T864 256t22.5 9.5T896 288v64q0-13 9.5-22.5T928 320t22.5 9.5T960 352v96q27 0 45.5 18.5t18.5 45t-18.5 45.5t-45.5 19z"
              fill="#fff"
            />
            <rect
              x="0"
              y="0"
              width="1024"
              height="1024"
              fill="rgba(0, 0, 0, 0)"
            />
          </svg>
        </div>
        <div
          :id="`loadingHead${id1}`"
          class="
            absolute
            pointer-events-none
            inset-0
            z-20
            bg-audioLoading
            transition-all
            duration-75
          "
        ></div>

        <!-- current head -->
        <div
          :id="`currentHead${this.id1}`"
          class="
            absolute
            pointer-events-none
            inset-0
            z-20
            bg-audioCurrent
            border-brandPurple
          "
        ></div>

        <div :id="id1" class="w-full"></div>
      </div>
    </div>

    <!-- info -->
    <div
      class="relative z-50 w-full p-5 flex items-start justify-between gap-5"
    >
      <div class="flex flex-col items-start truncate justify-center">
        <a
          :href="`/item/${title.toLowerCase().split(' ').join('-')}/${id}`"
          v-if="title"
          class="
            font-semibold
            text-left
            w-full
            max-w-sm
            truncate
            text-gray-800 text-sm
          "
        >
          {{ title }}
        </a>
        <div v-else class="w-40 h-3 rounded bg-gray-400 animate-pulse"></div>

        <div v-if="author" class="mt-3 flex gap-1 items-center justify-start">
          <!-- badge -->
          <div
            v-if="isNew"
            class="
              text-xxs
              border border-brandPurple
              text-brandPurple
              rounded-md
              px-1
            "
          >
            New
          </div>
          <!-- author -->
          <h4 class="text-xs font-gray-600">
            By
            <a
              class="hover:underline hover:text-brandPurple"
              :href="`/user/${author}`"
              >{{ author }}</a
            >
          </h4>
        </div>
        <div
          v-else
          class="mt-1 w-32 h-3 rounded bg-gray-400 animate-pulse"
        ></div>
      </div>

      <div class="flex justify-end flex-col gap-2">
        <div class="flex items-center justify-center gap-5">
          <!-- duration -->
          <span
            v-if="length"
            class="inline-flex text-xs items-center gap-1 select-none"
            ><svg
              class="h-4 w-4 text-gray-800"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                clip-rule="evenodd"
              />
            </svg>
            {{
              length &&
              length
                .split(":")
                .filter((t, i) => i > 0)
                .join(":")
            }}
          </span>
          <div v-else class="w-10 h-3 rounded bg-gray-400 animate-pulse"></div>

          <!-- tracks -->
          <h4
            v-if="trackLength"
            class="
              tooltip
              whitespace-nowrap
              text-xs text-gray-600
              underline
              cursor-pointer
              hover:text-gray-800
            "
          >
            <span class="tooltiptext z-50">
              <p
                v-for="(length, i) in trackLength.split(',')"
                :key="i"
                class="py-2"
              >
                Track {{ i + 1 }} : {{ length }}
              </p>
            </span>
            {{ trackLength && trackLength.split(",").length }} Track{{
              trackLength.split(",").length > 1 ? "s" : ""
            }}
          </h4>
          <div v-else class="w-10 h-3 rounded bg-gray-400 animate-pulse"></div>
          <!-- bpm -->
          <h4
            v-if="bpm && currentCategory !== 'Sound Effects'"
            class="
              whitespace-nowrap
              text-xs text-gray-600
              cursor-pointer
              hover:text-gray-800
            "
          >
            BPM <span class="font-semibold">{{ bpm }}</span>
          </h4>
          <div
            v-if="!bpm && currentCategory !== 'Sound Effects'"
            class="w-10 h-3 rounded bg-gray-400 animate-pulse"
          ></div>
        </div>

        <!-- download and collection buttons -->
        <div class="flex items-center justify-end gap-2">
          <div
            v-if="isCollection"
            @click="isCollection = false"
            class="fixed h-screen w-screen z-50 inset-0"
          ></div>
          <div
            v-if="isDownload"
            @click="isDownload = !isDownload"
            class="fixed h-screen w-screen z-50 inset-0"
          ></div>
          <!-- collection popup -->
          <div class="focus:outline-none outline-none">
            <button
              @click="handleCollectionClick"
              v-if="title"
              class="
                tooltip
                w-7
                h-7
                rounded-md
                bg-transparent
                hover:bg-white
                p-1
                focus:outline-none
                flex
                items-center
                justify-center
              "
            >
              <span class="hidden lg:block tooltiptext text-xs"
                >Add to collection</span
              >
              <svg
                class="w-6 h-6 text-gray-900"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
              </svg>
            </button>
            <div
              v-else
              class="w-7 h-7 rounded-md bg-gray-400 animate-pulse"
            ></div>

            <Transition
              enter-active-class="transition transform duration-100 ease-in"
              enter-from-class="opacity-0 scale-95"
              enter-to-class="opacity-100 scale-100"
              leave-active-class="transition transform duration-300 ease-out"
              leave-from-class="opacity-100 scale-100"
              leave-to-class="opacity-0 scale-95"
            >
              <div
                v-if="isCollection"
                class="
                  absolute
                  z-50
                  w-full
                  bottom-0
                  right-0
                  focus:outline-none
                  outline-none
                  rounded-md
                  shadow-lg
                  bg-white
                  overflow-hidden
                  border border-gray-300
                  dark:border-darkBackground
                "
              >
                <div
                  v-if="getCurrentUser"
                  class="w-full p-3 flex items-center justify-between"
                >
                  <h1 class="text-sm font-semibold text-gray-800">
                    My Collection
                  </h1>
                  <a
                    href="/collections"
                    class="text-xs text-brandPurple focus:outline-none"
                    >See All</a
                  >
                </div>

                <!-- search -->
                <form
                  v-if="getCurrentUser"
                  @submit.prevent="handleCreateCollection"
                  class="mt-1 mb-3 w-full px-3"
                >
                  <div
                    class="
                      px-3
                      w-full
                      flex
                      items-center
                      justify-between
                      bg-gray-200
                      rounded-md
                    "
                  >
                    <input
                      type="search"
                      v-model="collectionName"
                      @input="handleSearchInput"
                      placeholder="search or create a collection"
                      class="
                        py-2
                        rounded-md
                        w-full
                        text-sm text-gray-800
                        bg-transparent
                        placeholder-gray-500
                        focus:outline-none
                      "
                    />
                    <svg
                      class="w-5 h-5 text-gray-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                </form>

                <!-- end of search -->

                <!-- suggestion -->
                <div
                  v-if="getCurrentUser"
                  class="
                    mt-4
                    mb-3
                    px-3
                    h-16
                    overflow-y-auto
                    flex flex-col
                    gap-2
                    items-start
                    justify-start
                  "
                >
                  <div
                    v-for="collection in getFilteredAvailableCollections"
                    :key="collection.id"
                    class="w-full flex items-center justify-between gap-5"
                  >
                    <span class="truncate text-xs font-medium text-gray-800">
                      {{ collection.name }}
                    </span>

                    <abbr
                      v-if="
                        collection.products &&
                        collection.products.filter((p) => p.id === id).length >
                          0
                      "
                      title="Remove"
                    >
                      <button
                        @click="
                          removeFromCollection({
                            productId: id,
                            collectionId: collection.id,
                          })
                        "
                        class="
                          p-1
                          text-xs
                          font-medium
                          rounded
                          bg-red-100
                          text-red-900
                          focus:outline-none
                        "
                      >
                        Remove
                      </button>
                    </abbr>
                    <abbr v-else title="Add">
                      <button
                        @click="
                          addToCollection({
                            productId: id,
                            collectionId: collection.id,
                          })
                        "
                        class="
                          p-1
                          text-xs
                          font-medium
                          text-center
                          rounded
                          bg-green-100
                          text-green-900
                          focus:outline-none
                        "
                      >
                        Add
                      </button>
                    </abbr>
                  </div>
                </div>
                <!-- end of suggestion -->

                <!-- create button -->
                <button
                  v-if="getCurrentUser"
                  @click="handleCreateCollection"
                  class="
                    py-3
                    text-sm text-center
                    bg-gray-200
                    border-t border-gray-300
                    w-full
                    flex
                    items-center
                    justify-center
                    focus:outline-none
                  "
                >
                  <svg
                    class="w-6 h-6 text-buttonColor"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Create
                </button>
                <!-- end of create button -->

                <!-- if not logged in -->
                <div v-if="!getCurrentUser" class="p-5">
                  <h1 class="text-base font-bold text-gray-800 text-center">
                    Create an account to save this item for later
                  </h1>
                  <a
                    href="/register"
                    class="
                      mt-5
                      mb-2
                      w-full
                      block
                      px-2
                      py-2
                      md:px-3
                      md:py-2
                      font-semibold
                      text-center text-gray-900 text-xs
                      md:text-sm
                      bg-brandGreen
                      transition-colors
                      duration-300
                      hover:bg-brandGreenHover
                      rounded-md
                      focus:outline-none
                    "
                  >
                    Create a free account
                  </a>

                  <a
                    href="/login"
                    class="text-center w-full block text-gray-800 text-xs"
                  >
                    <span>Already a Vooaxis member?</span>
                    <span class="text-brandPurple hover:underline">
                      Sign in here</span
                    >
                  </a>
                </div>
                <!-- end of if not logged in -->
              </div>
            </Transition>
          </div>
          <!-- end of collection popup -->

          <!-- download popup -->
          <div class="hidden md:block focus:outline-none outline-none">
            <button
              @click="handleDownloadClick"
              v-if="title"
              class="
                tooltip
                w-7
                h-7
                rounded-md
                bg-transparent
                hover:bg-white
                p-1
                focus:outline-none
                flex
                items-center
                justify-center
              "
            >
              <span class="hidden lg:block tooltiptext text-xs">Download</span>
              <svg
                class="w-6 h-6 text-gray-900"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <div
              v-else
              class="w-7 h-7 rounded-md bg-gray-400 animate-pulse"
            ></div>

            <Transition
              enter-active-class="transition transform duration-100 ease-in"
              enter-from-class="opacity-0 scale-95"
              enter-to-class="opacity-100 scale-100"
              leave-active-class="transition transform duration-300 ease-out"
              leave-from-class="opacity-100 scale-100"
              leave-to-class="opacity-0 scale-95"
            >
              <div
                v-if="isDownload"
                class="
                  absolute
                  z-50
                  w-full
                  bottom-0
                  right-0
                  focus:outline-none
                  outline-none
                  rounded-md
                  shadow-lg
                  bg-white
                  overflow-hidden
                  border border-gray-300
                  dark:border-darkBackground
                "
              >
                <div
                  v-if="
                    getCurrentUser &&
                    getCurrentUser.subscription !== 'free' &&
                    !free
                  "
                  class="w-full p-3 flex items-center justify-between"
                >
                  <h1 class="text-sm font-semibold text-gray-800">Download</h1>
                </div>

                <div
                  v-if="getCurrentUser && free"
                  class="w-full p-3 flex items-center justify-between"
                >
                  <h1 class="text-sm font-semibold text-gray-800">
                    Download Free
                  </h1>
                </div>

                <h1
                  v-if="free && getCurrentUser"
                  class="mb-5 px-3 text-sm text-gray-700"
                >
                  This free item is available to you to use only once for a
                  single project. Enjoy your free file!
                </h1>

                <div
                  v-if="free && getCurrentUser"
                  class="w-full flex flex-start px-3 mt-5"
                >
                  <CustomRoundedCheckbox
                    v-on:clicked="isPolicy = !isPolicy"
                    lable="I agree to the Free Files Single Use Policy."
                    :select="isPolicy"
                    :truncate="false"
                  />
                </div>

                <h1
                  v-if="free && getCurrentUser"
                  class="pl-10 ml-1 mt-1 mb-5 text-xs text-gray-600 italic"
                >
                  see our
                  <router-link to="#" class="underline"
                    >Free files Policy</router-link
                  >
                </h1>

                <!-- search -->
                <form
                  v-if="
                    !free &&
                    getCurrentUser &&
                    getCurrentUser.subscription !== 'free'
                  "
                  @submit.prevent="handleCreateCollection"
                  class="mt-1 mb-3 w-full px-3"
                >
                  <div
                    class="
                      px-3
                      w-full
                      flex
                      items-center
                      justify-between
                      bg-gray-200
                      rounded-md
                    "
                  >
                    <input
                      type="search"
                      v-model="licenseName"
                      @input="handleLicense"
                      placeholder="Enter license name"
                      class="
                        py-2
                        rounded-md
                        w-full
                        text-sm text-gray-800
                        bg-transparent
                        placeholder-gray-500
                        focus:outline-none
                      "
                    />
                  </div>
                </form>

                <!-- end of search -->

                <!-- doanload button -->
                <button
                  v-if="
                    !free &&
                    getCurrentUser &&
                    getCurrentUser.subscription !== 'free'
                  "
                  @click="handleDownload"
                  :class="[
                    !activateDownload
                      ? 'text-gray-400 pointer-events-none'
                      : 'text-gray-800',
                    { 'pointer-events-none': getDownloadLoading },
                  ]"
                  class="
                    py-3
                    text-sm text-center
                    bg-gray-200
                    border-t border-gray-300
                    w-full
                    flex
                    items-center
                    justify-center
                    focus:outline-none
                  "
                >
                  <Loader v-if="getDownloadLoading" :bright="true" />
                  <svg
                    v-if="!getDownloadLoading"
                    :class="
                      !activateDownload
                        ? 'text-gray-400 pointer-events-none'
                        : 'text-buttonColor'
                    "
                    class="w-5 h-5 mr-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span v-if="!getDownloadLoading">Download</span>
                </button>

                <!-- free download button -->
                <button
                  v-if="free && getCurrentUser"
                  @click="handleFreeDownload"
                  :class="[
                    !isPolicy
                      ? 'text-gray-400 pointer-events-none'
                      : 'text-gray-800',
                    { 'pointer-events-none': getDownloadLoading },
                  ]"
                  class="
                    py-3
                    text-sm text-center
                    bg-gray-200
                    border-t border-gray-300
                    w-full
                    flex
                    items-center
                    justify-center
                    focus:outline-none
                  "
                >
                  <Loader v-if="getDownloadLoading" :bright="true" />
                  <svg
                    v-if="!getDownloadLoading"
                    :class="
                      !isPolicy
                        ? 'text-gray-400 pointer-events-none'
                        : 'text-buttonColor'
                    "
                    class="w-5 h-5 mr-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span v-if="!getDownloadLoading">Download</span>
                </button>
                <!-- end of doanload button -->

                <!-- if not logged in -->
                <div
                  v-if="
                    !getCurrentUser ||
                    (getCurrentUser && getCurrentUser.subscription === 'free')
                  "
                >
                  <h1
                    v-if="!free"
                    class="
                      text-base
                      pt-5
                      px-3
                      font-bold
                      text-gray-800 text-center
                    "
                  >
                    Subscribe to get this item and unlimited downloads for your
                    Creative Project.
                  </h1>
                  <h1
                    v-if="free && !getCurrentUser"
                    class="text-base pt-5 font-bold text-gray-800 text-center"
                  >
                    Please Create an account to download this product
                  </h1>
                  <div v-if="!free" class="px-3">
                    <a
                      href="/pricing"
                      class="
                        mt-5
                        mb-5
                        w-full
                        block
                        px-2
                        py-2
                        md:px-3
                        md:py-2
                        font-semibold
                        text-center text-gray-900 text-xs
                        md:text-sm
                        bg-brandGreen
                        transition-colors
                        duration-300
                        hover:bg-brandGreenHover
                        rounded-md
                        focus:outline-none
                      "
                    >
                      Subscribe to download
                    </a>
                  </div>

                  <div v-if="free && !getCurrentUser" class="px-3">
                    <a
                      href="/register"
                      class="
                        mt-5
                        mb-2
                        w-full
                        block
                        px-2
                        py-2
                        md:px-3
                        md:py-2
                        font-semibold
                        text-center text-gray-900 text-xs
                        md:text-sm
                        bg-brandGreen
                        transition-colors
                        duration-300
                        hover:bg-brandGreenHover
                        rounded-md
                        focus:outline-none
                      "
                    >
                      Create an account
                    </a>
                  </div>

                  <a
                    v-if="!getCurrentUser && !free"
                    href="/login"
                    class="text-center pb-5 w-full block text-gray-800 text-xs"
                  >
                    <span>Already a Vooaxis member?</span>
                    <span class="text-brandPurple hover:underline">
                      Sign in here</span
                    >
                  </a>

                  <a
                    v-if="!getCurrentUser && free"
                    href="/login"
                    class="text-center pb-5 w-full block text-gray-800 text-xs"
                  >
                    <span>Already a Vooaxis member?</span>
                    <span class="text-brandPurple hover:underline">
                      Sign in here</span
                    >
                  </a>
                </div>
                <!-- end of if not logged in -->
              </div>
            </Transition>
          </div>
          <!-- end of collection popup -->
        </div>
      </div>
    </div>
    <!-- end of info -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomRoundedCheckbox from "./CustomRoundedCheckbox.vue";
import WaveSurfer from "wavesurfer.js";
import Loader from "./Loader.vue";
export default {
  name: "new mini audio card",
  props: [
    "peaks",
    "audioUrl",
    "title",
    "author",
    "authorId",
    "id",
    "date",
    "trackLength",
    "bpm",
    "currentCategory",
    "length",
    "free",
  ],

  components: { CustomRoundedCheckbox, Loader },

  data() {
    return {
      audio: null,
      id1: null,
      loadingPercentage: 0,
      currentLoadInterval: null,
      isPlaying: false,
      waveformed: true,
      Wave: null,
      isNew: false,
      isCollection: false,
      collectionName: "",
      isDownload: false,
      licenseName: "",
      activateDownload: false,
      isPolicy: false,
    };
  },

  created() {
    this.id1 = `waveform1${Math.floor(Math.random() * (100000 - 1 + 1)) + 1}`;
  },

  mounted() {
    const currentDate = new Date().getMonth();
    const productDate = parseInt(this.date.split("-")[1]) - 1;
    if (currentDate === productDate) {
      this.isNew = true;
    } else {
      this.isNew = false;
    }
    document.getElementById(`loadingHead${this.id1}`).style.width = `0%`;
    document.getElementById(`currentHead${this.id1}`).style.width = `0%`;
    if (this.peaks) {
      this.genWave();
      this.waveformed = false;
    }
  },

  computed: {
    ...mapGetters(["getFilteredAvailableCollections"]),
    ...mapGetters(["getCurrentUser"]),
    ...mapGetters(["getDownloadLoading"]),
    ...mapGetters(["getIsDownloaded"]),
  },

  methods: {
    ...mapActions(["createCollection"]),
    ...mapActions(["addToCollection"]),
    ...mapActions(["removeFromCollection"]),
    ...mapActions(["filterCollections"]),
    ...mapActions(["downloadProduct"]),
    ...mapActions(["appendAlert"]),
    ...mapActions(["downloadFreeProduct"]),

    handleDownloadClick() {
      if (
        !this.getCurrentUser ||
        (this.getCurrentUser && this.getCurrentUser.isEmailVerified)
      ) {
        this.isDownload = true;
      } else {
        this.appendAlert({
          id: Math.floor(Math.random() * 10000),
          title: "Your Email is not yet verified",
          sub: "Click the link sent to your email to verify your account",
          icon: "bi:info-circle-fill",
          type: "warning",
          button: {
            name: "Resend Verification email",
            action: "resendVerificationEmail",
          },
        });
      }
    },

    handleCollectionClick() {
      if (
        !this.getCurrentUser ||
        (this.getCurrentUser && this.getCurrentUser.isEmailVerified)
      ) {
        this.isCollection = true;
      } else {
        this.appendAlert({
          id: Math.floor(Math.random() * 10000),
          title: "Your Email is not yet verified",
          sub: "Click the link sent to your email to verify your account",
          icon: "bi:info-circle-fill",
          type: "warning",
          button: {
            name: "Resend Verification email",
            action: "resendVerificationEmail",
          },
        });
      }
    },

    handleFreeDownload() {
      this.downloadFreeProduct(this.id);
    },

    handleDownload() {
      this.downloadProduct({
        id: this.id,
        license: this.licenseName,
        type: "download",
      });
    },

    handleLicense() {
      if (this.licenseName.length <= 0) {
        this.activateDownload = false;
      } else {
        this.activateDownload = true;
      }
    },

    handleSearchInput(e) {
      this.filterCollections(e.target.value);
    },

    handleCreateCollection() {
      if (
        this.collectionName.length > 0 &&
        this.getFilteredAvailableCollections.length <= 0
      ) {
        this.createCollection({ name: this.collectionName, id: this.id });
        this.collectionName = "";
      }
    },

    genAudio() {
      const id = this.id1;
      const audio = document.createElement("audio");
      audio.src = this.audioUrl;
      let loader = this.loadingPercentage;
      audio.addEventListener("progress", function () {
        var range = 0;
        var bf = this.buffered;
        var time = this.currentTime;

        while (!(bf.start(range) <= time && time <= bf.end(range))) {
          range += 1;
        }
        const currentTime = this.currentTime;
        const totalTime = this.duration;

        const per = (currentTime / totalTime) * 100;

        const loadStartPercentage = bf.start(range) / this.duration;
        const loadEndPercentage = bf.end(range) / this.duration;

        const loadPercentage = (
          (loadEndPercentage - loadStartPercentage) *
          100
        ).toFixed(2);

        loader = parseFloat(per) + parseFloat(loadPercentage);

        document.getElementById(`loadingHead${id}`).style.width = `${
          parseFloat(per) + parseFloat(loadPercentage)
        }%`;
      });

      let isPlay = this.isPlaying;
      audio.addEventListener("ended", (e) => {
        clearInterval(this.currentLoadInterval);
        this.pause();
        this.currentTime = 0;
        isPlay = false;
        document.getElementById(`currentHead${this.id1}`).style.width = `0%`;
        document
          .getElementById(`currentHead${this.id1}`)
          .classList.remove("border-r");
      });

      this.audio = audio;
    },

    genWave() {
      const player = () => {
        if (!this.audio) {
          this.genAudio();
        }
        this.isPlaying = true;
        this.audio.play();
        clearInterval(this.currentLoadInterval);
        this.currentLoadInterval = setInterval(() => {
          const currentTime = this.audio.currentTime;
          const totalTime = this.audio.duration;

          const per = (currentTime / totalTime) * 100;
          document.getElementById(
            `currentHead${this.id1}`
          ).style.width = `${per}%`;
          document
            .getElementById(`currentHead${this.id1}`)
            .classList.add("border-r");
        }, 20);
      };
      const wavesurfer2 = WaveSurfer.create({
        container: `#${this.id1}`,
        waveColor: "#CBD5E1",
        progressColor: "transparent",
        cursorColor: "transparent",
        mediaType: "audio",
        height: 65,
        barHeight:
          this.currentCategory && this.currentCategory === "Sound Effects"
            ? 2.5
            : 0.8,
        autoCenter: true,
        responsive: true,
        backend: "MediaElement",
        hideScrollbar: true,
      });

      this.Wave = wavesurfer2;

      wavesurfer2.backend.peaks = this.peaks;

      wavesurfer2.peaks = this.peaks;

      //Draw peaks
      wavesurfer2.drawBuffer();

      wavesurfer2.on("seek", (e) => {
        if (!this.audio) {
          this.genAudio();
          setTimeout(() => {
            player();
            this.isPlaying = true;
            const seek = e * this.audio.duration;
            const currentTime = this.audio.currentTime;
            const totalTime = this.audio.duration;

            const per = (seek / totalTime) * 100;
            document.getElementById(
              `currentHead${this.id1}`
            ).style.width = `${per}%`;
            document
              .getElementById(`currentHead${this.id1}`)
              .classList.add("border-r");
            this.audio.currentTime = seek.toFixed(2);
          }, 400);
        } else {
          player();
          this.isPlaying = true;
          const seek = e * this.audio.duration;
          const currentTime = this.audio.currentTime;
          const totalTime = this.audio.duration;

          const per = (seek / totalTime) * 100;
          document.getElementById(
            `currentHead${this.id1}`
          ).style.width = `${per}%`;
          document
            .getElementById(`currentHead${this.id1}`)
            .classList.add("border-r");
          this.audio.currentTime = seek.toFixed(2);
        }
      });
    },

    play() {
      clearInterval(this.currentLoadInterval);
      if (!this.audio) {
        this.genAudio();
      }
      this.isPlaying = true;
      this.audio.play();

      this.currentLoadInterval = setInterval(() => {
        const currentTime = this.audio.currentTime;
        const totalTime = this.audio.duration;

        const per = (currentTime / totalTime) * 100;
        document.getElementById(
          `currentHead${this.id1}`
        ).style.width = `${per}%`;
        document
          .getElementById(`currentHead${this.id1}`)
          .classList.add("border-r");
      }, 20);
    },

    pause() {
      clearInterval(this.currentLoadInterval);
      this.isPlaying = false;
      this.audio.pause();
      clearInterval(this.currentLoadInterval);
    },
  },

  watch: {
    getIsDownloaded: function (nv, ov) {
      if (nv) {
        this.licenseName = "";
        this.isDownload = false;
        this.isPolicy = false;
      }
    },
    $route: function (nv) {
      this.waveformed = true;
      clearInterval(this.currentLoadInterval);
      if (this.audio) {
        this.audio.pause();
      }
    },
  },
};
</script>
