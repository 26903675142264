<template>
	<img
		class="object-cover rounded-full"
		:class="[small ? 'h-6 w-6' : 'h-9 w-9']"
		:src="img"
		alt="users profile image"
	/>
</template>

<script>
export default {
	name: "Avatar",
	props: ["img", "small"],
	components: {}
}
</script>
