<template>
	<div class="">
		<div
			@click="isExpanded = !isExpanded"
			class="p-5 cursor-pointer w-full bg-white shadow-xl flex justify-between items-start text-gray-800 font-medium text-sm md:text-base rounded-md rounded-b-none"
		>
			{{ question }}

			<svg
				:class="isExpanded ? 'rotate-180' : ''"
				class="h-5 w-5 text-gray-600 transform transition-all duration-300"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
					clip-rule="evenodd"
				/>
			</svg>
		</div>
		<Transition
			enter-active-class="transition duration-300 transform"
			enter-from-class="opacity-0"
			enter-to-class="opacity-100"
			leave-active-class="transition duration-200 transform"
			leave-from-class="opacity-50 -translate-y-full"
			leave-to-class="opacity-0 -translate-y-10"
		>
			<div
				v-if="isExpanded"
				class="p-5 bg-gray-100 rounded-b-md text-sm text-gray-700"
			>
				{{ answer }}
			</div>
		</Transition>
	</div>
</template>

<script>
export default {
	name: "FaqCard",
	components: {},
	props: ["question", "answer"],
	data() {
		return {
			isExpanded: false
		}
	}
}
</script>
