<template>
	<div
		v-if="type === 'full' && pages > 1"
		class="flex items-center justify-center space-x-2 md:space-x-3"
	>
		<!-- previous -->
		<button
			v-if="pages > 1"
			@click="handlePrevious"
			:class="
				currentPage > 1
					? 'text-gray-500 hover:text-white hover:bg-indigo-500'
					: 'bg-gray-200 text-gray-400'
			"
			class="w-6 h-6  rounded-md focus:outline-none outline-none flex items-center justify-center transition-colors duration-300 flex-shrink-0"
		>
			<svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
				<path
					fill-rule="evenodd"
					d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
					clip-rule="evenodd"
				/>
			</svg>
		</button>

		<div
			class="p-1 md:px-3 h-8 md:w-72 rounded-full flex items-center justify-center space-x-2 flex-shrink-0"
		>
			<div
				v-if="pages <= 10"
				class="flex items-center justify-center space-x-2 flex-shrink-0"
			>
				<button
					@click="currentPage = page"
					v-for="page in pagesArray"
					:key="page"
					class="relative w-6 h-6 focus:outline-none"
				>
					<span
						:class="
							page === currentPage
								? 'text-white'
								: 'hover:text-indigo-500 text-gray-600'
						"
						class="absolute inset-0 z-20 text-s flex items-center justify-center focus:outline-none transition-colors duration-100 flex-shrink-0 select-none font-bold"
						>{{ page }}</span
					>
					<Transition
						enter-active-class="transition transform duration-300"
						enter-from-class="scale-0"
						enter-to-class="scale-100"
						leave-active-class="transition transform duration-75"
						leave-from-class="opacity-100"
						leave-to-class="opacity-0"
					>
						<span
							v-if="page === currentPage"
							class="absolute inset-0 z-10 bg-indigo-500 rounded focus:outline-none flex-shrink-0"
						></span>
					</Transition>
				</button>
			</div>

			<div
				v-if="pages > 10 && currentPage < 5"
				class="flex items-center justify-center space-x-2 flex-shrink-0"
			>
				<button
					@click="currentPage = page"
					v-for="page in pagesArray.slice(0, 4)"
					:key="page"
					class="relative w-6 h-6 focus:outline-none"
				>
					<span
						:class="
							page === currentPage
								? 'text-white'
								: 'hover:text-indigo-500 text-gray-600'
						"
						class="absolute inset-0 z-20 text-s flex items-center justify-center focus:outline-none transition-colors duration-100 flex-shrink-0 select-none font-bold"
						>{{ page }}</span
					>
					<Transition
						enter-active-class="transition transform duration-300"
						enter-from-class="scale-0"
						enter-to-class="scale-100"
						leave-active-class="transition transform duration-75"
						leave-from-class="opacity-100"
						leave-to-class="opacity-0"
					>
						<span
							v-if="page === currentPage"
							class="absolute inset-0 z-10 bg-indigo-500 rounded focus:outline-none flex-shrink-0"
						></span>
					</Transition>
				</button>
			</div>

			<div
				@click="currentPage = 5"
				v-if="pages > 10 && currentPage < 5"
				class="hover:text-indigo-500 text-gray-600 text-xs cursor-pointer select-none"
			>
				...
			</div>

			<div
				v-if="pages > 10 && currentPage < 5"
				class="flex items-center justify-center space-x-2 flex-shrink-0"
			>
				<button
					@click="currentPage = page"
					v-for="page in pagesArray.slice(pages - 4, pages)"
					:key="page"
					class="relative w-6 h-6 focus:outline-none"
				>
					<span
						:class="
							page === currentPage
								? 'text-white'
								: 'hover:text-indigo-500 text-gray-600'
						"
						class="absolute inset-0 z-20 text-s flex items-center justify-center focus:outline-none transition-colors duration-100 flex-shrink-0 select-none font-bold"
						>{{ page }}</span
					>
					<Transition
						enter-active-class="transition transform duration-300"
						enter-from-class="scale-0"
						enter-to-class="scale-100"
						leave-active-class="transition transform duration-75"
						leave-from-class="opacity-100"
						leave-to-class="opacity-0"
					>
						<span
							v-if="page === currentPage"
							class="absolute inset-0 z-10 bg-indigo-500 rounded focus:outline-none flex-shrink-0"
						></span>
					</Transition>
				</button>
			</div>

			<!-- pages greater than 10 and current page above 5 and less than pages -4 -->
			<div
				v-if="pages > 10 && currentPage > 4 && currentPage < pages - 3"
				class="flex items-center justify-center space-x-2 flex-shrink-0"
			>
				<button @click="currentPage = 1" class="relative w-6 h-6 focus:outline-none">
					<span
						:class="
							currentPage === 1
								? 'text-white'
								: 'hover:text-indigo-500 text-gray-600'
						"
						class="absolute inset-0 z-20 text-s flex items-center justify-center focus:outline-none transition-colors duration-100 flex-shrink-0 select-none font-bold"
						>1</span
					>
					<Transition
						enter-active-class="transition transform duration-300"
						enter-from-class="scale-0"
						enter-to-class="scale-100"
						leave-active-class="transition transform duration-75"
						leave-from-class="opacity-100"
						leave-to-class="opacity-0"
					>
						<span
							v-if="currentPage === 1"
							class="absolute inset-0 z-10 bg-indigo-500 rounded focus:outline-none flex-shrink-0"
						></span>
					</Transition>
				</button>
			</div>

			<div
				@click="currentPage = 5"
				v-if="pages > 10 && currentPage > 4 && currentPage < pages - 3"
				class="hover:text-indigo-500 text-gray-600 text-xs cursor-pointer select-none"
			>
				...
			</div>

			<div
				v-if="pages > 10 && currentPage > 4 && currentPage < pages - 3"
				class="flex items-center justify-center space-x-2 flex-shrink-0"
			>
				<button
					@click="currentPage = page"
					v-for="page in pagesArray.slice(currentPage - 3, currentPage + 2)"
					:key="page"
					class="relative w-6 h-6 focus:outline-none"
				>
					<span
						:class="
							page === currentPage
								? 'text-white'
								: 'hover:text-indigo-500 text-gray-600'
						"
						class="absolute inset-0 z-20 text-s flex items-center justify-center focus:outline-none transition-colors duration-100 flex-shrink-0 select-none font-bold"
						>{{ page }}</span
					>
					<span
						v-if="page === currentPage"
						class="absolute inset-0 z-10 bg-indigo-500 rounded focus:outline-none flex-shrink-0"
					></span>
				</button>
			</div>

			<div
				@click="currentPage = pages - 4"
				v-if="pages > 10 && currentPage > 4 && currentPage < pages - 3"
				class="hover:text-indigo-500 text-gray-600 text-xs cursor-pointer select-none"
			>
				...
			</div>

			<div
				v-if="pages > 10 && currentPage > 4 && currentPage < pages - 3"
				class="flex items-center justify-center space-x-2 flex-shrink-0"
			>
				<button
					@click="currentPage = pages"
					class="relative w-6 h-6 focus:outline-none"
				>
					<span
						:class="
							currentPage === pages
								? 'text-white'
								: 'hover:text-indigo-500 text-gray-600'
						"
						class="absolute inset-0 z-20 text-s flex items-center justify-center focus:outline-none transition-colors duration-100 flex-shrink-0 select-none font-bold"
						>{{ pages }}</span
					>
					<Transition
						enter-active-class="transition transform duration-300"
						enter-from-class="scale-0"
						enter-to-class="scale-100"
						leave-active-class="transition transform duration-75"
						leave-from-class="opacity-100"
						leave-to-class="opacity-0"
					>
						<span
							v-if="currentPage === pages"
							class="absolute inset-0 z-10 bg-indigo-500 rounded focus:outline-none flex-shrink-0"
						></span>
					</Transition>
				</button>
			</div>

			<!-- where current page > pages-4 -->
			<div
				v-if="pages > 10 && currentPage > pages - 4"
				class="flex items-center justify-center space-x-2 flex-shrink-0"
			>
				<button
					@click="currentPage = page"
					v-for="page in pagesArray.slice(0, 4)"
					:key="page"
					class="relative w-6 h-6 focus:outline-none"
				>
					<span
						:class="
							page === currentPage
								? 'text-white'
								: 'hover:text-indigo-500 text-gray-600'
						"
						class="absolute inset-0 z-20 text-s flex items-center justify-center focus:outline-none transition-colors duration-100 flex-shrink-0 select-none font-bold"
						>{{ page }}</span
					>
					<Transition
						enter-active-class="transition transform duration-300"
						enter-from-class="scale-0"
						enter-to-class="scale-100"
						leave-active-class="transition transform duration-75"
						leave-from-class="opacity-100"
						leave-to-class="opacity-0"
					>
						<span
							v-if="page === currentPage"
							class="absolute inset-0 z-10 bg-indigo-500 rounded focus:outline-none flex-shrink-0"
						></span>
					</Transition>
				</button>
			</div>

			<div
				@click="currentPage = pages - 5"
				v-if="pages > 10 && currentPage > pages - 4"
				class="hover:text-indigo-500 text-gray-600 text-xs cursor-pointer select-none"
			>
				...
			</div>

			<div
				v-if="pages > 10 && currentPage > pages - 4"
				class="flex items-center justify-center space-x-2 flex-shrink-0"
			>
				<button
					@click="currentPage = page"
					v-for="page in pagesArray.slice(pages - 4, pages)"
					:key="page"
					class="relative w-6 h-6 focus:outline-none"
				>
					<span
						:class="
							page === currentPage
								? 'text-white'
								: 'hover:text-indigo-500 text-gray-600'
						"
						class="absolute inset-0 z-20 text-s flex items-center justify-center focus:outline-none transition-colors duration-100 flex-shrink-0 select-none font-bold"
						>{{ page }}</span
					>
					<Transition
						enter-active-class="transition transform duration-300"
						enter-from-class="scale-0"
						enter-to-class="scale-100"
						leave-active-class="transition transform duration-75"
						leave-from-class="opacity-100"
						leave-to-class="opacity-0"
					>
						<span
							v-if="page === currentPage"
							class="absolute inset-0 z-10 bg-indigo-500 rounded focus:outline-none flex-shrink-0"
						></span>
					</Transition>
				</button>
			</div>
		</div>

		<button
			@click="handleNext"
			:class="
				currentPage < pages
					? 'text-gray-500 hover:text-white hover:bg-indigo-500'
					: 'bg-gray-200 text-gray-400'
			"
			class="w-6 h-6  rounded-md focus:outline-none outline-none flex items-center justify-center transition-colors duration-300 flex-shrink-0"
		>
			<svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
				<path
					fill-rule="evenodd"
					d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
					clip-rule="evenodd"
				/>
			</svg>
		</button>
	</div>

	<!-- half -->

	<div
		v-if="type === 'half' && pages > 1"
		class="flex items-center justify-center space-x-2 md:space-x-3"
	>
		<!-- previous -->
		<button
			@click="handlePrevious"
			:class="
				currentPage > 1
					? 'text-gray-500 hover:text-white hover:bg-indigo-500'
					: 'bg-gray-200 text-gray-400'
			"
			class="w-6 h-6  rounded-md focus:outline-none outline-none flex items-center justify-center transition-colors duration-300 flex-shrink-0"
		>
			<svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
				<path
					fill-rule="evenodd"
					d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
					clip-rule="evenodd"
				/>
			</svg>
		</button>

		<div
			class="p-1 md:px-3 h-8 md:w-72 rounded-full flex items-center justify-center space-x-2 flex-shrink-0"
		>
			<div
				v-if="pages <= 7"
				class="flex items-center justify-center space-x-2 flex-shrink-0"
			>
				<button
					@click="currentPage = page"
					v-for="page in pagesArray"
					:key="page"
					class="relative w-6 h-6 focus:outline-none"
				>
					<span
						:class="
							page === currentPage
								? 'text-white'
								: 'hover:text-indigo-500 text-gray-600'
						"
						class="absolute inset-0 z-20 text-s flex items-center justify-center focus:outline-none transition-colors duration-100 flex-shrink-0 select-none font-bold"
						>{{ page }}</span
					>
					<Transition
						enter-active-class="transition transform duration-300"
						enter-from-class="scale-0"
						enter-to-class="scale-100"
						leave-active-class="transition transform duration-75"
						leave-from-class="opacity-100"
						leave-to-class="opacity-0"
					>
						<span
							v-if="page === currentPage"
							class="absolute inset-0 z-10 bg-indigo-500 rounded focus:outline-none flex-shrink-0"
						></span>
					</Transition>
				</button>
			</div>

			<div
				v-if="pages > 7 && currentPage < 7"
				class="flex items-center justify-center space-x-2 flex-shrink-0"
			>
				<button
					@click="currentPage = page"
					v-for="page in pagesArray.slice(0, 6)"
					:key="page"
					class="relative w-6 h-6 focus:outline-none"
				>
					<span
						:class="
							page === currentPage
								? 'text-white'
								: 'hover:text-indigo-500 text-gray-600'
						"
						class="absolute inset-0 z-20 text-s flex items-center justify-center focus:outline-none transition-colors duration-100 flex-shrink-0 select-none font-bold"
						>{{ page }}</span
					>
					<Transition
						enter-active-class="transition transform duration-300"
						enter-from-class="scale-0"
						enter-to-class="scale-100"
						leave-active-class="transition transform duration-75"
						leave-from-class="opacity-100"
						leave-to-class="opacity-0"
					>
						<span
							v-if="page === currentPage"
							class="absolute inset-0 z-10 bg-indigo-500 rounded focus:outline-none flex-shrink-0"
						></span>
					</Transition>
				</button>
			</div>

			<div
				@click="currentPage = 7"
				v-if="pages > 7 && currentPage < 7"
				class="hover:text-indigo-500 text-gray-600 text-xs cursor-pointer select-none"
			>
				...
			</div>

			<!-- pages greater than 10 and current page above 9 and less than pages -->
			<div
				v-if="pages > 7 && currentPage >= 7 && currentPage <= pages"
				class="flex items-center justify-center space-x-2 flex-shrink-0"
			>
				<button @click="currentPage = 1" class="relative w-6 h-6 focus:outline-none">
					<span
						:class="
							currentPage === 1
								? 'text-white'
								: 'hover:text-indigo-500 text-gray-600'
						"
						class="absolute inset-0 z-20 text-s flex items-center justify-center focus:outline-none transition-colors duration-100 flex-shrink-0 select-none font-bold"
						>1</span
					>
					<Transition
						enter-active-class="transition transform duration-300"
						enter-from-class="scale-0"
						enter-to-class="scale-100"
						leave-active-class="transition transform duration-75"
						leave-from-class="opacity-100"
						leave-to-class="opacity-0"
					>
						<span
							v-if="currentPage === 1"
							class="absolute inset-0 z-10 bg-indigo-500 rounded focus:outline-none flex-shrink-0"
						></span>
					</Transition>
				</button>
			</div>

			<div
				@click="currentPage = 6"
				v-if="pages > 7 && currentPage >= 7 && currentPage <= pages"
				class="hover:text-indigo-500 text-gray-600 text-xs cursor-pointer select-none"
			>
				...
			</div>

			<div
				v-if="pages > 7 && currentPage >= 7 && currentPage <= pages"
				class="flex items-center justify-center space-x-2 flex-shrink-0"
			>
				<button
					@click="currentPage = page"
					v-for="page in pagesArray.slice(currentPage - 3, currentPage + 3)"
					:key="page"
					class="relative w-6 h-6 focus:outline-none"
				>
					<span
						:class="
							page === currentPage
								? 'text-white'
								: 'hover:text-indigo-500 text-gray-600'
						"
						class="absolute inset-0 z-20 text-s flex items-center justify-center focus:outline-none transition-colors duration-100 flex-shrink-0 select-none font-bold"
						>{{ page }}</span
					>
					<span
						v-if="page === currentPage"
						class="absolute inset-0 z-10 bg-indigo-500 rounded focus:outline-none flex-shrink-0"
					></span>
				</button>
			</div>
		</div>

		<button
			v-if="pages > 1"
			@click="handleNext"
			:class="
				currentPage < pages
					? 'text-gray-500 hover:text-white hover:bg-indigo-500'
					: 'bg-gray-200 text-gray-400'
			"
			class="w-6 h-6  rounded-md focus:outline-none outline-none flex items-center justify-center transition-colors duration-300 flex-shrink-0"
		>
			<svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
				<path
					fill-rule="evenodd"
					d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
					clip-rule="evenodd"
				/>
			</svg>
		</button>
	</div>
</template>

<script>
export default {
	name: "Pagination",
	components: {},
	props: ["type", "pages", "selected"],
	emits: ["currentPage"],
	mounted() {
		for (let index = 1; index <= this.pages; index++) {
			this.pagesArray.push(index)
		}
	},

	methods: {
		handlePrevious() {
			if (this.currentPage > 1) {
				this.currentPage--
			}
		},
		handleNext() {
			if (this.currentPage < this.pages) {
				this.currentPage++
			}
		}
	},

	data() {
		return {
			currentPage: 1,
			pagesArray: []
		}
	},

	watch: {
		selected: function(nv) {
			this.currentPage = parseInt(nv)
		},

		currentPage: function(nv) {
			this.$emit("currentPage", nv)
		},

		pages: function(nv) {
			let p = []
			for (let index = 1; index <= nv; index++) {
				p.push(index)
			}
			this.pagesArray = p
		},

		$route(to, from) {
			if (this.$route.params.page) {
				const page = parseInt(this.$route.params.page.split("pg-")[1])
				this.currentPage = page
			} else {
				this.currentPage = 1
			}
		}
	}
}
</script>
