import axios from "axios"
const state = {
	availableCollections: [],
	individualCollection: [],
	filteredAvailableCollections: [],
	loading: false
}

const getters = {
	getLoading: (state) => state.loading,
	getAvailableCollections: (state) => state.availableCollections,
	getIndividualCollection: (state) => state.individualCollection,
	getFilteredAvailableCollections: (state) => state.filteredAvailableCollections
}

const actions = {
	filterCollections({ commit, state }, query) {
		if (query.length > 0) {
			const newData = state.availableCollections.filter((col) => {
				const regex = new RegExp(`${query}`, "gi")
				return col.name.match(regex)
			})
			commit("setFilteredAvailableCollections", newData)
		} else {
			commit("setFilteredAvailableCollections", state.availableCollections)
		}
	},

	async fetchAvailableCollections({ commit }) {
		try {
			const { data } = await axios.get(
				`${process.env.VUE_APP_BASE_URI}/collection/get-collections`
			)
			commit("setAvailableCollections", data.collection)
			commit("setFilteredAvailableCollections", data.collection)
		} catch (error) {
			console.log(error)
		}
	},

	async createCollection({ commit, state }, params) {
		try {
			const { data } = await axios.post(
				`${process.env.VUE_APP_BASE_URI}/collection/create`,
				{
					name: params.name
				}
			)
			commit("setAvailableCollections", data)
			commit("setFilteredAvailableCollections", data)
		} catch (error) {
			console.log(error)
		}
	},

	async editCollectionTitle({ commit, state }, params) {
		try {
			await axios.patch(`${process.env.VUE_APP_BASE_URI}/collection/update`, {
				name: params.name,
				id: params.id
			})
			const newCollection = state.availableCollections.map((col) => {
				if (col.id === params.id) {
					col.name = params.name
					return col
				}
				return col
			})
			commit("setAvailableCollections", newCollection)
			commit("setFilteredAvailableCollections", newCollection)
		} catch (error) {
			console.log(error)
		}
	},

	async addToCollection({ commit, state }, params) {
		try {
			commit("setLoading", true)
			const { data } = await axios.post(
				`${process.env.VUE_APP_BASE_URI}/collection/add`,
				{
					collectionId: params.collectionId,
					productId: params.productId
				}
			)
			const newCollection = state.availableCollections.map((col) => {
				if (col.id === params.collectionId) {
					col.products.push({ id: params.productId })
					return col
				}
				return col
			})
			commit("setAvailableCollections", newCollection)
			commit("setFilteredAvailableCollections", newCollection)
			commit("setLoading", false)
		} catch (error) {
			console.log(error)
		}
	},

	async removeFromCollection({ commit, state }, params) {
		try {
			const { data } = await axios.post(
				`${process.env.VUE_APP_BASE_URI}/collection/remove`,
				{
					collectionId: params.collectionId,
					productId: params.productId
				}
			)
			const newCollection = state.availableCollections.map((col) => {
				if (col.id === params.collectionId) {
					const removedProduct = col.products.filter(
						(p) => p.id !== params.productId
					)
					col.products = removedProduct
					return col
				}
				return col
			})
			commit("setAvailableCollections", newCollection)
		} catch (error) {
			console.log(error)
		}
	},

	async deleteCollection({ commit, state }, params) {
		try {
			const { data } = await axios.post(
				`${process.env.VUE_APP_BASE_URI}/collection/delete`,
				{
					collectionId: params.collectionId
				}
			)
			const newCollection = state.availableCollections.filter(
				(col) => col.id !== params.collectionId
			)

			commit("setAvailableCollections", newCollection)
		} catch (error) {
			console.log(error)
		}
	}
}

const mutations = {
	setLoading: (state, val) => (state.loading = val),
	setIndividualCollection: (state, val) => (state.individualCollection = val),
	setAvailableCollections: (state, val) => (state.availableCollections = val),
	setFilteredAvailableCollections: (state, val) =>
		(state.filteredAvailableCollections = val)
}

export default {
	state,
	getters,
	actions,
	mutations
}
