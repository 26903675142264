<template>
	<div
		class="animate-spin"
		:class="[
			bright ? 'loader-bright' : 'loader',
			{ 'loader-white': white },
			{ 'loader-white-small': whiteSmall },
			{ 'loader-brand': brand }
		]"
	></div>
</template>

<script>
export default {
	name: "Loader",
	components: {},
	props: ["bright", "white", "whiteSmall", "brand"]
}
</script>

<style scoped>
.loader {
	border: 2px solid #f3aaaa;
	border-radius: 50%;
	border-top: 3px solid #fff;
	width: 20px;
	height: 20px;
}
.loader-bright {
	border: 2px solid #f73859;
	border-radius: 50%;
	border-top: 3px solid transparent;
	width: 20px;
	height: 20px;
}
.loader-white {
	border: 2px solid #ffffff;
	border-radius: 50%;
	border-top: 3px solid transparent;
	width: 40px;
	height: 40px;
}
.loader-white-small {
	border: 2px solid #ffffff;
	border-radius: 50%;
	border-top: 3px solid transparent;
	width: 20px;
	height: 20px;
}
.loader-brand {
	border: 2px solid #7631ff;
	border-radius: 50%;
	border-top: 3px solid transparent;
	width: 40px;
	height: 40px;
}
</style>
