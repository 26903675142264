<template>
  <div class="font-def bg-gray-900 min-h-screen flex flex-col justify-between">
    <NewNav />
    <!-- <Topbar /> -->
    <div
      class="fixed max-w-screen-2xl mx-auto z-50 inset-0 pointer-events-none"
    >
      <div class="absolute m-2 md:m-6 top-0 right-0 z-50 pointer-events-none">
        <transition-group
          tag="div"
          class="
            flex flex-col
            items-end
            justify-start
            gap-5
            pointer-events-auto
          "
          :enter-active-class="
            getAlert.length > 1
              ? 'transition transform delay-100 duration-300 ease-in'
              : 'transition transform duration-300 ease-in'
          "
          enter-from-class="translate-x-full opacity-0"
          enter-to-class="translate-x-0 opacity-100"
          leave-active-class="transition transform duration-300 ease-out"
          leave-from-class="translate-x-0 opacity-100"
          leave-to-class="translate-x-full opacity-0"
          move-class="transition duration-500"
        >
          <Alert
            v-for="alert in getAlert"
            :key="alert.id"
            :id="alert.id"
            :icon="alert.icon"
            :title="alert.title"
            :sub="alert.sub"
            :type="alert.type"
            :button="alert.button"
          />
        </transition-group>
      </div>
    </div>
    <div class="mt-10">
      <router-view />
    </div>
    <NewFooter />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Footer from "./components/Footer.vue";
import Topbar from "./components/topbar/Topbar.vue";
import Alert from "./components/utils/Alert.vue";
import NewNav from "./components/NewNav.vue";
import NewFooter from "./components/NewFooter.vue";
import { analytics } from "./firebase";

export default {
  name: "App",
  components: {
    Alert,
    Topbar,
    Footer,
    NewNav,
    NewFooter,
  },

  mounted() {
    this.fetchAvailableCollections();
    this.checkForUser(this.$router);
    Paddle.Setup({
      vendor: 128736,
      eventCallback: this.updatePrices,
    });
  },

  computed: {
    ...mapGetters(["getAlert"]),
  },

  methods: {
    ...mapActions(["checkForUser"]),
    ...mapActions(["updatePrices"]),
    ...mapActions(["fetchAvailableCollections"]),
  },

  data() {
    return { count: 0, routeType: "" };
  },

  watch: {
    $route(to, from) {
      // react to route changes...
      this.routeType = to.name;
    },
  },
};
</script>
