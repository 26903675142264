<template>
  <div
    class="relative w-full min-h-dashboardCardHeight flex flex-col items-start justify-start border border-gray-300 rounded-md"
  >
    <div
      v-if="!free && productType.toLowerCase() !== 'published'"
      class="w-full absolute top-0 right-0 z-20 flex items-center justify-end"
    >
      <div
        class="group h-6 m-3 w-6 flex items-center justify-end text-yellow-100 bg-red-500 rounded-full cursor-pointer transform transition-all duration-300 hover:w-56 overflow-hidden"
      >
        <div class="flex items-center justify-between space-x-3">
          <h1
            class="text-red-100 font-semibold text-xs flex-shrink whitespace-nowrap"
          >
            Author has removed this item
          </h1>
          <svg
            class="w-6 h-6 transform transition-all duration-500 group-hover:rotate-180 flex-shrink-0"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>
    <div v-if="state === 'default'" class="w-full">
      <!-- thumbnail -->
      <div
        v-if="thumbnail"
        class="relative image-container overflow-hidden rounded-md rounded-b-none"
      >
        <img
          class="absolute inset-0 w-full object-cover"
          :src="thumbnail"
          alt="image"
        />
      </div>
      <div
        v-else
        class="relative image-container overflow-hidden rounded-md bg-white rounded-b-none flex items-center justify-center"
      >
        <svg
          class="absolute mt-12 inset-0 w-full object-cover  flex items-center justify-center"
          data-name="Layer 1"
          viewBox="0 0 559.9812 189.24273"
        >
          <defs>
            <linearGradient
              id="a"
              x1="440.75296"
              x2="574"
              y1="159.53222"
              y2="159.53222"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stop-color="#333" />
              <stop offset=".47087" stop-color="#161616" />
              <stop offset=".67149" stop-color="#1d1d1d" />
              <stop offset=".95934" stop-color="#303030" />
              <stop offset=".99953" stop-color="#333" />
            </linearGradient>
            <linearGradient
              id="b"
              x1="14.0188"
              x2="147.26601"
              y1="159.53222"
              y2="159.53222"
              xlink:href="#a"
            />
            <linearGradient
              id="c"
              x1="183.41782"
              x2="189.75559"
              y1="175.7598"
              y2="175.7598"
              xlink:href="#a"
            />
            <linearGradient
              id="d"
              x1="193.65521"
              x2="199.99299"
              y1="165.84144"
              y2="165.84144"
              xlink:href="#a"
            />
            <linearGradient
              id="e"
              x1="152.70558"
              x2="159.04335"
              y1="160.64039"
              y2="160.64039"
              xlink:href="#a"
            />
            <linearGradient
              id="f"
              x1="285.79188"
              x2="292.12966"
              y1="137.20642"
              y2="137.20642"
              xlink:href="#a"
            />
            <linearGradient
              id="g"
              x1="285.79188"
              x2="292.12966"
              y1="98.84687"
              y2="98.84687"
              xlink:href="#a"
            />
            <linearGradient
              id="h"
              x1="285.79188"
              x2="292.12966"
              y1="202.18756"
              y2="202.18756"
              xlink:href="#a"
            />
            <linearGradient
              id="i"
              x1="296.02928"
              x2="302.36705"
              y1="165.84144"
              y2="165.84144"
              xlink:href="#a"
            />
            <linearGradient
              id="j"
              x1="275.55449"
              x2="281.89226"
              y1="120.92019"
              y2="120.92019"
              xlink:href="#a"
            />
            <linearGradient
              id="k"
              x1="275.55449"
              x2="281.89226"
              y1="195.72498"
              y2="195.72498"
              xlink:href="#a"
            />
            <linearGradient
              id="l"
              x1="162.94303"
              x2="169.28074"
              y1="166.80273"
              y2="166.80273"
              xlink:href="#a"
            />
            <linearGradient
              id="m"
              x1="162.94303"
              x2="169.28074"
              y1="134.02552"
              y2="134.02552"
              xlink:href="#a"
            />
            <linearGradient
              id="n"
              x1="255.07964"
              x2="261.41741"
              y1="152.78677"
              y2="152.78677"
              xlink:href="#a"
            />
            <linearGradient
              id="o"
              x1="255.07958"
              x2="261.41735"
              y1="248.85315"
              y2="248.85315"
              xlink:href="#a"
            />
            <linearGradient
              id="p"
              x1="224.36746"
              x2="230.70523"
              y1="165.84144"
              y2="165.84144"
              xlink:href="#a"
            />
            <linearGradient
              id="q"
              x1="234.60485"
              x2="240.94262"
              y1="146.06531"
              y2="146.06531"
              xlink:href="#a"
            />
            <linearGradient
              id="r"
              x1="234.60485"
              x2="240.94262"
              y1="209.07737"
              y2="209.07737"
              xlink:href="#a"
            />
            <linearGradient
              id="s"
              x1="244.84219"
              x2="251.17996"
              y1="187.53515"
              y2="187.53515"
              xlink:href="#a"
            />
            <linearGradient
              id="t"
              x1="244.84207"
              x2="251.17984"
              y1="112.16501"
              y2="112.16501"
              xlink:href="#a"
            />
            <linearGradient
              id="u"
              x1="244.84207"
              x2="251.17984"
              y1="88.54166"
              y2="88.54166"
              xlink:href="#a"
            />
            <linearGradient
              id="v"
              x1="214.13006"
              x2="220.46778"
              y1="165.84144"
              y2="165.84144"
              gradientTransform="rotate(180 217.29892 165.84144)"
              xlink:href="#a"
            />
            <linearGradient
              id="w"
              x1="265.31709"
              x2="271.65481"
              y1="165.84144"
              y2="165.84144"
              xlink:href="#a"
            />
            <linearGradient
              id="x"
              x1="203.89261"
              x2="210.23038"
              y1="165.84141"
              y2="165.84141"
              xlink:href="#a"
            />
            <linearGradient
              id="y"
              x1="142.46818"
              x2="148.80595"
              y1="160.64042"
              y2="160.64042"
              xlink:href="#a"
            />
            <linearGradient
              id="z"
              x1="439.21296"
              x2="445.55074"
              y1="159.53213"
              y2="159.53213"
              xlink:href="#a"
            />
            <linearGradient
              id="A"
              x1="173.18037"
              x2="179.51814"
              y1="143.82555"
              y2="143.82555"
              xlink:href="#a"
            />
            <linearGradient
              id="B"
              x1="173.18037"
              x2="179.51814"
              y1="194.94415"
              y2="194.94415"
              xlink:href="#a"
            />
            <linearGradient
              id="C"
              x1="388.16595"
              x2="394.50372"
              y1="167.1952"
              y2="167.1952"
              xlink:href="#a"
            />
            <linearGradient
              id="D"
              x1="336.97892"
              x2="343.31669"
              y1="192.71587"
              y2="192.71587"
              xlink:href="#a"
            />
            <linearGradient
              id="E"
              x1="398.40334"
              x2="404.74112"
              y1="151.44531"
              y2="151.44531"
              xlink:href="#a"
            />
            <linearGradient
              id="F"
              x1="398.40334"
              x2="404.74112"
              y1="203.26061"
              y2="203.26061"
              xlink:href="#a"
            />
            <linearGradient
              id="G"
              x1="363.90975"
              x2="370.24747"
              y1="71.66339"
              y2="71.66339"
              xlink:href="#a"
            />
            <linearGradient
              id="H"
              x1="377.92849"
              x2="384.26627"
              y1="206.28537"
              y2="206.28537"
              xlink:href="#a"
            />
            <linearGradient
              id="I"
              x1="367.69116"
              x2="374.02893"
              y1="192.71584"
              y2="192.71584"
              xlink:href="#a"
            />
            <linearGradient
              id="J"
              x1="394.622"
              x2="400.95971"
              y1="116.15941"
              y2="116.15941"
              xlink:href="#a"
            />
            <linearGradient
              id="K"
              x1="347.21631"
              x2="353.55408"
              y1="171.91119"
              y2="171.91119"
              xlink:href="#a"
            />
            <linearGradient
              id="L"
              x1="347.21631"
              x2="353.55408"
              y1="222.84633"
              y2="222.84633"
              xlink:href="#a"
            />
            <linearGradient
              id="M"
              x1="316.50413"
              x2="322.84184"
              y1="165.84144"
              y2="165.84144"
              xlink:href="#a"
            />
            <linearGradient
              id="N"
              x1="306.26673"
              x2="312.60445"
              y1="165.84144"
              y2="165.84144"
              xlink:href="#a"
            />
            <linearGradient
              id="O"
              x1="326.74152"
              x2="333.07929"
              y1="192.71584"
              y2="192.71584"
              xlink:href="#a"
            />
            <linearGradient
              id="P"
              x1="343.43496"
              x2="349.77268"
              y1="121.47219"
              y2="121.47219"
              xlink:href="#a"
            />
            <linearGradient
              id="Q"
              x1="429.11559"
              x2="435.45336"
              y1="159.53216"
              y2="159.53216"
              xlink:href="#a"
            />
            <linearGradient
              id="R"
              x1="404.85939"
              x2="411.1971"
              y1="81.9054"
              y2="81.9054"
              xlink:href="#a"
            />
            <linearGradient
              id="S"
              x1="418.87813"
              x2="425.2159"
              y1="178.70488"
              y2="178.70488"
              xlink:href="#a"
            />
          </defs>
          <path
            fill="url(#a)"
            d="M572.37117 157.90339h-129.9895a1.62883 1.62883 0 000 3.25766h129.9895a1.62883 1.62883 0 100-3.25766z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#b)"
            d="M145.63725 157.90339H15.64763a1.62883 1.62883 0 100 3.25766h129.98962a1.62883 1.62883 0 000-3.25766z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#c)"
            d="M186.58671 207.73364a3.16886 3.16886 0 01-3.16889-3.16889v-57.6099a3.16891 3.16891 0 013.16889-3.16889 3.16891 3.16891 0 013.16888 3.16889v57.6099a3.16886 3.16886 0 01-3.16888 3.16889z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#d)"
            d="M196.8241 183.39119a3.16888 3.16888 0 01-3.16889-3.16889v-28.76172a3.16887 3.16887 0 013.16889-3.16889 3.16887 3.16887 0 013.16889 3.16889v28.76172a3.16888 3.16888 0 01-3.16889 3.16889z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#e)"
            d="M156.301 177.81279h-.85312a2.7423 2.7423 0 01-2.74232-2.74227v-28.43364a3.1689 3.1689 0 013.16888-3.16888 3.16886 3.16886 0 013.16889 3.16888v28.43364a2.74231 2.74231 0 01-2.74233 2.74227z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#f)"
            d="M288.96077 165.09079a3.16887 3.16887 0 01-3.16889-3.16889v-49.431a3.16892 3.16892 0 013.16889-3.16889 3.16892 3.16892 0 013.16889 3.16889v49.431a3.16887 3.16887 0 01-3.16889 3.16889z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#g)"
            d="M288.96077 105.70364a3.16887 3.16887 0 01-3.16889-3.16889V95.159a3.16888 3.16888 0 013.16889-3.16889 3.16888 3.16888 0 013.16889 3.16889v7.37576a3.16887 3.16887 0 01-3.16889 3.16888z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#h)"
            d="M288.96077 237.67171a3.16887 3.16887 0 01-3.16889-3.16889v-64.63053a3.16887 3.16887 0 013.16889-3.16889 3.16887 3.16887 0 013.16889 3.16889v64.63053a3.16887 3.16887 0 01-3.16889 3.16889z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#i)"
            d="M299.19816 206.346a3.1689 3.1689 0 01-3.16888-3.16888v-74.67138a3.16889 3.16889 0 013.16888-3.16883 3.16885 3.16885 0 013.16889 3.16883v74.67134a3.16886 3.16886 0 01-3.16889 3.16892z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#j)"
            d="M278.72338 140.17158a3.16886 3.16886 0 01-3.16889-3.16888v-32.165a3.16887 3.16887 0 013.16889-3.16889 3.16891 3.16891 0 013.16888 3.16889v32.165a3.1689 3.1689 0 01-3.16888 3.16888z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#k)"
            d="M278.72338 248.89952a3.16886 3.16886 0 01-3.16889-3.16883V145.71932a3.16886 3.16886 0 013.16889-3.16888 3.1689 3.1689 0 013.16888 3.16888v100.01137a3.16889 3.16889 0 01-3.16888 3.16883z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#l)"
            d="M166.53847 190.7141h-.85317a2.7423 2.7423 0 01-2.74227-2.74233v-41.91152a3.16886 3.16886 0 013.16883-3.16889 3.16887 3.16887 0 013.16888 3.16889v41.91152a2.7423 2.7423 0 01-2.74227 2.74233z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#m)"
            d="M166.53847 140.588h-.85317a2.74229 2.74229 0 01-2.74227-2.74227v-7.21383a3.16886 3.16886 0 013.16883-3.16889 3.16887 3.16887 0 013.16888 3.16889v7.21385a2.74229 2.74229 0 01-2.74227 2.74225z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#n)"
            d="M258.24853 234.32985a3.16891 3.16891 0 003.16888-3.16889V74.41251a3.16889 3.16889 0 00-3.16888-3.16882 3.16885 3.16885 0 00-3.16889 3.16882V231.161a3.16887 3.16887 0 003.16889 3.16885z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#o)"
            d="M258.24847 260.48641a3.16886 3.16886 0 003.16888-3.16888v-16.92876a3.16887 3.16887 0 00-3.16888-3.16889 3.16887 3.16887 0 00-3.16889 3.16889v16.92876a3.16886 3.16886 0 003.16889 3.16888z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#p)"
            d="M227.53634 247.69482a3.1689 3.1689 0 003.16889-3.16889V87.15688a3.16889 3.16889 0 00-3.16889-3.16882 3.16885 3.16885 0 00-3.16888 3.16882v157.369a3.16886 3.16886 0 003.16888 3.16894z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#q)"
            d="M237.77374 187.31276a3.1689 3.1689 0 003.16888-3.16888v-76.15714a3.16886 3.16886 0 00-3.16888-3.16888 3.16886 3.16886 0 00-3.16889 3.16888v76.15714a3.1689 3.1689 0 003.16889 3.16888z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#r)"
            d="M237.77374 229.22618a3.1689 3.1689 0 003.16888-3.16889v-33.95983a3.1689 3.1689 0 00-3.16888-3.16889 3.16886 3.16886 0 00-3.16889 3.16889v33.95983a3.16886 3.16886 0 003.16889 3.16889z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#s)"
            d="M248.01107 245.89935a3.16887 3.16887 0 003.16889-3.16889V132.33985a3.16891 3.16891 0 00-3.16889-3.16889 3.16894 3.16894 0 00-3.16888 3.16889v110.39061a3.16891 3.16891 0 003.16888 3.16889z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#t)"
            d="M248.011 126.41479a3.16887 3.16887 0 003.16889-3.16889v-22.16185a3.16885 3.16885 0 00-3.16889-3.16882 3.16885 3.16885 0 00-3.16888 3.16882v22.16185a3.16887 3.16887 0 003.16888 3.16889z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#u)"
            d="M248.011 95.159a3.16886 3.16886 0 003.16889-3.16889v-6.897a3.16885 3.16885 0 00-3.16889-3.16882 3.16885 3.16885 0 00-3.16888 3.16882v6.897A3.16886 3.16886 0 00248.011 95.159z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#v)"
            d="M217.29895 117.64484a3.16882 3.16882 0 013.16882 3.16882v90.05555a3.16883 3.16883 0 01-3.16883 3.16883h-.00006a3.16883 3.16883 0 01-3.16883-3.16883v-90.05548a3.16889 3.16889 0 013.1689-3.16889z"
            transform="rotate(-180 210.28952 130.219595)"
          />
          <path
            fill="url(#w)"
            d="M268.486 222.99037a3.16886 3.16886 0 003.16883-3.16889V111.86139a3.16886 3.16886 0 00-3.16883-3.16889 3.16888 3.16888 0 00-3.16889 3.16889v107.96009a3.16887 3.16887 0 003.16889 3.16889z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#x)"
            d="M207.06149 194.53792a3.16891 3.16891 0 01-3.16888-3.16889v-51.05524a3.16894 3.16894 0 013.16888-3.16889 3.1689 3.1689 0 013.16889 3.16889V191.369a3.16887 3.16887 0 01-3.16889 3.16892z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#y)"
            d="M145.63707 168.60232a3.16891 3.16891 0 01-3.16889-3.16889v-9.586a3.16887 3.16887 0 013.16889-3.16888 3.16886 3.16886 0 013.16888 3.16888v9.586a3.1689 3.1689 0 01-3.16888 3.16889z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#z)"
            d="M442.38185 167.494a3.16887 3.16887 0 01-3.16889-3.16888v-9.586a3.16892 3.16892 0 013.16889-3.16889 3.16892 3.16892 0 013.16889 3.16889v9.586a3.16887 3.16887 0 01-3.16889 3.16888z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#A)"
            d="M176.77581 174.44743h-.85312a2.74231 2.74231 0 01-2.74232-2.74233v-55.33254a3.1689 3.1689 0 013.16888-3.16888 3.16887 3.16887 0 013.16889 3.16888v55.33254a2.74232 2.74232 0 01-2.74233 2.74233z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#B)"
            d="M176.77581 212.0755h-.85312a2.74231 2.74231 0 01-2.74232-2.74233v-28.35149a3.1689 3.1689 0 013.16888-3.16889 3.16891 3.16891 0 013.16889 3.16889v28.35149a2.74232 2.74232 0 01-2.74233 2.74233z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#C)"
            d="M391.33483 240.54861a3.16885 3.16885 0 01-3.16888-3.16883V97.01068a3.16886 3.16886 0 013.16888-3.16888 3.16886 3.16886 0 013.16889 3.16888v140.3691a3.16885 3.16885 0 01-3.16889 3.16883z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#D)"
            d="M340.1478 230.86554a3.1689 3.1689 0 01-3.16888-3.16889v-69.96156a3.16887 3.16887 0 013.16888-3.16889 3.16888 3.16888 0 013.16889 3.16889v69.96156a3.16891 3.16891 0 01-3.16889 3.16889z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#E)"
            d="M401.57223 187.40313a3.169 3.169 0 01-3.16889-3.16889v-65.57786a3.169 3.169 0 013.16889-3.16889 3.16891 3.16891 0 013.16889 3.16889v65.57786a3.16892 3.16892 0 01-3.16889 3.16889z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#F)"
            d="M401.57223 216.49477a3.16891 3.16891 0 01-3.16889-3.16889v-20.1306a3.1689 3.1689 0 013.16889-3.16883 3.16886 3.16886 0 013.16889 3.16883v20.1306a3.16887 3.16887 0 01-3.16889 3.16889z"
            transform="translate(-14.0188 -71.24369)"
          />
          <rect
            width="6.33771"
            height="17.32632"
            x="363.90975"
            y="63.00023"
            fill="url(#G)"
            rx="3.16885"
            ry="3.16885"
          />
          <path
            fill="url(#H)"
            d="M381.09738 256.80114a3.169 3.169 0 01-3.16889-3.16889v-94.69377a3.16892 3.16892 0 013.16889-3.16889 3.16888 3.16888 0 013.16889 3.16889v94.69377a3.16892 3.16892 0 01-3.16889 3.16889z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#I)"
            d="M370.86005 231.98579a3.16886 3.16886 0 01-3.16889-3.16883v-72.20218a3.16887 3.16887 0 013.16889-3.16889 3.16891 3.16891 0 013.16888 3.16889V228.817a3.16889 3.16889 0 01-3.16888 3.16879z"
            transform="translate(-14.0188 -71.24369)"
          />
          <rect
            width="6.33771"
            height="65.67379"
            x="394.622"
            y="83.32252"
            fill="url(#J)"
            rx="3.16885"
            ry="3.16885"
          />
          <path
            fill="url(#K)"
            d="M350.3852 195.87067a3.16889 3.16889 0 01-3.16889-3.16882V151.1206a3.1689 3.1689 0 013.16889-3.16889 3.16886 3.16886 0 013.16888 3.16889v41.58125a3.16885 3.16885 0 01-3.16888 3.16882z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#L)"
            d="M350.3852 247.96a3.1689 3.1689 0 01-3.16889-3.16889v-43.88956a3.16887 3.16887 0 013.16889-3.16889 3.16887 3.16887 0 013.16888 3.16889v43.88956a3.1689 3.1689 0 01-3.16888 3.16889z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#M)"
            d="M319.673 190.02645a3.1689 3.1689 0 01-3.16888-3.16889v-42.03225a3.16886 3.16886 0 013.16888-3.16888 3.16885 3.16885 0 013.16883 3.16888v42.03225a3.16889 3.16889 0 01-3.16883 3.16889z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#N)"
            d="M309.43556 203.99114a3.16886 3.16886 0 01-3.16883-3.16889v-69.96162a3.16886 3.16886 0 013.16883-3.16889 3.16887 3.16887 0 013.16889 3.16889v69.96162a3.16888 3.16888 0 01-3.16889 3.16889z"
            transform="translate(-14.0188 -71.24369)"
          />
          <path
            fill="url(#O)"
            d="M329.91041 221.52251a3.16885 3.16885 0 01-3.16889-3.16883v-51.27562a3.16887 3.16887 0 013.16889-3.16889 3.16887 3.16887 0 013.16888 3.16889v51.27562a3.16885 3.16885 0 01-3.16888 3.16883z"
            transform="translate(-14.0188 -71.24369)"
          />
          <rect
            width="6.33771"
            height="56.56617"
            x="343.43496"
            y="93.1891"
            fill="url(#P)"
            rx="3.16885"
            ry="3.16885"
          />
          <path
            fill="url(#Q)"
            d="M432.28447 176.4882a3.16886 3.16886 0 01-3.16888-3.16889V145.745a3.16886 3.16886 0 013.16888-3.16889 3.16887 3.16887 0 013.16889 3.16889v27.5743a3.16887 3.16887 0 01-3.16889 3.1689z"
            transform="translate(-14.0188 -71.24369)"
          />
          <rect
            width="6.33771"
            height="31.60726"
            x="404.85939"
            y="66.10177"
            fill="url(#R)"
            rx="3.16885"
            ry="3.16885"
          />
          <path
            fill="url(#S)"
            d="M422.047 186.6067a3.16894 3.16894 0 01-3.16889-3.16888v-9.46589a3.1689 3.1689 0 013.16889-3.16888 3.16886 3.16886 0 013.16888 3.16888v9.46589a3.1689 3.1689 0 01-3.16888 3.16888z"
            transform="translate(-14.0188 -71.24369)"
          />
        </svg>
      </div>
      <!-- end of thumbnail -->
      <div class="my-5">
        <router-link
          v-if="productType.toLowerCase() === 'published'"
          :to="
            `/item/${title
              .toLowerCase()
              .split(' ')
              .join('-')}/${id}`
          "
          class=" px-5 py-5 hover:underline font-medium text-sm md:text-base"
        >
          {{ title }}
        </router-link>

        <h1 v-else class=" px-5 py-5 font-medium text-sm md:text-base">
          {{ title }}
        </h1>
      </div>
      <div
        v-if="!free && productType.toLowerCase() === 'published'"
        class="w-full mt-5 px-5 flex items-center justify-between gap-3"
      >
        <button
          @click="changeState('modal')"
          class="w-full py-2  border border-brandPurple rounded-md text-sm font-medium text-brandPurple whitespace-nowrap focus:outline-none"
        >
          Add license
        </button>
        <button
          @click="handleDownloadState"
          class="w-full py-2 rounded-md border border-buttonColor bg-buttonColor text-sm font-medium text-white whitespace-nowrap focus:outline-none"
        >
          Download
        </button>
      </div>

      <div
        class="mt-4 px-5 w-full flex flex-col items-start justify-start divide-y divide-gray-300 divide-opacity-50"
      >
        <div
          v-if="license.length > 0"
          class="py-4 w-full flex items-start justify-between gap-5"
        >
          <h1 class="text-sm text-gray-600 font-medium">
            {{ license[0].name }}
          </h1>
          <div class="flex flex-col items-end justify-end">
            <h1 class=" text-gray-600 font-medium text-xs">
              {{ license[0].updatedAt.split("T")[0] }}
            </h1>
            <button
              v-if="!free"
              @click="
                handleDownloadLicense(
                  license[0].id,
                  license[0].updatedAt,
                  license[0].name
                )
              "
              class="text-brandPurple text-sm  whitespace-nowrap hover:underline cursor-pointer focus:outline-none"
            >
              Download License
            </button>
          </div>
        </div>
      </div>
      <Menu
        v-if="license.length > 1"
        as="div"
        class="relative focus:outline-none outline-none"
      >
        <MenuButton
          class="mb-5 w-full block text-center text-sm text-gray-700 focus:outline-none outline-none"
          >Show more</MenuButton
        >
        <Transition
          class="absolute z-20"
          enter-active-class="transition transform duration-100 ease-in"
          enter-from-class="opacity-0 scale-95"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="transition transform duration-300 ease-out"
          leave-from-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-95"
        >
          <MenuItems
            ref="menuItems"
            id="menuItems"
            class="absolute z-20 py-2 w-full bottom-0 focus:outline-none outline-none rounded-md shadow-lg bg-white   overflow-hidden border border-gray-300 dark:border-darkBackground"
          >
            <div
              class="mt-4 px-5 w-full h-60 overflow-y-auto flex flex-col items-start justify-start divide-y divide-gray-300 divide-opacity-50"
            >
              <div
                v-for="lic in license.filter((l, i) => i > 0)"
                :key="lic"
                class="py-4 w-full flex items-start justify-between gap-5"
              >
                <h1 class="text-sm text-gray-600 font-medium">
                  {{ lic.name }}
                </h1>
                <div class="flex flex-col items-end justify-end">
                  <h1 class=" text-gray-600 font-medium text-xs">
                    {{ lic.updatedAt.split("T")[0] }}
                  </h1>
                  <button
                    v-if="!free"
                    @click="
                      handleDownloadLicense(lic.id, lic.updatedAt, lic.name)
                    "
                    class="text-brandPurple text-sm  whitespace-nowrap hover:underline cursor-pointer focus:outline-none"
                  >
                    Download License
                  </button>
                </div>
              </div>
            </div>
          </MenuItems>
        </Transition>
      </Menu>
    </div>

    <!-- add license inner modal -->
    <div v-if="state === 'modal'" class="px-5">
      <h1 class="my-5 font-medium text-sm md:text-base">
        {{ title }}
      </h1>

      <h1 class="mb-2 text-sm font-medium text-gray-800">License this item?</h1>

      <p class="mb-5 text-gray-600 text-sm">
        Register to license this for a specified project. You then get ongoing
        rights for that end use, even if you subscription ends. So make sure you
        register each project you use an item on.
      </p>

      <input
        id="product"
        @input="handleLicense"
        v-model="licenseName"
        placeholder="Project name"
        class="w-full rounded-md border text-sm placeholder-gray-500 border-gray-300 focus:outline-none px-3 py-2"
        type="text"
      />

      <div class="w-full flex items-center justify-between space-x-4">
        <button
          @click="changeState('default')"
          class="mt-6 px-4 py-2 rounded-md text-sm text-gray-500 border border-gray-300 text-center hover:bg-gray-200 focus:outline-none"
        >
          Cancel
        </button>
        <button
          @click="handleDownload"
          :class="[
            !activateDownload
              ? 'bg-gray-300 text-gray-500 pointer-events-none'
              : 'bg-buttonColor text-white',
            { 'pointer-events-none': getDownloadLoading },
          ]"
          class="mt-6 w-full flex items-center justify-center px-3 py-2 rounded-md text-sm font-medium text-center  focus:outline-none"
        >
          <Loader v-if="getDownloadLoading" :whiteSmall="true" />
          <span v-if="!getDownloadLoading">{{
            type === "download" ? "Download" : "Add license"
          }}</span>
        </button>
      </div>

      <h4
        class="mt-2 block w-full text-center text-xs fone-medium text-gray-800"
      >
        For more information see our
        <router-link to="#" class="underline">License Terms</router-link>
      </h4>
    </div>
    <!--end of add license inner modal -->
  </div>
</template>

<script>
import moment from "moment";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { mapActions, mapGetters } from "vuex";
import Loader from "./Loader.vue";
export default {
  name: "DashboardCard",

  components: { Menu, MenuButton, MenuItems, MenuItem, Loader },
  props: [
    "thumbnail",
    "title",
    "category",
    "id",
    "license",
    "contributor",
    "free",
    "page",
    "productType",
  ],
  computed: {
    ...mapGetters(["getCurrentUser"]),
    ...mapGetters(["getIsDownloaded"]),
    ...mapGetters(["getDownloadLoading"]),
  },
  methods: {
    ...mapActions(["downloadProduct"]),
    changeState(state) {
      this.state = state;
    },

    handleDownloadState() {
      this.state = "modal";
      this.type = "download";
    },

    handleLicense() {
      if (this.licenseName.length <= 0) {
        this.activateDownload = false;
      } else {
        this.activateDownload = true;
      }
    },

    handleDownload() {
      this.downloadProduct({
        id: this.id,
        license: this.licenseName,
        type: this.type,
        page: this.page,
      });
    },

    handleDownloadLicense(id, dt, name) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const date = new Date(dt);
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      const getOrdinal = function(d) {
        if (d > 3 && d < 21) return "th";
        switch (d % 10) {
          case 1:
            return "st";
          case 2:
            return "nd";
          case 3:
            return "rd";
          default:
            return "th";
        }
      };

      const day = `${date.getDate()}${getOrdinal(date.getDate())}`;

      const template = `
LICENSE CERTIFICATE: Motion Ridge Item
=================================================
This license certificate documents a license to use the item listed below
on a non-exclusive, commercial, worldwide and revokable basis, for
one Single Use for this Registered Project.

Item Title:                      ${this.title}
Item URL:                        https://motionridge.com/item/${this.title
        .toLowerCase()
        .split(" ")
        .join("-")}/${this.id}
Item ID:                         ${this.id}
Author Username:                 ${this.contributor.userName}
Licensee:                        ${this.getCurrentUser.firstName} ${
        this.getCurrentUser.lastName
      }
Registered Project Name:         ${name}
License Date:                    ${month} ${day}, ${year}
Item License Code:               ${id}

The license you hold for this item is only valid if you complete your End
Product while your subscription is active. Then the license continues
for the life of the End Product (even if your subscription ends).

For any queries related to this document or license please contact
Vooaxis Support via https://help.motionridge.vooaxis.com/hc/en-us/requests/new

Vooaxis Pvt Ltd
==== THIS IS NOT A TAX RECEIPT OR INVOICE ====`;

      const hiddenElement = document.createElement("a");

      hiddenElement.href = "data:attachment/text," + encodeURI(template);
      hiddenElement.target = "_blank";
      hiddenElement.download = `license_certificate-${id}.txt`;
      hiddenElement.click();
    },
  },
  data() {
    return {
      state: "default",
      licenseName: "",
      activateDownload: false,
      type: "license",
    };
  },
  watch: {
    getIsDownloaded: function(nv, ov) {
      if (nv) {
        this.licenseName = "";
        this.state = "default";
      }
    },
  },
};
</script>

<style scoped>
.image-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
</style>
