<template>
  <footer v-if="routeType !== 'become author'" class="glass-effect">
    <div
      v-if="
        routeType !== 'login' &&
          routeType !== 'register' &&
          routeType !== 'checkout'
      "
      class=" w-full max-w-1xl mx-auto flex lg:items-start lg:justify-between items-center justify-center pt-20 px-5 2xl:px-0"
    >
      <router-link
        to="/"
        @mouseenter="logoHovered = true"
        @mouseleave="logoHovered = false"
        class="lg:-ml-2 flex flex-col lg:items-start lg:justify-start items-center justify-center"
      >
        <ColorFooterLogo v-if="logoHovered" />
        <WhiteFooterLogo v-else />
      </router-link>
    </div>

    <div
      v-if="
        routeType !== 'login' &&
          routeType !== 'register' &&
          routeType !== 'checkout'
      "
      class="w-full mt-10 pb-20 px-5 2xl:px-0 max-w-1xl gap-10 mx-auto flex lg:flex-row lg:items-start lg:justify-between flex-col items-center justify-center"
    >
      <div
        class="flex flex-col items-center justify-center lg:items-start lg:justify-start"
      >
        <h3
          class="lg:mt-5 mt-3 text-sm text-white font-bold uppercase tracking-wide"
        >
          made with love.
        </h3>

        <h6 class="mt-3 text-xs text-gray-500 text-center lg:text-left">
          &copy; {{ getDate }} Vooaxis Pvt Ltd <br />
          All rights reserved.
        </h6>
      </div>

      <div class="mt-10 w-full max-w-5xl text-center lg:text-left lg:mt-5">
        <h3 class="text-xl md:text-2xl w-full max-w-5xl text-white font-medium">
          Top Creative digital assets on Vooaxis Market. Everything you're
          looking<br />
          for from project files and video templates - its here.
        </h3>

        <!-- links -->
        <div
          class="mt-20 flex flex-col md:flex-row items-center justify-center md:items-start md:justify-between w-full gap-10"
        >
          <div>
            <h3
              class="uppercase font-semibold text-xs md:text-sm tracking-wide mb-6 text-gray-400"
            >
              {{ footerLinks[0].title }}
            </h3>
            <div class="mt-3 lg:mt-12 flex flex-col space-y-4">
              <a
                v-for="link in footerLinks[0].links"
                :key="link.url"
                :href="link.url"
                class="text-xs text-white hover:underline hover:font-semibold cursor-pointer"
                >{{ link.name }}
              </a>
            </div>
          </div>

          <div>
            <h3
              class="uppercase font-semibold text-xs md:text-sm tracking-wide mb-6 text-gray-400"
            >
              {{ footerLinks[2].title }}
            </h3>
            <div class="mt-3 lg:mt-12  flex flex-col space-y-4">
              <a
                v-for="link in footerLinks[2].links"
                :key="link.url"
                :href="link.url"
                class="text-xs text-white hover:underline hover:font-semibold cursor-pointer"
                >{{ link.name }}
              </a>
            </div>
          </div>

          <div>
            <h3
              class="uppercase font-semibold text-xs md:text-sm tracking-wide mb-6 text-gray-400"
            >
              {{ footerLinks[3].title }}
            </h3>
            <div class="mt-3 lg:mt-12  flex flex-col space-y-4">
              <a
                v-for="link in footerLinks[3].links"
                :key="link.url"
                :href="link.url"
                class="text-xs text-white hover:underline hover:font-semibold cursor-pointer"
                >{{ link.name }}
              </a>
            </div>
          </div>

          <!-- social -->
          <div class="-mt-2 ">
            <div class="-ml-3 flex items-start justify-start ">
              <a
                href="https://www.facebook.com/vooaxis"
                target="_blank"
                class="cursor-pointer p-2 hover:text-gray-200 text-gray-400 rounded-full transition-colors duration-300"
              >
                <svg
                  class="h-6 w-6"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12.001 2.002c-5.522 0-9.999 4.477-9.999 9.999c0 4.99 3.656 9.126 8.437 9.879v-6.988h-2.54v-2.891h2.54V9.798c0-2.508 1.493-3.891 3.776-3.891c1.094 0 2.24.195 2.24.195v2.459h-1.264c-1.24 0-1.628.772-1.628 1.563v1.875h2.771l-.443 2.891h-2.328v6.988C18.344 21.129 22 16.992 22 12.001c0-5.522-4.477-9.999-9.999-9.999z"
                    fill="currentColor"
                  />
                  <rect
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                    fill="rgba(0, 0, 0, 0)"
                  />
                </svg>
              </a>

              <a
                href="https://twitter.com/vooaxis"
                target="_blank"
                class="cursor-pointer p-2 hover:text-gray-200 text-gray-400 rounded-full transition-colors duration-300"
              >
                <svg
                  class="h-6 w-6"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M19.633 7.997c.013.175.013.349.013.523c0 5.325-4.053 11.461-11.46 11.461c-2.282 0-4.402-.661-6.186-1.809c.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721a4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062c.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973a4.02 4.02 0 0 1-1.771 2.22a8.073 8.073 0 0 0 2.319-.624a8.645 8.645 0 0 1-2.019 2.083z"
                    fill="currentColor"
                  />
                  <rect
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                    fill="rgba(0, 0, 0, 0)"
                  />
                </svg>
              </a>

              <a
                href="https://www.instagram.com/vooaxis"
                target="_blank"
                class="cursor-pointer p-2 hover:text-gray-200 text-gray-400 rounded-full transition-colors duration-300"
              >
                <svg
                  class="h-6 w-6"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M20.947 8.305a6.53 6.53 0 0 0-.419-2.216a4.61 4.61 0 0 0-2.633-2.633a6.606 6.606 0 0 0-2.186-.42c-.962-.043-1.267-.055-3.709-.055s-2.755 0-3.71.055a6.606 6.606 0 0 0-2.185.42a4.607 4.607 0 0 0-2.633 2.633a6.554 6.554 0 0 0-.419 2.185c-.043.963-.056 1.268-.056 3.71s0 2.754.056 3.71c.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632a6.584 6.584 0 0 0 2.185.45c.963.043 1.268.056 3.71.056s2.755 0 3.71-.056a6.59 6.59 0 0 0 2.186-.419a4.615 4.615 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.187c.043-.962.056-1.267.056-3.71c-.002-2.442-.002-2.752-.058-3.709zm-8.953 8.297c-2.554 0-4.623-2.069-4.623-4.623s2.069-4.623 4.623-4.623a4.623 4.623 0 0 1 0 9.246zm4.807-8.339c-.597 0-1.078-.482-1.078-1.078a1.077 1.077 0 1 1 2.155 0c0 .596-.482 1.078-1.077 1.078z"
                    fill="currentColor"
                  />
                  <circle
                    cx="11.994"
                    cy="11.979"
                    r="3.003"
                    fill="currentColor"
                  />
                  <rect
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                    fill="rgba(0, 0, 0, 0)"
                  />
                </svg>
              </a>

              <a
                href="https://www.youtube.com/channel/UCZMyk_9kKsTPyLE0w0gyeHw"
                target="_blank"
                class="cursor-pointer p-2 hover:text-gray-200 text-gray-400 rounded-full transition-colors duration-300"
              >
                <svg
                  class="h-6 w-6"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M21.593 7.203a2.506 2.506 0 0 0-1.762-1.766C18.265 5.007 12 5 12 5s-6.264-.007-7.831.404a2.56 2.56 0 0 0-1.766 1.778c-.413 1.566-.417 4.814-.417 4.814s-.004 3.264.406 4.814c.23.857.905 1.534 1.763 1.765c1.582.43 7.83.437 7.83.437s6.265.007 7.831-.403a2.515 2.515 0 0 0 1.767-1.763c.414-1.565.417-4.812.417-4.812s.02-3.265-.407-4.831zM9.996 15.005l.005-6l5.207 3.005l-5.212 2.995z"
                    fill="currentColor"
                  />
                  <rect
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                    fill="rgba(0, 0, 0, 0)"
                  />
                </svg>
              </a>

              <a
                href="https://www.pinterest.com/vooaxis"
                target="_blank"
                class="cursor-pointer p-2 hover:text-gray-200 text-gray-400 rounded-full transition-colors duration-300"
              >
                <svg
                  class="h-6 w-6"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M11.99 2C6.472 2 2 6.473 2 11.99c0 4.232 2.633 7.85 6.35 9.306c-.088-.79-.166-2.006.034-2.868c.182-.78 1.172-4.966 1.172-4.966s-.299-.599-.299-1.484c0-1.388.805-2.425 1.808-2.425c.853 0 1.264.64 1.264 1.407c0 .858-.546 2.139-.827 3.327c-.235.994.499 1.805 1.479 1.805c1.775 0 3.141-1.872 3.141-4.575c0-2.392-1.719-4.064-4.173-4.064c-2.843 0-4.512 2.132-4.512 4.335c0 .858.331 1.779.744 2.28a.3.3 0 0 1 .069.286c-.076.315-.245.994-.277 1.133c-.044.183-.145.222-.335.134c-1.247-.581-2.027-2.405-2.027-3.871c0-3.151 2.289-6.045 6.601-6.045c3.466 0 6.159 2.469 6.159 5.77c0 3.444-2.171 6.213-5.184 6.213c-1.013 0-1.964-.525-2.29-1.146l-.623 2.374c-.225.868-.834 1.956-1.241 2.62a10 10 0 0 0 2.958.445c5.517 0 9.99-4.473 9.99-9.99S17.507 2 11.99 2"
                    fill="currentColor"
                  />
                  <rect
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                    fill="rgba(0, 0, 0, 0)"
                  />
                </svg>
              </a>
            </div>

            <div class="mt-10 flex flex-col gap-3">
              <a
                href="https://vooaxis.com/about-us"
                class="text-xs uppercase tracking-wide text-gray-500 hover:text-white hover:underline  cursor-pointer"
                >About Vooaxis
              </a>
              <a
                href="https://vooaxis.com/privacy"
                class="text-xs uppercase tracking-wide text-gray-500 hover:text-white hover:underline  cursor-pointer"
                >Privacy policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="
        routeType === 'login' ||
          routeType === 'register' ||
          routeType === 'checkout'
      "
      class="py-4 flex flex-col md:flex-row items-center justify-center lg:items-center lg:justify-between px-5 2xl:px-0 w-full max-w-1xl mx-auto"
    >
      <h6 class="text-xs text-gray-500 text-center lg:text-left">
        &copy; {{ getDate }} Vooaxis Pvt Ltd All rights reserved
      </h6>

      <div class="flex items-start justify-start ">
        <a
          href="https://www.facebook.com/vooaxis"
          target="_blank"
          class="cursor-pointer p-2 hover:text-gray-200 text-gray-400 rounded-full transition-colors duration-300"
        >
          <svg
            class="h-6 w-6"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M12.001 2.002c-5.522 0-9.999 4.477-9.999 9.999c0 4.99 3.656 9.126 8.437 9.879v-6.988h-2.54v-2.891h2.54V9.798c0-2.508 1.493-3.891 3.776-3.891c1.094 0 2.24.195 2.24.195v2.459h-1.264c-1.24 0-1.628.772-1.628 1.563v1.875h2.771l-.443 2.891h-2.328v6.988C18.344 21.129 22 16.992 22 12.001c0-5.522-4.477-9.999-9.999-9.999z"
              fill="currentColor"
            />
            <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </a>

        <a
          href="https://twitter.com/vooaxis"
          target="_blank"
          class="cursor-pointer p-2 hover:text-gray-200 text-gray-400 rounded-full transition-colors duration-300"
        >
          <svg
            class="h-6 w-6"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M19.633 7.997c.013.175.013.349.013.523c0 5.325-4.053 11.461-11.46 11.461c-2.282 0-4.402-.661-6.186-1.809c.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721a4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062c.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973a4.02 4.02 0 0 1-1.771 2.22a8.073 8.073 0 0 0 2.319-.624a8.645 8.645 0 0 1-2.019 2.083z"
              fill="currentColor"
            />
            <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </a>

        <a
          href="https://www.instagram.com/vooaxis"
          target="_blank"
          class="cursor-pointer p-2 hover:text-gray-200 text-gray-400 rounded-full transition-colors duration-300"
        >
          <svg
            class="h-6 w-6"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M20.947 8.305a6.53 6.53 0 0 0-.419-2.216a4.61 4.61 0 0 0-2.633-2.633a6.606 6.606 0 0 0-2.186-.42c-.962-.043-1.267-.055-3.709-.055s-2.755 0-3.71.055a6.606 6.606 0 0 0-2.185.42a4.607 4.607 0 0 0-2.633 2.633a6.554 6.554 0 0 0-.419 2.185c-.043.963-.056 1.268-.056 3.71s0 2.754.056 3.71c.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632a6.584 6.584 0 0 0 2.185.45c.963.043 1.268.056 3.71.056s2.755 0 3.71-.056a6.59 6.59 0 0 0 2.186-.419a4.615 4.615 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.187c.043-.962.056-1.267.056-3.71c-.002-2.442-.002-2.752-.058-3.709zm-8.953 8.297c-2.554 0-4.623-2.069-4.623-4.623s2.069-4.623 4.623-4.623a4.623 4.623 0 0 1 0 9.246zm4.807-8.339c-.597 0-1.078-.482-1.078-1.078a1.077 1.077 0 1 1 2.155 0c0 .596-.482 1.078-1.077 1.078z"
              fill="currentColor"
            />
            <circle cx="11.994" cy="11.979" r="3.003" fill="currentColor" />
            <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </a>

        <a
          href="https://www.youtube.com/channel/UCZMyk_9kKsTPyLE0w0gyeHw"
          target="_blank"
          class="cursor-pointer p-2 hover:text-gray-200 text-gray-400 rounded-full transition-colors duration-300"
        >
          <svg
            class="h-6 w-6"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M21.593 7.203a2.506 2.506 0 0 0-1.762-1.766C18.265 5.007 12 5 12 5s-6.264-.007-7.831.404a2.56 2.56 0 0 0-1.766 1.778c-.413 1.566-.417 4.814-.417 4.814s-.004 3.264.406 4.814c.23.857.905 1.534 1.763 1.765c1.582.43 7.83.437 7.83.437s6.265.007 7.831-.403a2.515 2.515 0 0 0 1.767-1.763c.414-1.565.417-4.812.417-4.812s.02-3.265-.407-4.831zM9.996 15.005l.005-6l5.207 3.005l-5.212 2.995z"
              fill="currentColor"
            />
            <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </a>

        <a
          href="https://www.pinterest.com/vooaxis"
          target="_blank"
          class="cursor-pointer p-2 hover:text-gray-200 text-gray-400 rounded-full transition-colors duration-300"
        >
          <svg
            class="h-6 w-6"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M11.99 2C6.472 2 2 6.473 2 11.99c0 4.232 2.633 7.85 6.35 9.306c-.088-.79-.166-2.006.034-2.868c.182-.78 1.172-4.966 1.172-4.966s-.299-.599-.299-1.484c0-1.388.805-2.425 1.808-2.425c.853 0 1.264.64 1.264 1.407c0 .858-.546 2.139-.827 3.327c-.235.994.499 1.805 1.479 1.805c1.775 0 3.141-1.872 3.141-4.575c0-2.392-1.719-4.064-4.173-4.064c-2.843 0-4.512 2.132-4.512 4.335c0 .858.331 1.779.744 2.28a.3.3 0 0 1 .069.286c-.076.315-.245.994-.277 1.133c-.044.183-.145.222-.335.134c-1.247-.581-2.027-2.405-2.027-3.871c0-3.151 2.289-6.045 6.601-6.045c3.466 0 6.159 2.469 6.159 5.77c0 3.444-2.171 6.213-5.184 6.213c-1.013 0-1.964-.525-2.29-1.146l-.623 2.374c-.225.868-.834 1.956-1.241 2.62a10 10 0 0 0 2.958.445c5.517 0 9.99-4.473 9.99-9.99S17.507 2 11.99 2"
              fill="currentColor"
            />
            <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";
import ColorFooterLogo from "./utils/ColorFooterLogo.vue";
import WhiteFooterLogo from "./utils/WhiteFooterLogo.vue";
export default {
  name: "NewFooter",
  components: { ColorFooterLogo, WhiteFooterLogo },
  computed: {
    ...mapGetters(["footerLinks"]),

    getDate() {
      return new Date().getFullYear();
    },
  },
  data() {
    return { routeType: "General", logoHovered: false };
  },
  watch: {
    $route(to, from) {
      // react to route changes...
      this.routeType = to.name;
    },
  },
};
</script>

<style>
.glass-effect {
  background-color: rgba(38, 38, 38, 0.9);
  backdrop-filter: blur(7px);
}

.blur {
  background-color: rgba(38, 38, 38, 0.97);
  backdrop-filter: blur(20px);
}
</style>
