<template>
  <section class="mt-14 min-h-screen w-full bg-subtleGray">
    <div
      class="w-full pt-20 max-w-1xl mx-auto flex flex-col items-center justify-start"
    >
      <!-- first section -->
      <div class="w-full flex items-center flex-col justify-center">
        <img
          src="../assets/motionridge.svg"
          class="w-16 h-16"
          alt="motion ridge logo"
        />
        <!-- badge -->
        <span
          class="mt-5 px-3 py-1 rounded-md bg-gray-200 text-gray-700 text-sm font-medium"
        >
          Made With Love
        </span>
        <!-- end of badge -->
        <h1
          class="mt-5 text-3xl md:text-5xl text-center font-bold text-gray-800"
        >
          No Limit For Your Creativity
        </h1>
        <h3 class="mt-5 text-center text-base text-gray-600 max-w-xl">
          Motion Ridge empowers you to bring creative possibilities around the
          world for your design projects. Together with the community of
          talented designers, our subscription provides the best design for
          everyone.
        </h3>
      </div>
      <!-- end of first section -->

      <!-- second section -->
      <div class="my-20 w-full flex items-center flex-col justify-center">
        <!-- badge -->
        <span
          class="mt-5 px-3 py-1 rounded-md bg-gray-200 text-gray-700 text-sm font-medium"
        >
          The Key Points
        </span>
        <!-- end of badge -->

        <section class="text-gray-600">
          <div class="container px-5 md:py-10 mx-auto flex flex-wrap">
            <div
              class="flex flex-col-reverse lg:flex-row justify-between w-full"
            >
              <div class="w-full mt-10 md:mt-0 md:pr-10 md:py-6">
                <div class="flex relative pb-12">
                  <div
                    class="h-full w-10 absolute inset-0 flex items-center justify-center"
                  >
                    <div
                      class="h-full w-1 bg-gray-200 pointer-events-none"
                    ></div>
                  </div>
                  <div
                    class="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                  </div>
                  <div class="flex-grow pl-4">
                    <h2
                      class="font-medium text-base md:text-lg text-gray-900 mb-1"
                    >
                      +Unlimited Downloads
                    </h2>
                    <p class="leading-relaxed">
                      You want to focus on the best design, do not worry about
                      registering a license or managing download limits. With
                      unlimited downloads, focus on your creativity.
                    </p>
                  </div>
                </div>
                <div class="flex relative pb-12">
                  <div
                    class="h-full w-10 absolute inset-0 flex items-center justify-center"
                  >
                    <div
                      class="h-full w-1 bg-gray-200 pointer-events-none"
                    ></div>
                  </div>
                  <div
                    class="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                  </div>
                  <div class="flex-grow pl-4">
                    <h2
                      class="font-medium text-base md:text-lg text-gray-900 mb-1"
                    >
                      Made by Top Designers
                    </h2>
                    <p class="leading-relaxed">
                      You are looking for high quality and unique items for your
                      creative projects. We complete your search with a
                      community of talented designers interested.
                    </p>
                  </div>
                </div>
                <div class="flex relative pb-12">
                  <div
                    class="h-full w-10 absolute inset-0 flex items-center justify-center"
                  >
                    <div
                      class="h-full w-1 bg-gray-200 pointer-events-none"
                    ></div>
                  </div>
                  <div
                    class="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                  </div>
                  <div class="flex-grow pl-4">
                    <h2
                      class="font-medium text-base md:text-lg text-gray-900 mb-1"
                    >
                      Share Your Perfection
                    </h2>
                    <p class="leading-relaxed">
                      As a company, Vooaxis believes that their success by
                      working with the community takes us on the path to success
                      as well. We are proud to offer independent designers a way
                      to earn what they love.
                    </p>
                  </div>
                </div>
                <div class="flex relative pb-12">
                  <div
                    class="h-full w-10 absolute inset-0 flex items-center justify-center"
                  >
                    <div
                      class="h-full w-1 bg-gray-200 pointer-events-none"
                    ></div>
                  </div>
                  <div
                    class="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                  </div>
                  <div class="flex-grow pl-4">
                    <h2
                      class="font-medium text-base md:text-lg text-gray-900 mb-1"
                    >
                      Affordable prices for everyone!
                    </h2>
                    <p class="leading-relaxed">
                      Ridge is a membership based website that focuses on
                      delivering the highest quality, most user-friendly video
                      products at the most affordable prices.
                    </p>
                  </div>
                </div>
                <div class="flex relative">
                  <div
                    class="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                  </div>
                  <div class="flex-grow pl-4">
                    <h2
                      class="font-medium text-base md:text-lg text-gray-900 mb-1"
                    >
                      One simple commercial license
                    </h2>
                    <p class="leading-relaxed">
                      We want to ease in doing what you love. That’s why we came
                      up with one license that gives you broad commercial
                      rights; build great features with one simple commercial
                      license possibility.
                    </p>
                  </div>
                </div>
              </div>
              <div class="md:min-w-lottiWidth 2xl:min-w-largeLottiWidth ">
                <lottie-player
                  src="https://assets2.lottiefiles.com/packages/lf20_9xrenieu.json"
                  class="w-full object-cover object-center rounded-lg md:mt-0 mt-5"
                  background="transparent"
                  speed="1"
                  loop
                  autoplay
                ></lottie-player>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- end of second section -->
    </div>
  </section>
</template>

<script>
export default {
  name: "About",
  components: {},
};
</script>
