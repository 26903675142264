<template>
	<div
		class="p-3 max-w-sm rounded-md bg-white shadow-md cursor-default border-t-8 flex flex-col items-center"
		:class="{
			'border-green-600': type === 'success',
			'border-yellow-400': type === 'warning',
			'border-red-500': type === 'error'
		}"
	>
		<div class="flex gap-2 max-w-xs md:max-w-full items-start justify-center">
			<div class="h-5 w-5">
				<span
					class="iconify h-5 w-5"
					:class="{
						'text-green-600': type === 'success',
						'text-yellow-400': type === 'warning',
						'text-red-500': type === 'error'
					}"
					:data-icon="icon"
					data-inline="false"
				></span>
			</div>

			<div class="font-def">
				<h1 class="text-sm md:text-base font-semibold text-gray-900">
					{{ title }}
				</h1>
				<h3 class="mt-1 gray-600 text-xs">
					{{ sub }}
				</h3>
			</div>

			<button
				@click="handleRemove"
				class="h-5 w-5 md:h-6 md:w-6 focus:outline-none rounded-md text-gray-600 bg-gray-300 md:bg-white cursor-pointer hover:bg-gray-100 p-1 flex items-center justify-center transition-colors duration-150"
			>
				<svg class="text-gray-600" viewBox="0 0 20 20" fill="currentColor">
					<path
						fill-rule="evenodd"
						d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
						clip-rule="evenodd"
					/>
				</svg>
			</button>
		</div>

		<button
			v-if="button"
			@click.once="handleResend"
			class="mt-2 w-full px-3 py-2 rounded-md text-sm bg-gray-100 text-gray-800 hover:bg-gray-200 transition duration-200 focus:outline-none flex items-center justify-center"
		>
			{{ button.name }}
		</button>
	</div>
</template>

<script>
import { mapActions } from "vuex"
export default {
	name: "Alert",
	props: ["id", "title", "sub", "icon", "type", "button"],
	components: {},

	methods: {
		...mapActions(["removeAlertById"]),
		...mapActions(["resendVerificationEmail"]),

		handleResend() {
			this.resendVerificationEmail()
			setTimeout(() => {
				this.removeAlertById(this.id)
			}, 1500)
		},

		handleRemove() {
			this.removeAlertById(this.id)
		}
	}
}
</script>
