import axios from "axios";
import router from "../../router/index";
import setAuthToken from "../../config/setAuthToken";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

const state = {
  loading: false,
  userNameLoading: false,
  isUserNameExist: false,
  alert: [],
  currentUser: null,
  currentUserLoading: false,
  loginError: { email: null, password: null },
  cancelToken: undefined,
  userUpdated: false,

  registerError: {
    firstName: null,
    lastName: null,
    userName: null,
    email: null,
    password: null,
    confirmpassword: null,
  },

  forgotPasswordError: { email: null },
  count: 0,

  updateUserLoading: false,
};

const getters = {
  getUserUpdated: (state) => state.userUpdated,
  getUpdateUserLoading: (state) => state.updateUserLoading,
  getCurrentUser: (state) => state.currentUser,
  getCurrentUserLoading: (state) => state.currentUserLoading,
  getAuthLoading: (state) => state.loading,
  getUserNameLoading: (state) => state.userNameLoading,
  getIsUserNameExist: (state) => state.isUserNameExist,
  getLoginError: (state) => state.loginError,
  getRegisterError: (state) => state.registerError,
  getForgotPasswordError: (state) => state.forgotPasswordError,
  getAlert: (state) => [...state.alert].reverse().slice(0, 4),
};

const actions = {
  async updateuserInformation({ commit, state }, params) {
    try {
      state.currentUser.address1 = params.form.address1;
      commit("setUpdateUserLoading", true);
      await axios.patch(
        `${process.env.VUE_APP_BASE_URI}/user/update-user-info`,
        params.form
      );
      commit("setUserUpdated", true);
      commit("setUpdateUserLoading", true);
      const plan = params.plan;

      params.router.push(`/checkout/${params.route.params.plan}`);
    } catch (error) {
      console.log(error);
    }
  },

  async verifyEmail({ commit }, token) {
    try {
      commit("setLoading", true);
      axios.post(
        `${process.env.VUE_APP_BASE_URI}/user/verify-my-email`,
        {
          token,
        },
        { "x-auth-token": token }
      );
      commit("setLoading", false);
      router.push("/");
    } catch (error) {
      console.log(error);
    }
  },

  async checkForUser({ commit }, router) {
    let token = localStorage.getItem("token");

    if (token) {
      setAuthToken(token);
      try {
        commit("setCurrentUserLoading", true);
        const { data } = await axios.get(
          `${process.env.VUE_APP_BASE_URI}/user/check-for-user`
        );

        commit("setCurrentUser", data);
        localStorage.setItem("user", data.id);

        if (!data) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }

        let affiliate = window.location.search.split("?ref=")[1];

        if (affiliate) {
          router.push("/");
        }

        commit("setCurrentUser", data);
        commit("setCurrentUserLoading", false);
        setTimeout(async () => {
          const { data } = await axios.get(
            `${process.env.VUE_APP_BASE_URI}/user/check-for-user`
          );
          if (!data.isEmailVerified) {
            commit("setAlert", {
              id: Math.floor(Math.random() * 10000),
              title: "Your Email is not yet verified",
              sub: "Click the link sent to your email to verify your account",
              icon: "bi:info-circle-fill",
              type: "warning",
              button: {
                name: "Resend Verification email",
                action: "resendVerificationEmail",
              },
            });
          }
        }, 3000);
      } catch (error) {
        if (error.response.data.code === "invalid-token") {
          commit("setAlert", {
            id: Math.floor(Math.random() * 10000),
            title: "Access deinied! token Invalid",
            sub: "Your access token is not valid",
            icon: "clarity:error-standard-solid",
            type: "error",
          });
        }
        if (error.response.data.code === "no-token") {
          commit("setAlert", {
            id: Math.floor(Math.random() * 10000),
            title: "Token expired",
            sub: "Your access token is expired, request for a new token",
            icon: "clarity:error-standard-solid",
            type: "error",
          });
        }
      }
    } else {
      let affiliate = window.location.search.split("?ref=")[1];

      if (affiliate) {
        console.log({ affiliate });
        localStorage.setItem("affiliate", affiliate);
        router.push("/");

        await axios.post(
          `${process.env.VUE_APP_BASE_URI}/affiliate/increment-click`,
          {
            id: affiliate,
          }
        );
      }
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    }
  },

  async checkForUserName({ commit, state }, val) {
    try {
      commit("setUserNameLoading", true);

      if (typeof state.cancelToken != typeof undefined) {
        state.cancelToken.cancel("cancelling the previous request");
      }

      commit("setCancelToken", axios.CancelToken.source());

      const { data } = await axios.post(
        `${process.env.VUE_APP_BASE_URI}/user/check-username`,
        {
          userName: val,
        },
        {
          cancelToken: state.cancelToken.token,
        }
      );

      if (data) {
        commit("setIsUserNameExist", true);
        commit("setRegisterError", {
          firstName: null,
          lastName: null,
          userName: "User name already taken",
          email: null,
          password: null,
          confirmpassword: null,
        });
      } else {
        commit("setIsUserNameExist", false);
      }
      commit("setUserNameLoading", false);
    } catch (error) {
      console.log(error);
    }
  },

  async resendVerificationEmail({ commit }) {
    const token = localStorage.getItem("token");
    try {
      commit("setLoading", true);
      await axios.post(`${process.env.VUE_APP_BASE_URI}/user/resendemail`, {
        token,
        location: "motionridge",
      });

      commit("setAlert", {
        id: Math.floor(Math.random() * 10000),
        title: "Verification email sent",
        sub: "Click the link sent to your email to verify your account",
        icon: "bi:shield-fill-check",
        type: "success",
      });
      commit("setLoading", false);
    } catch (error) {
      commit("setLoading", false);
      console.log(error);
    }
  },

  appendAlert({ commit }, alert) {
    let id = Math.floor(Math.random() * 10000);
    const formattedAlert = { id, ...alert };
    commit("setAlert", formattedAlert);
  },

  removeAlertById({ commit }, id) {
    commit("removeAlert", id);
  },

  async login({ commit }, form) {
    try {
      commit("setLoading", true);

      const { data } = await axios.post(
        `${process.env.VUE_APP_BASE_URI}/user/login`,
        form
      );

      localStorage.setItem("token", data.token);

      setAuthToken(data.token);

      commit("setCurrentUser", data);

      commit("setLoading", false);

      router.push("/");
    } catch (error) {
      console.log(error);
      commit("setLoading", false);

      if (error.response.data.type === "invalid") {
        commit("setLoginError", {
          email: "invalid credentials, Try again",
          password: "invalid credentials, Try again",
        });
      } else if (error.response.data.type === "suspended") {
        commit("setLoginError", {
          email: "Your account has been put on suspended. Please contact us.",
          password:
            "Your account has been put on suspended. Please contact us.",
        });
      } else if (error.response.data.type === "deleted") {
        commit("setLoginError", {
          email: "Your account has been Terminated. Please contact us.",
          password: "Your account has been Terminated. Please contact us.",
        });
      }
    }
  },

  async register({ commit }, form) {
    try {
      commit("setLoading", true);

      let affiliateId = null;

      affiliateId = localStorage.getItem("affiliate");

      const userData = { ...form, location: "motionridge", affiliateId };

      const { data } = await axios.post(
        `${process.env.VUE_APP_BASE_URI}/user/create`,
        userData
      );

      localStorage.setItem("token", data.token);

      localStorage.removeItem("affiliate");

      commit("setAlert", {
        id: Math.floor(Math.random() * 10000),
        title: "Verification email sent",
        sub: "Click the link sent to your email to verify your account",
        icon: "bi:shield-fill-check",
        type: "success",
      });

      commit("setCurrentUser", data);
      commit("setLoading", false);

      window.location = "/";

      // create user object in userCollections
      await fb.usersCollection.doc(data.id).set({
        firstName: form.firstName,
        lastName: form.lastName,
        userName: form.userName,
        subscribed: form.subscribed,
      });
    } catch (error) {
      commit("setLoading", false);
      if (error.response.data.code === "auth/email-already-in-use") {
        commit("setRegisterError", {
          firstName: null,
          lastName: null,
          userName: null,
          email: "This email address already taken",
          password: null,
          confirmpassword: null,
        });
      }
    }
  },

  //resets password in db
  async resetPassword({ commit }, { password, token }) {
    try {
      commit("setLoading", true);
      await axios.post(`${process.env.VUE_APP_BASE_URI}/user/reset-password`, {
        password,
        token,
      });
      commit("setLoading", false);
      router.push("/login");
      commit("setAlert", {
        id: Math.floor(Math.random() * 10000),
        title: "Reset password complete",
        sub: "Enter your new password to log in",
        icon: "heroicons-solid:check-circle",
        type: "success",
      });
    } catch (error) {
      commit("setLoading", false);
      console.log(error);
    }
  },

  async resetPasswordLink({ commit }, email) {
    try {
      commit("setLoading", true);
      await axios.post(
        `${process.env.VUE_APP_BASE_URI}/user/send-reset-password`,
        {
          email,
          location: "motionridge",
        }
      );
      commit("setLoading", false);
      commit("setAlert", {
        id: Math.floor(Math.random() * 10000),
        title: "Reset password email sent",
        sub: "Click the link sent to your email to reset your password",
        icon: "heroicons-solid:check-circle",
        type: "success",
      });
      router.push("/login");
    } catch (error) {
      commit("setLoading", false);
      console.log(error);
      if (error.response.data.code === "auth/user-not-found") {
        commit("setForgotPasswordError", {
          email: "This email is not registered",
        });
      }
    }
  },

  async logout({ commit }) {
    // log user out
    localStorage.removeItem("token");
    // clear user data from state
    commit("setCurrentUser", null);

    router.push("/");
  },
};

const mutations = {
  setUserUpdated: (state, val) => (state.userUpdated = val),
  setCancelToken: (state, val) => (state.cancelToken = val),
  setUpdateUserLoading: (state, val) => (state.updateUserLoading = val),
  setCurrentUserLoading: (state, val) => (state.currentUserLoading = val),
  setCurrentUser: (state, currentUser) => (state.currentUser = currentUser),
  setLoading: (state, val) => (state.loading = val),
  setUserNameLoading: (state, val) => (state.userNameLoading = val),
  setIsUserNameExist: (state, val) => (state.isUserNameExist = val),
  setLoginError: (state, error) => (state.loginError = error),
  setRegisterError: (state, error) => (state.registerError = error),
  setForgotPasswordError: (state, error) => (state.forgotPasswordError = error),
  setAlert: (state, alertContent) => {
    state.alert.push(alertContent);

    setTimeout(() => {
      let temp = state.alert;
      temp.shift();
      state.alert = temp;
    }, 15000);
  },
  removeAlert: (state, id) =>
    (state.alert = state.alert.filter((a) => a.id !== id)),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
