<template>
  <div class="">
    <Listbox
      as="div"
      v-slot="{ open }"
      v-model="selected"
      class="relative focus:outline-none"
    >
      <ListboxButton v-slot="{ active }" class="focus:outline-none">
        <span
          v-on:blur="handleFocusOut"
          class="px-3 py-2 w-full flex items-center justify-between text-left text-sm focus:outline-none rounded-md rounded-r-none"
          :class="active || open ? 'bg-gray-500' : ''"
        >
          {{ selected.name }}
          <svg
            class="w-5 h-5 text-white ml-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
      </ListboxButton>
      <Transition
        enter-active-class="transition duration-100"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition duration-300"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="mt-2 py-1 max-h-80 md:max-h-screen overflow-auto pb-3 px-3 xl:px-6 whitespace-nowrap flex flex-1 flex-col flex-shrink-0 absolute z-50 bg-extraDarkOverlay focus:outline-none shadow rounded-md"
        >
          <h1
            class="py-2 px-3 font-semibold uppercase tracking-wide text-gray-00 text-xxs"
          >
            search items
          </h1>
          <ListboxOption
            as="div"
            class="focus:outline-none"
            v-for="option in options"
            :key="option.id"
            :value="option"
            v-slot="{ selected, active }"
            :disabled="option.unavailable"
          >
            <div
              class="cursor-pointer flex items-center justify-start gap-3 flex-shrink-0 px-3 py-2 relative focus:outline-none"
              :class="{
                'bg-lightDarkOverlay rounded-md text-white': active,
                'bg-extraDarkOverlay text-gray-400': !active,
                'bg-gray-800 text-gray-400': option.unavailable,
              }"
            >
              <svg
                class="w-3 h-3"
                :class="{
                  'text-transparent': active && !selected,
                  'text-extraDarkOverlay': !active && !selected,
                  'text-white': selected,
                }"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
              {{ option.name }}
            </div>
          </ListboxOption>
        </ListboxOptions>
      </Transition>
    </Listbox>
  </div>
</template>

<script>
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
export default {
  name: "SearchList",
  emits: ["change"],
  components: { Listbox, ListboxButton, ListboxOptions, ListboxOption },
  props: ["options"],
  mounted() {
    // this.selected = this.options[0];
    if (
      this.$route.name === "all items" ||
      this.$route.name === "after effects templates" ||
      this.$route.name === "premiere pro templates" ||
      this.$route.name === "motion graphics templates" ||
      this.$route.name === "stock motion graphics" ||
      this.$route.name === "royalty free music" ||
      this.$route.name === "sound effects" ||
      this.$route.name === "add ons" ||
      this.$route.name === "after effects presets" ||
      this.$route.name === "premiere pro presets"
    ) {
      const cat = this.$route.name.split(" ").join("-");
      if (
        cat &&
        cat !== "after-effects-presets" &&
        cat !== "premiere-pro-presets"
      ) {
        const category = this.options.filter(
          (option) => option.value === cat.toLowerCase()
        )[0];
        this.selected = category;
      } else {
        this.selected = this.options[0];
      }
    }
  },

  data() {
    return {
      selected: this.options[0],
    };
  },

  watch: {
    $route(to, from) {
      // react to route changes...
      if (
        to.name === "all items" ||
        to.name === "after effects templates" ||
        to.name === "premiere pro templates" ||
        to.name === "motion graphics templates" ||
        to.name === "stock motion graphics" ||
        to.name === "royalty free music" ||
        to.name === "sound effects" ||
        to.name === "add ons" ||
        to.name === "after effects presets" ||
        to.name === "premiere pro presets"
      ) {
        const cat = to.name.split(" ").join("-");
        if (
          cat &&
          cat !== "after-effects-presets" &&
          cat !== "premiere-pro-presets"
        ) {
          const category = this.options.filter(
            (option) => option.value === cat.toLowerCase()
          )[0];
          this.selected = category;
        } else {
          this.selected = this.options[0];
        }
      }
    },
    selected: function(val) {
      this.$emit("change", val);
    },
  },
};
</script>

<style scoped>
.glass-effect {
  background-color: rgba(38, 38, 38, 0.9);
  backdrop-filter: blur(7px);
}

.blur {
  background-color: rgba(38, 38, 38, 0.97);
  backdrop-filter: blur(20px);
}
</style>
