import axios from "axios";
const state = {
  accountLoading: false,
  accountInfo: null,
};

const getters = {
  getAccountLoading: (state) => state.accountLoading,
  getAccountInfo: (state) => state.accountInfo,
};

const actions = {
  async upgradePlan({ commit, rootState }) {
    try {
      commit("setAccountLoading", true);
      await axios.get(
        `${process.env.VUE_APP_BASE_URI}/hooks/upgrade_subscriptions`
      );
      const { data } = await axios.get(
        `${process.env.VUE_APP_BASE_URI}/hooks/subscriber`
      );
      commit("setAccountInfo", data);
      commit("setAccountLoading", false);
      window.location = "/account";
    } catch (error) {
      console.log(error);
      commit("setAccountLoading", false);
    }
  },

  async fetchAccountDetails({ commit, rootState }, router) {
    commit("setAccountLoading", true);
    if (
      rootState.auth.currentUser &&
      !rootState.auth.currentUser.subscriptionId
    ) {
      commit("setAccountInfo", null);
      commit("setAccountLoading", false);
      router.push("/pricing");
    } else {
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_BASE_URI}/hooks/subscriber`
        );
        commit("setAccountInfo", data);
        commit("setAccountLoading", false);
      } catch (error) {
        console.log(error);
        commit("setAccountLoading", false);
      }
    }
  },
};

const mutations = {
  setAccountLoading: (state, val) => (state.accountLoading = val),
  setAccountInfo: (state, val) => (state.accountInfo = val),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
