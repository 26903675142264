<template>
	<div class="bg-gray-700 rounded-md p-6">
		<div class="w-16 h-16 bg-white rounded-full p-3 flex items-start justify-center">
			<svg
				v-if="icon === 'download'"
				class=" w-full h-full text-gray-800"
				aria-hidden="true"
				focusable="false"
				width="1em"
				height="1em"
				style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
				preserveAspectRatio="xMidYMid meet"
				viewBox="0 0 24 24"
			>
				<g fill="none">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M2 6a3 3 0 0 1 3-3h3.93a3 3 0 0 1 2.496 1.336l.812 1.219A1 1 0 0 0 13.07 6H19a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6zm11 5a1 1 0 1 0-2 0v3.586l-1.293-1.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l3-3a1 1 0 0 0-1.414-1.414L13 14.586V11z"
						fill="currentColor"
					/>
				</g>
				<rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
			</svg>

			<svg
				v-if="icon === 'cancel'"
				class=" w-full h-full text-gray-800"
				aria-hidden="true"
				focusable="false"
				width="1em"
				height="1em"
				style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
				preserveAspectRatio="xMidYMid meet"
				viewBox="0 0 16 16"
			>
				<g fill="none">
					<path
						d="M4.5 14h1.757A5.478 5.478 0 0 1 5 10.5c0-1.86.923-3.505 2.337-4.5H2v5.5A2.5 2.5 0 0 0 4.5 14z"
						fill="currentColor"
					/>
					<path
						d="M14 5H2v-.5A2.5 2.5 0 0 1 4.5 2h7A2.5 2.5 0 0 1 14 4.5V5z"
						fill="currentColor"
					/>
					<path
						d="M15 10.5a4.5 4.5 0 1 1-9 0a4.5 4.5 0 0 1 9 0zm-2.646-1.146a.5.5 0 0 0-.708-.708L10.5 9.793L9.354 8.646a.5.5 0 1 0-.708.708L9.793 10.5l-1.147 1.146a.5.5 0 0 0 .708.708l1.146-1.147l1.146 1.147a.5.5 0 0 0 .708-.708L11.207 10.5l1.147-1.146z"
						fill="currentColor"
					/>
				</g>
				<rect x="0" y="0" width="16" height="16" fill="rgba(0, 0, 0, 0)" />
			</svg>

			<svg
				v-if="icon === 'updates'"
				class=" w-full h-full text-gray-800"
				aria-hidden="true"
				focusable="false"
				width="1em"
				height="1em"
				style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
				preserveAspectRatio="xMidYMid meet"
				viewBox="0 0 24 24"
			>
				<path
					fill="none"
					stroke="currentColor"
					stroke-width="2"
					d="M2.998 7V1H17.5L21 4.5V23h-6m1-22v5h5M8 23A7 7 0 1 0 8 9a7 7 0 0 0 0 14zm-3.5-6.5L8 13l3.5 3.5m-3.5-3V20"
				/>
				<rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
			</svg>

			<svg
				v-if="icon === 'license'"
				class=" w-full h-full text-gray-800"
				aria-hidden="true"
				focusable="false"
				width="1em"
				height="1em"
				style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
				preserveAspectRatio="xMidYMid meet"
				viewBox="0 0 20 20"
			>
				<g fill="none">
					<path
						d="M4 3a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h7.5v-.671a4.514 4.514 0 0 1-.242-.329H4a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v2.468c.364.18.7.41 1 .678V5a2 2 0 0 0-2-2H4z"
						fill="currentColor"
					/>
					<path
						d="M18.5 11.5a3.5 3.5 0 1 1-7 0a3.5 3.5 0 0 1 7 0z"
						fill="currentColor"
					/>
					<path
						d="M15 16c.925 0 1.785-.28 2.5-.758V18a.5.5 0 0 1-.8.4l-1.4-1.05a.5.5 0 0 0-.6 0l-1.4 1.05a.5.5 0 0 1-.8-.4v-2.758A4.478 4.478 0 0 0 15 16z"
						fill="currentColor"
					/>
					<path
						d="M5 6.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z"
						fill="currentColor"
					/>
					<path
						d="M5.5 11a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4z"
						fill="currentColor"
					/>
				</g>
				<rect x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
			</svg>
		</div>
		<h1 class="pt-6 text-white font-medium text-base md:text-xl">
			{{ title }}
		</h1>
		<h3 class="mt-4 max-w-xs text-white text-sm md:text-base">
			{{ body }}
		</h3>
	</div>
</template>

<script>
export default {
	name: "Card",
	components: {},
	props: ["title", "body", "icon"]
}
</script>
