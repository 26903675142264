<template>
	<div class="w-full">
		<div
			id="my-slider"
			:se-min="minThreshold"
			:se-step="step"
			:se-min-value="min"
			:se-max-value="max"
			:se-max="maxThreshold"
			class="slider"
		>
			<div
				@mousedown="handleMouseDown"
				@mouseup="handleMouseUp"
				class="slider-touch-left"
			>
				<span class=""
					><span
						class="iconify w-8 h-8 ml-px text-gray-500"
						data-icon="clarity:drag-handle-line"
						data-inline="false"
					></span
				></span>
			</div>
			<div
				@mousedown="handleMouseDown"
				@mouseup="handleMouseUp"
				class="slider-touch-right"
			>
				<span class=""
					><span
						class="iconify w-8 h-8 ml-px text-gray-500"
						data-icon="clarity:drag-handle-line"
						data-inline="false"
					></span
				></span>
			</div>
			<div class="slider-line">
				<span></span>
			</div>
		</div>
	</div>
</template>

<script>
import rangeSlider from "./slider"

export default {
	name: "Range slider",
	emit: ["released"],
	props: {
		minThreshold: {
			type: Number,
			default: -100
		},
		maxThreshold: {
			type: Number,
			default: 100
		},
		step: {
			type: Number,
			default: 1
		},
		min: {
			type: Number,
			required: true
		},
		max: {
			type: Number,
			required: true
		}
	},
	data: function() {
		return {
			instance: undefined,
			clicked: false,
			compKey: 0,
			minimum: 0,
			maximum: 330
		}
	},
	mounted: function() {
		window.addEventListener("mouseup", () => {
			if (this.clicked) {
				this.clicked = false
				this.$emit("released")
			}
		})
		this.instance = new rangeSlider("my-slider")
		this.instance.onChange = (min, max) => this.updateValues(min, max)
	},
	destroyed: function() {},
	methods: {
		updateValues: function(min, max) {
			this.$emit("update:min", min)
			this.$emit("update:max", max)
		},

		handleMouseDown() {
			this.clicked = true
		},

		handleMouseUp() {
			this.clicked = false
			this.$emit("released")
		}
	}
}
</script>

<style>
.slider {
	display: block;
	position: relative;
	height: 36px;
	width: 100%;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.slider .slider-touch-left,
.slider .slider-touch-right {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	display: block;
	position: absolute;
	height: 36px;
	width: 36px;
	padding: 6px;
	z-index: 2;
}
.slider .slider-touch-left span,
.slider .slider-touch-right span {
	display: flex;
	justify-items: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background: #ffffff;
	border: 0.9px solid #cbd5e1;
	border-radius: 25%;
	cursor: pointer;
}
.slider .slider-line {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	position: absolute;
	width: calc(100% - 36px);
	left: 18px;
	top: 15px;
	height: 5px;
	border-radius: 4px;
	background: #e0e7ff;
	z-index: 0;
	overflow: hidden;
}
.slider .slider-line span {
	display: block;
	height: 100%;
	width: 0%;
	background: #818cf8;
}
</style>
