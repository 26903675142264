<template>
  <div
    @click="toggleSidebar"
    class="xl:hidden bg-black opacity-50 z-50 inset-0 fixed transition-opacity"
    :class="isSidebarOpen ? 'block ease-in' : 'hidden ease-out'"
  ></div>

  <div
    @click="closeSearch"
    class="bg-black opacity-50 z-40 inset-0 fixed transition-opacity"
    :class="searchExpanded ? 'block ease-in' : 'hidden ease-out'"
  ></div>

  <Sidebar
    :toggleSideBar="toggleSidebar"
    v-on:closeClick="toggleSidebar"
    :primaryLinks="primaryLinks"
    :productLinks="productLinks"
    class="xl:hidden transform xl:translate-x-0 xl:static xl:inset-auto"
    :class="
      isSidebarOpen
        ? 'translate-x-0 ease-out transition-transform'
        : '-translate-x-full ease-in transition-transform'
    "
  />

  <!-- suggestion -->
  <Transition
    enter-active-class="transition duration-100"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition duration-300"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="
        getSuggestions.length > 0 && searchQuery.length > 0 && showSuggestion
      "
      class="w-full fixed inset-0 z-40 flex items-start justify-center"
    >
      <div
        ref="suggestions"
        class="
          mt-14
          md:mr-16
          z-40
          py-2
          w-full
          md:max-w-3xl
          2xl:max-w-2xl
          bg-extraDarkOverlay
          border-t-2 border-gray-600
          rounded-md rounded-t-none
          shadow
          flex flex-col
          divide-y divide-gray-600
          overflow-hidden
        "
      >
        <router-link
          :to="{
            name: searchSelectedOption.split('-').join(' '),
            params: { ...$route.params, page: '' },
            query: {
              ...$route.query,
              search: suggestion,
            },
          }"
          v-for="suggestion in getSuggestions"
          :key="suggestion"
          :class="
            selectedSugestion && selectedSugestion === suggestion.toLowerCase()
              ? 'bg-lightDarkOverlay '
              : 'bg-transparent'
          "
          class="
            px-3
            py-2
            w-full
            text-left
            block
            text-sm text-white
            hover:bg-lightDarkOverlay
          "
        >
          <span>{{ searchQuery || "" }}</span>
          <strong>{{
            searchQuery &&
            suggestion
              .toLowerCase()
              .slice(
                suggestion.toLowerCase().search(searchQuery.toLowerCase()) +
                  searchQuery.length,
                suggestion.length
              )
          }}</strong>
        </router-link>
      </div>
    </div>
  </Transition>

  <nav
    v-if="$route.name !== 'become author' && $route.name !== 'checkout'"
    class="
      glass-effect
      w-full
      fixed
      z-40
      top-0
      flex flex-col
      items-center
      justify-center
    "
  >
    <router-link to="/pricing" class="hidden md:block w-full relative h-12">
      <div class="absolute inset-0">
        <img src="../assets/banner_1.jpg" class="w-full h-full" alt="" />
      </div>
      <div class="absolute inset-0">
        <div
          class="
            w-full
            py-2
            lg:py-2
            px-4
            md:px-6
            xl:px-0
            max-w-1xl
            mx-auto
            flex
            items-center
            justify-between
          "
        >
          <h3 class="text-white uppercase text-sm lg:text-2xl font-bold">
            Annual plan
          </h3>
          <div class="flex items-center justify-center gap-10">
            <h3 class="text-white uppercase text-sm lg:text-lg font-bold">
              Get unlimited assets from
            </h3>
            <div class="flex items-center justify-center gap-2">
              <h3
                class="
                  text-gray-300
                  uppercase
                  text-sm
                  lg:text-lg
                  font-medium
                  line-through
                "
              >
                $179.99
              </h3>
              <h3 class="text-white uppercase text-base lg:text-3xl font-bold">
                $99.99
              </h3>
            </div>
          </div>
          <router-link
            to="/pricing"
            class="
              text-gray-800
              border-2 border-gray-800
              hover:bg-gray-400
              bg-subtleGray
              px-3
              py-1
              rounded-md
              uppercase
              text-sm
              font-bold
            "
          >
            Save $80
          </router-link>
        </div>
      </div>
    </router-link>
    <div
      class="
        w-full
        py-2
        lg:py-0
        px-6
        xl:px-0
        max-w-1xl
        mx-auto
        flex
        items-center
        justify-between
        gap-3
      "
    >
      <div v-if="!searchExpanded" class="flex items-center justify-start gap-5">
        <a
          @mouseenter="logoHovered = true"
          @mouseleave="logoHovered = false"
          href="/"
        >
          <ColorLogo v-if="logoHovered" />
          <WhiteLogo v-else />
        </a>

        <button
          @click="toggleSidebar"
          class="
            mr-4
            lg:hidden
            block
            focus:text-white
            text-gray-300
            focus:outline-none
          "
        >
          <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <Transition
          enter-active-class="transition duration-500 delay-100"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition duration-500"
          leave-from-class="opacity-100 "
          leave-to-class="opacity-0"
        >
          <div
            v-if="!searchExpanded"
            class="hidden lg:flex items-center justify-start gap-5 xl:gap-10"
          >
            <router-link
              v-for="productLink in productLinks"
              :key="productLink.id"
              @mouseenter="handleMouseEnter(productLink.value)"
              @mouseleave="handleMouseLeave(productLink.value)"
              class="
                py-4
                relative
                focus:text-white
                hover:text-white
                text-gray-300
                focus:outline-none
                flex-shrink-0
                transition
                duration-300
              "
              :to="productLink.url"
            >
              <span
                v-if="productLink.title !== 'All items'"
                class="text-xs 2xl:text-sm"
                >{{ productLink.title }}</span
              >
              <span v-else class="">
                <svg
                  class="w-5 h-5 ml-5 -mr-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="2"
                    y="2"
                    width="8"
                    height="8"
                    rx="1"
                    stroke="currentColor"
                    stroke-width="2"
                  />
                  <rect
                    x="2"
                    y="14"
                    width="8"
                    height="8"
                    rx="1"
                    stroke="currentColor"
                    stroke-width="2"
                  />
                  <rect
                    x="14"
                    y="14"
                    width="8"
                    height="8"
                    rx="1"
                    stroke="currentColor"
                    stroke-width="2"
                  />
                  <line
                    x1="18"
                    y1="2"
                    x2="18"
                    y2="10"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <line
                    x1="22"
                    y1="6"
                    x2="14"
                    y2="6"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </span>
              <!-- dropdown -->
              <Transition
                enter-active-class="transition duration-100"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition duration-300"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <div
                  v-if="showSub[productLink.value] && productLink.value"
                  style="margin-top: 56px"
                  :class="productLink.value === 'addOns' ? 'w-48' : 'w-96'"
                  class="
                    bg-extraDarkOverlay
                    absolute
                    -ml-8
                    top-0
                    left-0
                    shadow
                    rounded-t-none rounded-md
                  "
                >
                  <div
                    :class="
                      productLink.value === 'addOns'
                        ? 'grid-cols-1'
                        : 'grid-cols-2'
                    "
                    class="w-full grid gap-5 p-5"
                  >
                    <div
                      v-for="category in getCategory[productLink.value]"
                      :key="category.id"
                      class=""
                    >
                      <router-link
                        v-if="category.title"
                        :to="category.url.toLowerCase()"
                        class="
                          max-w-min
                          text-xs
                          px-3
                          py-2
                          rounded-md rounded-l-none
                          border-l-2 border-transparent
                          hover:border-brandPurple hover:bg-gray-500
                          text-white
                          whitespace-nowrap
                        "
                        >{{ category.title }}</router-link
                      >
                    </div>
                  </div>
                  <router-link
                    :to="productLink.url"
                    class="
                      relative
                      flex
                      items-center
                      justify-center
                      bottom-0
                      rounded-b-md
                      w-full
                      bg-gray-700
                      py-4
                      col-span-2
                      text-center text-xs text-white
                      hover:text-gray-200
                    "
                  >
                    All {{ productLink.realTitle
                    }}<svg
                      class="ml-2 w-5 h-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </router-link>
                </div>
              </Transition>
            </router-link>
          </div>
        </Transition>
      </div>

      <!-- search -->
      <Transition
        enter-active-class="transition-all duration-500 delay-100"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-all duration-500"
        leave-from-class="w-full opacity-0"
        leave-to-class="w-0 opacity-0"
      >
        <div
          v-if="searchExpanded"
          class="w-full flex items-center justify-center lg:h-14"
        >
          <form
            @submit.prevent="handleSearchSubmit"
            v-if="
              routeType === 'General' ||
              routeType === 'Product by category' ||
              routeType === 'All Items'
            "
            class="
              w-full
              flex
              items-center
              justify-center
              max-w-3xl
              2xl:max-w-2xl
              mx-auto
            "
          >
            <div
              class="
                cursor-pointer
                flex flex-shrink-0
                items-center
                justify-between
                text-sm text-white
                bg-transparent
                border-r-2 border-gray-600
                rounded-md rounded-r-none
                focus:outline-none
              "
            >
              <SearchList
                v-on:change="handleSearchOption"
                :options="searchOptions"
              />
            </div>
            <input
              id="searchField"
              ref="searchField"
              type="text"
              autofocus="true"
              autocomplete="off"
              placeholder="search..."
              class="
                w-full
                bg-transparent
                px-3
                py-2
                text-sm
                placeholder-gray-300
                text-white
                focus:outline-none
              "
              @input="handleInput"
              @focus="showSuggestion = true"
              @blur="showSuggestion = false"
              @keydown="handleKeyPress"
              @keypress.enter="handleSearchSubmit"
              v-model="searchQuery"
            />

            <button @click="handleSearchExpanded" class="focus:outline-none">
              <svg class="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24">
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
                ></path>
              </svg>
            </button>

            <button
              type="button"
              @click="closeSearch"
              class="focus:outline-none"
            >
              <svg class="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24">
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M17.25 6.75L6.75 17.25"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M6.75 6.75L17.25 17.25"
                ></path>
              </svg>
            </button>
          </form>
        </div>
      </Transition>

      <div class="flex items-center justify-end gap-3 md:gap-8">
        <button
          v-if="!searchExpanded"
          @click="handleSearchExpanded"
          class="focus:outline-none"
        >
          <svg class="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24">
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
            ></path>
          </svg>
        </button>
        <div
          v-if="
            !searchExpanded &&
            (routeType === 'General' ||
              routeType === 'Product by category' ||
              routeType === 'All Items')
          "
          class="space-x-6 xl:flex items-center justify-center hidden"
        >
          <router-link
            v-if="
              !getCurrentUser ||
              (getCurrentUser && getCurrentUser.subscription === 'free')
            "
            class="
              px-2
              py-1
              focus:font-semibold focus:text-white
              hover:text-white
              text-gray-300 text-xs
              2xl:text-sm
              focus:bg-gray-700
              hover:bg-gray-700
              rounded-md
              focus:outline-none
            "
            to="/pricing"
          >
            Pricing
          </router-link>
        </div>

        <!-- signup and login -->
        <div class="space-x-3 md:space-x-6 flex flex-shrink-0 items-center">
          <a
            v-if="
              !searchExpanded &&
              (routeType === 'General' ||
                routeType === 'Product by category' ||
                routeType === 'All Items') &&
              !getCurrentUser
            "
            href="/register"
            class="
              px-2
              py-2
              md:px-3 md:py-2
              font-semibold
              text-center text-gray-900 text-xs
              2xl:text-sm
              bg-brandGreen
              transition-colors
              duration-300
              hover:bg-brandGreenHover
              rounded-md
              focus:outline-none
              focus:ring-2
              focus:ring-brandGreen
              focus:ring-offset-2
              focus:ring-offset-gray-900
            "
          >
            <span class="lg:block hidden">Create a free account</span>
            <span class="lg:hidden block">Sign up</span>
          </a>
          <router-link
            to="/pricing"
            v-if="
              !searchExpanded &&
              (routeType === 'login' ||
                routeType === 'forgotPassword' ||
                (getCurrentUser && getCurrentUser.subscription === 'free'))
            "
            class="
              hidden
              md:block
              px-2
              py-2
              md:px-3 md:py-2
              font-semibold
              text-center text-white text-xs
              2xl:text-sm
              bg-buttonColor
              transition-colors
              duration-300
              hover:bg-pink-700
              rounded-md
              focus:outline-none
              focus:ring-2
              focus:ring-buttonColor
              focus:ring-offset-2
              focus:ring-offset-gray-900
            "
          >
            <span class="">Get unlimited Downloads</span>
          </router-link>
          <router-link
            to="/pricing"
            v-if="
              routeType === 'login' ||
              routeType === 'forgotPassword' ||
              (getCurrentUser && getCurrentUser.subscription === 'monthly')
            "
            class="
              hidden
              md:block
              px-2
              py-2
              md:px-3 md:py-2
              font-semibold
              text-center text-white text-xs
              2xl:text-sm
              bg-buttonColor
              transition-colors
              duration-300
              hover:bg-pink-700
              rounded-md
              focus:outline-none
              focus:ring-2
              focus:ring-buttonColor
              focus:ring-offset-2
              focus:ring-offset-gray-900
            "
          >
            <span class="">Upgrade to Annual</span>
          </router-link>

          <a
            v-if="!getCurrentUser"
            href="/login"
            class="
              space-x-2
              flex
              items-center
              group
              focus:outline-none
              rounded-md
              hover:bg-gray-700
              px-2
              py-2
              focus:bg-gray-700
            "
            :class="
              $route.name === 'login' || $route.name === 'register'
                ? 'hidden md:flex'
                : 'flex'
            "
          >
            <span
              class="
                h-5
                w-5
                md:h-5 md:w-5
                flex
                items-center
                bg-gray-100
                rounded-full
                overflow-hidden
              "
              ><svg
                class="-mb-1 md:-mb-2 h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clip-rule="evenodd"
                /></svg
            ></span>
            <span class="font-medium text-xs 2xl:text-sm text-white"
              >Sign in
            </span>
          </a>

          <Menu
            as="div"
            v-if="getCurrentUser"
            class="relative focus:outline-none"
          >
            <MenuButton
              as="button"
              class="
                space-x-2
                text-xs
                2xl:text-sm
                flex
                items-center
                group
                focus:outline-none
                rounded-md
                hover:bg-gray-700
                px-2
                py-2
                focus:bg-gray-700
                text-white
              "
            >
              <span
                v-if="!getCurrentUser.profile"
                class="
                  h-5
                  w-5
                  flex
                  items-center
                  bg-gray-100
                  rounded-full
                  overflow-hidden
                "
                ><svg
                  class="-mb-1 md:-mb-2 h-5 w-5 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clip-rule="evenodd"
                  /></svg
              ></span>
              <span
                v-else
                class="
                  w-5
                  h-5
                  flex
                  items-center
                  bg-gray-100
                  rounded-full
                  overflow-hidden
                "
              >
                <img
                  :src="getCurrentUser.profile"
                  alt="users profile"
                  class="w-full h-full object-cover overflow-hidden"
                />
              </span>
              <svg
                class="h-5 w-5 md:h-5 md:w-5 text-gray-100"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                />
              </svg>
            </MenuButton>
            <Transition
              enter-active-class="transition duration-100"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="transition duration-300"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <MenuItems
                as="div"
                class="
                  glass-effect
                  absolute
                  w-44
                  flex flex-col
                  items-start
                  gap-2
                  pt-3
                  z-20
                  right-0
                  mt-2
                  rounded-t-none
                  shadow
                  rounded-md
                  focus:outline-none
                "
              >
                <MenuItem>
                  <div
                    class="
                      w-full
                      truncate
                      block
                      text-left
                      px-6
                      pb-3
                      border-b border-gray-100 border-opacity-25
                      text-xs
                      2xl:text-sm
                      text-white
                      transition
                      duration-100
                      focus:outline-none
                    "
                  >
                    {{ getCurrentUser.userName }}
                  </div>
                </MenuItem>
                <router-link class="focus:outline-none" to="/downloads">
                  <MenuItem v-slot="{ active }">
                    <span
                      class="
                        w-full
                        text-left
                        block
                        mx-3
                        px-3
                        py-2
                        rounded-md
                        text-xs
                        2xl:text-sm
                        text-white
                        transition
                        duration-100
                        focus:outline-none
                      "
                      :class="{ 'bg-gray-500': active }"
                      >My Downloads</span
                    >
                  </MenuItem>
                </router-link>
                <router-link class="focus:outline-none" to="/collections">
                  <MenuItem v-slot="{ active }">
                    <span
                      class="
                        w-full
                        text-left
                        block
                        mx-3
                        px-3
                        py-2
                        rounded-md
                        text-xs
                        2xl:text-sm
                        text-white
                        transition
                        duration-100
                        focus:outline-none
                      "
                      :class="{ 'bg-gray-500': active }"
                      >My Collections</span
                    >
                  </MenuItem>
                </router-link>

                <router-link class="focus:outline-none" to="/account">
                  <MenuItem v-slot="{ active }">
                    <span
                      class="
                        w-full
                        text-left
                        block
                        mx-3
                        px-3
                        py-2
                        rounded-md
                        text-xs
                        2xl:text-sm
                        text-white
                        transition
                        duration-100
                        focus:outline-none
                      "
                      :class="{ 'bg-gray-500': active }"
                      >My Account</span
                    >
                  </MenuItem>
                </router-link>

                <a
                  class="focus:outline-none"
                  href="https://account.vooaxis.com"
                >
                  <MenuItem v-slot="{ active }">
                    <span
                      class="
                        w-full
                        text-left
                        block
                        mx-3
                        px-3
                        py-2
                        rounded-md
                        text-xs
                        2xl:text-sm
                        text-white
                        transition
                        duration-100
                        focus:outline-none
                      "
                      :class="{ 'bg-gray-500': active }"
                      >Settings</span
                    >
                  </MenuItem>
                </a>

                <MenuItem v-slot="{ active }">
                  <button
                    @click="handleLogOut"
                    class="
                      w-full
                      block
                      text-center
                      bg-gray-900
                      px-3
                      py-3
                      rounded-md
                      text-xs
                      2xl:text-sm
                      text-white
                      transition
                      duration-100
                      focus:outline-none
                    "
                    :class="{ 'bg-gray-500': active }"
                  >
                    Log out
                  </button>
                </MenuItem>
              </MenuItems>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import ColorLogo from "./utils/ColorLogo.vue";
import SearchList from "./topbar/SearchList.vue";
import Sidebar from "./topbar/Sidebar.vue";
import WhiteLogo from "./utils/WhiteLogo.vue";
export default {
  name: "newNav",
  components: {
    SearchList,
    Sidebar,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    ColorLogo,
    WhiteLogo,
  },
  computed: {
    ...mapGetters(["getCurrentUser"]),
    ...mapGetters(["primaryLinks"]),
    ...mapGetters(["productLinks"]),
    ...mapGetters(["searchOptions"]),
    ...mapGetters(["getCategory"]),
    ...mapGetters(["getSuggestions"]),
  },

  methods: {
    ...mapActions(["setSearchQuery"]),
    ...mapActions(["setSearchOption"]),
    ...mapActions(["fetchSuggestions"]),
    ...mapActions(["fetchAvailableCollections"]),
    ...mapActions(["logout"]),
    ...mapActions(["getCurrentUser"]),

    handleSearchExpanded(e) {
      if (this.searchExpanded) {
        this.handleSearchSubmit();
      } else {
        this.searchExpanded = true;
        setTimeout(() => {
          this.$refs.searchField.focus();
        }, 600);
      }
    },

    closeSearch() {
      this.searchQuery = "";
      this.searchExpanded = false;
    },

    handleInput() {
      this.showSuggestion = true;
      this.selectedSugestion = null;
    },

    handleSuggestionSelect() {
      let query = {};
      if (this.selectedSugestion && this.searchQuery.length > 0) {
        query.search = this.selectedSugestion;
      } else if (!this.selectedSugestion && this.searchQuery.length > 0) {
        query.search = this.searchQuery.trim();
      } else {
        delete query.search;
      }

      this.$router.push({
        name: this.searchSelectedOption.toLowerCase().split("-").join(" "),
        params: { ...this.$route.params, page: "" },
        query,
        hash: this.$route.hash,
      });
      this.showSuggestion = false;
    },
    handleKeyPress(e) {
      if (e.keyCode === 40) {
        if (this.currentIndex < this.suggestions.length) {
          this.currentIndex++;
        } else {
          this.currentIndex = 1;
        }
        this.selectedSugestion =
          this.$refs.suggestions.children[this.currentIndex - 1].outerText;
      }
      if (e.keyCode === 38) {
        if (this.currentIndex > 1) {
          this.currentIndex--;
        } else {
          this.currentIndex = this.suggestions.length;
        }
        this.selectedSugestion =
          this.$refs.suggestions.children[this.currentIndex - 1].outerText;
      }
    },

    handleMouseEnter(e) {
      this.showSub[e] = true;
    },

    handleMouseLeave(e) {
      this.showSub[e] = false;
    },

    handleLogOut() {
      this.logout();
    },

    handleSearchSubmit(e) {
      this.searchExpanded = false;
      e.preventDefault();
      this.setSearchQuery(this.searchQuery);
      this.setSearchOption(this.searchSelectedOption);
      let query = {};
      if (this.selectedSugestion && this.searchQuery.length > 0) {
        query.search = this.selectedSugestion;
        localStorage.setItem("search", this.selectedSugestion);
      } else if (!this.selectedSugestion && this.searchQuery.length > 0) {
        localStorage.setItem("search", this.searchQuery.trim());
        query.search = this.searchQuery.trim();
      } else {
        delete query.search;
      }

      this.$router.push({
        name: this.searchSelectedOption.toLowerCase().split("-").join(" "),
        params: { ...this.$route.params, page: "" },
        query,
        hash: this.$route.hash,
      });
    },

    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },

    handleSearchOption(e) {
      if (e) {
        this.searchSelectedOption = e.value;
      }
    },
  },

  mounted() {
    if (this.getCurrentUser) {
      this.fetchAvailableCollections();
    }

    if (this.$route.query.search && this.$route.name !== "ContributorProfile") {
      this.searchQuery = this.$route.query.search;
      this.setSearchOption(this.searchOptions[0].value);
      this.searchSelectedOption = this.searchOptions[0].value;
    }
  },
  data() {
    return {
      logoHovered: false,
      searchExpanded: false,
      suggestions: [],
      currentIndex: 0,
      selectedSugestion: null,

      isSidebarOpen: false,
      searchQuery: "",
      searchSelectedOption: "all items",
      routeType: "General",
      showSuggestion: false,
      showSub: {
        afterEffectsTemplates: false,
        premiereProTemplates: false,
        soundEffects: false,
        addOns: false,
        music: false,
        motionGraphics: false,
      },
    };
  },
  watch: {
    $route(to, from) {
      this.isSidebarOpen = false;
      window.scrollTo({
        top: 0,
        left: 0,
      });

      if (this.getCurrentUser) {
        this.fetchAvailableCollections();
      }

      if (to.name !== "ContributorProfile") {
        if (to.query.search) {
          this.searchQuery = to.query.search.toLowerCase().split("+").join(" ");
        } else {
          this.searchQuery = "";
          this.selectedSugestion = null;
        }
      }
    },
    searchQuery: function (nv) {
      if (nv.length > 0) {
        this.fetchSuggestions({
          query: nv,
          category: this.searchSelectedOption,
        });
      }
    },
    getSuggestions: function (nv) {
      this.suggestions = nv;
    },

    getCurrentUser: function (newVal, oldVal) {
      if (oldVal && !newVal) {
        this.getCurrentUser = null;
      } else {
        this.getCurrentUser = oldVal;
      }
    },
  },
};
</script>

<style scoped>
.glass-effect {
  background-color: rgba(38, 38, 38, 0.9);
  backdrop-filter: blur(7px);
}

.blur {
  background-color: rgba(38, 38, 38, 0.97);
  backdrop-filter: blur(20px);
}
</style>
