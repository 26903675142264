<template>
	<button
		:class="[
			rounded ? 'rounded-full' : 'rounded-md',
			small ? 'w-8 h-8' : 'w-10 h-10'
		]"
		class="group p-2  relative flex items-center justify-center overflow-hidden transition-all duration-500 focus:outline-none outline-none"
	>
		<span
			:class="[
				{
					'rounded-full': rounded,
					'rounded-md': !rounded,
					'opacity-100 scale-100': active
				},
				dark ? 'bg-gray-300' : 'bg-indigo-100'
			]"
			class="absolute w-full h-full dark:bg-gray-800 inset-0 z-10 transition-all duration-300 transform scale-75 opacity-0 group-hover:scale-100 group-hover:opacity-100 focus:outline-none outline-none"
		></span>
		<div class="absolute z-20 flex items-center justify-center">
			<slot />
		</div>
	</button>
</template>

<script>
export default {
	name: "EffectButton",
	components: {},
	props: {
		small: Boolean,
		dark: Boolean,
		rounded: Boolean,
		active: {
			type: Boolean,
			default: false
		}
	}
}
</script>
