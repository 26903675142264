import axios from "axios";

const state = {
  total: 0.0,
  tax: 0.0,
  categories: {
    afterEffectsTemplates: [
      {
        id: 1,
        title: "Broadcast Packages",
        url: "/after-effects-templates/broadcast-packages",
      },
      {
        id: 2,
        title: "Product Promo",
        url: "/after-effects-templates/product-Promo",
      },
      {
        id: 3,
        title: "Elements",
        url: "/after-effects-templates/elements",
      },
      {
        id: 4,
        title: "Titles",
        url: "/after-effects-templates/titles",
      },
      {
        id: 10,
        title: "Infographics",
        url: "/after-effects-templates/infographics",
      },
      {
        id: 5,
        title: "Video Displays",
        url: "/after-effects-templates/video-displays",
      },
      {
        id: 6,
        title: "Logo Stings",
        url: "/after-effects-templates/logo-stings",
      },
      {
        id: 12,
        title: "",
        url: "",
      },
      {
        id: 8,
        title: "Openers",
        url: "/after-effects-templates/openers",
      },
    ],
    premiereProTemplates: [
      {
        id: 1,
        title: "Broadcast Packages",
        url: "/premiere-pro-templates/broadcast-packages",
      },
      {
        id: 2,
        title: "Product Promo",
        url: "/premiere-pro-templates/product-promo",
      },
      {
        id: 3,
        title: "Elements",
        url: "/premiere-pro-templates/elements",
      },
      {
        id: 4,
        title: "Titles",
        url: "/premiere-pro-templates/titles",
      },
      {
        id: 10,
        title: "Infographics",
        url: "/premiere-pro-templates/infographics",
      },
      {
        id: 5,
        title: "Video Displays",
        url: "/premiere-pro-templates/video-displays",
      },
      {
        id: 6,
        title: "Logo Stings",
        url: "/Premiere-Pro-Templates/logo-stings",
      },
      {
        id: 7,
        title: "MOGRT Templates",
        url: "/motion-graphics-templates",
      },
      {
        id: 8,
        title: "Openers",
        url: "/premiere-pro-templates/openers",
      },
    ],
    stockFootages: [
      {
        id: 1,
        title: "Stock Footage",
        url: "/stock-footage",
      },
      {
        id: 1,
        title: "Motion Graphics",
        url: "/stock-motion-graphics",
      },
    ],

    music: [
      {
        id: 2,
        title: "Logo & idents",
        url: "/royalty-free-music/logo-&-idents",
      },
    ],
    soundEffects: [
      {
        id: 1,
        title: "Buttons & Menus",
        url: "/sound-effects/buttons-&-menus",
      },
      {
        id: 2,
        title: "Interface Sounds",
        url: "/sound-effects/interface-sounds",
      },
      {
        id: 3,
        title: "Cartoon Sounds",
        url: "/sound-effects/cartoon-sounds",
      },
      {
        id: 4,
        title: "Nature Sounds",
        url: "/sound-effects/nature-sounds",
      },
      {
        id: 5,
        title: "Domestic Sounds",
        url: "/sound-effects/domestic-sounds",
      },
      {
        id: 6,
        title: "Sound",
        url: "/sound-effects/sounds",
      },
      {
        id: 7,
        title: "Futuristic Sounds",
        url: "/sound-effects/futuristic-sounds",
      },
      {
        id: 8,
        title: "Sound Packs",
        url: "/sound-effects/sound-packs",
      },
      {
        id: 9,
        title: "Game Sounds",
        url: "/sound-effects/game-sounds",
      },
      {
        id: 10,
        title: "Transitions & Movement",
        url: "/sound-effects/transitions-&-movement",
      },
      {
        id: 11,
        title: "Human Sounds",
        url: "/sound-effects/human-sounds",
      },
      {
        id: 12,
        title: "Urban Sounds",
        url: "/sound-effects/urban-sounds",
      },
      {
        id: 13,
        title: "Industrial Sounds",
        url: "/sound-effects/idustrial-sounds",
      },
      {
        id: 14,
        title: "Miscellaneous",
        url: "/sound-effects/miscellaneous",
      },
    ],
    addOns: [
      {
        id: 1,
        title: "After Effects Presets",
        url: "/after-effects-presets",
      },
      {
        id: 2,
        title: "Premiere Pro Presets",
        url: "/premiere-pro-presets",
      },
    ],
  },

  footerLinks: [
    {
      title: "Motion Ridge",
      links: [
        { name: "About Ridge", url: "/about" },
        { name: "Free files", url: "/free" },
        { name: "License", url: "/license" },
        { name: "Terms and conditions", url: "/user-terms" },
      ],
    },
    {
      title: "Meet Vooaxis",
      links: [{ name: "About Vooaxis", url: "https://vooaxis.com/about-us" }],
    },
    {
      title: "Help",
      links: [
        { name: "Help center", url: "https://motionridge.com/upload-rules" },
        { name: "Contact us", url: "https://vooaxis.com/contact-us" },
        { name: "Become an affiliate", url: "https://vooaxis.com/affiliates" },
      ],
    },
    {
      title: "Author",
      links: [
        { name: "Become an author", url: "/become-an-author" },
        {
          name: "Author Sign in",
          url: "https://contributors.vooaxis.com",
        },
      ],
    },
  ],

  primaryLinks: [
    {
      id: 0,
      url: "/become-an-author",
      title: "Start Earning",
      value: "",
      isActive: true,
    },
    {
      id: 1,
      url: "/about",
      title: "About",
      value: "",
      isActive: false,
    },
    {
      id: 2,
      url: "/license",
      title: "License",
      value: "",
      isActive: false,
    },
  ],

  productLinks: [
    {
      id: 1,
      url: "/all-items",
      title: "All items",
      value: "",
      isActive: false,
    },
    {
      id: 2,
      url: "/after-effects-templates",
      title: "After Effects",
      realTitle: "After Effects Templates",
      value: "afterEffectsTemplates",
      isActive: false,
    },
    {
      id: 3,
      url: "/premiere-pro-templates",
      title: "Premiere Pro",
      realTitle: "Premiere Pro Templates",
      value: "premiereProTemplates",
      isActive: false,
    },
    {
      id: 8,
      url: "/stock-footage",
      title: "Stock Video",
      realTitle: "Stock Footage",
      value: "stockFootages",
      isActive: false,
    },
    // {
    //   id: 9,
    //   url: "/photo",
    //   title: "Photos",
    //   realTitle: "Photos",
    //   value: "",
    //   isActive: false,
    // },
    {
      id: 5,
      url: "/royalty-free-music",
      title: "Music",
      realTitle: "Music",
      value: "",
      isActive: false,
    },
    {
      id: 6,
      url: "/sound-effects",
      title: "Sound Effects",
      value: "soundEffects",
      realTitle: "Sound Effects",
      isActive: false,
    },
    {
      id: 7,
      url: "/add-ons",
      title: "Add-ons",
      value: "addOns",
      realTitle: "Add-ons",
      isActive: false,
    },
  ],

  productDisplayImages: [],

  testimonials: [
    {
      id: 1,
      avatar:
        "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.8&w=144&h=144&q=80",
      desc:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consectetur esse sint eveniet tempore! Ducimus consequatur nulla a recusandae voluptatibus eaque dignissimos magni ipsam exercitationem enim, nem doloribus voluptas perferendis harum.",
      name: "Lara Watson",
      sub: "CEO noNames",
    },
    {
      id: 2,
      avatar:
        "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.8&w=144&h=144&q=80",
      desc:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consectetur esse sint eveniet tempore! Ducimus consequatur nulla a recusandae voluptatibus eaque dignissimos magni ipsam exercitationem enim, nem doloribus voluptas perferendis harum.",
      name: "James Watson",
      sub: "CEO Apple",
    },
    {
      id: 3,
      avatar:
        "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.8&w=144&h=144&q=80",
      desc:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consectetur esse sint eveniet tempore! Ducimus consequatur nulla a recusandae voluptatibus eaque dignissimos magni ipsam exercitationem enim, nem doloribus voluptas perferendis harum.",
      name: "Lisa Watson",
      sub: "CEO Pepsi",
    },
    {
      id: 4,
      avatar:
        "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.8&w=144&h=144&q=80",
      desc:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consectetur esse sint eveniet tempore! Ducimus consequatur nulla a recusandae voluptatibus eaque dignissimos magni ipsam exercitationem enim, nem doloribus voluptas perferendis harum.",
      name: "mayuran",
      sub: "CEO MT",
    },
  ],

  freeTemplates: [{ id: 1 }, { id: 2 }, { id: 3 }],

  searchOptions: [
    { id: 1, name: "All items", value: "all-items", unavailable: false },
    {
      id: 2,
      name: "After Effects",
      value: "after-effects-templates",
      unavailable: false,
    },
    {
      id: 3,
      name: "Premiere pro",
      value: "premiere-pro-templates",
      unavailable: false,
    },
    {
      id: 4,
      name: "Mogrt Templates",
      value: "motion-graphics-templates",
      unavailable: false,
    },
    {
      id: 4,
      name: "Motion Graphics",
      value: "stock-motion-graphics",
      unavailable: false,
    },
    {
      id: 4,
      name: "Stock Footage",
      value: "stock-footage",
      unavailable: false,
    },
    {
      id: 4,
      name: "Photo",
      value: "photo",
      unavailable: false,
    },
    { id: 5, name: "Music", value: "royalty-free-music", unavailable: false },
    {
      id: 6,
      name: "Sound effects",
      value: "sound-effects",
      unavailable: false,
    },
    { id: 12, name: "Add-ons", value: "add-ons", unavailable: false },
  ],
};

const getters = {
  getTotal: (state) => state.total,
  getTax: (state) => state.tax,
  getCategory: (state) => state.categories,
  footerLinks: (state) => state.footerLinks,
  primaryLinks: (state) => state.primaryLinks,
  productLinks: (state) => state.productLinks,
  productDisplayImages: (state) => state.productDisplayImages,
  searchOptions: (state) => state.searchOptions,
  testimonials: (state) => state.testimonials,
  freeTemplates: (state) => state.freeTemplates,
};

const actions = {
  async fetchDisplayImages({ commit, state }) {
    const { data: images } = await axios.get(
      `${process.env.VUE_APP_BASE_URI}/product/display-products`
    );

    const temp = images.map((img, ind) => {
      let obj = {
        id: ind,
        title:
          ind == 0
            ? "After Effects"
            : ind == 1
            ? "Premiere Pro"
            : ind == 2
            ? "Stock Video"
            : "Stock Audio",
        url:
          ind == 0
            ? "/after-effects-templates"
            : ind == 1
            ? "/premiere-pro-templates"
            : ind == 2
            ? "stock-video"
            : "/royalty-free-music",
        image: img,
      };
      return obj;
    });

    temp.push({
      id: 4,
      title: "Stock Audio",
      url: "/royalty-free-music",
      image:
        "https://firebasestorage.googleapis.com/v0/b/motion-ridge-b81bb.appspot.com/o/motion-ridge-item-preview-image%2Fsa_preview_img.jpg?alt=media&token=8d173ecd-bcb1-4527-ab12-05935739f9ef",
    });

    commit("setDisplayImages", temp);
  },

  updatePrices({ commit }, data) {
    const tax = parseFloat(data.eventData.checkout.prices.vendor.total_tax);

    const total =
      parseFloat(data.eventData.checkout.prices.vendor.total) +
      parseFloat(data.eventData.checkout.prices.vendor.total_tax);

    commit("setTotal", total.toFixed(2));
    commit("setTax", tax.toFixed(2));
  },
};

const mutations = {
  setDisplayImages: (state, images) => (state.productDisplayImages = images),
  setTotal: (state, val) => (state.total = val),
  setTax: (state, val) => (state.tax = val),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
