<template>
 <SwitchGroup as="button" class="flex items-center w-12 flex-col justify-center focus:outline-none">

    <Switch
      as="button"
      v-model="switchValue"
      class="mt-3 relative inline-flex w-6 h-10 rounded-full focus:outline-none insetShadow overflow-hidden"
      :class="switchValue ? 'bg-indigo-400' : 'bg-gray-200'"
      v-slot="{ checked }"
    >
      <span
        class="absolute ml-1 mt-1 inline-block w-4 h-4 transform bg-white rounded-full shadow-md transition-all duration-300"
        :class="{ 'translate-y-4': checked, 'translate-y-0': !checked }"
      />
    </Switch>
    
    <SwitchLabel class="mt-1 text-xxs font-bold text-gray-800 w-full">{{lable}}</SwitchLabel>

  </SwitchGroup>
</template>

<script>
import { SwitchGroup, Switch, SwitchLabel } from '@headlessui/vue'
  export default ({
    name: 'VerticalSwitch',
    components: {SwitchGroup, Switch, SwitchLabel},
    props:['select','lable'],
    emits:['changed'],
    mounted(){
      this.switchValue = this.select
    },

    data(){
      return{
        switchValue:false
      }
    },

    watch:{
      select:function(nv){
        this.switchValue = nv
      },

      switchValue:function (nv) {
        this.$emit('changed',nv)
      }
    }
  });
</script>


