<template>
	<h2 class="text-xs font-def font-semibold text-gray-300 uppercase tracking-wide">
		{{ title }}
	</h2>

	<!-- links -->
	<div class="mt-2 -mx-3">
		<div v-for="link in links" :key="link.id">
			<router-link :to="link.url">
				<Navlink
					:id="link.id"
					:title="link.title"
					:value="link.value"
					:isActive="link.isActive"
					@click="$emit('linkClick', { id: link.id, title })"
				/>
			</router-link>
		</div>
	</div>
</template>

<script>
import Navlink from "./Navlink.vue"
export default {
	name: "NavigationLink",
	props: ["title", "links"],
	emits: ["linkClick"],
	components: { Navlink }
}
</script>
