<template>
	<div
		@click="$emit('clicked')"
		class="flex items-center justify-center cursor-pointer"
	>
		<div
			:class="select ? 'bg-indigo-400' : 'bg-white'"
			class="w-6 h-6 rounded-md border border-gray-300 flex items-center justify-center cursor-pointer"
		>
			<svg
				:class="select ? 'w-4 h-4' : 'w-0 h-0'"
				class="text-white"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
					clip-rule="evenodd"
				/>
			</svg>
		</div>

		<h1
			:class="{ 'truncate w-36': truncate }"
			class="ml-2 text-xs text-gray-500 select-none font-bold "
		>
			{{ lable }}
		</h1>
	</div>
</template>

<script>
export default {
	name: "CustomRoundedCheckbox",
	components: {},
	data() {
		return {}
	},
	emits: ["clicked"],
	props: {
		select: { type: Boolean, default: false },
		lable: { type: String, default: "lable" },
		truncate: { type: Boolean, default: true }
	}
}
</script>
