<template>
	<section
		class="p-3 pt-32 pb-20 min-h-screen flex items-center justify-center bg-gray-50"
	>
		<div
			class="w-full max-w-screen-lg  md:grid md:grid-cols-2 overflow-hidden rounded-xl shadow-md"
		>
			<div class="hidden md:block relative overflow-hidden">
				<img
					class="absolute inset-0 z-10 w-full h-full object-cover"
					src="../assets/authenticate-bg.jpg"
					alt="authentication background image"
				/>

				<div class="absolute inset-x-0 bottom-0 z-20">
					<h1 class="pb-5 text-white font-medium text-center">
						We are glad to see you again
					</h1>
				</div>

				<div
					class="absolute inset-y-0 right-0 z-20 flex flex-col justify-center items-end space-y-2"
				>
					<router-link
						to="/login"
						class=" w-20 rounded-l-full p-2 pl-3 text-sm font-medium bg-white text-gray-900 focus:outline-none cursor-pointer"
					>
						Sign in
					</router-link>
					<router-link
						to="/register"
						class=" w-20 rounded-l-full p-2 pl-3 text-sm font-medium text-white  focus:outline-none cursor-pointer"
					>
						Register
					</router-link>
				</div>
			</div>

			<div
				class="px-8 py-44 my-1 bg-white flex flex-col items-center justify-center"
			>
				<h1 class="text-xl font-semibold text-gray-900">
					Reset password for your account
				</h1>

				<form
					@submit.prevent="handlePasswordReset"
					name="form"
					class="mt-6 w-full flex flex-col"
				>
					<div class="h-16">
						<input
							name="email"
							@input="handleChange"
							@blur="setTouched"
							@focus="checkError"
							v-model="email.value"
							type="text"
							placeholder="Email address"
							:class="{ 'ring-2 ring-red-700': errors.email }"
							class="border block w-full border-gray-300 rounded-md px-3 py-2 text-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-pink-300"
						/>
						<h3
							v-if="errors.email && email.isTouched"
							class="text-xs text-red-700 mt-1"
						>
							{{ errors.email }}
						</h3>
					</div>

					<div class="w-full flex gap-3 mt-5">
						<button
							type="submit"
							class="w-full px-2 py-2 md:px-3 md:py-2 flex justify-center items-center font-semibold text-center text-white text-xs md:text-sm bg-buttonColor transition-colors duration-300 hover:bg-pink-700 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2 focus:ring-offset-white"
						>
							<Loader v-if="getAuthLoading" />
							<span v-if="!getAuthLoading">Send password reset link</span>
						</button>
						<router-link
							to="/login"
							class="w-full px-2 py-2 md:px-3 md:py-2 flex justify-center items-center font-semibold text-center text-gray-800 text-xs md:text-sm bg-gray-100 transition-colors duration-300 hover:bg-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 focus:ring-offset-white"
						>
							<span>Cancel</span>
						</router-link>
					</div>
				</form>
			</div>
		</div>
	</section>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import Loader from "../components/utils/Loader.vue"
export default {
	name: "Log in",
	components: { Loader },

	computed: {
		...mapGetters(["getAuthLoading"]),
		...mapGetters(["getForgotPasswordError"])
	},

	mounted() {
		this.errors = this.getForgotPasswordError
	},

	methods: {
		...mapActions(["login"]),
		...mapActions(["resetPasswordLink"]),

		checkError(e) {
			if (e.target.name === "form") {
				this.email.isTouched = true

				if (e.target.name === "email") {
					const regEx = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
					if (value.length <= 0) {
						this.errors.email = "email is required"
					} else if (!regEx.exec(value)) {
						this.errors.email = "Invalid email"
					} else {
						this.errors.email = null
					}
				}
			} else {
				let field = e.target.name
				let value = e.target.value
				if (field === "email") {
					const regEx = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
					if (value.length <= 0) {
						this.errors.email = "email is required"
					} else if (!regEx.exec(value)) {
						this.errors.email = "Invalid email"
					} else {
						this.errors.email = null
					}
				}
			}
		},
		setTouched(e) {
			const field = e.target.name
			this[field].isTouched = true
		},
		handleChange(e) {
			this.checkError(e)
		},

		handlePasswordReset(e) {
			this.checkError(e)
			if (!this.errors.email && !this.errors.password) {
				this.resetPasswordLink(this.email.value)
			}
		}
	},
	data() {
		return {
			remember: false,
			email: {
				value: "",
				isTouched: false
			},

			errors: {
				email: null
			}
		}
	},

	watch: {
		getForgotPasswordError: function(nv, ov) {
			this.errors = nv
		}
	}
}
</script>
