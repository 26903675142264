<template>
	<section
		class="p-3 pt-32 pb-20 min-h-screen flex items-center justify-center bg-gray-50"
	>
		<div
			class="w-full max-w-screen-lg  md:grid md:grid-cols-2 overflow-hidden rounded-xl shadow-md"
		>
			<div class="hidden md:block relative overflow-hidden">
				<img
					class="absolute inset-0 z-10 w-full h-full object-cover"
					src="../assets/authenticate-bg.jpg"
					alt="authentication background image"
				/>

				<div
					class="absolute inset-y-0 right-0 z-20 flex flex-col justify-center items-end space-y-2"
				>
					<router-link
						to="/login"
						class=" w-20 rounded-l-full p-2 pl-3 text-sm font-medium bg-white text-gray-900 focus:outline-none cursor-pointer"
					>
						Sign in
					</router-link>
					<router-link
						to="/register"
						class=" w-20 rounded-l-full p-2 pl-3 text-sm font-medium text-white  focus:outline-none cursor-pointer"
					>
						Sign up
					</router-link>
				</div>
			</div>

			<div
				class="px-8 py-28 my-3 bg-white flex flex-col items-center justify-center"
			>
				<h1 class="block w-full text-left text-xl font-semibold text-gray-900">
					Reset your password
				</h1>

				<form
					@submit.prevent="handleResetPassword"
					name="form"
					class="mt-6 w-full flex flex-col"
				>
					<div class="h-16">
						<input
							name="password"
							@input="handleChange"
							@blur="setTouched"
							@focus="checkError"
							v-model="password.value"
							type="password"
							placeholder="Enter password"
							:class="{
								'ring-2 ring-red-700': errors.password && password.isTouched
							}"
							class="border block w-full border-gray-300 rounded-md px-3 py-2 text-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500"
						/>
						<h3
							v-if="errors.password && password.isTouched"
							class="text-xs text-red-700 mt-1"
						>
							{{ errors.password }}
						</h3>
					</div>
					<div class="mt-1 h-16">
						<input
							name="confirmPassword"
							@input="handleChange"
							@blur="setTouched"
							@focus="checkError"
							v-model="confirmPassword.value"
							type="password"
							placeholder="Confirm Password"
							:class="{
								'ring-2 ring-red-700':
									errors.confirmPassword && confirmPassword.isTouched
							}"
							class="border block w-full border-gray-300 rounded-md px-3 py-2 text-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500"
						/>
						<h3
							v-if="errors.confirmPassword && confirmPassword.isTouched"
							class="text-xs text-red-700 mt-1"
						>
							{{ errors.confirmPassword }}
						</h3>
					</div>

					<button
						type="submit"
						class="mt-5 px-2 py-2 md:px-3 md:py-2 flex justify-center items-center font-semibold text-center text-white text-xs md:text-sm bg-buttonColor transition-colors duration-300 hover:bg-pink-700 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2 focus:ring-offset-white"
					>
						<Loader v-if="getAuthLoading" />
						<span v-if="!getAuthLoading">Reset</span>
					</button>
				</form>
			</div>
		</div>
	</section>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import Loader from "../components/utils/Loader.vue"
export default {
	name: "ResetPassword",
	components: { Loader },

	methods: {
		...mapActions(["resetPassword"]),

		checkError(e) {
			if (e.target.name === "form") {
				this.password.isTouched = true
				this.confirmPassword.isTouched = true

				const regEx = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
				if (this.password.value.length <= 0) {
					this.errors.password = "Password is required"
				} else if (this.password.value.length < 8) {
					this.errors.password = "This field must have a minimum of 8 characters"
				} else if (!regEx.exec(this.password.value)) {
					this.errors.password = "Password must contain text and number"
				} else {
					this.errors.password = null
				}

				if (this.confirmPassword.value.length <= 0) {
					this.errors.confirmPassword = "confirm password is required"
				} else if (this.confirmPassword.value.length < 8) {
					this.errors.confirmPassword =
						"This field must have a minimum of 8 characters"
				} else if (!regEx.exec(this.confirmPassword.value)) {
					this.errors.confirmPassword =
						"confirm password must contain text and number"
				} else {
					this.errors.confirmPassword = null
				}
			} else {
				let field = e.target.name
				let value = e.target.value

				if (field === "password") {
					const regEx = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
					if (value.length <= 0) {
						this.errors.password = "Password is required"
					} else if (value.length < 8) {
						this.errors.password = "This field must have a minimum of 8 characters"
					} else if (!regEx.exec(value)) {
						this.errors.password = "Password must contain text and number"
					} else {
						this.errors.password = null
					}
				}

				if (field === "confirmPassword") {
					const regEx = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
					if (value.length <= 0) {
						this.errors.confirmPassword = "confirm password is required"
					} else if (value.length < 8) {
						this.errors.confirmPassword =
							"This field must have a minimum of 8 characters"
					} else if (!regEx.exec(value)) {
						this.errors.confirmPassword =
							"confirm password must contain text and number"
					} else {
						this.errors.confirmPassword = null
					}
				}
			}
		},
		setTouched(e) {
			const field = e.target.name
			this[field].isTouched = true
		},

		handleChange(e) {
			this.checkError(e)
		},

		handleResetPassword(e) {
			this.checkError(e)
			if (!this.errors.password && !this.errors.confirmPassword) {
				if (this.password.value !== this.confirmPassword.value) {
					this.errors.confirmPassword = "Password didnt match! try again"
				} else {
					let { token } = this.$route.params
					let obj = { password: this.password.value, token }
					this.resetPassword(obj)
				}
			}
		}
	},

	data() {
		return {
			password: {
				value: "",
				isTouched: false
			},

			confirmPassword: {
				value: "",
				isTouched: false
			},

			errors: {
				password: null,
				confirmPassword: null
			}
		}
	},

	computed: {
		...mapGetters(["getAuthLoading"])
	}
}
</script>
