<template>
	<div class="rounded-md max-w-xs cursor-pointer flex-shrink-0 xl:flex-shrink">
		<img
			class="w-full h-full max-w-xs object-cover rounded-t-md"
			src="https://images.pexels.com/photos/2166711/pexels-photo-2166711.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
			alt=""
		/>
		<div
			class="bg-gray-50 p-3 flex justify-between items-center flex-shrink rounded-b-md"
		>
			<div>
				<h1 class="text-gray-900 w-48  font-semibold text-sm truncate">
					3D Photo Animator
				</h1>
				<h3 class="text-gray-600 text-xs w-48  truncate">
					Motion graphics in corporate
				</h3>
			</div>

			<div class="flex space-x-2">
				<button
					class="tooltip p-2 rounded-full bg-red-100 group hover:bg-red-200 transition-colors duration-300 focus-outline-none"
				>
					<span class="tooltiptext mb-2 text-xs">Add to collection</span>
					<svg
						class="h-5 w-5 text-red-700 group-hover:text-red-900"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
						/>
					</svg>
				</button>
				<button
					class="tooltip p-2 rounded-full bg-red-100 group hover:bg-red-200 transition-colors duration-300 focus-outline-none"
				>
					<span class="tooltiptext mb-2 text-xs">Download</span>
					<svg
						class="h-5 w-5 text-red-700 group-hover:text-red-900"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
						/>
					</svg>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "TemplateCard",
	components: {}
}
</script>

<style>
/* Tooltip container */
.tooltip {
	position: relative;
	display: inline-block;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: #27272a;
	color: #fafafa;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 100%;
	left: 50%;
	margin-left: -60px;
}

.tooltip .tooltiptext::after {
	content: " ";
	position: absolute;
	top: 100%; /* At the bottom of the tooltip */
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #27272a transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
	visibility: visible;
}
</style>
