<template>
  <svg
    class="w-8 h-8"
    viewBox="0 0 362 367"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M187.12 333.06C186.9 333.28 186.68 333.5 186.45 333.72C168.57 350.9 138.76 342.82 131.43 319.13C126.91 304.51 119.39 282.95 112.58 276.22C105.77 269.48 84.14 262.19 69.47 257.82C45.7 250.74 37.31 221.02 54.3 202.96C54.51 202.73 54.73 202.51 54.95 202.28C66.68 190.43 84.53 188.31 98.44 195.97C106.6 200.47 115.14 204.25 124.05 206.98C138.48 211.41 149.61 210.28 163.86 224.38C178.92 239.28 177.1 249.62 181.68 264C184.51 272.88 188.38 281.38 192.96 289.49C200.78 303.34 198.85 321.21 187.12 333.06ZM158.91 63.85C172.11 71.12 185.91 77.24 200.32 81.66C223.65 88.82 241.65 87 264.69 109.79C289.04 133.89 286.09 150.6 293.5 173.85C298.07 188.21 304.33 201.94 311.74 215.07C324.37 237.44 321.25 266.33 302.28 285.5C301.92 285.86 301.56 286.22 301.2 286.57C272.29 314.35 224.1 301.29 212.24 262.98C204.93 239.35 192.77 204.49 181.76 193.6C170.75 182.71 135.77 170.92 112.06 163.86C73.63 152.41 60.06 104.36 87.53 75.16C87.88 74.79 88.23 74.43 88.59 74.06C107.55 54.88 136.4 51.45 158.91 63.85Z"
      fill="#EDEDED"
    />
    <path
      d="M290.8 98.1999C305.712 98.1999 317.8 86.1116 317.8 71.1999C317.8 56.2883 305.712 44.2 290.8 44.2C275.888 44.2 263.8 56.2883 263.8 71.1999C263.8 86.1116 275.888 98.1999 290.8 98.1999Z"
      fill="#EDEDED"
    />
    <path
      d="M107.8 191.2V250.84C107.8 266.22 124.14 276.1 137.76 268.95L202.8 235.2"
      fill="#DBDBDB"
    />
    <path
      d="M202.76 234.82L262.31 203.55C276.9 195.89 276.9 175 262.31 167.33L137.76 101.93C124.14 94.78 107.8 104.66 107.8 120.04V162.39"
      fill="#DBDBDB"
    />
    <path
      d="M107.8 191.2V250.84C107.8 266.22 124.14 276.1 137.76 268.95L202.8 235.2"
      stroke="#675F55"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M202.76 234.82L262.31 203.55C276.9 195.89 276.9 175 262.31 167.33L137.76 101.93C124.14 94.78 107.8 104.66 107.8 120.04V162.39"
      stroke="#675F55"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M294.8 13.2H69.8C39 13.2 13.8 38.3999 13.8 69.1999V294.2C13.8 325 39 350.2 69.8 350.2H294.8C325.6 350.2 350.8 325 350.8 294.2V69.1999C350.8 38.3999 325.6 13.2 294.8 13.2ZM290.8 44.2C305.71 44.2 317.8 56.2899 317.8 71.1999C317.8 86.1099 305.71 98.2 290.8 98.2C275.89 98.2 263.8 86.1099 263.8 71.1999C263.8 56.2899 275.89 44.2 290.8 44.2ZM187.12 333.06C186.9 333.29 186.68 333.51 186.45 333.72C168.57 350.9 138.76 342.82 131.43 319.13C126.91 304.52 119.39 282.96 112.58 276.22C105.78 269.49 84.14 262.2 69.48 257.83C45.71 250.75 37.32 221.03 54.31 202.96C54.52 202.74 54.74 202.51 54.96 202.29C62.09 195.08 71.48 191.47 80.88 191.47C86.94 191.47 93 192.97 98.45 195.98C106.62 200.47 115.15 204.26 124.06 206.99C138.49 211.42 149.62 210.29 163.87 224.39C178.93 239.29 177.11 249.63 181.69 264.01C184.52 272.89 188.39 281.39 192.97 289.5C200.78 303.34 198.85 321.21 187.12 333.06ZM302.27 285.49C301.91 285.85 301.55 286.21 301.19 286.56C272.27 314.34 224.08 301.28 212.23 262.97C204.91 239.34 192.76 204.48 181.75 193.59C170.75 182.7 135.76 170.91 112.05 163.85C73.63 152.4 60.06 104.35 87.53 75.1399C87.87 74.7799 88.23 74.41 88.58 74.05C100.11 62.4 115.29 56.56 130.49 56.56C140.28 56.56 150.08 58.9899 158.91 63.8499C172.11 71.1199 185.91 77.23 200.31 81.65C223.65 88.81 241.64 86.99 264.68 109.78C289.03 133.88 286.08 150.59 293.49 173.85C298.06 188.21 304.32 201.94 311.73 215.07C324.36 237.44 321.23 266.33 302.27 285.49Z"
      fill="#DBDBDB"
    />
    <path
      d="M290.8 98.1999C305.712 98.1999 317.8 86.1116 317.8 71.1999C317.8 56.2883 305.712 44.2 290.8 44.2C275.888 44.2 263.8 56.2883 263.8 71.1999C263.8 86.1116 275.888 98.1999 290.8 98.1999Z"
      stroke="#675F55"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M187.12 333.06C186.9 333.28 186.68 333.5 186.45 333.72C168.57 350.9 138.76 342.82 131.43 319.13C126.91 304.51 119.39 282.95 112.58 276.22C105.77 269.48 84.14 262.19 69.47 257.82C45.7 250.74 37.31 221.02 54.3 202.96C54.51 202.73 54.73 202.51 54.95 202.28C66.68 190.43 84.53 188.31 98.44 195.97C106.6 200.47 115.14 204.25 124.05 206.98C138.48 211.41 149.61 210.28 163.86 224.38C178.92 239.28 177.1 249.62 181.68 264C184.51 272.88 188.38 281.38 192.96 289.49C200.78 303.34 198.85 321.21 187.12 333.06ZM158.91 63.85C172.11 71.12 185.91 77.24 200.32 81.66C223.65 88.82 241.65 87 264.69 109.79C289.04 133.89 286.09 150.6 293.5 173.85C298.07 188.21 304.33 201.94 311.74 215.07C324.37 237.44 321.25 266.33 302.28 285.5C301.92 285.86 301.56 286.22 301.2 286.57C272.29 314.35 224.1 301.29 212.24 262.98C204.93 239.35 192.77 204.49 181.76 193.6C170.75 182.71 135.77 170.92 112.06 163.86C73.63 152.41 60.06 104.36 87.53 75.16C87.88 74.79 88.23 74.43 88.59 74.06C107.55 54.88 136.4 51.45 158.91 63.85Z"
      stroke="#675F55"
      stroke-width="2"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
export default {
  name: "WhiteLogo",
};
</script>

<style></style>
