import axios from "axios"
const state = {
	profile: null,
	profileCategories: []
}

const getters = {
	getProfile: (state) => state.profile,
	getProfileCategories: (state) => state.profileCategories
}

const actions = {
	async fetchContributorProducts({ commit }, params) {
		try {
			const { data } = await axios.post(
				`${process.env.VUE_APP_BASE_URI}/product/get-author-products`,
				{
					userName: params.userName,
					page: params.page,
					category: params.category
				}
			)
			let categoriesArray = []

			data.categories.rows.forEach((category, ind) => {
				categoriesArray.push(`${category} (${data.categories.count[ind]})`)
			})

			commit("setProfile", data)
			commit("setProfileCategories", categoriesArray)
		} catch (error) {
			console.log(error)
		}
	}
}

const mutations = {
	setProfile: (state, val) => (state.profile = val),
	setProfileCategories: (state, val) => (state.profileCategories = val)
}

export default {
	state,
	getters,
	actions,
	mutations
}
