<template>
  <div
    v-if="isCollection"
    @click="isCollection = false"
    class="fixed h-screen w-screen z-10 inset-0"
  ></div>
  <div
    v-if="isDownload"
    @click="isDownload = !isDownload"
    class="fixed h-screen w-screen z-10 inset-0"
  ></div>
  <div
    class="relative z-20 w-full lg:h-24 bg-white shadow-md rounded-md flex flex-col lg:flex-row items-center justify-center p-0 lg:p-3 transform hover:-translate-y-1 hover:z-50"
  >
    <div
      v-if="isCollection"
      @click="isCollection = false"
      class="fixed h-screen w-screen z-30 inset-0"
    ></div>
    <div class=" w-full h-24 flex items-center justify-center p-5">
      <div
        v-if="isCollection"
        @click="isCollection = false"
        class="fixed h-screen w-screen z-30 inset-0"
      ></div>
      <div class="h-full lg:w-full flex items-center justify-start max-w-xxs">
        <button
          v-if="!isPlaying && title && !loading"
          @click="handlePlay"
          class="flex-shrink-0 w-8 h-8 md:w-9 md:h-9 rounded bg-gray-800 flex items-center justify-center focus:outline-none"
        >
          <span
            class="iconify text-white w-6 h-6 ml-1"
            data-icon="bi:play-fill"
            data-inline="false"
          ></span>
        </button>
        <button
          v-if="isPlaying && title && loading"
          @click="handlePlay"
          class="flex-shrink-0 w-8 h-8 md:w-9 md:h-9 rounded bg-gray-800 flex items-center justify-center focus:outline-none"
        >
          <Loader :whiteSmall="true" />
        </button>
        <button
          v-if="isPlaying && title && !loading"
          @click="handlePause"
          class="flex-shrink-0 w-8 h-8 md:w-9 md:h-9 rounded bg-gray-800 flex items-center justify-center focus:outline-none"
        >
          <svg
            class="text-white w-6 h-6"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <div
          v-if="!title"
          class="w-8 h-8 md:w-9 md:h-9 rounded bg-gray-400 animate-pulse"
        ></div>

        <!-- information -->
        <div
          @click.self="isCollection = false"
          class="hidden ml-5 truncate lg:flex flex-col items-start justify-start"
        >
          <a
            :href="
              `/item/${title
                .toLowerCase()
                .split(' ')
                .join('-')}/${id}`
            "
            exact-path
            v-if="title"
            class="w-full truncate text-s text-gray-800 font-semibold"
          >
            {{ title }}
          </a>
          <div v-else class="w-32 h-3 rounded bg-gray-400 animate-pulse"></div>
          <div class="mt-1 flex items-center justify-center">
            <span
              v-if="isNew"
              class="text-xxs mr-2 text-brandPurple border border-brandPurple px-1 rounded font-bold"
              >New</span
            >
            <h1 v-if="author" class="text-xs text-gray-600">
              By
              <a
                :href="`/user/${author}`"
                class="hover:underline hover:text-brandPurple"
                >{{ author }}</a
              >
            </h1>
            <div
              v-else
              class="w-16 h-3 rounded bg-gray-400 animate-pulse"
            ></div>
          </div>
        </div>
      </div>

      <!-- audio -->
      <div
        @click.self="isCollection = false"
        class="ml-5 lg:ml-0 relative h-full w-full flex items-center justify-center"
      >
        <div
          v-if="waveformed"
          class="absolute inset-0 z-30 bg-gray-400 rounded-md animate-pulse flex items-center justify-center"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            width="2em"
            height="2em"
            class="text-white"
            style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 1024 1024"
          >
            <path
              d="M960 576v96q0 13-9.5 22.5T928 704t-22.5-9.5T896 672v64q0 13-9.5 22.5T864 768t-22.5-9.5T832 736V608q0 13-9.5 22.5T800 640t-22.5-9.5T768 608v256q0 13-9.5 22.5T736 896t-22.5-9.5T704 864v64q0 13-9.5 22.5T672 960t-22.5-9.5T640 928V608q0 13-9.5 22.5T608 640t-22.5-9.5T576 608v128q0 13-9.5 22.5T544 768t-22.5-9.5T512 736v64q0 13-9.5 22.5T480 832t-22.5-9.5T448 800v192q0 13-9.5 22.5T416 1024t-22.5-9.5T384 992V864q0 13-9.5 22.5T352 896t-22.5-9.5T320 864V672q0 13-9.5 22.5T288 704t-22.5-9.5T256 672v64q0 13-9.5 22.5T224 768t-22.5-9.5T192 736v-64q0 13-9.5 22.5T160 704t-22.5-9.5T128 672v-64q0 13-9.5 22.5T96 640t-22.5-9.5T64 608v-32q-27 0-45.5-19T0 511.5t18.5-45T64 448v-32q0-13 9.5-22.5T96 384t22.5 9.5T128 416v-64q0-13 9.5-22.5T160 320t22.5 9.5T192 352v-64q0-13 9.5-22.5T224 256t22.5 9.5T256 288v64q0-13 9.5-22.5T288 320t22.5 9.5T320 352V160q0-13 9.5-22.5T352 128t22.5 9.5T384 160V32q0-13 9.5-22.5T416 0t22.5 9.5T448 32v192q0-13 9.5-22.5T480 192t22.5 9.5T512 224v64q0-13 9.5-22.5T544 256t22.5 9.5T576 288v128q0-13 9.5-22.5T608 384t22.5 9.5T640 416V96q0-13 9.5-22.5T672 64t22.5 9.5T704 96v64q0-13 9.5-22.5T736 128t22.5 9.5T768 160v256q0-13 9.5-22.5T800 384t22.5 9.5T832 416V288q0-13 9.5-22.5T864 256t22.5 9.5T896 288v64q0-13 9.5-22.5T928 320t22.5 9.5T960 352v96q27 0 45.5 18.5t18.5 45t-18.5 45.5t-45.5 19z"
              fill="#fff"
            />
            <rect
              x="0"
              y="0"
              width="1024"
              height="1024"
              fill="rgba(0, 0, 0, 0)"
            />
          </svg>
        </div>
        <div
          :class="isWave1Loaded ? 'z-10 opacity-0' : 'z-20 opacity-100'"
          class="absolute z-20 h-full w-full inset-0"
        >
          <div class="mt-1">
            <div class="" :id="id1" :ref="id1"></div>
          </div>
        </div>
        <div
          :class="isWave1Loaded ? 'z-20 opacity-100' : 'z-10 opacity-0'"
          class="absolute z-10 h-full w-full inset-0"
        >
          <div class="mt-1">
            <div class="" :id="id2" :ref="id2"></div>
          </div>
        </div>
      </div>

      <!-- meta info and downlaod -->
      <div
        class="hidden h-full pl-5 lg:flex items-center justify-end space-x-3"
      >
        <div class="flex items-center space-x-2">
          <svg
            v-if="title"
            class="w-4 h-4 text-gray-800"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
              clip-rule="evenodd"
            />
          </svg>
          <div
            v-else
            class="w-4 h-4 rounded-full bg-gray-400 animate-pulse"
          ></div>
          <h1 v-if="title" class="text-xs text-gray-600">
            {{
              length &&
                length
                  .split(":")
                  .filter((t, i) => i > 0)
                  .join(":")
            }}
          </h1>
          <div v-else class="w-8 h-3 rounded bg-gray-400 animate-pulse"></div>
        </div>

        <h1
          v-if="trackLength"
          class="tooltip whitespace-nowrap text-xs text-gray-600 underline hover:text-gray-800"
        >
          <span class="tooltiptext z-50">
            <p
              v-for="(length, i) in trackLength.split(',')"
              :key="i"
              class="py-2"
            >
              Track {{ i + 1 }} : {{ length }}
            </p>
          </span>
          {{ trackLength && trackLength.split(",").length }} Track{{
            trackLength.split(",").length > 1 ? "s" : ""
          }}
        </h1>
        <div v-else class="w-10 h-3 rounded bg-gray-400 animate-pulse"></div>

        <h1
          v-if="bpm && currentCategory !== 'Sound Effects'"
          class="whitespace-nowrap text-xs text-gray-600"
        >
          BPM <span class="font-bold">{{ bpm }}</span>
        </h1>

        <div
          v-if="!bpm && currentCategory !== 'Sound Effects'"
          class="w-14 h-3 rounded bg-gray-400 animate-pulse"
        ></div>

        <div class="relative ml-2 flex items-center justify-center space-x-2">
          <!-- collection popup -->
          <div class="focus:outline-none outline-none">
            <button
              @click="handleCollectionClick"
              v-if="title"
              class="tooltip w-7 h-7 rounded-md bg-indigo-100 p-1 focus:outline-none flex items-center justify-center"
            >
              <span class="hidden lg:block tooltiptext text-xs"
                >Add to collection</span
              >
              <svg
                class="w-5 h-5 text-indigo-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
              </svg>
            </button>
            <div
              v-else
              class="w-7 h-7 rounded-md bg-gray-400 animate-pulse"
            ></div>

            <Transition
              enter-active-class="transition transform duration-100 ease-in"
              enter-from-class="opacity-0 scale-95"
              enter-to-class="opacity-100 scale-100"
              leave-active-class="transition transform duration-300 ease-out"
              leave-from-class="opacity-100 scale-100"
              leave-to-class="opacity-0 scale-95"
            >
              <div
                v-if="isCollection"
                class="absolute z-50 w-72 bottom-0 right-0 focus:outline-none outline-none rounded-md shadow-lg bg-white overflow-hidden border border-gray-300 dark:border-darkBackground"
              >
                <div
                  v-if="getCurrentUser"
                  class="w-full p-3 flex items-center justify-between"
                >
                  <h1 class="text-sm font-semibold text-gray-800">
                    My Collection
                  </h1>
                  <a
                    href="/collections"
                    class="text-xs text-brandPurple focus:outline-none"
                    >See All</a
                  >
                </div>

                <!-- search -->
                <form
                  v-if="getCurrentUser"
                  @submit.prevent="handleCreateCollection"
                  class="mt-1 mb-3 w-full px-3"
                >
                  <div
                    class="px-3 w-full flex items-center justify-between bg-gray-200 rounded-md"
                  >
                    <input
                      type="search"
                      v-model="collectionName"
                      @input="handleSearchInput"
                      placeholder="search or create a collection"
                      class="py-2 rounded-md w-full text-sm text-gray-800 bg-transparent placeholder-gray-500 focus:outline-none"
                    />
                    <svg
                      class="w-5 h-5 text-gray-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                </form>

                <!-- end of search -->

                <!-- suggestion -->
                <div
                  v-if="getCurrentUser"
                  class="mt-4 mb-3 px-3 h-16 overflow-y-auto flex flex-col gap-2 items-start justify-start"
                >
                  <div
                    v-for="collection in getFilteredAvailableCollections"
                    :key="collection.id"
                    class="w-full flex items-center justify-between gap-5"
                  >
                    <span class=" truncate text-xs font-medium text-gray-800">
                      {{ collection.name }}
                    </span>

                    <abbr
                      v-if="
                        collection.products &&
                          collection.products.filter((p) => p.id === id)
                            .length > 0
                      "
                      title="Remove"
                    >
                      <button
                        @click="
                          removeFromCollection({
                            productId: id,
                            collectionId: collection.id,
                          })
                        "
                        class="p-1 text-xs font-medium rounded bg-red-100 text-red-900 focus:outline-none"
                      >
                        Remove
                      </button>
                    </abbr>
                    <abbr v-else title="Add">
                      <button
                        @click="
                          addToCollection({
                            productId: id,
                            collectionId: collection.id,
                          })
                        "
                        class="p-1 text-xs font-medium text-center rounded bg-green-100 text-green-900 focus:outline-none"
                      >
                        Add
                      </button>
                    </abbr>
                  </div>
                </div>
                <!-- end of suggestion -->

                <!-- create button -->
                <button
                  v-if="getCurrentUser"
                  @click="handleCreateCollection"
                  class="py-3 text-sm text-center bg-gray-200 border-t border-gray-300 w-full flex items-center justify-center focus:outline-none"
                >
                  <svg
                    class="w-6 h-6 text-buttonColor"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Create
                </button>
                <!-- end of create button -->

                <!-- if not logged in -->
                <div v-if="!getCurrentUser" class="p-5">
                  <h1 class="text-base font-bold text-gray-800 text-center">
                    Create an account to save this item for later
                  </h1>
                  <a
                    href="/register"
                    class="mt-5 mb-2 w-full block px-2 py-2 md:px-3 md:py-2 font-semibold text-center text-gray-900 text-xs md:text-sm bg-brandGreen transition-colors duration-300 hover:bg-brandGreenHover rounded-md focus:outline-none"
                  >
                    Create a free account
                  </a>

                  <a
                    href="/login"
                    class="text-center w-full block text-gray-800 text-xs"
                  >
                    <span>already Vooaxis member?</span>
                    <span class="text-brandPurple hover:underline">
                      Sign in here</span
                    >
                  </a>
                </div>
                <!-- end of if not logged in -->
              </div>
            </Transition>
          </div>
          <!-- end of collection popup -->

          <!-- download popup -->
          <div class="focus:outline-none outline-none">
            <button
              @click="handleDownloadClick"
              v-if="title"
              class="tooltip w-7 h-7 rounded-md bg-red-100 p-1 focus:outline-none flex items-center justify-center"
            >
              <span class="hidden lg:block tooltiptext text-xs">Download</span>
              <svg
                class="w-5 h-5 text-buttonColor"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <div
              v-else
              class="w-7 h-7 rounded-md bg-gray-400 animate-pulse"
            ></div>

            <Transition
              class="absolute z-20"
              enter-active-class="transition transform duration-100 ease-in"
              enter-from-class="opacity-0 scale-95"
              enter-to-class="opacity-100 scale-100"
              leave-active-class="transition transform duration-300 ease-out"
              leave-from-class="opacity-100 scale-100"
              leave-to-class="opacity-0 scale-95"
            >
              <div
                v-if="isDownload"
                class="absolute z-30 w-72 bottom-0 right-0 focus:outline-none outline-none rounded-md shadow-lg bg-white overflow-hidden border border-gray-300 dark:border-darkBackground"
              >
                <div
                  v-if="
                    getCurrentUser && getCurrentUser.subscription !== 'free'
                  "
                  class="w-full p-3 flex items-center justify-between"
                >
                  <h1 class="text-sm font-semibold text-gray-800">
                    Download
                  </h1>
                </div>

                <!-- search -->
                <form
                  v-if="
                    getCurrentUser && getCurrentUser.subscription !== 'free'
                  "
                  @submit.prevent="handleCreateCollection"
                  class="mt-1 mb-3 w-full px-3"
                >
                  <div
                    class="px-3 w-full flex items-center justify-between bg-gray-200 rounded-md"
                  >
                    <input
                      type="search"
                      v-model="licenseName"
                      @input="handleLicense"
                      placeholder="Enter license name"
                      class="py-2 rounded-md w-full text-sm text-gray-800 bg-transparent placeholder-gray-500 focus:outline-none"
                    />
                  </div>
                </form>

                <!-- end of search -->

                <!-- download button -->
                <button
                  v-if="
                    getCurrentUser && getCurrentUser.subscription !== 'free'
                  "
                  @click="handleDownload"
                  :class="[
                    !activateDownload
                      ? 'text-gray-400 pointer-events-none'
                      : 'text-gray-800',
                    { 'pointer-events-none': getDownloadLoading },
                  ]"
                  class="py-3 text-sm text-center bg-gray-200 border-t border-gray-300 w-full flex items-center justify-center focus:outline-none"
                >
                  <Loader v-if="getDownloadLoading" :bright="true" />
                  <svg
                    v-if="!getDownloadLoading"
                    :class="
                      !activateDownload
                        ? 'text-gray-400 pointer-events-none'
                        : 'text-buttonColor'
                    "
                    class="w-5 h-5 mr-1 "
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span v-if="!getDownloadLoading">Download</span>
                </button>
                <!-- end of download button -->

                <!-- if not logged in -->
                <div
                  v-if="
                    !getCurrentUser ||
                      (getCurrentUser && getCurrentUser.subscription === 'free')
                  "
                  class="p-5"
                >
                  <h1
                    class="text-base px-3 font-bold text-gray-800 text-center"
                  >
                    Subscribe to get this item and unlimited downloads for your
                    Creative Project.
                  </h1>
                  <a
                    href="/pricing"
                    class="mt-5 mb-5 w-full block px-2 py-2 md:px-3 md:py-2 font-semibold text-center text-gray-900 text-xs md:text-sm bg-brandGreen transition-colors duration-300 hover:bg-brandGreenHover rounded-md focus:outline-none"
                  >
                    Subscribe to download
                  </a>

                  <a
                    v-if="!getCurrentUser"
                    href="/login"
                    class="text-center w-full block text-gray-800 text-xs"
                  >
                    <span>already Vooaxis member?</span>
                    <span class="text-brandPurple hover:underline">
                      Sign in here</span
                    >
                  </a>
                </div>
                <!-- end of if not logged in -->
              </div>
            </Transition>
          </div>
          <!-- end of collection popup -->
        </div>
      </div>
    </div>

    <!-- mobile  -->
    <div
      class="lg:hidden w-full  p-5 pt-0 space-x-5  flex items-start justify-between "
    >
      <div
        class="w-full truncate flex flex-col items-start justify-start max-w-xs "
      >
        <a
          :href="
            `/item/${title
              .toLowerCase()
              .split(' ')
              .join('-')}/${id}`
          "
          v-if="title"
          class="w-full truncate text-s text-gray-800 font-semibold"
        >
          {{ title }}
        </a>
        <div v-else class="w-full h-3 rounded bg-gray-400 animate-pulse"></div>
        <div class="mt-1 flex items-center justify-center">
          <span
            v-if="isNew"
            class="text-xxs mr-2 text-brandPurple border border-brandPurple px-1 rounded font-bold"
            >New</span
          >
          <h1 v-if="author" class="text-xs text-gray-600">
            By
            <a
              :href="`/user/${author}`"
              class="hover:underline hover:text-brandPurple"
              >{{ author && author }}</a
            >
          </h1>
          <div v-else class="w-16 h-3 rounded bg-gray-400 animate-pulse"></div>
        </div>
      </div>

      <!-- meta -->
      <div
        class="h-ful md:pl-5 flex flex-col md:flex-row items-center justify-center md:justify-end md:space-x-3"
      >
        <div class="flex items-center space-x-2">
          <svg
            v-if="title"
            class="w-4 h-4 text-gray-800"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
              clip-rule="evenodd"
            />
          </svg>
          <div
            v-else
            class="w-4 h-4 rounded-full bg-gray-400 animate-pulse"
          ></div>
          <h1 v-if="title" class="text-xs text-gray-600">
            {{
              length &&
                length
                  .split(":")
                  .filter((t, i) => i > 0)
                  .join(":")
            }}
          </h1>
          <div v-else class="w-8 h-3 rounded bg-gray-400 animate-pulse"></div>

          <h1
            v-if="trackLength"
            class="whitespace-nowrap text-xs text-gray-600"
          >
            {{ trackLength && trackLength.split(",").length }} Track{{
              trackLength && trackLength.split(",").length > 1 ? "s" : ""
            }}
          </h1>
          <div v-else class="w-10 h-3 rounded bg-gray-400 animate-pulse"></div>

          <h1
            v-if="bpm && currentCategory !== 'Sound Effects'"
            class="whitespace-nowrap text-xs text-gray-600"
          >
            BPM <span class="font-bold">{{ bpm }}</span>
          </h1>
          <div
            v-if="!bpm && currentCategory !== 'Sound Effects'"
            class="w-14 h-3 rounded bg-gray-400 animate-pulse"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import WaveSurfer from "wavesurfer.js";
import Loader from "./Loader.vue";
export default {
  name: "ProductAudioCard",
  components: { Loader },
  props: [
    "peaks",
    "audio",
    "title",
    "author",
    "authorId",
    "id",
    "date",
    "trackLength",
    "bpm",
    "currentCategory",
    "length",
  ],

  methods: {
    ...mapActions(["createCollection"]),
    ...mapActions(["addToCollection"]),
    ...mapActions(["removeFromCollection"]),
    ...mapActions(["filterCollections"]),
    ...mapActions(["downloadProduct"]),
    ...mapActions(["appendAlert"]),

    handleDownloadClick() {
      if (
        !this.getCurrentUser ||
        (this.getCurrentUser && this.getCurrentUser.isEmailVerified)
      ) {
        this.isDownload = true;
      } else {
        this.appendAlert({
          id: Math.floor(Math.random() * 10000),
          title: "Your Email is not yet verified",
          sub: "Click the link sent to your email to verify your account",
          icon: "bi:info-circle-fill",
          type: "warning",
          button: {
            name: "Resend Verification email",
            action: "resendVerificationEmail",
          },
        });
      }
    },

    handleCollectionClick() {
      if (
        !this.getCurrentUser ||
        (this.getCurrentUser && this.getCurrentUser.isEmailVerified)
      ) {
        this.isCollection = true;
      } else {
        this.appendAlert({
          id: Math.floor(Math.random() * 10000),
          title: "Your Email is not yet verified",
          sub: "Click the link sent to your email to verify your account",
          icon: "bi:info-circle-fill",
          type: "warning",
          button: {
            name: "Resend Verification email",
            action: "resendVerificationEmail",
          },
        });
      }
    },

    handleDownload() {
      this.downloadProduct({
        id: this.id,
        license: this.licenseName,
        type: "download",
      });
    },

    handleLicense() {
      if (this.licenseName.length <= 0) {
        this.activateDownload = false;
      } else {
        this.activateDownload = true;
      }
    },

    handleSearchInput(e) {
      this.filterCollections(e.target.value);
    },

    handleCreateCollection() {
      if (
        this.collectionName.length > 0 &&
        this.getFilteredAvailableCollections.length <= 0
      ) {
        this.createCollection({ name: this.collectionName, id: this.id });
        this.collectionName = "";
      }
    },

    handlePause() {
      this.isPlaying = false;
      this.WaveSurfer2.pause();
    },
    handlePlay() {
      if (!this.loading) {
        this.isPlaying = true;
        if (!this.isWave1Loaded) {
          this.loading = true;

          const wavesurfer2 = WaveSurfer.create({
            container: `#${this.id2}`,
            waveColor: "#CBD5E1",
            progressColor: "#7631ff",
            cursorColor: "transparent",
            mediaType: "audio",
            height: 50,
            autoCenter: true,
            responsive: true,
          });

          this.WaveSurfer2 = wavesurfer2;

          const peaks = this.peaks;

          wavesurfer2.load(this.audio);

          wavesurfer2.backend.peaks = peaks;

          wavesurfer2.peaks = peaks;

          //Draw peaks
          wavesurfer2.drawBuffer();

          wavesurfer2.on("ready", (e) => {
            this.loading = false;
            this.isWave1Loaded = true;

            wavesurfer2.play();
            if (this.seeked > 0) {
              wavesurfer2.seekTo(this.seeked);
            }
          });

          wavesurfer2.on("finish", () => {
            this.isPlaying = false;
            wavesurfer2.stop();
          });
        } else {
          this.WaveSurfer2.play();
        }
      }
    },
  },

  computed: {
    ...mapGetters(["getFilteredAvailableCollections"]),
    ...mapGetters(["getCurrentUser"]),
    ...mapGetters(["getDownloadLoading"]),
    ...mapGetters(["getIsDownloaded"]),
  },

  created() {
    this.id1 = `waveform1${Math.floor(Math.random() * (100000 - 1 + 1)) + 1}`;
    this.id2 = `waveform2${Math.floor(Math.random() * (100000 - 1 + 1)) + 1}`;
  },

  mounted() {
    const currentDate = new Date().getMonth();
    const productDate = parseInt(this.date.split("-")[1]) - 1;
    if (currentDate === productDate) {
      this.isNew = true;
    } else {
      this.isNew = false;
    }
    if (this.peaks) {
      setTimeout(() => {
        const peaks = this.peaks;
        const wavesurfer = WaveSurfer.create({
          container: `#${this.id1}`,
          waveColor: "#CBD5E1",
          progressColor: "#7631ff",
          cursorColor: "transparent",
          mediaType: "audio",
          normalize: true,
          height: 50,
          autoCenter: true,
          responsive: true,
        });

        //Set peaks
        wavesurfer.backend.peaks = peaks;

        this.WaveSurfer.peaks = peaks;

        //Draw peaks
        wavesurfer.drawBuffer();

        this.waveformed = false;

        //Start playing after song is loaded
        wavesurfer.on("ready", () => {
          this.loaded = true;
          this.loading = false;

          wavesurfer.play();
        });

        wavesurfer.on("seek", (e) => {
          this.seeked = e;
          this.handlePlay();
        });

        this.WaveSurfer = wavesurfer;

        if (this.$refs[this.id1].children.length > 2) {
          this.$refs[this.id1].children[0].classList.add("hidden");
        }
      }, 200);
    }
  },

  watch: {
    $route: function(nv) {
      if (this.isPlaying) {
        this.handlePause();
      }
    },
    getIsDownloaded: function(nv, ov) {
      if (nv) {
        this.licenseName = "";
        this.isDownload = false;
      }
    },
  },

  data() {
    return {
      id1: 0,
      id2: 0,
      isPlaying: false,
      loaded: false,
      loading: false,
      duration: 0.0,
      WaveSurfer: {},
      WaveSurfer2: {},
      Player: null,
      interval: null,
      isWave1Loaded: false,
      seeked: 0,
      waveformed: true,
      isNew: false,
      isCollection: false,
      collectionName: "",
      isDownload: false,
      licenseName: "",
      activateDownload: false,
    };
  },
};
</script>

<style scoped>
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #27272a;
  color: #fafafa;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 50;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  margin-bottom: 10px;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #27272a transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
