<template>
  <section class="mt-14 w-full bg-subtleGray">
    <div
      class="
        mt-6
        w-full
        max-w-1xl
        mx-auto
        p-3
        py-10
        md:py-10 md:p-6
        min-h-screen
        bg-subtleGray
      "
    >
      <div class="w-full flex flex-col items-center justify-center">
        <!-- badge -->
        <span
          class="
            px-3
            py-1
            rounded-md
            bg-brandPurple
            text-white text-sm
            font-medium
          "
        >
          Join us today
        </span>
        <h1
          class="mt-3 text-2xl md:text-4xl text-center font-bold text-gray-800"
        >
          Pricing for Everyone
        </h1>
        <h3 class="mt-3 text-sm text-center text-gray-600">
          Top Creative Digital Assets. Unlimited Downloads For your Creative
          Project.
        </h3>
        <!-- end of badge -->
      </div>

      <!-- cards -->
      <div
        class="
          mt-20
          flex
          items-center
          md:flex-row
          flex-col
          justify-center
          gap-5
        "
      >
        <!-- card free -->
        <div
          v-if="
            !getCurrentUser ||
            (getCurrentUser && getCurrentUser.subscription === 'free')
          "
          class="p-10 w-full md:w-auto rounded-md bg-gray-200"
        >
          <span
            class="
              bg-brandBlack
              text-white text-sm
              font-medium
              p-1
              px-2
              rounded
            "
            >Basic
          </span>
          <div class="my-4">
            <h1 class="text-5xl 2xl:text-6xl text-gray-800 font-semibold">
              Free
            </h1>
            <h3 class="text-gray-500 text-sm">For personal projects</h3>
          </div>
          <!-- features -->
          <div class="my-8 space-y-5">
            <div class="space-x-3 flex">
              <svg
                class="h-5 w-5 text-gray-800"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                />
              </svg>
              <span class="text-gray-800 text-sm">Free downloads only</span>
            </div>

            <div class="space-x-3 flex">
              <svg
                class="h-5 w-5 text-gray-800"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
              <span class="text-gray-800 text-sm"
                >Limited customer support</span
              >
            </div>

            <div class="space-x-3 flex">
              <svg
                class="h-5 w-5 text-gray-800"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                />
              </svg>
              <span class="text-gray-800 text-sm">Cancel anytime</span>
            </div>
          </div>

          <!-- cta -->
          <router-link
            to="/register"
            v-if="!getCurrentUser"
            class="
              block
              w-full
              md:max-w-xs
              mt-6
              px-8
              py-2
              text-center
              font-medium
              text-sm text-white
              bg-brandBlack
              transition-colors
              duration-300
              rounded-md
              focus:outline-none
            "
          >
            Create your account
          </router-link>
          <button
            v-if="getCurrentUser && getCurrentUser.subscription === 'free'"
            disabled
            class="
              block
              pointer-events-none
              w-full
              md:max-w-xs
              mt-6
              px-8
              py-2
              font-medium
              text-sm text-white
              bg-brandBlack
              transition-colors
              duration-300
              rounded-md
              focus:outline-none
            "
          >
            Currently selected
          </button>
        </div>
        <!-- end of card free -->

        <!-- card yearly main -->
        <div
          class="
            relative
            w-full
            md:w-auto
            p-10
            rounded-md
            bg-brandPurple
            border-2
            group
            hover:bg-transparent
            transition-all
            duration-500
            hover:border-brandPurple
          "
        >
          <span
            class="
              text-center
              font-medium
              pt-1
              -mt-4
              shadow
              border-2 border-transparent
              absolute
              top-0
              inset-x-0
              h-7
              rounded
              text-xs text-white
              bg-brandBlack
              max-w-xxxs
              mx-auto
            "
            >Save over $80
          </span>
          <div class="flex items-start justify-start gap-2">
            <span
              class="
                bg-brandGreen
                group-hover:bg-brandPurple
                transition-all
                duration-500
                group-hover:text-white
                text-gray-800 text-sm
                font-medium
                p-1
                px-2
                rounded
              "
              >Annual</span
            >

            <h3
              class="
                text-lg text-white
                group-hover:text-brandPurple
                font-medium
                line-through
              "
            >
              $179.99
            </h3>
          </div>
          <div class="my-4">
            <div
              class="
                text-white
                group-hover:text-gray-800
                transition-all
                duration-500
              "
            >
              <span class="text-5xl 2xl:text-6xl font-bold">$99</span>
              <span class="text-base font-bold">.99</span>
              <span class="text-sm"> /year</span>
            </div>
            <h3
              class="
                text-white
                group-hover:text-gray-800
                transition-all
                duration-500
                text-sm
              "
            >
              For Commercial projects
            </h3>
          </div>
          <!-- features -->
          <div class="my-8 space-y-5">
            <div class="space-x-3 flex">
              <svg
                class="
                  h-5
                  w-5
                  group-hover:text-gray-800
                  transition-all
                  duration-500
                  text-white
                "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                />
              </svg>

              <span
                class="
                  text-white
                  group-hover:text-gray-800
                  transition-all
                  duration-500
                  text-sm
                "
                >Unlimited Downloads</span
              >
            </div>

            <div class="space-x-3 flex">
              <svg
                class="
                  h-5
                  w-5
                  text-white
                  group-hover:text-gray-800
                  transition-all
                  duration-500
                "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
              <span
                class="
                  text-white
                  group-hover:text-gray-800
                  transition-all
                  duration-500
                  text-sm
                "
                >Standard customer support</span
              >
            </div>

            <div class="space-x-3 flex">
              <svg
                class="
                  h-5
                  w-5
                  text-white
                  group-hover:text-gray-800
                  transition-all
                  duration-500
                "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                />
              </svg>
              <span
                class="
                  text-white
                  group-hover:text-gray-800
                  transition-all
                  duration-500
                  text-sm
                "
                >Cancel anytime
              </span>
            </div>
          </div>

          <!-- cta -->
          <router-link
            v-if="!getCurrentUser"
            to="/register"
            class="
              block
              w-full
              text-center
              md:max-w-xs
              mt-6
              px-8
              py-2
              font-medium
              text-sm
              group-hover:text-white group-hover:bg-brandPurple
              bg-brandGreen
              hover:bg-brandGreenHover
              text-gray-800
              rounded-md
              focus:outline-none
              transition-all
              duration-500
            "
          >
            Create an account
          </router-link>
          <button
            id="start_yearly"
            @click="handleUpgradeYearly"
            v-if="getCurrentUser && getCurrentUser.subscription === 'free'"
            to="/checkout/yearly"
            class="
              block
              w-full
              text-center
              md:max-w-xs
              mt-6
              px-8
              py-2
              font-medium
              text-sm
              group-hover:text-white group-hover:bg-brandPurple
              bg-brandGreen
              hover:bg-brandGreenHover
              text-gray-800
              rounded-md
              focus:outline-none
              transition-all
              duration-500
            "
          >
            Start now
          </button>
          <button
            @click="handleUpgradeCurrentPlan"
            v-if="getCurrentUser && getCurrentUser.subscription === 'monthly'"
            to="/checkout/upgrade"
            class="
              block
              w-full
              text-center
              md:max-w-xs
              mt-6
              px-8
              py-2
              font-medium
              text-sm
              group-hover:text-white group-hover:bg-brandPurple
              bg-brandGreen
              hover:bg-brandGreenHover
              text-gray-800
              rounded-md
              focus:outline-none
              transition-all
              duration-500
            "
          >
            Upgrade
          </button>
          <button
            v-if="getCurrentUser && getCurrentUser.subscription === 'yearly'"
            disabled
            class="
              pointer-events-none
              block
              w-full
              text-center
              md:max-w-xs
              mt-6
              px-8
              py-2
              font-medium
              text-sm
              group-hover:text-white group-hover:bg-brandPurple
              bg-brandGreen
              hover:bg-brandGreenHover
              text-gray-800
              rounded-md
              focus:outline-none
              transition-all
              duration-500
            "
          >
            Currently Selected
          </button>
        </div>
        <!-- main card yearly main -->

        <!-- card monthly -->
        <div
          v-if="
            !getCurrentUser ||
            (getCurrentUser && getCurrentUser.subscription !== 'yearly')
          "
          class="p-10 w-full md:w-auto rounded-md bg-brandBlack"
        >
          <span
            class="
              bg-gray-200
              text-gray-800 text-sm
              font-medium
              p-1
              px-2
              rounded
            "
          >
            Monthly
          </span>
          <div class="my-4">
            <div class="text-white">
              <span class="text-5xl 2xl:text-6xl font-bold">$14</span>
              <span class="text-base font-bold">.99</span>
              <span class="text-sm"> /monthly</span>
            </div>
            <h3 class="text-white text-sm">For Commercial projects</h3>
          </div>
          <!-- features -->
          <div class="my-8 space-y-5">
            <div class="space-x-3 flex">
              <svg
                class="h-5 w-5 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                />
              </svg>

              <span class="text-white text-sm">Unlimited Downloads</span>
            </div>

            <div class="space-x-3 flex">
              <svg
                class="h-5 w-5 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
              <span class="text-white text-sm">Standard customer support</span>
            </div>

            <div class="space-x-3 flex">
              <svg
                class="h-5 w-5 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                />
              </svg>
              <span class="text-white text-sm">Cancel anytime</span>
            </div>
          </div>

          <!-- cta -->
          <router-link
            v-if="!getCurrentUser"
            to="/register"
            class="
              block
              w-full
              md:max-w-xs
              mt-6
              px-8
              py-2
              text-center
              font-medium
              text-sm text-gray-800
              bg-gray-200
              hover:bg-gray-100
              transition-colors
              duration-300
              rounded-md
              focus:outline-none
            "
          >
            Create an account
          </router-link>
          <button
            id="start_monthly"
            @click="handleUpgradeMonthly"
            v-if="getCurrentUser && getCurrentUser.subscription !== 'monthly'"
            class="
              block
              w-full
              md:max-w-xs
              mt-6
              px-8
              py-2
              text-center
              font-medium
              text-sm text-gray-800
              bg-gray-200
              hover:bg-gray-100
              transition-colors
              duration-300
              rounded-md
              focus:outline-none
            "
          >
            Start now
          </button>
          <button
            v-if="getCurrentUser && getCurrentUser.subscription === 'monthly'"
            disabled
            class="
              pointer-events-none
              block
              w-full
              md:max-w-xs
              mt-6
              px-8
              py-2
              text-center
              font-medium
              text-sm text-gray-800
              bg-gray-200
              hover:bg-gray-100
              transition-colors
              duration-300
              rounded-md
              focus:outline-none
            "
          >
            Currently selected
          </button>
        </div>
        <!-- end of card monthly -->
      </div>
      <h3
        class="
          mt-3
          mb-20
          w-full
          block
          text-center
          font-semibold
          text-sm text-gray-800
        "
      >
        All membership are for single use only
      </h3>
      <!-- end of cards -->

      <!-- easy payments  -->
      <h1
        class="
          mt-20
          mb-5
          w-full
          block
          text-center text-base
          md:text-xl
          font-bold
          text-gray-800
        "
      >
        Easy and secure payment
      </h1>
      <div
        class="
          flex
          items-center
          md:flex-row
          flex-col
          justify-center
          mx-auto
          gap-16
        "
      >
        <div
          class="
            gap-2
            w-full
            max-w-xs
            md:w-64
            flex
            bg-transparent
            rounded-md
            items-center
            justify-start
          "
        >
          <div class="h-10 w-10">
            <svg
              class="h-10 w-10 text-gray-800"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 16 16"
            >
              <g fill="none">
                <path
                  d="M10.5 10a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"
                  fill="currentColor"
                />
                <path
                  d="M1 5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V5zm13 0a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1h12V5zM2 11a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7H2v4z"
                  fill="currentColor"
                />
              </g>
              <rect
                x="0"
                y="0"
                width="16"
                height="16"
                fill="rgba(0, 0, 0, 0)"
              />
            </svg>
          </div>
          <div class="">
            <h1 class="text-gray-800 font-medium text-sm md:text-base">
              Trusted payment methods
            </h1>
            <h3 class="text-gray-800 text-xs md:text-sm">
              Pay with Paypal, visa or master card
            </h3>
          </div>
        </div>

        <div
          class="
            gap-2
            w-full
            max-w-xs
            md:w-64
            flex
            bg-transparent
            rounded-md
            items-center
            justify-start
          "
        >
          <div class="h-10 w-10">
            <svg
              class="w-10 h-10 text-gray-800"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 16 16"
            >
              <g fill="none">
                <path
                  d="M7.999 2a6 6 0 0 1 5.995 6.21a2.49 2.49 0 0 0-.996-.206V8A5 5 0 1 0 9 12.9v1.017A6 6 0 0 1 2 8a6 6 0 0 1 5.999-6zm-.75 7.042l2.87-2.878a.5.5 0 0 1 .765.637l-.058.07l-3.223 3.232a.5.5 0 0 1-.638.059l-.07-.058l-1.75-1.75a.5.5 0 0 1 .639-.765l.069.057l1.395 1.396zm6.252 4.454a.5.5 0 1 1-1 0a.5.5 0 0 1 1 0zm-2.003-2.498a1.5 1.5 0 0 0-1.497 1.5L9.998 14.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5L16 12.498a1.5 1.5 0 0 0-1.5-1.5h-.003v-.494a1.5 1.5 0 1 0-2.999 0v.494zm1-.494a.5.5 0 1 1 1 0v.494h-1v-.494zM11 12.498a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5l-.004 2.002a.5.5 0 0 1-.5.5h-2.999a.5.5 0 0 1-.5-.5l.003-2.002z"
                  fill="currentColor"
                />
              </g>
              <rect
                x="0"
                y="0"
                width="16"
                height="16"
                fill="rgba(0, 0, 0, 0)"
              />
            </svg>
          </div>
          <div class="">
            <h1 class="text-gray-800 font-medium text-sm md:text-base">
              Secure payments
            </h1>
            <h3 class="text-gray-800 text-xs md:text-sm">
              Processed in a level 1 PCI compliant environment
            </h3>
          </div>
        </div>

        <div
          class="
            gap-2
            w-full
            max-w-xs
            md:w-64
            flex
            bg-transparent
            rounded-md
            items-center
            justify-start
          "
        >
          <div class="h-10 w-10">
            <svg
              class="h-10 w-10 text-gray-800"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 16 16"
            >
              <g fill="none">
                <path
                  d="M11.5 2A2.5 2.5 0 0 1 14 4.5v1.757A5.478 5.478 0 0 0 10.5 5H13v-.5A1.5 1.5 0 0 0 11.5 3h-7A1.5 1.5 0 0 0 3 4.5V5h7.5c-1.177 0-2.268.37-3.163 1H3v5.5A1.5 1.5 0 0 0 4.5 13h1.1c.183.358.404.693.657 1H4.5A2.5 2.5 0 0 1 2 11.5v-7A2.5 2.5 0 0 1 4.5 2h7z"
                  fill="currentColor"
                />
                <path
                  d="M15 10.5a4.5 4.5 0 1 1-9 0a4.5 4.5 0 0 1 9 0zm-2.646-1.146a.5.5 0 0 0-.708-.708L10.5 9.793L9.354 8.646a.5.5 0 1 0-.708.708L9.793 10.5l-1.147 1.146a.5.5 0 0 0 .708.708l1.146-1.147l1.146 1.147a.5.5 0 0 0 .708-.708L11.207 10.5l1.147-1.146z"
                  fill="currentColor"
                />
              </g>
              <rect
                x="0"
                y="0"
                width="16"
                height="16"
                fill="rgba(0, 0, 0, 0)"
              />
            </svg>
          </div>
          <div class="">
            <h1 class="text-gray-800 font-medium text-sm md:text-base">
              Cancel anytime
            </h1>
            <h3 class="text-gray-800 text-xs md:text-sm">
              Canceling your subscription is quick and simple
            </h3>
          </div>
        </div>
      </div>
      <!-- end of easy payments  -->

      <!-- faq -->
      <div class="mt-36 w-full flex flex-col items-center justify-center">
        <h1
          class="mt-3 text-2xl md:text-4xl text-center font-bold text-gray-800"
        >
          Still have Questions?
        </h1>
        <h3 class="mt-3 text-sm text-center text-gray-600">
          Everything you will find questions that can come in your mind. For
          more question, contact with us
        </h3>

        <!-- grid -->
        <div
          class="
            w-full
            select-none
            max-w-xl
            mx-auto
            my-14
            grid grid-cols-1
            gap-10
            transition-height
            duration-300
          "
        >
          <FaqCard
            question="What is Motion Ridge?"
            answer="Motion Ridge is a service that provides unlimited downloads of hundreds of thousands of digital assets;"
          />
          <FaqCard
            question="Can I use Motion Ridge products for commercial use?"
            answer="Absolutely! Most assets available for download on Motion Ridge are
							completely cleared for commercial use. This includes everything from
							social media to big-budget national commercial spots. A smaller number
							of video and image assets are labeled at “Editorial use only. Not for
							commercial use.” These are assets with recognizable brands, locations,
							or people that are not cleared for commercial use, but can be used for
							editorial purposes."
          />

          <FaqCard
            question="How does the unlimited download license work?"
            answer="As a paid Motion Ridge member, you are able to download and use
							unlimited products from our marketplace. You can continue to use all of
							your downloads in as many projects as you would like, as long as you
							remain a paying member. If you cancel your paid account, you are no
							longer allowed to use your downloads in new projects."
          />
          <FaqCard
            question="Can I cancel or upgrade any time?"
            answer="Absolutely! And best of all, it is easy to do. In your account
							settings, simply choose the free plan under “My Subscription.” See?
							Super simple."
          />

          <FaqCard
            question="Is it possible to get a refund from Motion Ridge Membership service?"
            answer="Yes, you can get a refund if you still haven’t downloaded any products
							from Motion Ridge, during 14 days from the date of purchase."
          />

          <FaqCard
            question="I should be signing up. What am I waiting on?"
            answer="Beats me! Enter your details, and start making video awesomeness."
          />
        </div>
        <!-- end of grid -->
      </div>
      <!-- end of faq -->
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FaqCard from "../components/utils/FaqCard.vue";
export default {
  name: "Pricing",
  components: { FaqCard },
  methods: {
    ...mapActions(["appendAlert"]),
    handleUpgradeMonthly() {
      if (
        !this.getCurrentUser ||
        (this.getCurrentUser && !this.getCurrentUser.isEmailVerified)
      ) {
        this.appendAlert({
          id: Math.floor(Math.random() * 10000),
          title: "Your Email is not yet verified",
          sub: "Click the link sent to your email to verify your account",
          icon: "bi:info-circle-fill",
          type: "warning",
          button: {
            name: "Resend Verification email",
            action: "resendVerificationEmail",
          },
        });
      } else {
        this.$router.push("/checkout/monthly");
      }
    },
    handleUpgradeYearly() {
      if (
        !this.getCurrentUser ||
        (this.getCurrentUser && !this.getCurrentUser.isEmailVerified)
      ) {
        this.appendAlert({
          id: Math.floor(Math.random() * 10000),
          title: "Your Email is not yet verified",
          sub: "Click the link sent to your email to verify your account",
          icon: "bi:info-circle-fill",
          type: "warning",
          button: {
            name: "Resend Verification email",
            action: "resendVerificationEmail",
          },
        });
      } else {
        this.$router.push("/checkout/yearly");
      }
    },

    handleUpgradeCurrentPlan() {
      if (
        !this.getCurrentUser ||
        (this.getCurrentUser && !this.getCurrentUser.isEmailVerified)
      ) {
        this.appendAlert({
          id: Math.floor(Math.random() * 10000),
          title: "Your Email is not yet verified",
          sub: "Click the link sent to your email to verify your account",
          icon: "bi:info-circle-fill",
          type: "warning",
          button: {
            name: "Resend Verification email",
            action: "resendVerificationEmail",
          },
        });
      } else {
        this.$router.push("/account/upgrade");
      }
    },
  },
  computed: {
    ...mapGetters(["getCurrentUser"]),
  },
};
</script>
