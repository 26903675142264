<template>
  <div
    v-if="youtubeOpened"
    @click.self="youtubeOpened = false"
    class="
      fixed
      z-50
      inset-0
      bg-lightDarkOverlay
      flex
      items-center
      justify-center
      p-16
      md:p-20
    "
  >
    <button
      @click="youtubeOpened = false"
      class="absolute top-0 right-0 m-5 md:m-14 focus:outline-none"
    >
      <svg class="w-8 h-8 text-white" fill="none" viewBox="0 0 24 24">
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M4.75 12C4.75 7.99594 7.99594 4.75 12 4.75V4.75C16.0041 4.75 19.25 7.99594 19.25 12V12C19.25 16.0041 16.0041 19.25 12 19.25V19.25C7.99594 19.25 4.75 16.0041 4.75 12V12Z"
        ></path>
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M9.75 9.75L14.25 14.25"
        ></path>
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M14.25 9.75L9.75 14.25"
        ></path>
      </svg>
    </button>
    <iframe
      class="w-full h-full max-w-screen-2xl mx-auto"
      src="https://www.youtube.com/embed/gVskh0mpomA?controls=0;autoplay=1"
    >
    </iframe>
  </div>
  <!-- hero content -->
  <main class="xl:min-h-screen bg-subtleGray">
    <section
      v-if="!getCurrentUserLoading && !getCurrentUser"
      class="
        relative
        2xl:mt-5
        xl:min-h-screen xl:pb-32
        pb-28
        pt-20
        md:pt-32
        2xl:pt-32
        px-3
        overflow-hidden
        bg-subtleGray
      "
    >
      <div class="relative z-10 flex flex-col items-center justify-start">
        <div class="">
          <span
            class="
              bg-purple-600
              rounded-md
              text-white
              font-medium
              text-xs
              2xl:text-sm
              px-2
              py-1
            "
            >Top Selling Products</span
          >
        </div>

        <h1
          class="
            2xl:mt-6
            md:mt-10
            mt-4
            2xl:max-w-5xl
            md:max-w-xl
            w-full
            2xl:text-6xl
            font-semibold
            md:text-4xl
            text-2xl text-center text-gray-800
          "
        >
          Top Creative Digital Assets Unlimited Downloads For Your Creative
          Projects
        </h1>

        <h3
          class="
            max-w-2xl
            md:max-w-2xl
            mt-6
            2xl:mt-10
            w-full
            md:text-sm
            text-center text-xs text-gray-800
          "
        >
          Unlimited downloads of royalty-free music & Video templates like
          openers, backgrounds, product promos, titles, motion graphics &
          thousands of stock video clips.
        </h3>

        <button
          @click="youtubeOpened = true"
          class="
            mt-3
            2xl:mt-4
            focus:ring-white
            focus:outline-none
            focus:ring-2
            focus:ring-offset-gray-800
            focus:ring-offset
            rounded-full
          "
        >
          <svg
            class="h-10 w-10 text-gray-800"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <div
          v-if="productDisplayImages.length > 0"
          class="2xl:mt-12 mt-12 space-x-6 flex flex-shrink-0 overflow-auto"
        >
          <router-link
            :to="productDisplayImage.url"
            v-for="productDisplayImage in productDisplayImages"
            :key="productDisplayImage.id"
            class="
              overflow-hidden
              rounded-xl
              flex flex-col
              items-center
              justify-center
              flex-shrink-0
              focus:outline-none
              cursor-pointer
            "
          >
            <img
              class="
                w-56
                h-40
                rounded-xl
                flex-shrink-0
                object-cover
                overflow-hidden
              "
              :src="productDisplayImage.image"
              :alt="productDisplayImage.title"
            />
            <span class="text-sm font-medium text-gray-800 mt-3">{{
              productDisplayImage.title
            }}</span>
          </router-link>
        </div>
      </div>

      <div
        class="
          hidden
          overflow-hidden
          inset-x-0 inset-y-0
          xl:flex
          absolute
          justify-center
          items-center
        "
      >
        <Circle class="animate-spin-slow" />
      </div>
    </section>

    <!-- 2nd section -->
    <section
      v-if="!getCurrentUserLoading && !getCurrentUser"
      class="
        relative
        2xl:-mt-20
        md:mt-0
        mt-2
        px-3
        xl:min-h-screen
        bg-subtleGray
      "
    >
      <div
        class="
          inset-x-0
          -mt-5
          2xl:-mt-6
          absolute
          z-20
          w-full
          flex
          jusitfy-center
        "
      >
        <router-link
          to="/all-items"
          class="
            max-w-xxs
            mx-auto
            whitespace-nowrap
            rounded-md
            px-8
            py-2
            text-white text-sm
            2xl:text-lg
            font-medium
            transition-colors
            duration-300
            bg-orange-600
            hover:bg-orange-500
            focus:outline-none
          "
        >
          See all categories
        </router-link>
      </div>

      <!-- content -->
      <div
        class="
          2xl:pt-28 2xl:pb-28
          max-w-screen-2xl
          md:pb-16 md:pt-16 md:px-6
          mx-auto
          pt-12
          px-3
          w-full
          flex flex-1 flex-col
          items-center
          justify-start
        "
      >
        <h1
          class="
            2xl:mt-28
            text-xl
            md:text-4xl
            2xl:text-5xl
            text-gray-800
            font-semibold
          "
        >
          Why Choose Me ?
        </h1>

        <div
          class="
            mt-10
            pb-10
            grid grid-cols-1
            gap-6
            lg:grid-cols-2
            w-full
            md:max-w-5xl
            2xl:max-w-7xl
            place-items-stretch
            items-center
          "
        >
          <!-- col -1 -->
          <div class="md:block flex flex-col justify-center items-center">
            <span
              class="
                bg-purple-600
                rounded-md
                text-white
                font-medium
                text-xs
                2xl:text-sm
                px-2
                py-1
              "
              >New Release</span
            >

            <h1
              class="
                mt-4
                text-xl
                md:text-4xl
                2xl:text-5xl
                text-gray-800
                font-medium
                text-center
                md:text-left
              "
            >
              <span class="hidden md:block">
                Work Like a Pro.<br />
                Subscribe Now
              </span>
              <span class="md:hidden text-center">
                Work Like a Pro. Subscribe Now
              </span>
            </h1>

            <h3
              class="
                mt-4
                text-xs
                md:text-sm
                text-gray-800
                md:max-w-xs
                text-center
                md:text-left
              "
            >
              An outstanding collection of premium digital assets for affordable
              price.
            </h3>

            <a
              rel="noreferrer"
              href="https://motionridge.com/pricing"
              target="_blank"
              class="
                block
                w-full
                text-center
                md:max-w-xxs
                whitespace-nowrap
                mt-6
                px-8
                py-2
                text-sm
                font-medium
                text-white
                rounded-md
                transition-colors
                duration-300
                bg-orange-600
                hover:bg-orange-500
                focus:outline-none
              "
            >
              Learn more
            </a>
          </div>

          <!-- col-2 -->
          <div class="lg:-ml-32 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div class="flex flex-col gap-6 lg:mt-32">
              <Card
                icon="download"
                title="Unlimited Downloads"
                body="Download as many items as you want for the duration of your membership, with no restrictions."
              />
              <Card
                icon="license"
                title="Simple Licensing
"
                body="You can use any items you need for unlimited number of projects as long as your license is active."
              />
            </div>
            <div class="flex flex-col gap-6">
              <Card
                icon="updates"
                title="Regular Updates"
                body="New products are continuously added every month, so keep an eye on your inbox for updates!"
              />
              <Card
                icon="cancel"
                title="Cancel anytime"
                body="No Strings attached! You can cancel your payment subscription anytime you want."
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 3rd section -->
    <section
      v-if="!getCurrentUserLoading && !getCurrentUser"
      class="px-3 pb-10 bg-subtleGray"
    >
      <!-- content -->
      <div
        class="
          max-w-screen-2xl
          md:px-6
          mx-auto
          md:pt-12
          w-full
          flex flex-1 flex-col
          items-center
          justify-start
        "
      >
        <div
          class="
            gap-10
            mt-10
            pb-6
            md:pb-10
            w-full
            grid grid-cols-1
            max-w-5xl
            2xl:max-w-7xl
            items-center
            justify-between
            md:grid-cols-2
            place-items-stretch
          "
        >
          <!-- col -1 -->
          <div class="md:block flex flex-col justify-center items-center">
            <span
              class="
                px-2
                py-1
                2xl:text-sm
                font-medium
                text-white text-xs
                bg-purple-600
                rounded-md
              "
              >Create &middot; Share &middot; Enjoy</span
            >

            <h1
              class="
                mt-4
                md:mt-6
                2xl:text-5xl
                font-medium
                md:text-4xl
                text-gray-800 text-xl text-center
                md:text-left
              "
            >
              <span class="hidden md:block">
                Make better Brand<br />
                and spend less time
              </span>
              <span class="md:hidden text-center">
                Make better Brand and spend less time
              </span>
            </h1>

            <h3
              class="
                md:max-w-xs
                mt-4
                md:text-sm
                text-gray-800 text-xs text-center
                md:text-left
              "
            >
              Each week our creators adds new projects to our blog, you can find
              many usefull templates. Have fun!
            </h3>

            <router-link
              to="/all-items"
              class="
                block
                w-full
                text-center
                md:max-w-xxs
                whitespace-nowrap
                mt-6
                px-8
                py-2
                font-medium
                text-sm text-white
                bg-orange-600
                hover:bg-orange-500
                transition-colors
                duration-300
                rounded-md
                focus:outline-none
              "
            >
              Browse more products
            </router-link>
          </div>

          <!-- col-2 -->
          <div class="mx-auto">
            <div class="flex flex-col items-center">
              <div class="">
                <lottie-player
                  src="https://assets7.lottiefiles.com/packages/lf20_Qx2ElX.json"
                  background="transparent"
                  speed="1"
                  class="
                    2xl:w-largeLotifyWidth
                    xl:w-lotifyWidth xl:h-lotifyHeight
                    w-full
                    h-full
                  "
                  loop
                  autoplay
                ></lottie-player>
              </div>

              <div class="flex flex-col justify-center items-center">
                <h1
                  class="
                    mt-6
                    lg:whitespace-nowrap
                    2xl:text-3xl
                    font-medium
                    text-base
                    md:text-xl
                    lg:text-2xl
                    text-center text-gray-800
                  "
                >
                  Unique &middot; Creative &middot; Support
                </h1>
                <h3
                  class="
                    md:max-w-xl
                    mt-4
                    md:text-sm
                    text-center text-gray-800 text-xs
                  "
                >
                  We carefully make new product from our community one by one to
                  make sure they meet high quality design and functionality
                  standards. From Video templates & Stock Footage. You'll always
                  find something that catches your eyes
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 4th section -->
    <section
      v-if="!getCurrentUserLoading && !getCurrentUser"
      class="mt-6 px-3 pb-10 2xl:pb-20 bg-subtleGray"
    >
      <!-- content -->
      <div
        class="
          max-w-screen-2xl
          md:px-6
          mx-auto
          md:pt-12
          w-full
          flex flex-1 flex-col
          items-center
          justify-start
        "
      >
        <div class="flex px-3 md:px-0 flex-col justify-center items-center">
          <span
            class="
              px-2
              py-1
              2xl:text-sm
              font-medium
              text-white text-xs
              bg-purple-600
              rounded-md
            "
            >Join us today</span
          >

          <h1
            class="
              mt-3
              md:mt-6
              2xl:text-5xl
              font-medium
              md:text-4xl
              text-gray-800 text-xl text-center
            "
          >
            <span class="text-center"> Pricing for Everyone </span>
          </h1>

          <h3
            class="
              md:max-w-md
              mt-4
              md:text-sm
              text-gray-800 text-xs text-center
            "
          >
            Make better Brand and spend less time without restricting creative
            freedom, get essentials today on Vooaxis marketplace
          </h3>
        </div>
        <!-- <div
          class="hidden mt-4 w-full max-w-5xl 2xl:max-w-7xl mx-auto md:flex justify-end"
        >
          <div class="p-1 rounded-md flex items-center bg-subtleGray">
            <button
              @click="changeType('yearly')"
              class="text-xs font-medium py-2 px-3 rounded-md outline-none focus:outline-none transition-all duration-300"
              :class="
                type === 'yearly'
                  ? 'bg-gray-100 text-orange-700 shadow'
                  : 'bg-subtleGray  text-gray-600 '
              "
            >
              Yearly
            </button>
            <button
              @click="changeType('monthly')"
              class="text-xs font-medium  py-2 px-3 rounded-md outline-none focus:outline-none transition-all duration-300"
              :class="
                type === 'monthly'
                  ? 'bg-gray-100 text-orange-700 shadow'
                  : 'bg-subtleGray  text-gray-600 '
              "
            >
              Monthly
            </button>
          </div>
        </div> -->
        <div
          class="
            w-full
            max-w-7xl
            mx-auto
            px-10
            md:px-20
            my-10
            border-2 border-brandPurple
            rounded-lg
            flex flex-1 flex-col
            items-center
            justify-start
          "
        >
          <div
            class="
              gap-10
              mt-10
              pb-6
              md:pb-10
              w-full
              grid grid-cols-1
              max-w-5xl
              2xl:max-w-7xl
              items-center
              justify-between
              md:grid-cols-2
              place-items-stretch
            "
          >
            <div class="max-w-xs px-3 md:px-0 2xl:max-w-md">
              <div class="">
                <span
                  ><svg
                    class="w-12 h-12 text-orange-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    /></svg
                ></span>
                <h1
                  class="
                    mt-3
                    text-xl
                    2xl:text-5xl
                    font-medium
                    md:text-4xl
                    max-w-4xl
                    text-gray-800
                  "
                >
                  The right price for every usage type
                </h1>
                <h3 class="mt-5 text-gray-800 text-sm">
                  Just for artist,students and everyone else
                </h3>
              </div>

              <router-link
                to="/pricing"
                @mouseover="handleMouseOver"
                @mouseleave="handleMouseLeave"
                class="
                  mt-4
                  flex
                  justify-between
                  max-w-xs
                  items-center
                  group
                  transition-all
                  duration-300
                  cursor-pointer
                "
              >
                <div class="flex pointer-events-none">
                  <div class="relative z-20 bg-subtleGray rounded-full">
                    <svg
                      class="h-6 w-6 text-brandGreen"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                      />
                    </svg>
                  </div>

                  <div
                    class="
                      relative
                      z-10
                      bg-subtleGray
                      transform
                      rounded-full
                      transition-all
                      duration-300
                    "
                    :class="
                      featuresHovered ? 'translate-x-0' : '-translate-x-3'
                    "
                  >
                    <svg
                      class="h-6 w-6 text-orange-700"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                      />
                    </svg>
                  </div>

                  <svg
                    class="
                      h-6
                      w-6
                      relative
                      transform
                      z-0
                      text-gray-800
                      transition-all
                      duration-300
                    "
                    :class="
                      featuresHovered ? 'translate-x-0' : '-translate-x-6'
                    "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                    />
                  </svg>
                </div>

                <div
                  class="
                    text-gray-800
                    mr-20
                    md:mr-16
                    font-medium
                    text-sm
                    flex
                    items-center
                    gap-1
                    pointer-events-none
                  "
                >
                  Check all features
                  <span
                    ><svg
                      class="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      /></svg
                  ></span>
                </div>
              </router-link>
            </div>

            <!-- pricing card -->
            <div
              class="
                md:hidden
                mt-4
                w-full
                max-w-5xl
                mx-auto
                flex
                justify-center
              "
            >
              <div class="p-1 rounded-md flex items-center bg-subtleGray">
                <button
                  @click="changeType('yearly')"
                  class="
                    text-xs
                    font-medium
                    py-2
                    px-3
                    rounded-md
                    outline-none
                    focus:outline-none
                    transition-all
                    duration-300
                  "
                  :class="
                    type === 'yearly'
                      ? 'bg-gray-100 text-orange-700 shadow'
                      : 'bg-subtleGray  text-gray-600 '
                  "
                >
                  Yearly
                </button>
                <button
                  @click="changeType('monthly')"
                  class="
                    text-xs
                    font-medium
                    py-2
                    px-3
                    rounded-md
                    outline-none
                    focus:outline-none
                    transition-all
                    duration-300
                  "
                  :class="
                    type === 'monthly'
                      ? 'bg-gray-100 text-orange-700 shadow'
                      : 'bg-subtleGray  text-gray-600 '
                  "
                >
                  Monthly
                </button>
              </div>
            </div>

            <div
              class="
                flex flex-col-reverse
                items-center
                md:flex-row
                gap-5
                xl:gap-20 xl:-ml-32
              "
            >
              <div
                class="
                  p-5
                  py-10
                  w-full
                  max-w-md
                  bg-gray-800
                  rounded-md
                  border-2 border-gray-700
                "
              >
                <span
                  class="
                    px-2
                    py-1
                    2xl:text-sm
                    font-medium
                    text-gray-900 text-xs
                    bg-white
                    rounded-md
                  "
                  >Life long
                </span>

                <div class="my-4">
                  <h1 class="text-5xl 2xl:text-6xl text-white font-semibold">
                    Free
                  </h1>
                  <h3 class="text-white text-sm">For personal projects</h3>
                </div>

                <!-- features -->
                <div class="my-8 space-y-5">
                  <div class="space-x-3 flex">
                    <svg
                      class="h-5 w-5 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                      />
                    </svg>
                    <span class="text-white text-sm">Free downloads only</span>
                  </div>

                  <div class="space-x-3 flex">
                    <svg
                      class="h-5 w-5 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                      />
                    </svg>
                    <span class="text-white text-sm"
                      >Limited customer support</span
                    >
                  </div>

                  <div class="space-x-3 flex">
                    <svg
                      class="h-5 w-5 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                      />
                    </svg>
                    <span class="text-white text-sm">Cancel anytime</span>
                  </div>
                </div>

                <!-- cta -->
                <router-link
                  to="/register"
                  v-if="!getCurrentUser"
                  class="
                    block
                    w-full
                    md:max-w-xs
                    mt-6
                    px-8
                    py-2
                    text-center
                    font-medium
                    text-sm text-white
                    bg-brandBlack
                    transition-colors
                    duration-300
                    rounded-md
                    focus:outline-none
                  "
                >
                  Create your account
                </router-link>
                <button
                  v-if="
                    getCurrentUser && getCurrentUser.subscription === 'free'
                  "
                  disabled
                  class="
                    block
                    pointer-events-none
                    w-full
                    md:max-w-xs
                    mt-6
                    px-8
                    py-2
                    font-medium
                    text-sm text-white
                    bg-brandBlack
                    transition-colors
                    duration-300
                    rounded-md
                    focus:outline-none
                  "
                >
                  Currently selected
                </button>
              </div>

              <div
                class="
                  mt-10
                  md:mt-0
                  relative
                  max-w-md
                  w-full
                  bg-white
                  rounded-md
                  p-5
                  py-10
                "
              >
                <span
                  v-if="false"
                  class="
                    absolute
                    inset-x-0
                    text-center
                    top-0
                    -mt-3
                    max-w-xxxs
                    mx-auto
                    px-2
                    py-1
                    2xl:text-sm
                    font-medium
                    text-white text-xs
                    bg-purple-700
                    rounded-md
                  "
                  >save over $20
                </span>

                <span
                  class="
                    px-2
                    py-1
                    2xl:text-sm
                    font-medium
                    text-white text-xs
                    bg-gray-900
                    rounded-md
                  "
                  >Annual
                </span>

                <div class="my-4">
                  <div class="text-gray-900">
                    <span
                      v-if="type === 'yearly'"
                      class="text-5xl 2xl:text-6xl font-bold"
                      >$8</span
                    >
                    <span
                      v-if="type === 'monthly'"
                      class="text-5xl 2xl:text-6xl font-bold"
                      >$9</span
                    >
                    <span class="text-base font-bold">.33</span>
                    <span v-if="type === 'yearly'" class="text-sm">
                      /monthly</span
                    >
                    <span v-if="type === 'monthly'" class="text-sm">
                      /month</span
                    >
                  </div>
                  <h3 class="text-gray-900 text-sm">For Commercial projects</h3>
                </div>

                <!-- features -->
                <div class="my-8 space-y-5">
                  <div class="space-x-3 flex">
                    <svg
                      class="h-5 w-5 text-gray-900"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                      />
                    </svg>

                    <span class="text-gray-900 text-sm"
                      >Unlimited Downloads</span
                    >
                  </div>

                  <div class="space-x-3 flex">
                    <svg
                      class="h-5 w-5 text-gray-900"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                      />
                    </svg>
                    <span class="text-gray-900 text-sm"
                      >Standard customer support</span
                    >
                  </div>

                  <div class="space-x-3 flex">
                    <svg
                      class="h-5 w-5 text-gray-900"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                      />
                    </svg>
                    <span class="text-gray-900 text-sm">Cancel anytime</span>
                  </div>
                </div>

                <!-- cta -->
                <button
                  @click="handleCheckout"
                  class="
                    block
                    w-full
                    md:max-w-xs
                    mt-6
                    px-8
                    py-2
                    font-medium
                    text-sm text-white
                    bg-purple-600
                    hover:bg-purple-700
                    transition-colors
                    duration-300
                    rounded-md
                    focus:outline-none
                  "
                >
                  Create your account
                </button>
              </div>
            </div>

            <!-- e o pricing card -->
          </div>
        </div>

        <div
          class="
            md:mt-8
            2xl:mt-6
            gap-10
            md:gap-6
            hidden
            grid-cols-3
            items-stretch
            lg:grid
          "
        >
          <div class="gap-2 max-w-xs px-3 py-5 flex bg-subtleGray rounded-md">
            <div class="h-10 w-10">
              <svg
                class="h-10 w-10 text-gray-800"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 16 16"
              >
                <g fill="none">
                  <path
                    d="M10.5 10a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"
                    fill="currentColor"
                  />
                  <path
                    d="M1 5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V5zm13 0a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1h12V5zM2 11a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7H2v4z"
                    fill="currentColor"
                  />
                </g>
                <rect
                  x="0"
                  y="0"
                  width="16"
                  height="16"
                  fill="rgba(0, 0, 0, 0)"
                />
              </svg>
            </div>
            <div class="">
              <h1 class="text-gray-800 font-medium text-sm md:text-base">
                Trusted payment methods
              </h1>
              <h3 class="text-gray-600 text-xs md:text-sm">
                Pay with Paypal, visa or mastercard
              </h3>
            </div>
          </div>

          <div class="gap-2 max-w-xs px-3 py-5 flex bg-subtleGray rounded-md">
            <div class="h-10 w-10">
              <svg
                class="w-10 h-10 text-gray-800"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 16 16"
              >
                <g fill="none">
                  <path
                    d="M7.999 2a6 6 0 0 1 5.995 6.21a2.49 2.49 0 0 0-.996-.206V8A5 5 0 1 0 9 12.9v1.017A6 6 0 0 1 2 8a6 6 0 0 1 5.999-6zm-.75 7.042l2.87-2.878a.5.5 0 0 1 .765.637l-.058.07l-3.223 3.232a.5.5 0 0 1-.638.059l-.07-.058l-1.75-1.75a.5.5 0 0 1 .639-.765l.069.057l1.395 1.396zm6.252 4.454a.5.5 0 1 1-1 0a.5.5 0 0 1 1 0zm-2.003-2.498a1.5 1.5 0 0 0-1.497 1.5L9.998 14.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5L16 12.498a1.5 1.5 0 0 0-1.5-1.5h-.003v-.494a1.5 1.5 0 1 0-2.999 0v.494zm1-.494a.5.5 0 1 1 1 0v.494h-1v-.494zM11 12.498a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5l-.004 2.002a.5.5 0 0 1-.5.5h-2.999a.5.5 0 0 1-.5-.5l.003-2.002z"
                    fill="currentColor"
                  />
                </g>
                <rect
                  x="0"
                  y="0"
                  width="16"
                  height="16"
                  fill="rgba(0, 0, 0, 0)"
                />
              </svg>
            </div>
            <div class="">
              <h1 class="text-gray-800 font-medium text-sm md:text-base">
                Secure payments
              </h1>
              <h3 class="text-gray-600 text-xs md:text-sm">
                Processed in a level 1 PCI compliant environment
              </h3>
            </div>
          </div>

          <div class="gap-2 max-w-xs px-3 py-5 flex bg-subtleGray rounded-md">
            <div class="h-10 w-10">
              <svg
                class="h-10 w-10 text-gray-800"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 16 16"
              >
                <g fill="none">
                  <path
                    d="M11.5 2A2.5 2.5 0 0 1 14 4.5v1.757A5.478 5.478 0 0 0 10.5 5H13v-.5A1.5 1.5 0 0 0 11.5 3h-7A1.5 1.5 0 0 0 3 4.5V5h7.5c-1.177 0-2.268.37-3.163 1H3v5.5A1.5 1.5 0 0 0 4.5 13h1.1c.183.358.404.693.657 1H4.5A2.5 2.5 0 0 1 2 11.5v-7A2.5 2.5 0 0 1 4.5 2h7z"
                    fill="currentColor"
                  />
                  <path
                    d="M15 10.5a4.5 4.5 0 1 1-9 0a4.5 4.5 0 0 1 9 0zm-2.646-1.146a.5.5 0 0 0-.708-.708L10.5 9.793L9.354 8.646a.5.5 0 1 0-.708.708L9.793 10.5l-1.147 1.146a.5.5 0 0 0 .708.708l1.146-1.147l1.146 1.147a.5.5 0 0 0 .708-.708L11.207 10.5l1.147-1.146z"
                    fill="currentColor"
                  />
                </g>
                <rect
                  x="0"
                  y="0"
                  width="16"
                  height="16"
                  fill="rgba(0, 0, 0, 0)"
                />
              </svg>
            </div>
            <div class="">
              <h1 class="text-gray-800 font-medium text-sm md:text-base">
                Cancel anytime
              </h1>
              <h3 class="text-gray-600 text-xs md:text-sm">
                Canceling your subscription is quick and simple
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 5th section -->
    <!-- !getCurrentUserLoading && !getCurrentUser -->
    <section v-if="false" class="px-3 mt-16 2xl:mt-28 bg-subtleGray">
      <!-- content -->
      <div
        class="
          max-w-screen-2xl
          md:px-6
          mx-auto
          md:pt-12
          2xl:pt-0
          w-full
          flex flex-1 flex-col
          items-center
          justify-start
        "
      >
        <h2
          class="
            text-sm
            md:text-lg
            2xl:text-xl
            text-white
            font-medium
            text-center
          "
        >
          Trusted by many of the worlds leading companies
        </h2>

        <!-- companies -->
        <div
          class="
            max-w-5xl
            mt-10
            py-12
            px-5
            md:px-12
            space-x-10
            w-full
            overflow-x-auto
            flex
            justify-start
            items-center
            md:justify-between
            bg-gray-800
            rounded-md
          "
        >
          <div class="h-12 w-40 flex-shrink-0">
            <svg width="100%" height="100%" viewBox="0 0 318 81" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M101.735 63.7782L105.703 54.4951C109.99 57.7218 115.701 59.3991 121.336 59.3991C125.496 59.3991 128.129 57.7858 128.129 55.3402C128.065 48.5027 103.21 53.8549 103.019 36.7099C102.956 28.003 110.639 21.2935 121.527 21.2935C128.001 21.2935 134.463 22.9069 139.08 26.5817L135.366 36.0569C131.143 33.3424 125.89 31.4089 120.891 31.4089C117.495 31.4089 115.256 33.0223 115.256 35.0838C115.32 41.7932 140.365 38.1184 140.62 54.4951C140.62 63.3941 133.128 69.6554 122.366 69.6554C114.48 69.6426 107.242 67.7731 101.735 63.7782V63.7782ZM254.133 51.1404C252.149 54.6231 248.434 57.0047 244.135 57.0047C237.788 57.0047 232.674 51.8446 232.674 45.468C232.674 39.0915 237.8 33.9314 244.135 33.9314C248.422 33.9314 252.149 36.313 254.133 39.7957L265.085 33.6753C260.989 26.3256 253.103 21.2935 244.135 21.2935C230.868 21.2935 220.107 32.126 220.107 45.4809C220.107 58.8357 230.868 69.6682 244.135 69.6682C253.166 69.6682 260.989 64.7001 265.085 57.2864L254.133 51.1404ZM146.127 1.22919H159.84V68.7463H146.127V1.22919ZM270.465 1.22919V68.7463H284.178V48.4899L300.446 68.7463H318L297.305 44.687L316.461 22.241H299.67L284.165 40.8713V1.22919H270.465Z"
                fill="#52525B"
              />
              <path
                d="M200.582 51.2684C198.597 54.5591 194.502 57.0048 189.884 57.0048C183.537 57.0048 178.423 51.8446 178.423 45.4681C178.423 39.0915 183.55 33.9314 189.884 33.9314C194.502 33.9314 198.597 36.5051 200.582 39.8598V51.2684ZM200.582 22.2539V27.7341C198.343 23.9313 192.772 21.2808 186.933 21.2808C174.887 21.2808 165.411 31.9852 165.411 45.4041C165.411 58.823 174.887 69.6554 186.933 69.6554C192.759 69.6554 198.33 67.0177 200.582 63.202V68.6823H214.294V22.2539H200.582Z"
                fill="#52525B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.9812 51.2044C16.9812 55.9164 13.2034 59.7193 8.5224 59.7193C3.84144 59.7193 0.0636005 55.9036 0.0636005 51.2044C0.0636005 46.5052 3.84144 42.6895 8.5224 42.6895H16.9812V51.2044ZM21.2042 51.2044C21.2042 46.4924 24.9821 42.6895 29.663 42.6895C34.344 42.6895 38.1218 46.4924 38.1218 51.2044V72.4852C38.1218 77.1971 34.344 81 29.663 81C24.9821 81 21.2042 77.1971 21.2042 72.4852V51.2044Z"
                fill="#E01E5A"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.663 17.0297C24.9821 17.0297 21.2042 13.2268 21.2042 8.51486C21.2042 3.80288 24.9821 0 29.663 0C34.344 0 38.1218 3.80288 38.1218 8.51486V17.0297H29.663ZM29.663 21.3448C34.344 21.3448 38.1218 25.1476 38.1218 29.8596C38.1218 34.5716 34.344 38.3745 29.663 38.3745H8.4588C3.77784 38.3745 0 34.5588 0 29.8596C0 25.1604 3.77784 21.3448 8.4588 21.3448H29.663Z"
                fill="#36C5F0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M63.5491 29.8596C63.5491 25.1476 67.327 21.3448 72.0079 21.3448C76.6889 21.3448 80.4667 25.1476 80.4667 29.8596C80.4667 34.5716 76.6889 38.3745 72.0079 38.3745H63.5491V29.8596ZM59.3261 29.8596C59.3261 34.5716 55.5482 38.3745 50.8673 38.3745C46.1863 38.3745 42.4085 34.5716 42.4085 29.8596V8.51486C42.4085 3.80288 46.1863 0 50.8673 0C55.5482 0 59.3261 3.80288 59.3261 8.51486V29.8596Z"
                fill="#2EB67D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M50.8673 63.9703C55.5482 63.9703 59.3261 67.7732 59.3261 72.4852C59.3261 77.1971 55.5482 81 50.8673 81C46.1863 81 42.4085 77.1971 42.4085 72.4852V63.9703H50.8673ZM50.8673 59.7193C46.1863 59.7193 42.4085 55.9164 42.4085 51.2044C42.4085 46.4924 46.1863 42.6895 50.8673 42.6895H72.0715C76.7525 42.6895 80.5303 46.4924 80.5303 51.2044C80.5303 55.9164 76.7525 59.7193 72.0715 59.7193H50.8673Z"
                fill="#ECB22E"
              />
            </svg>
          </div>

          <div class="h-12 w-40 flex-shrink-0">
            <svg width="100%" height="100%" viewBox="0 0 318 81" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M101.735 63.7782L105.703 54.4951C109.99 57.7218 115.701 59.3991 121.336 59.3991C125.496 59.3991 128.129 57.7858 128.129 55.3402C128.065 48.5027 103.21 53.8549 103.019 36.7099C102.956 28.003 110.639 21.2935 121.527 21.2935C128.001 21.2935 134.463 22.9069 139.08 26.5817L135.366 36.0569C131.143 33.3424 125.89 31.4089 120.891 31.4089C117.495 31.4089 115.256 33.0223 115.256 35.0838C115.32 41.7932 140.365 38.1184 140.62 54.4951C140.62 63.3941 133.128 69.6554 122.366 69.6554C114.48 69.6426 107.242 67.7731 101.735 63.7782V63.7782ZM254.133 51.1404C252.149 54.6231 248.434 57.0047 244.135 57.0047C237.788 57.0047 232.674 51.8446 232.674 45.468C232.674 39.0915 237.8 33.9314 244.135 33.9314C248.422 33.9314 252.149 36.313 254.133 39.7957L265.085 33.6753C260.989 26.3256 253.103 21.2935 244.135 21.2935C230.868 21.2935 220.107 32.126 220.107 45.4809C220.107 58.8357 230.868 69.6682 244.135 69.6682C253.166 69.6682 260.989 64.7001 265.085 57.2864L254.133 51.1404ZM146.127 1.22919H159.84V68.7463H146.127V1.22919ZM270.465 1.22919V68.7463H284.178V48.4899L300.446 68.7463H318L297.305 44.687L316.461 22.241H299.67L284.165 40.8713V1.22919H270.465Z"
                fill="#52525B"
              />
              <path
                d="M200.582 51.2684C198.597 54.5591 194.502 57.0048 189.884 57.0048C183.537 57.0048 178.423 51.8446 178.423 45.4681C178.423 39.0915 183.55 33.9314 189.884 33.9314C194.502 33.9314 198.597 36.5051 200.582 39.8598V51.2684ZM200.582 22.2539V27.7341C198.343 23.9313 192.772 21.2808 186.933 21.2808C174.887 21.2808 165.411 31.9852 165.411 45.4041C165.411 58.823 174.887 69.6554 186.933 69.6554C192.759 69.6554 198.33 67.0177 200.582 63.202V68.6823H214.294V22.2539H200.582Z"
                fill="#52525B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.9812 51.2044C16.9812 55.9164 13.2034 59.7193 8.5224 59.7193C3.84144 59.7193 0.0636005 55.9036 0.0636005 51.2044C0.0636005 46.5052 3.84144 42.6895 8.5224 42.6895H16.9812V51.2044ZM21.2042 51.2044C21.2042 46.4924 24.9821 42.6895 29.663 42.6895C34.344 42.6895 38.1218 46.4924 38.1218 51.2044V72.4852C38.1218 77.1971 34.344 81 29.663 81C24.9821 81 21.2042 77.1971 21.2042 72.4852V51.2044Z"
                fill="#E01E5A"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.663 17.0297C24.9821 17.0297 21.2042 13.2268 21.2042 8.51486C21.2042 3.80288 24.9821 0 29.663 0C34.344 0 38.1218 3.80288 38.1218 8.51486V17.0297H29.663ZM29.663 21.3448C34.344 21.3448 38.1218 25.1476 38.1218 29.8596C38.1218 34.5716 34.344 38.3745 29.663 38.3745H8.4588C3.77784 38.3745 0 34.5588 0 29.8596C0 25.1604 3.77784 21.3448 8.4588 21.3448H29.663Z"
                fill="#36C5F0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M63.5491 29.8596C63.5491 25.1476 67.327 21.3448 72.0079 21.3448C76.6889 21.3448 80.4667 25.1476 80.4667 29.8596C80.4667 34.5716 76.6889 38.3745 72.0079 38.3745H63.5491V29.8596ZM59.3261 29.8596C59.3261 34.5716 55.5482 38.3745 50.8673 38.3745C46.1863 38.3745 42.4085 34.5716 42.4085 29.8596V8.51486C42.4085 3.80288 46.1863 0 50.8673 0C55.5482 0 59.3261 3.80288 59.3261 8.51486V29.8596Z"
                fill="#2EB67D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M50.8673 63.9703C55.5482 63.9703 59.3261 67.7732 59.3261 72.4852C59.3261 77.1971 55.5482 81 50.8673 81C46.1863 81 42.4085 77.1971 42.4085 72.4852V63.9703H50.8673ZM50.8673 59.7193C46.1863 59.7193 42.4085 55.9164 42.4085 51.2044C42.4085 46.4924 46.1863 42.6895 50.8673 42.6895H72.0715C76.7525 42.6895 80.5303 46.4924 80.5303 51.2044C80.5303 55.9164 76.7525 59.7193 72.0715 59.7193H50.8673Z"
                fill="#ECB22E"
              />
            </svg>
          </div>
          <div class="h-12 w-40 flex-shrink-0">
            <svg width="100%" height="100%" viewBox="0 0 318 81" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M101.735 63.7782L105.703 54.4951C109.99 57.7218 115.701 59.3991 121.336 59.3991C125.496 59.3991 128.129 57.7858 128.129 55.3402C128.065 48.5027 103.21 53.8549 103.019 36.7099C102.956 28.003 110.639 21.2935 121.527 21.2935C128.001 21.2935 134.463 22.9069 139.08 26.5817L135.366 36.0569C131.143 33.3424 125.89 31.4089 120.891 31.4089C117.495 31.4089 115.256 33.0223 115.256 35.0838C115.32 41.7932 140.365 38.1184 140.62 54.4951C140.62 63.3941 133.128 69.6554 122.366 69.6554C114.48 69.6426 107.242 67.7731 101.735 63.7782V63.7782ZM254.133 51.1404C252.149 54.6231 248.434 57.0047 244.135 57.0047C237.788 57.0047 232.674 51.8446 232.674 45.468C232.674 39.0915 237.8 33.9314 244.135 33.9314C248.422 33.9314 252.149 36.313 254.133 39.7957L265.085 33.6753C260.989 26.3256 253.103 21.2935 244.135 21.2935C230.868 21.2935 220.107 32.126 220.107 45.4809C220.107 58.8357 230.868 69.6682 244.135 69.6682C253.166 69.6682 260.989 64.7001 265.085 57.2864L254.133 51.1404ZM146.127 1.22919H159.84V68.7463H146.127V1.22919ZM270.465 1.22919V68.7463H284.178V48.4899L300.446 68.7463H318L297.305 44.687L316.461 22.241H299.67L284.165 40.8713V1.22919H270.465Z"
                fill="#52525B"
              />
              <path
                d="M200.582 51.2684C198.597 54.5591 194.502 57.0048 189.884 57.0048C183.537 57.0048 178.423 51.8446 178.423 45.4681C178.423 39.0915 183.55 33.9314 189.884 33.9314C194.502 33.9314 198.597 36.5051 200.582 39.8598V51.2684ZM200.582 22.2539V27.7341C198.343 23.9313 192.772 21.2808 186.933 21.2808C174.887 21.2808 165.411 31.9852 165.411 45.4041C165.411 58.823 174.887 69.6554 186.933 69.6554C192.759 69.6554 198.33 67.0177 200.582 63.202V68.6823H214.294V22.2539H200.582Z"
                fill="#52525B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.9812 51.2044C16.9812 55.9164 13.2034 59.7193 8.5224 59.7193C3.84144 59.7193 0.0636005 55.9036 0.0636005 51.2044C0.0636005 46.5052 3.84144 42.6895 8.5224 42.6895H16.9812V51.2044ZM21.2042 51.2044C21.2042 46.4924 24.9821 42.6895 29.663 42.6895C34.344 42.6895 38.1218 46.4924 38.1218 51.2044V72.4852C38.1218 77.1971 34.344 81 29.663 81C24.9821 81 21.2042 77.1971 21.2042 72.4852V51.2044Z"
                fill="#E01E5A"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.663 17.0297C24.9821 17.0297 21.2042 13.2268 21.2042 8.51486C21.2042 3.80288 24.9821 0 29.663 0C34.344 0 38.1218 3.80288 38.1218 8.51486V17.0297H29.663ZM29.663 21.3448C34.344 21.3448 38.1218 25.1476 38.1218 29.8596C38.1218 34.5716 34.344 38.3745 29.663 38.3745H8.4588C3.77784 38.3745 0 34.5588 0 29.8596C0 25.1604 3.77784 21.3448 8.4588 21.3448H29.663Z"
                fill="#36C5F0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M63.5491 29.8596C63.5491 25.1476 67.327 21.3448 72.0079 21.3448C76.6889 21.3448 80.4667 25.1476 80.4667 29.8596C80.4667 34.5716 76.6889 38.3745 72.0079 38.3745H63.5491V29.8596ZM59.3261 29.8596C59.3261 34.5716 55.5482 38.3745 50.8673 38.3745C46.1863 38.3745 42.4085 34.5716 42.4085 29.8596V8.51486C42.4085 3.80288 46.1863 0 50.8673 0C55.5482 0 59.3261 3.80288 59.3261 8.51486V29.8596Z"
                fill="#2EB67D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M50.8673 63.9703C55.5482 63.9703 59.3261 67.7732 59.3261 72.4852C59.3261 77.1971 55.5482 81 50.8673 81C46.1863 81 42.4085 77.1971 42.4085 72.4852V63.9703H50.8673ZM50.8673 59.7193C46.1863 59.7193 42.4085 55.9164 42.4085 51.2044C42.4085 46.4924 46.1863 42.6895 50.8673 42.6895H72.0715C76.7525 42.6895 80.5303 46.4924 80.5303 51.2044C80.5303 55.9164 76.7525 59.7193 72.0715 59.7193H50.8673Z"
                fill="#ECB22E"
              />
            </svg>
          </div>
          <div class="h-12 w-40 flex-shrink-0">
            <svg width="100%" height="100%" viewBox="0 0 318 81" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M101.735 63.7782L105.703 54.4951C109.99 57.7218 115.701 59.3991 121.336 59.3991C125.496 59.3991 128.129 57.7858 128.129 55.3402C128.065 48.5027 103.21 53.8549 103.019 36.7099C102.956 28.003 110.639 21.2935 121.527 21.2935C128.001 21.2935 134.463 22.9069 139.08 26.5817L135.366 36.0569C131.143 33.3424 125.89 31.4089 120.891 31.4089C117.495 31.4089 115.256 33.0223 115.256 35.0838C115.32 41.7932 140.365 38.1184 140.62 54.4951C140.62 63.3941 133.128 69.6554 122.366 69.6554C114.48 69.6426 107.242 67.7731 101.735 63.7782V63.7782ZM254.133 51.1404C252.149 54.6231 248.434 57.0047 244.135 57.0047C237.788 57.0047 232.674 51.8446 232.674 45.468C232.674 39.0915 237.8 33.9314 244.135 33.9314C248.422 33.9314 252.149 36.313 254.133 39.7957L265.085 33.6753C260.989 26.3256 253.103 21.2935 244.135 21.2935C230.868 21.2935 220.107 32.126 220.107 45.4809C220.107 58.8357 230.868 69.6682 244.135 69.6682C253.166 69.6682 260.989 64.7001 265.085 57.2864L254.133 51.1404ZM146.127 1.22919H159.84V68.7463H146.127V1.22919ZM270.465 1.22919V68.7463H284.178V48.4899L300.446 68.7463H318L297.305 44.687L316.461 22.241H299.67L284.165 40.8713V1.22919H270.465Z"
                fill="#52525B"
              />
              <path
                d="M200.582 51.2684C198.597 54.5591 194.502 57.0048 189.884 57.0048C183.537 57.0048 178.423 51.8446 178.423 45.4681C178.423 39.0915 183.55 33.9314 189.884 33.9314C194.502 33.9314 198.597 36.5051 200.582 39.8598V51.2684ZM200.582 22.2539V27.7341C198.343 23.9313 192.772 21.2808 186.933 21.2808C174.887 21.2808 165.411 31.9852 165.411 45.4041C165.411 58.823 174.887 69.6554 186.933 69.6554C192.759 69.6554 198.33 67.0177 200.582 63.202V68.6823H214.294V22.2539H200.582Z"
                fill="#52525B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.9812 51.2044C16.9812 55.9164 13.2034 59.7193 8.5224 59.7193C3.84144 59.7193 0.0636005 55.9036 0.0636005 51.2044C0.0636005 46.5052 3.84144 42.6895 8.5224 42.6895H16.9812V51.2044ZM21.2042 51.2044C21.2042 46.4924 24.9821 42.6895 29.663 42.6895C34.344 42.6895 38.1218 46.4924 38.1218 51.2044V72.4852C38.1218 77.1971 34.344 81 29.663 81C24.9821 81 21.2042 77.1971 21.2042 72.4852V51.2044Z"
                fill="#E01E5A"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.663 17.0297C24.9821 17.0297 21.2042 13.2268 21.2042 8.51486C21.2042 3.80288 24.9821 0 29.663 0C34.344 0 38.1218 3.80288 38.1218 8.51486V17.0297H29.663ZM29.663 21.3448C34.344 21.3448 38.1218 25.1476 38.1218 29.8596C38.1218 34.5716 34.344 38.3745 29.663 38.3745H8.4588C3.77784 38.3745 0 34.5588 0 29.8596C0 25.1604 3.77784 21.3448 8.4588 21.3448H29.663Z"
                fill="#36C5F0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M63.5491 29.8596C63.5491 25.1476 67.327 21.3448 72.0079 21.3448C76.6889 21.3448 80.4667 25.1476 80.4667 29.8596C80.4667 34.5716 76.6889 38.3745 72.0079 38.3745H63.5491V29.8596ZM59.3261 29.8596C59.3261 34.5716 55.5482 38.3745 50.8673 38.3745C46.1863 38.3745 42.4085 34.5716 42.4085 29.8596V8.51486C42.4085 3.80288 46.1863 0 50.8673 0C55.5482 0 59.3261 3.80288 59.3261 8.51486V29.8596Z"
                fill="#2EB67D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M50.8673 63.9703C55.5482 63.9703 59.3261 67.7732 59.3261 72.4852C59.3261 77.1971 55.5482 81 50.8673 81C46.1863 81 42.4085 77.1971 42.4085 72.4852V63.9703H50.8673ZM50.8673 59.7193C46.1863 59.7193 42.4085 55.9164 42.4085 51.2044C42.4085 46.4924 46.1863 42.6895 50.8673 42.6895H72.0715C76.7525 42.6895 80.5303 46.4924 80.5303 51.2044C80.5303 55.9164 76.7525 59.7193 72.0715 59.7193H50.8673Z"
                fill="#ECB22E"
              />
            </svg>
          </div>
        </div>

        <div
          class="
            gap-10
            mt-10
            w-full
            grid grid-cols-1
            items-center
            max-w-5xl
            2xl:max-w-7xl
            justify-between
            md:grid-cols-2
            place-items-stretch
          "
        >
          <!-- col -1 -->
          <div class="md:block flex flex-col justify-center items-center">
            <h1
              class="
                mt-4
                md:mt-6
                2xl:text-5xl
                font-medium
                md:text-4xl
                text-white text-xl text-center
                md:text-left
              "
            >
              Great companies and small business trusted and use Vooaxis
              products
            </h1>

            <h3
              class="
                md:max-w-md
                mt-4
                md:text-sm
                text-white text-xs text-center
                md:text-left
              "
            >
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illo
              perspiciatis soluta similique!
            </h3>

            <router-link
              to="/license"
              class="
                w-full
                md:max-w-xxs
                whitespace-nowrap
                mt-6
                px-8
                py-2
                font-medium
                text-sm text-gray-900
                bg-white
                hover:bg-gray-300
                transition-colors
                duration-300
                rounded-md
                focus:outline-none
                inline-flex
                justify-center
                items-center
              "
            >
              <span
                ><svg
                  class="h-5 w-5 mr-3"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  /></svg
              ></span>
              About license
            </router-link>
          </div>

          <!-- col-2 -->
          <div
            class="
              md:mt-10
              overflow-hidden
              bg-gray-800
              rounded-md
              p-5
              md:py-10
              2xl:py-12
            "
          >
            <transition-group
              tag="div"
              class="flex justify-center flex-row gap-10"
              enter-active-class="transition transform duration-500 ease-out delay-500"
              enter-from-class="translate-x-full opacity-0"
              enter-to-class="translate-x-0 opacity-100"
              leave-active-class="transition transform duration-500 ease-in"
              leave-from-class="translate-x-0 opacity-100"
              leave-to-class="-translate-x-full opacity-0"
              move-class="transition duration-500"
            >
              <div
                class="
                  flex-shrink-0
                  max-w-xs
                  md:max-w-md
                  flex flex-col
                  items-center
                  justify-center
                "
                v-for="t in sorted"
                :key="t.id"
              >
                <Avatar class="mx-auto" :img="t.avatar" />

                <p
                  class="
                    mt-6
                    md:max-w-md
                    2xl:max-w-xl
                    text-white text-center text-sm
                    md:textbase
                    2xl:text-lg
                  "
                >
                  {{ t.desc }}
                </p>

                <div class="mt-5 flex gap-2 items-center justify-center">
                  <span class="w-8 h-1 rounded-full bg-brandGreen"></span>
                  <h1 class="text-base font-medium text-white">
                    {{ t.name }}
                  </h1>
                  <span class="w-8 h-1 rounded-full bg-brandGreen"></span>
                </div>

                <h6 class="mt-2 text-xs text-center text-white">
                  {{ t.sub }}
                </h6>
              </div>
            </transition-group>

            <div class="mt-6 flex items-center justify-center gap-3">
              <div
                v-for="(_, ind) in testimonials"
                @click="addTest(ind)"
                :key="_.id"
                class="
                  flex
                  items-center
                  justify-center
                  p-1
                  border-b
                  rounded-full
                  transition-all
                  duration-300
                  cursor-pointer
                "
                :class="ind === count ? 'border-white' : 'border-gray-800'"
              >
                <span
                  class="p-1 bg-white rounded-full transition-all duration-300"
                  :class="ind === count ? 'bg-white' : 'bg-gray-500'"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 6th section -->
    <section
      v-if="!getCurrentUserLoading && !getCurrentUser"
      class="2xl:mt-28 mt-16 px-2 pb-20 2xl:pb-44 bg-subtleGray overflow-hidden"
    >
      <!-- content -->
      <div
        class="
          max-w-screen-2xl
          md:px-6
          mx-auto
          md:pt-12
          w-full
          flex flex-1 flex-col
          items-center
          justify-start
        "
      >
        <div class="flex flex-col justify-center items-center">
          <span
            class="
              px-2
              py-1
              2xl:text-sm
              font-medium
              text-white text-xs
              bg-purple-600
              rounded-md
            "
            >Free files</span
          >

          <h1
            class="
              mt-3
              md:mt-6
              2xl:text-5xl
              font-medium
              md:text-4xl
              text-gray-800 text-xl text-center
            "
          >
            <span class="text-center">
              Instant access to hundreds of free templates
            </span>
          </h1>

          <h3
            class="
              md:max-w-md
              mt-4
              md:text-sm
              text-gray-800 text-xs text-center
            "
          >
            for new free downloads every week. Join today
          </h3>

          <router-link
            to="/register"
            class="
              block
              text-center
              w-full
              md:max-w-xxs
              mt-6
              px-8
              py-2
              font-medium
              text-sm text-white
              bg-orange-600
              hover:bg-orange-500
              transition-colors
              duration-300
              rounded-md
              focus:outline-none
            "
          >
            Create account now
          </router-link>
        </div>

        <div
          v-if="
            getSampleProducts &&
            getSampleProducts[0] &&
            getSampleProducts[1] &&
            getSampleProducts[2]
          "
          class="max-w-1xl mx-auto gap-10 my-10 grid grid-cols-1 md:grid-cols-3"
        >
          <ProductVideoCard
            v-for="product in getSampleProducts"
            :textWhite="false"
            :key="product.id"
            :id="product.id"
            :thumbnail="product.thumbnailUrl"
            :video="product.previewUrl"
            :author="product.user.userName"
            :authorId="product.user.id"
            :title="product.productName"
            :category="product.category"
            :date="product.updatedAt.split('T')[0]"
            :free="true"
          />
        </div>

        <router-link
          to="/free"
          class="
            mb-10
            text-center
            md:max-w-xxs
            mt-6
            w-full
            block
            font-medium
            text-sm text-gray-800
            focus:outline-none
            underline
            hover:no-underline
          "
        >
          View all free files
        </router-link>

        <div class="my-10 2xl:mt-28 flex flex-col justify-center items-center">
          <h3
            class="
              md:max-w-md
              mt-4
              md:text-sm
              text-gray-800 text-xs text-center
            "
          >
            Join over +2,000 happy clients
          </h3>

          <h1
            class="
              mt-3
              md:mt-6
              2xl:text-5xl
              font-medium
              md:text-4xl
              text-gray-800 text-xl text-center
            "
          >
            <span class="text-center"> Start doing great things! </span>
          </h1>

          <router-link
            to="/pricing"
            class="
              mt-6
              2xl:mt-10
              text-center
              px-8
              py-2
              font-medium
              text-sm text-gray-900
              bg-brandGreen
              hover:bg-brandGreenHover
              transition-colors
              duration-300
              rounded-md
              focus:outline-none
            "
          >
            Subscribe now
          </router-link>
        </div>
      </div>
    </section>

    <!-- after becomming a user -->
    <section
      v-if="
        !getCurrentUserLoading &&
        getCurrentUser &&
        getSubscriberHomepage &&
        getSearchSubscriberHomepage
      "
      class="mt-14 pt-5 min-h-screen"
    >
      <div
        v-if="
          getSearchSubscriberHomepage.videos &&
          getSearchSubscriberHomepage.videos.length > 0
        "
        class="
          w-full
          mt-20
          max-w-1xl
          mx-auto
          grid grid-cols-1
          md:grid-cols-3
          xl:grid-cols-4
          py-10
          px-4
          md:px-6
          2xl:px-0
          gap-10
        "
      >
        <ProductVideoCard
          v-for="product in getSearchSubscriberHomepage.videos"
          :textWhite="false"
          :key="product.id"
          :id="product.id"
          :thumbnail="product.thumbnailUrl"
          :video="product.previewUrl"
          :author="product.user.userName"
          :authorId="product.user.id"
          :title="product.productName"
          :category="product.category"
          :date="product.updatedAt.split('T')[0]"
          :free="product.free"
        />
      </div>

      <div
        v-if="
          getSearchSubscriberHomepage.audios &&
          getSearchSubscriberHomepage.audios.length > 0
        "
        class="
          w-full
          mt-20
          max-w-1xl
          mx-auto
          grid grid-cols-1
          md:grid-cols-2
          xl:grid-cols-3
          py-10
          px-4
          md:px-6
          2xl:px-0
          gap-10
        "
      >
        <NewMiniAudio
          v-for="product in getSearchSubscriberHomepage.audios"
          :key="product.id"
          :currentCategory="product.category"
          :id="product.id"
          :audioUrl="product.previewUrl"
          :author="product.user.userName"
          :authorId="product.user.id"
          :title="product.productName"
          :peaks="product.peaks"
          :trackLength="product.trackLength"
          :bpm="product.tempo"
          :length="product.length"
          :category="product.category"
          :date="product.updatedAt.split('T')[0]"
          :free="product.free"
        />
      </div>

      <div
        class="
          w-full
          mt-10
          max-w-1xl
          mx-auto
          px-4
          md:px-6
          2xl:px-0
          flex
          items-center
          justify-between
          gap-10
        "
      >
        <h1
          v-if="fetchToggler === 'freeFiles'"
          class="text-base md:text-lg font-medium text-gray-800"
        >
          Awesome freebies ready to use in your next creative project
        </h1>
        <h1
          v-if="fetchToggler === 'recentlyAdded'"
          class="text-base md:text-lg font-medium text-gray-800"
        >
          There’s always something new to discover in our library.
        </h1>
        <h1
          v-if="fetchToggler === 'downloaded'"
          class="text-base md:text-lg font-medium text-gray-800"
        >
          Need to use an asset again? Here are the latest ones you downloaded.
        </h1>
        <router-link
          v-if="fetchToggler === 'freeFiles'"
          to="/free"
          class="
            px-3
            py-2
            text-center
            whitespace-nowrap
            flex
            items-center
            justify-center
            bg-brandPurple
            rounded-md
            text-xs
            font-medium
            text-white
            focus:outline-none
          "
          >See more
          <svg
            class="w-4 h-4 ml-2 text-white"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </router-link>
        <router-link
          v-if="fetchToggler === 'recentlyAdded'"
          to="/all-items"
          class="
            px-3
            py-2
            text-center
            whitespace-nowrap
            flex
            items-center
            justify-center
            bg-brandPurple
            rounded-md
            text-xs
            font-medium
            text-white
            focus:outline-none
          "
          >See more
          <svg
            class="w-4 h-4 ml-2 text-white"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </router-link>
        <router-link
          v-if="fetchToggler === 'downloaded'"
          to="/downloads"
          class="
            px-3
            py-2
            text-center
            whitespace-nowrap
            flex
            items-center
            justify-center
            bg-brandPurple
            rounded-md
            text-xs
            font-medium
            text-white
            focus:outline-none
          "
          >See more
          <svg
            class="w-4 h-4 ml-2 text-white"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </router-link>
      </div>

      <div
        class="
          w-full
          max-w-1xl
          mx-auto
          grid grid-cols-1
          md:grid-cols-3
          xl:grid-cols-4
          py-10
          px-4
          md:px-6
          2xl:px-0
          gap-10
        "
      >
        <!-- buttons -->
        <div class="w-full h-52 image-container">
          <div
            class="absolute inset-0 flex flex-col rounded-md overflow-hidden"
          >
            <button
              @click="fetchToggler = 'recentlyAdded'"
              :class="
                fetchToggler === 'recentlyAdded'
                  ? 'bg-brandPurple text-white'
                  : 'bg-white text-brandPurple hover:bg-brandPurple hover:text-white'
              "
              class="
                w-full
                h-full
                text-sm
                font-medium
                text-center
                focus:outline-none
                transition
                duration-100
              "
            >
              Recently added
            </button>
            <button
              @click="fetchToggler = 'freeFiles'"
              :class="
                fetchToggler === 'freeFiles'
                  ? 'bg-brandPurple text-white'
                  : 'bg-white text-brandPurple hover:bg-brandPurple hover:text-white'
              "
              class="
                w-full
                h-full
                border-t border-b border-gray-300
                text-sm
                font-medium
                text-center
                focus:outline-none
                transition
                duration-100
              "
            >
              Free Files
            </button>
            <button
              @click="fetchToggler = 'downloaded'"
              :class="
                fetchToggler === 'downloaded'
                  ? 'bg-brandPurple text-white'
                  : 'bg-white text-brandPurple hover:bg-brandPurple hover:text-white'
              "
              class="
                w-full
                h-full
                text-sm
                font-medium
                text-center
                focus:outline-none
                transition
                duration-100
              "
            >
              Downloaded
            </button>
          </div>
        </div>
        <!-- end of buttons -->

        <h1
          v-if="
            getSubscriberHomepage.videos.length <= 0 &&
            getSubscriberHomepage.audios.length <= 0
          "
          class="
            md:col-span-2
            xl:col-span-3
            w-full
            h-full
            flex
            items-center
            justify-center
            text-center text-gray-200 text-sm
            font-medium
          "
        >
          No Downloads Available
        </h1>

        <!-- video cards -->
        <ProductVideoCard
          v-for="product in getSubscriberHomepage.videos"
          :textWhite="false"
          :key="product.id"
          :id="product.id"
          :thumbnail="product.thumbnailUrl"
          :video="product.previewUrl"
          :author="product.user.userName"
          :authorId="product.user.id"
          :title="product.productName"
          :category="product.category"
          :date="product.updatedAt.split('T')[0]"
          :free="product.free"
        />

        <!-- end of video cards -->
      </div>
      <div
        class="
          w-full
          mb-10
          -mt-10
          max-w-1xl
          mx-auto
          grid grid-cols-1
          md:grid-cols-2
          xl:grid-cols-3
          py-10
          px-4
          md:px-6
          2xl:px-0
          gap-10
        "
      >
        <NewMiniAudio
          v-for="product in getSubscriberHomepage.audios"
          :key="product.id"
          :currentCategory="product.category"
          :id="product.id"
          :audioUrl="product.previewUrl"
          :author="product.user.userName"
          :authorId="product.user.id"
          :title="product.productName"
          :peaks="product.peaks"
          :trackLength="product.trackLength"
          :bpm="product.tempo"
          :length="product.length"
          :category="product.category"
          :date="product.updatedAt.split('T')[0]"
          :free="product.free"
        />
      </div>
    </section>
  </main>
</template>

<script>
import Card from "../components/utils/Card.vue";
import Circle from "../components/utils/Circle.vue";
import TemplateCard from "../components/utils/TemplateCard.vue";
import Avatar from "../components/utils/Avatar.vue";
import { mapActions, mapGetters } from "vuex";
import ProductVideoCard from "../components/utils/ProductVideoCard.vue";
import MiniProductAudioCard from "../components/utils/MiniProductAudioCard.vue";
import NewMiniAudio from "../components/utils/NewMiniAudio.vue";
export default {
  components: {
    Circle,
    Card,
    TemplateCard,
    Avatar,
    ProductVideoCard,
    MiniProductAudioCard,
    NewMiniAudio,
  },
  name: "Home",

  created() {
    this.ts = [this.testimonials[0]];
  },

  mounted() {
    this.fetchDisplayImages();
    if (this.getCurrentUser) this.fetchSubscriberHomepage(this.fetchToggler);
    this.fetchRecentSearch();
    if (!this.getCurrentUser) {
      this.fetchSampleProducts();
    }
  },

  computed: {
    ...mapGetters(["productDisplayImages"]),
    ...mapGetters(["testimonials"]),
    ...mapGetters(["freeTemplates"]),
    ...mapGetters(["getCurrentUser"]),
    ...mapGetters(["getSubscriberHomepage"]),
    ...mapGetters(["getSearchSubscriberHomepage"]),
    ...mapGetters(["getSampleProducts"]),
    ...mapGetters(["getCurrentUserLoading"]),

    sorted() {
      let d = this.ts;
      return d.reverse().slice(0, 1);
    },
  },

  methods: {
    ...mapActions(["appendAlert"]),
    ...mapActions(["fetchDisplayImages"]),
    ...mapActions(["fetchSubscriberHomepage"]),
    ...mapActions(["fetchRecentSearch"]),
    ...mapActions(["fetchSampleProducts"]),
    handleCheckout() {
      if (!this.getCurrentUser) {
        this.$router.push("/pricing");
      } else if (this.getCurrentUser && !this.getCurrentUser.isEmailVerified) {
        this.appendAlert({
          id: Math.floor(Math.random() * 10000),
          title: "Your Email is not yet verified",
          sub: "Click the link sent to your email to verify your account",
          icon: "bi:info-circle-fill",
          type: "warning",
          button: {
            name: "Resend Verification email",
            action: "resendVerificationEmail",
          },
        });
      } else {
        this.$router.push(`/checkout/${this.type}`);
      }
    },
    handleMouseOver() {
      this.featuresHovered = true;
    },
    handleMouseLeave() {
      this.featuresHovered = false;
    },
    changeType(val) {
      this.type = val;
    },

    addTest(id) {
      this.count = id;
      this.ts.push(this.testimonials[id]);
    },
  },

  data() {
    return {
      youtubeOpened: false,
      ts: [],
      type: "yearly",
      featuresHovered: false,
      count: 0,
      fetchToggler: "recentlyAdded",
    };
  },

  watch: {
    productDisplayImages: function (nv, ov) {
      console.log({ nv });
    },
    fetchToggler: function (nv) {
      this.fetchSubscriberHomepage(nv);
    },
    getCurrentUser: function (nv) {
      if (nv) {
        this.fetchSubscriberHomepage(this.fetchToggler);
        this.fetchRecentSearch();
      }
    },
  },
};
</script>

<style scoped>
.image-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
</style>
