<template>
	<section class="min-h-screen flex flex-col items-center justify-center bg-white">
		<h1 class="text-xl md:text-2xl font-bold text-gray-900">
			Verifying your email
		</h1>
		<div class="mt-10 bg-gray-100 shadow-md rounded-lg p-10">
			<Loader v-if="getAuthLoading" :bright="true" />
		</div>
	</section>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import Loader from "../components/utils/Loader.vue"
export default {
	name: "VerifyEmail",
	components: { Loader },

	methods: {
		...mapActions(["verifyEmail"])
	},

	computed: {
		...mapGetters(["getAuthLoading"])
	},

	mounted() {
		let { token } = this.$route.params
		this.verifyEmail(token)
	}
}
</script>
