<template>
  <svg
    class="w-52"
    viewBox="0 0 422 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M150.62 28.5898L145.86 37.8798L128.77 71.4298C126.82 75.2598 120.08 75.2598 118.13 71.4298L101.04 37.8798L96.28 28.5898H100.31C104.61 28.5898 108.56 30.9998 110.52 34.8298L112.08 37.8798L123.45 60.1798L134.82 37.8798L136.38 34.8298C138.34 30.9998 142.28 28.5898 146.59 28.5898H150.62V28.5898Z"
      fill="#fff"
    />
    <path
      d="M177.61 28.52H168.48C156.3 28.52 150.21 34.61 150.21 46.79V55.92C150.21 68.1 156.3 74.19 168.48 74.19H177.61C189.79 74.19 195.88 68.1 195.88 55.92V46.79C195.87 34.61 189.78 28.52 177.61 28.52ZM186.74 55.92C186.74 62.01 183.7 65.05 177.61 65.05H168.48C162.39 65.05 159.35 62.01 159.35 55.92V46.79C159.35 40.7 162.39 37.66 168.48 37.66H177.61C183.7 37.66 186.74 40.7 186.74 46.79V55.92Z"
      fill="#fff"
    />
    <path
      d="M226.83 28.52H217.7C205.52 28.52 199.43 34.61 199.43 46.79V55.92C199.43 68.1 205.52 74.19 217.7 74.19H226.83C239.01 74.19 245.1 68.1 245.1 55.92V46.79C245.1 34.61 239.01 28.52 226.83 28.52ZM235.96 55.92C235.96 62.01 232.92 65.05 226.83 65.05H217.7C211.61 65.05 208.57 62.01 208.57 55.92V46.79C208.57 40.7 211.61 37.66 217.7 37.66H226.83C232.92 37.66 235.96 40.7 235.96 46.79V55.92Z"
      fill="#fff"
    />
    <path
      d="M276.06 28.52H266.93C254.75 28.52 248.66 34.61 248.66 46.79V55.92C248.66 68.1 254.75 74.19 266.93 74.19H294.33V46.79C294.32 34.61 288.23 28.52 276.06 28.52ZM276.06 65.05H266.93C261.89 65.05 257.8 60.96 257.8 55.92V46.79C257.8 41.75 261.89 37.66 266.93 37.66H276.06C281.1 37.66 285.19 41.75 285.19 46.79V55.92C285.19 60.96 281.1 65.05 276.06 65.05Z"
      fill="#fff"
    />
    <path
      d="M337.12 28.52C333.49 28.52 330.06 30.23 327.88 33.13L319.89 43.73L311.9 33.13C309.71 30.23 306.29 28.52 302.66 28.52H296.97L314.19 51.35L296.97 74.18H302.66C306.29 74.18 309.72 72.47 311.9 69.57L319.89 58.97L327.88 69.57C330.07 72.47 333.49 74.18 337.12 74.18H342.81L325.64 51.35L342.81 28.52H337.12V28.52Z"
      fill="#fff"
    />
    <path
      d="M345.6 37.65V74.18H354.73V28.52C349.69 28.52 345.6 32.61 345.6 37.65Z"
      fill="#fff"
    />
    <path d="M354.73 15.1499H345.6V24.2799H354.73V15.1499Z" fill="#fff" />
    <path
      d="M380.05 46.7898C370.92 45.8798 366.35 44.1298 366.35 41.5398C366.35 38.9498 369.39 37.6598 375.48 37.6598H391.46C396.5 37.6598 400.59 33.5698 400.59 28.5298H375.47C363.29 28.5298 357.2 32.8698 357.2 41.5398C357.2 50.2198 364.81 55.0098 380.03 55.9298C389.16 56.8398 393.73 58.5898 393.73 61.1798C393.73 63.7698 390.69 65.0598 384.6 65.0598H368.62C363.58 65.0598 359.49 69.1498 359.49 74.1898H384.61C396.79 74.1898 402.88 69.8498 402.88 61.1798C402.89 52.4998 395.28 47.6998 380.05 46.7898Z"
      fill="#fff"
    />
    <path
      d="M51.03 74.3898L56.34 64.0198L80.64 16.3298L104.94 64.0198L110.25 74.3898C103.12 74.3898 96.59 70.3998 93.35 64.0498L93.33 64.0198L89.1 55.7198C85.58 48.8098 75.71 48.8098 72.18 55.7198L67.95 64.0198L67.93 64.0498C64.69 70.3998 58.16 74.3898 51.03 74.3898Z"
      fill="#20DE82"
    />
    <path
      d="M80.01 14.9299L74.7 25.2899L50.4 72.9899L26.1 25.2899L20.79 14.9199C27.92 14.9199 34.45 18.9099 37.69 25.2599L37.71 25.2899L41.94 33.5899C45.46 40.4999 55.33 40.4999 58.86 33.5899L63.09 25.2899L63.11 25.2599C66.36 18.9199 72.89 14.9299 80.01 14.9299Z"
      fill="#20DE82"
    />
  </svg>
</template>

<script>
export default {
  name: "ColorFooterLogo",
};
</script>

<style></style>
