<template>
  <div
    class="relative flex flex-col transition-all duration-300 rounded-md pt-1"
  >
    <div
      @mouseenter="showPlay = true"
      @mouseleave="showPlay = false"
      class="
        image-container
        rounded-md rounded-b-none
        overflow-hidden
        hover:-translate-y-1
        transform
      "
    >
      <div class="absolute inset-0 rounded-md rounded-b-none overflow-hidden">
        <div class="relative top-0 right-0 z-30">
          <span
            v-if="isNew"
            class="
              absolute
              mt-2
              right-0
              top-0
              text-xs
              mr-2
              text-white
              bg-brandPurple
              px-2
              py-1
              rounded
              font-bold
            "
          >
            New
          </span>
        </div>
        <div
          v-if="video && thumbnail"
          class="relative w-full h-full bg-transparent overflow-hidden"
        >
          <Transition
            enter-active-class="transition duration-500"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div
              @click="handlePlay"
              v-if="(showPlay && !play && thumbnail) || loading"
              class="
                absolute
                cursor-pointer
                inset-0
                z-30
                w-full
                h-full
                bg-darkOverlay
                flex
                items-center
                justify-center
                overflow-hidden
              "
            >
              <div
                v-if="!loading"
                class="
                  w-8
                  h-8
                  rounded-full
                  bg-white
                  flex
                  items-center
                  justify-center
                "
              >
                <span
                  class="iconify text-gray-800 w-6 h-6 ml-1"
                  data-icon="bi:play-fill"
                  data-inline="false"
                ></span>
              </div>

              <Loader v-if="loading" :white="true" />
            </div>
          </Transition>
          <Transition
            enter-active-class="transition duration-500"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <img
              v-if="(!play && thumbnail) || loading"
              :src="thumbnail"
              class="
                absolute
                inset-0
                z-20
                w-full
                h-full
                object-cover
                overflow-hidden
              "
              alt="product image"
            />
          </Transition>
          <div
            class="
              absolute
              z-10
              inset-0
              w-full
              h-full
              bg-gray-400
              animate-pulse
              flex
              items-center
              justify-center
              overflow-hidden
            "
          >
            <svg
              class="w-12 h-12 text-white"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"
              />
            </svg>
          </div>
          <Transition
            enter-active-class="transition duration-500"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <video
              ref="video"
              v-if="play && video"
              class="
                absolute
                inset-0
                z-10
                w-full
                h-full
                overflow-hidden
                focus:outline-none
              "
              controls
              controlsList="nodownload"
              disablePictureInPicture
              @ended="play = false"
              @loadedmetadata="handleLoaded"
            >
              <source :src="video" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Transition>
        </div>
        <div
          v-if="!video && thumbnail"
          class="relative w-full h-full bg-transparent overflow-hidden"
        >
          <img
            :src="thumbnail"
            class="
              absolute
              inset-0
              z-20
              w-full
              h-full
              object-cover
              overflow-hidden
            "
            alt="product image"
          />
        </div>
      </div>
    </div>

    <!-- :to="{
							name: 'single product',
							params: {
								name: title
									.toLowerCase()
									.split(' ')
									.join('-'),
								id
							}
						}" -->

    <!-- info -->
    <div
      class="
        select-none
        mt-3
        flex flex-col
        items-start
        justify-start
        rounded-md
      "
    >
      <div class="mt-1 w-full flex items-start justify-between">
        <div class="w-full truncate flex items-start justify-start flex-col">
          <a
            :href="`/item/${title.toLowerCase().split(' ').join('-')}/${id}`"
            v-if="title"
            :class="
              textWhite
                ? 'text-gray-200 hover:text-gray-100'
                : 'text-gray-800 hover:text-gray-900'
            "
            class="w-full truncate text-s font-semibold cursor-pointer"
          >
            {{ title }}
          </a>
          <div
            v-else
            class="w-full h-3 rounded bg-gray-400 animate-pulse"
          ></div>
          <div class="mt-1 flex items-center justify-center">
            <h1
              v-if="author"
              :class="textWhite ? 'text-gray-300 ' : 'text-gray-600'"
              class="text-xs"
            >
              By
              <a
                :href="`/user/${author}`"
                class="hover:underline hover:text-brandPurple"
                >{{ author }}</a
              >
            </h1>

            <div
              v-else
              class="w-32 h-3 rounded bg-gray-400 animate-pulse"
            ></div>

            <p
              v-if="category"
              :class="textWhite ? 'text-gray-300' : 'text-gray-600'"
              class="ml-1 text-xs truncate"
            >
              in
              <a
                :href="`/${category.toLowerCase().split(' ').join('-')}/pg-1`"
                :class="textWhite ? 'text-gray-300' : 'text-gray-600'"
                class="hover:underline hover:text-brandPurple truncate"
                >{{ category.split(" ")[0] }} {{ category.split(" ")[1] }}</a
              >
            </p>
          </div>
        </div>

        <div class="ml-2 flex items-center justify-center space-x-2">
          <!-- collection popup -->
          <div class="focus:outline-none outline-none">
            <div
              v-if="isCollection"
              @click="isCollection = !isCollection"
              class="fixed z-30 inset-0"
            ></div>
            <button
              @click="handleCollectionClick"
              v-if="title"
              class="
                tooltip
                w-7
                h-7
                rounded-md
                bg-transparent
                hover:bg-white
                p-1
                focus:outline-none
                flex
                items-center
                justify-center
              "
            >
              <span class="hidden lg:block tooltiptext text-xs"
                >Add to collection</span
              >

              <svg
                v-if="getFilteredAvailableCollections.length > 0"
                class="w-6 h-6 text-gray-900"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
              </svg>
              <svg
                v-else
                class="w-6 h-6 text-gray-900"
                viewBox="0 0 20 20"
                fill="none"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z"
                />
              </svg>
            </button>
            <div
              v-else
              class="w-7 h-7 rounded-md bg-gray-400 animate-pulse"
            ></div>

            <Transition
              class="absolute z-20"
              enter-active-class="transition transform duration-100 ease-in"
              enter-from-class="opacity-0 scale-95"
              enter-to-class="opacity-100 scale-100"
              leave-active-class="transition transform duration-300 ease-out"
              leave-from-class="opacity-100 scale-100"
              leave-to-class="opacity-0 scale-95"
            >
              <div
                v-if="isCollection"
                class="
                  absolute
                  z-30
                  w-full
                  bottom-0
                  right-0
                  focus:outline-none
                  outline-none
                  rounded-md
                  shadow-lg
                  bg-white
                  overflow-hidden
                  border border-gray-300
                  dark:border-darkBackground
                "
              >
                <div
                  v-if="getCurrentUser"
                  class="w-full p-3 flex items-center justify-between"
                >
                  <h1 class="text-sm font-semibold text-gray-800">
                    My Collection
                  </h1>
                  <a
                    href="/collections"
                    class="text-xs text-brandPurple focus:outline-none"
                    >See All</a
                  >
                </div>

                <!-- search -->
                <form
                  v-if="getCurrentUser"
                  @submit.prevent="handleCreateCollection"
                  class="mt-1 mb-3 w-full px-3"
                >
                  <div
                    class="
                      px-3
                      w-full
                      flex
                      items-center
                      justify-between
                      bg-gray-200
                      rounded-md
                    "
                  >
                    <input
                      type="search"
                      v-model="collectionName"
                      @input="handleSearchInput"
                      placeholder="search or create a collection"
                      class="
                        py-2
                        rounded-md
                        w-full
                        text-sm text-gray-800
                        bg-transparent
                        placeholder-gray-500
                        focus:outline-none
                      "
                    />
                    <svg
                      class="w-5 h-5 text-gray-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                </form>

                <!-- end of search -->

                <!-- suggestion -->
                <div
                  v-if="getCurrentUser"
                  class="
                    mt-4
                    mb-3
                    px-3
                    h-16
                    overflow-y-auto
                    flex flex-col
                    gap-2
                    items-start
                    justify-start
                  "
                >
                  <div
                    v-for="collection in getFilteredAvailableCollections"
                    :key="collection.id"
                    class="w-full flex items-center justify-between gap-5"
                  >
                    <span class="truncate text-xs font-medium text-gray-800">
                      {{ collection.name }}
                    </span>

                    <abbr
                      v-if="
                        collection.products &&
                        collection.products.filter((p) => p.id === id).length >
                          0
                      "
                      title="Remove"
                    >
                      <button
                        @click="
                          removeFromCollection({
                            productId: id,
                            collectionId: collection.id,
                          })
                        "
                        class="
                          p-1
                          text-xs
                          font-medium
                          rounded
                          bg-red-100
                          text-red-900
                          focus:outline-none
                        "
                      >
                        Remove
                      </button>
                    </abbr>
                    <abbr v-else title="Add">
                      <button
                        @click="
                          addToCollection({
                            productId: id,
                            collectionId: collection.id,
                          })
                        "
                        class="
                          p-1
                          text-xs
                          font-medium
                          text-center
                          rounded
                          bg-green-100
                          text-green-900
                          focus:outline-none
                        "
                      >
                        Add
                      </button>
                    </abbr>
                  </div>
                </div>
                <!-- end of suggestion -->

                <!-- create button -->
                <button
                  v-if="getCurrentUser"
                  @click="handleCreateCollection"
                  class="
                    py-3
                    text-sm text-center
                    bg-gray-200
                    border-t border-gray-300
                    w-full
                    flex
                    items-center
                    justify-center
                    focus:outline-none
                  "
                >
                  <svg
                    class="w-6 h-6 text-buttonColor"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Create
                </button>
                <!-- end of create button -->

                <!-- if not logged in -->
                <div v-if="!getCurrentUser" class="p-5">
                  <h1 class="text-base font-bold text-gray-800 text-center">
                    Please create an account to save this item for later
                  </h1>
                  <a
                    href="/register"
                    class="
                      mt-5
                      mb-2
                      w-full
                      block
                      px-2
                      py-2
                      md:px-3
                      md:py-2
                      font-semibold
                      text-center text-gray-900 text-xs
                      md:text-sm
                      bg-brandGreen
                      transition-colors
                      duration-300
                      hover:bg-brandGreenHover
                      rounded-md
                      focus:outline-none
                    "
                  >
                    Create a free account
                  </a>

                  <a
                    href="/login"
                    class="text-center w-full block text-gray-800 text-xs"
                  >
                    <span>Already a Vooaxis member?</span>
                    <span class="text-brandPurple hover:underline">
                      Sign in here</span
                    >
                  </a>
                </div>
                <!-- end of if not logged in -->
              </div>
            </Transition>
          </div>
          <!-- end of collection popup -->

          <!-- download popup -->
          <div class="hidden md:block focus:outline-none outline-none">
            <div
              v-if="isDownload"
              @click="isDownload = !isDownload"
              class="fixed z-30 inset-0"
            ></div>
            <button
              @click="handleDownloadClick"
              v-if="title"
              class="
                tooltip
                w-7
                h-7
                rounded-md
                bg-transparent
                hover:bg-white
                p-1
                focus:outline-none
                flex
                items-center
                justify-center
              "
            >
              <span class="hidden lg:block tooltiptext text-xs">Download</span>
              <svg
                class="w-6 h-6 text-gray-900"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <div
              v-else
              class="w-7 h-7 rounded-md bg-gray-400 animate-pulse"
            ></div>

            <Transition
              class="absolute z-20"
              enter-active-class="transition transform duration-100 ease-in"
              enter-from-class="opacity-0 scale-95"
              enter-to-class="opacity-100 scale-100"
              leave-active-class="transition transform duration-300 ease-out"
              leave-from-class="opacity-100 scale-100"
              leave-to-class="opacity-0 scale-95"
            >
              <div
                v-if="isDownload"
                class="
                  absolute
                  z-30
                  w-full
                  bottom-0
                  right-0
                  focus:outline-none
                  outline-none
                  rounded-md
                  shadow-lg
                  bg-white
                  overflow-hidden
                  border border-gray-300
                  dark:border-darkBackground
                "
              >
                <div
                  v-if="
                    getCurrentUser &&
                    getCurrentUser.subscription !== 'free' &&
                    !free
                  "
                  class="w-full p-3 flex items-center justify-between"
                >
                  <h1 class="text-sm font-semibold text-gray-800">Download</h1>
                </div>

                <div
                  v-if="getCurrentUser && free"
                  class="w-full p-3 flex items-center justify-between"
                >
                  <h1 class="text-sm font-semibold text-gray-800">
                    Download Free
                  </h1>
                </div>

                <h1
                  v-if="free && getCurrentUser"
                  class="mb-5 px-3 text-sm text-gray-700"
                >
                  This free item is available to you to use only once for a
                  single project. Enjoy your free file!
                </h1>

                <div
                  v-if="free && getCurrentUser"
                  class="w-full flex flex-start px-3 mt-5"
                >
                  <CustomRoundedCheckbox
                    v-on:clicked="isPolicy = !isPolicy"
                    lable="I agree to the Free Files Single Use Policy."
                    :select="isPolicy"
                    :truncate="false"
                  />
                </div>

                <h1
                  v-if="free && getCurrentUser"
                  class="pl-10 ml-1 mt-1 mb-5 text-xs text-gray-600 italic"
                >
                  see our
                  <router-link to="#" class="underline"
                    >Free files Policy</router-link
                  >
                </h1>

                <!-- search -->
                <form
                  v-if="
                    !free &&
                    getCurrentUser &&
                    getCurrentUser.subscription !== 'free'
                  "
                  @submit.prevent="handleCreateCollection"
                  class="mt-1 mb-3 w-full px-3"
                >
                  <div
                    class="
                      px-3
                      w-full
                      flex
                      items-center
                      justify-between
                      bg-gray-200
                      rounded-md
                    "
                  >
                    <input
                      type="search"
                      v-model="licenseName"
                      @input="handleLicense"
                      placeholder="Enter license name"
                      class="
                        py-2
                        rounded-md
                        w-full
                        text-sm text-gray-800
                        bg-transparent
                        placeholder-gray-500
                        focus:outline-none
                      "
                    />
                  </div>
                </form>

                <!-- end of search -->

                <!-- download button -->
                <button
                  v-if="
                    !free &&
                    getCurrentUser &&
                    getCurrentUser.subscription !== 'free'
                  "
                  @click="handleDownload"
                  :class="[
                    !activateDownload
                      ? 'text-gray-400 pointer-events-none'
                      : 'text-gray-800',
                    { 'pointer-events-none': getDownloadLoading },
                  ]"
                  class="
                    py-3
                    text-sm text-center
                    bg-gray-200
                    border-t border-gray-300
                    w-full
                    flex
                    items-center
                    justify-center
                    focus:outline-none
                  "
                >
                  <Loader v-if="getDownloadLoading" :bright="true" />
                  <svg
                    v-if="!getDownloadLoading"
                    :class="
                      !activateDownload
                        ? 'text-gray-400 pointer-events-none'
                        : 'text-buttonColor'
                    "
                    class="w-5 h-5 mr-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span v-if="!getDownloadLoading">Download</span>
                </button>

                <!-- free download button -->
                <button
                  v-if="free && getCurrentUser"
                  @click="handleFreeDownload"
                  :class="[
                    !isPolicy
                      ? 'text-gray-400 pointer-events-none'
                      : 'text-gray-800',
                    { 'pointer-events-none': getDownloadLoading },
                  ]"
                  class="
                    py-3
                    text-sm text-center
                    bg-gray-200
                    border-t border-gray-300
                    w-full
                    flex
                    items-center
                    justify-center
                    focus:outline-none
                  "
                >
                  <Loader v-if="getDownloadLoading" :bright="true" />
                  <svg
                    v-if="!getDownloadLoading"
                    :class="
                      !isPolicy
                        ? 'text-gray-400 pointer-events-none'
                        : 'text-buttonColor'
                    "
                    class="w-5 h-5 mr-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span v-if="!getDownloadLoading">Download</span>
                </button>
                <!-- end of download button -->

                <!-- if not logged in -->
                <div
                  v-if="
                    !getCurrentUser ||
                    (getCurrentUser && getCurrentUser.subscription === 'free')
                  "
                >
                  <h1
                    v-if="!free"
                    class="
                      text-base
                      pt-5
                      px-3
                      font-bold
                      text-gray-800 text-center
                    "
                  >
                    Subscribe to get this item and unlimited downloads for your
                    Creative Project.
                  </h1>
                  <h1
                    v-if="free && !getCurrentUser"
                    class="text-base pt-5 font-bold text-gray-800 text-center"
                  >
                    Please Create an account to download this product
                  </h1>
                  <div v-if="!free" class="px-3">
                    <a
                      href="/pricing"
                      class="
                        mt-5
                        mb-5
                        w-full
                        block
                        px-2
                        py-2
                        md:px-3
                        md:py-2
                        font-semibold
                        text-center text-gray-900 text-xs
                        md:text-sm
                        bg-brandGreen
                        transition-colors
                        duration-300
                        hover:bg-brandGreenHover
                        rounded-md
                        focus:outline-none
                      "
                    >
                      Subscribe to download
                    </a>
                  </div>

                  <div v-if="free && !getCurrentUser" class="px-3">
                    <a
                      href="/register"
                      class="
                        mt-5
                        mb-2
                        w-full
                        block
                        px-2
                        py-2
                        md:px-3
                        md:py-2
                        font-semibold
                        text-center text-gray-900 text-xs
                        md:text-sm
                        bg-brandGreen
                        transition-colors
                        duration-300
                        hover:bg-brandGreenHover
                        rounded-md
                        focus:outline-none
                      "
                    >
                      Create an account
                    </a>
                  </div>

                  <a
                    v-if="!getCurrentUser && !free"
                    href="/login"
                    class="text-center pb-5 w-full block text-gray-800 text-xs"
                  >
                    <span>Already a Vooaxis member?</span>
                    <span class="text-brandPurple hover:underline">
                      Sign in here</span
                    >
                  </a>

                  <a
                    v-if="!getCurrentUser && free"
                    href="/login"
                    class="text-center pb-5 w-full block text-gray-800 text-xs"
                  >
                    <span>already a Vooaxis member?</span>
                    <span class="text-brandPurple hover:underline">
                      Sign in here</span
                    >
                  </a>
                </div>
                <!-- end of if not logged in -->
              </div>
            </Transition>
          </div>
          <!-- end of collection popup -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "./Loader.vue";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { mapActions, mapGetters } from "vuex";
import CustomRoundedCheckbox from "./CustomRoundedCheckbox.vue";
export default {
  name: "ProductVideoCard",
  components: {
    Loader,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    CustomRoundedCheckbox,
  },
  props: [
    "thumbnail",
    "video",
    "title",
    "author",
    "date",
    "authorId",
    "id",
    "category",
    "free",
    "textWhite",
  ],
  mounted() {
    const currentDate = new Date().getMonth();
    const productDate = parseInt(this.date.split("-")[1]) - 1;
    if (currentDate === productDate) {
      this.isNew = true;
    } else {
      this.isNew = false;
    }
  },
  computed: {
    ...mapGetters(["getFilteredAvailableCollections"]),
    ...mapGetters(["getCurrentUser"]),
    ...mapGetters(["getDownloadLoading"]),
    ...mapGetters(["getIsDownloaded"]),
  },
  methods: {
    ...mapActions(["createCollection"]),
    ...mapActions(["addToCollection"]),
    ...mapActions(["removeFromCollection"]),
    ...mapActions(["filterCollections"]),
    ...mapActions(["downloadProduct"]),
    ...mapActions(["downloadFreeProduct"]),
    ...mapActions(["appendAlert"]),

    handleDownloadClick() {
      if (
        !this.getCurrentUser ||
        (this.getCurrentUser && this.getCurrentUser.isEmailVerified)
      ) {
        this.isDownload = true;
      } else {
        this.appendAlert({
          id: Math.floor(Math.random() * 10000),
          title: "Your Email is not yet verified",
          sub: "Click the link sent to your email to verify your account",
          icon: "bi:info-circle-fill",
          type: "warning",
          button: {
            name: "Resend Verification email",
            action: "resendVerificationEmail",
          },
        });
      }
    },

    handleCollectionClick() {
      if (
        !this.getCurrentUser ||
        (this.getCurrentUser && this.getCurrentUser.isEmailVerified)
      ) {
        this.isCollection = true;
      } else {
        this.appendAlert({
          id: Math.floor(Math.random() * 10000),
          title: "Your Email is not yet verified",
          sub: "Click the link sent to your email to verify your account",
          icon: "bi:info-circle-fill",
          type: "warning",
          button: {
            name: "Resend Verification email",
            action: "resendVerificationEmail",
          },
        });
      }
    },

    handleFreeDownload() {
      this.downloadFreeProduct(this.id);
    },

    handleSearchInput(e) {
      this.filterCollections(e.target.value);
    },

    handleLicense() {
      if (this.licenseName.length <= 0) {
        this.activateDownload = false;
      } else {
        this.activateDownload = true;
      }
    },

    handleCreateCollection() {
      if (
        this.collectionName.length > 0 &&
        this.getFilteredAvailableCollections.length <= 0
      ) {
        this.createCollection({ name: this.collectionName });
        this.collectionName = "";
      }
    },

    handleLoaded() {
      this.loading = false;
      setTimeout(() => {
        this.$refs.video.play();
      }, 100);
    },
    handlePlay() {
      this.play = true;
      this.loading = true;
    },
    handleDownload() {
      this.downloadProduct({
        id: this.id,
        license: this.licenseName,
        type: "download",
      });
    },
  },

  watch: {
    getIsDownloaded: function (nv, ov) {
      if (nv) {
        this.licenseName = "";
        this.isDownload = false;
        this.isPolicy = false;
      }
    },
  },

  data() {
    return {
      play: false,
      showPlay: false,
      loading: false,
      isNew: false,
      isCollection: false,
      collectionName: "",
      isDownload: false,
      licenseName: "",
      activateDownload: false,
      isPolicy: false,
    };
  },
};
</script>

<style scoped>
video::-webkit-media-controls-mute-button {
  display: none !important;
}

video::-webkit-media-controls-volume-slider {
  display: none !important;
}

.image-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
/* Tooltip container */
.tooltip {
  z-index: 20;
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #27272a;
  color: #fafafa;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 50;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  margin-bottom: 10px;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #27272a transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
