<template>
	<div
		class="flex justify-between items-center px-3 py-2 text-gray-300 rounded-lg transition ease-in duration-200 font-medium hover:font-semibold hover:text-white"
		:class="{ 'bg-gray-700': isActive }"
	>
		<span class=" text-sm font-def">{{ title }}</span>
		<span class="text-xs font-def font-semibold text-white">{{ value }}</span>
	</div>
</template>

<script>
export default {
	name: "NavLink",
	props: ["title", "value", "isActive"],
	components: {}
}
</script>
