import { createStore } from "vuex"
import configuration from "./modules/configuration"
import search from "./modules/search"
import auth from "./modules/auth"
import product from "./modules/product"
import contributor from "./modules/contributor"
import collection from "./modules/collection"
import account from "./modules/account"

export default createStore({
	modules: {
		auth,
		configuration,
		search,
		contributor,
		collection,
		product,
		account
	}
})
