<template>
	<h1 class="">{{ getTime }}</h1>
</template>

<script>
export default {
	name: "Time",
	components: {},
	props: ["time", "type"],
	computed: {
		getTime() {
			if (parseInt(this.time) === 0 && this.type === "max") {
				return `0:10`
			} else if (parseInt(this.time) === 0) {
				return `0:00`
			} else if (parseInt(this.time) < 10) {
				return `0:0${this.getDecimal(parseInt(this.time))}`
			} else if (parseInt(this.time) < 60) {
				return `0:${this.getDecimal(parseInt(this.time))}`
			} else if (parseInt(this.time) <= 300) {
				return `${this.getDecimal(parseInt(this.time) / 60)}:${
					Math.round(parseInt(this.time) % 60) < 10
						? `0${this.getDecimal(parseInt(this.time) % 60)}`
						: this.getDecimal(parseInt(this.time) % 60)
				}`
			} else if (this.type === "max") {
				return "Any"
			} else {
				return `5:00`
			}
		}
	},
	methods: {
		getDecimal(num) {
			return parseInt(num.toString().split(".")[0])
		}
	},

	watch: {
		getTime: function(nv, ov) {
			this.$emit("selected", nv)
		}
	}
}
</script>
